import React, { Fragment } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import CardSmall from 'components/cards/CardSmall';
import TextEyebrow from 'components/ui/TextEyebrow';

import { blockRootProps } from 'utils';

import styles from './BlockCardTrioSmall.module.scss';

const renderCard = card => {
    return (
        <div className={styles.cardSmall}>
            <CardSmall {...card} />
        </div>
    );
};

const BlockCardTrioSmall = ({
    __typename,
    anchorId,
    headline,
    eyebrow,
    cards,
    flushTop,
    className,
}) => {
    if (cards?.length !== 3) return null;

    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.blockCardTrioSmall, className, {
                [styles.flushTop]: flushTop,
            })}
        >
            <div className={styles.inner}>
                <div className={styles.eyebrow}>
                    {headline && (
                        <TextEyebrow
                            eyebrow={eyebrow ? eyebrow : ''}
                            headline={headline}
                            theme="display"
                        />
                    )}
                </div>
                <ul className={styles.cards}>
                    {cards?.map((card, i) => {
                        if (i < cards.length - 1) {
                            return (
                                <Fragment key={i}>
                                    <li className={styles.listItem}>
                                        {renderCard(card)}
                                    </li>
                                    <li
                                        className={styles.listItemGap}
                                        role="presentation"
                                    />
                                </Fragment>
                            );
                        }
                        return (
                            <li key={i} className={styles.listItem}>
                                {renderCard(card)}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </section>
    );
};

BlockCardTrioSmall.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    cards: PropTypes.array,
    className: PropTypes.string,
    flushTop: PropTypes.bool,
};

export default BlockCardTrioSmall;
