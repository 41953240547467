import React, { useEffect, useRef } from 'react';

import { easeOut } from 'framer-motion';
import PropTypes from 'prop-types';

import { useAnimation } from 'hooks/useAnimation';

const parsePointsString = points =>
    points.split(' ').map(item => item.split(',').map(parseFloat));

const interpolateValue = (to, from, t) => to + (from - to) * t;

const interpolatePoints = (prevPoints, newPoints) => {
    const prevPointsArray =
        typeof prevPoints === 'string'
            ? parsePointsString(prevPoints)
            : prevPoints;
    const newPointsArray =
        typeof newPoints === 'string'
            ? parsePointsString(newPoints)
            : newPoints;

    return t => {
        const easedTime = easeOut(t);
        return prevPointsArray.map(([fromX, fromY], i) => {
            const [toX, toY] = newPointsArray[i];
            return [
                interpolateValue(fromX, toX, easedTime),
                interpolateValue(fromY, toY, easedTime),
            ];
        });
    };
};

const AnimatedPolyLine = props => {
    const { points } = props;

    const [progress, play] = useAnimation(600);
    const interpolateRef = useRef(interpolatePoints(points, points));

    useEffect(() => {
        interpolateRef.current = interpolatePoints(
            interpolateRef.current(progress), // grab current points based on progress
            points
        );
        play();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [points]);

    return <polyline {...props} points={interpolateRef.current(progress)} />;
};

AnimatedPolyLine.propTypes = {
    points: PropTypes.string.isRequired,
};

export default AnimatedPolyLine;
