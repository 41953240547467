'use client';

import React, { Fragment } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Text from 'components/ui/Text';
import TextEyebrow from 'components/ui/TextEyebrow';

import { blockRootProps } from 'utils';
import FAQBlockJsonLd from 'utils/schema-dts/FAQBlockJsonLd';

import AccordionItem from './AccordionItem';

import styles from './BlockAccordion.module.scss';

const BlockAccordion = ({
    __typename,
    anchorId,
    accordionItems,
    bodyCopy,
    cta,
    eyebrow,
    groups,
    headline,
    theme,
    flushTop,
    flushBottom,
}) => {
    theme ??= 'Default';

    return (
        <div
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.blockAccordionFlex, {
                [styles.themeFAQ]: theme === 'FAQ',
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            {accordionItems && <script {...FAQBlockJsonLd(accordionItems)} />}
            <div className={styles.blockAccordion}>
                <div className={styles.textBlock}>
                    {theme === 'FAQ' ? (
                        <Fragment>
                            {headline && (
                                <div
                                    className={cx(
                                        styles.eyebrow,
                                        styles.headline
                                    )}
                                >
                                    <TextEyebrow
                                        eyebrow={eyebrow}
                                        headline={headline}
                                        theme="display"
                                    />
                                </div>
                            )}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {headline && (
                                <Text
                                    baseTheme="headingMedium"
                                    themes={{ large: 'headingLarge' }}
                                    className={styles.headline}
                                    as="h3"
                                >
                                    {headline}
                                </Text>
                            )}
                            {cta && (
                                <ButtonCTA className={styles.cta} {...cta} />
                            )}
                        </Fragment>
                    )}
                </div>
                {accordionItems && (
                    <div className={styles.accordionItems}>
                        {accordionItems.map((item, id) => {
                            return (
                                <AccordionItem
                                    {...item}
                                    bodyCopy={item.bodyCopy}
                                    key={id}
                                />
                            );
                        })}
                    </div>
                )}
                {groups?.map((groupItem, i) => {
                    return (
                        <Fragment key={i}>
                            {groupItem.itemsCollection.items && (
                                <div className={styles.accordionItems}>
                                    {groupItem.headline && (
                                        <Text
                                            as="div"
                                            className={styles.groupHeadline}
                                            baseTheme="headingSmall"
                                        >
                                            {groupItem.headline}
                                        </Text>
                                    )}
                                    {groupItem.itemsCollection.items.map(
                                        (item, id) => {
                                            return (
                                                <AccordionItem
                                                    {...item}
                                                    bodyCopy={item.bodyCopy}
                                                    key={id}
                                                />
                                            );
                                        }
                                    )}
                                </div>
                            )}
                        </Fragment>
                    );
                })}

                {theme === 'FAQ'
                    ? cta && <ButtonCTA className={styles.cta} {...cta} />
                    : bodyCopy && (
                          <div className={styles.bodyCopyBlock}>
                              <Text
                                  baseTheme="bodyXSmall"
                                  themes={{ large: 'bodyMedium' }}
                                  className={styles.bodyCopy}
                              >
                                  {bodyCopy}
                              </Text>
                          </div>
                      )}
            </div>
        </div>
    );
};

BlockAccordion.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    accordionItems: PropTypes.array,
    bodyCopy: PropTypes.string,
    cta: PropTypes.object,
    eyebrow: PropTypes.string,
    groups: PropTypes.array,
    headline: PropTypes.string,
    theme: PropTypes.oneOf(['Default', 'FAQ']),
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
};

export default BlockAccordion;
