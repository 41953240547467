'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { useTheme } from 'hooks/useTheme';
import { blockRootProps } from 'utils';

import FlavorPairings from '../shared/FlavorPairings';

import styles from './BlockProductPairings.module.scss';

const BlockProductPairings = ({
    __typename,
    anchorId,
    flushBottom,
    flushTop,
    ...props
}) => {
    const [globalTheme] = useTheme();

    return (
        <div
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.root, styles[globalTheme], {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <div className={styles.inner}>
                <FlavorPairings {...props} />
            </div>
        </div>
    );
};

BlockProductPairings.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
};

export default BlockProductPairings;
