import React from 'react';

import { graphRequest } from 'services/contentful';

import { assetFragment } from 'lib/graphql/fragments';

import BlockVideoCarousel from './BlockVideoCarousel';

const query = /* GraphQL */ `
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockVideoCarousel(preview: $preview, id: $id) {
            __typename
            flushTop
            flushBottom
            title
            anchorId
            videosCollection {
                items {
                    ... on VideoPlayerYoutube {
                        headline
                        richTextHeadline {
                            json
                        }
                        richTextHeadlineSize
                        richTextHeadlineIcon
                        eyebrow
                        youtubeVideoId
                        image {
                            ...Asset
                        }
                        imageLarge {
                            ...Asset
                        }
                        videoTextureMp4 {
                            ...Asset
                        }
                        videoTextureWebm {
                            ...Asset
                        }
                        videoTexturePoster {
                            ...Asset
                        }
                    }
                }
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockVideoCarousel },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockVideoCarousel;
}

const BlockVideoCarouselServer = async ({ id }) => {
    const block = await fetchBlock(id);

    return (
        <BlockVideoCarousel {...block} videos={block.videosCollection.items} />
    );
};

export default BlockVideoCarouselServer;
