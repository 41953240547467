'use client';

import React, { createRef, useEffect, useMemo, useState } from 'react';
import { useIntersection, useMeasure } from 'react-use';

import { checkColorContrast } from '@tillamook/brand/lib/colors';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Img from 'components/ui/Img';
import Link from 'components/ui/Link';
import Text from 'components/ui/Text';

import { useTheme } from 'hooks/useTheme';
import { getColor } from 'utils';

import styles from './CardFoodServiceProduct.module.scss';

const CardFoodServiceProduct = ({
    category,
    color,
    displayName,
    eyebrow,
    image,
    slug,
    award,
}) => {
    color ??= 'blue';
    award ??= 'none';

    const isDark = useMemo(() => checkColorContrast(color) < 4.5, [color]);
    const ref = createRef();
    const [theme] = useTheme();
    const [cardRef, { width }] = useMeasure();

    const isTopSeller = award === 'top seller';

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    const [visible, setVisibility] = useState(false);

    useEffect(() => {
        intersection && intersection.isIntersecting
            ? setVisibility(true)
            : setVisibility(false);
    }, [intersection, intersection?.isIntersecting]);

    if (!category) {
        return null;
    }

    return (
        <Link
            as={`/foodservice/products/${category.slug}/${slug}`}
            href={'/foodservice/products/[category]/[product]'}
            className={styles.cardLinkWrap}
        >
            <article
                ref={ref}
                style={{
                    '--card-background-color': getColor(color),
                    '--card-width': `${width}px`,
                }}
                className={cx(styles.card, styles[`theme--${theme}`])}
            >
                {isTopSeller && (
                    <img
                        className={styles.topSeller}
                        src="/images/icons/top-seller.svg"
                        alt=""
                    />
                )}
                <div
                    ref={cardRef}
                    className={cx(styles.cardInner, {
                        [styles[`cardInner--dark-text`]]: isDark, // WCAG Contrast Level AAA large text
                    })}
                >
                    <div className={styles.cardContent}>
                        {image?.url && (
                            <div
                                className={cx(styles.imageContainer, {
                                    [styles[`imageContainer--portrait`]]:
                                        image.height > image.width,
                                })}
                            >
                                <figure
                                    className={cx(styles.image, {
                                        [styles.isVisible]: visible,
                                    })}
                                >
                                    <Img
                                        src={image.url}
                                        fallbackImageWidth={600}
                                        alt={displayName}
                                    />
                                </figure>
                            </div>
                        )}
                        <div className={styles.textGroup}>
                            <Text.Theme
                                config={{
                                    'makers-reserve': {
                                        baseTheme: 'labelSmallAlt',
                                        themes: {
                                            xxLarge: 'labelMediumAlt',
                                        },
                                    },
                                    default: {
                                        baseTheme: 'labelSmall',
                                    },
                                }}
                                className={styles.eyebrow}
                            >
                                {eyebrow}
                            </Text.Theme>
                            <Text.Theme
                                config={{
                                    'makers-reserve': {
                                        baseTheme: 'parkinsonSmall',
                                        themes: {
                                            xxLarge: 'parkinsonMedium',
                                        },
                                    },
                                    default: {
                                        baseTheme: 'bodyLarge',
                                        themes: {
                                            large: 'bodyMedium',
                                            xxLarge: 'bodyLarge',
                                        },
                                    },
                                }}
                                className={styles.cta}
                            >
                                {displayName}
                            </Text.Theme>
                        </div>
                    </div>
                </div>
            </article>
        </Link>
    );
};

CardFoodServiceProduct.propTypes = {
    category: PropTypes.object.isRequired,
    color: PropTypes.string,
    displayName: PropTypes.string,
    eyebrow: PropTypes.string,
    image: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
    }),
    slug: PropTypes.string.isRequired,
    award: PropTypes.oneOf(['none', 'top seller']),
};

export default CardFoodServiceProduct;
