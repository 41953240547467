'use client';

import React, { useEffect } from 'react';

import { m, useAnimate, useMotionValue, useTransform } from 'framer-motion';
import PropTypes from 'prop-types';

const CountUp = ({ startOnMount = true, decimals = 0, ...props }) =>
    startOnMount || props.duration !== 0 ? (
        <Counter {...props} decimals={decimals} />
    ) : (
        <>{props.start.toFixed(decimals)}</>
    );

const Counter = ({
    end = 1,
    duration = 1000,
    delay = 0,
    start,
    decimals,
    easing = 'easeInOut',
}) => {
    const count = useMotionValue(start);

    const [, animate] = useAnimate();

    const value = useTransform(count, latest => latest.toFixed(decimals));

    useEffect(() => {
        animate(count, end, {
            duration: duration / 1000,
            delay: delay / 1000,
            ease: easing,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <m.div>{value}</m.div>;
};

Counter.propTypes = {
    children: PropTypes.any,
    duration: PropTypes.number,
    end: PropTypes.number,
    start: PropTypes.number,
    decimals: PropTypes.number,
    delay: PropTypes.number,
    easing: PropTypes.oneOf([
        'linear',
        'easeIn',
        'easeOut',
        'easeInOut',
        'circIn',
        'circOut',
        'circInOut',
        'backIn',
        'backOut',
        'backInOut',
        'anticipate',
    ]),
};

CountUp.propTypes = {
    children: PropTypes.any,
    decimals: PropTypes.number,
    duration: PropTypes.oneOf([PropTypes.number, PropTypes.bool]),
    start: PropTypes.shape({
        toFixed: PropTypes.func,
    }),
    startOnMount: PropTypes.bool,
};

export default CountUp;
