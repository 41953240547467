'use client';

import React, { useContext, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ButtonCircle from 'components/ui/ButtonCircle';
import { CarouselContext } from 'components/ui/Carousel';
import Img from 'components/ui/Img';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import Text from 'components/ui/Text';
import UltraBoldHeading from 'components/ui/UltraBoldHeading';
import Video from 'components/ui/Video';

import VideoPlayerYTPortal from './VideoPlayerYTPortal';

import styles from './VideoPlayerYT.module.scss';

const textStyleDefault = {
    baseTheme: 'headingMedium2',
    themes: { large: 'displayLarge' },
};

const textStyleEmbeddedEntry = {
    baseTheme: 'headingLarge',
    themes: {
        medium: 'headingMedium',
        large: 'headingLarge',
        xxLarge: 'headingXLarge',
    },
};

const VideoPlayerYT = ({
    className,
    eyebrow,
    headline,
    youtubeVideoId,
    image,
    imageLarge,
    index,
    videoTextureMp4,
    videoTextureWebm,
    videoTexturePoster,
    embeddedEntry,
    heroVideo,
    isActive,
    richTextHeadline,
    richTextHeadlineSize,
    richTextHeadlineColor,
    richTextHeadlineIcon,
    centerPlayButton,
    threshold,
}) => {
    isActive ??= true;
    richTextHeadlineSize ??= 'medium';
    richTextHeadlineColor ??= 'cream';
    richTextHeadlineIcon ??= 'none';
    threshold ??= 1;

    const { activeIndices } = useContext(CarouselContext);

    const [isOpen, setIsOpen] = useState(false);

    const isInCarousel = activeIndices.length !== 0;

    const handlePlay = () => {
        if (activeIndices.includes(index) || !isInCarousel) {
            setIsOpen(true);
        }
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const headlineSizes = {
        small: {
            baseTheme: 'displaySmall',
            themes: {
                medium: 'displayLarge',
                large: 'displayXLarge',
                xxxLarge: 'displayXXLarge',
            },
        },
        medium: {
            baseTheme: 'displayMedium',
            themes: {
                medium: 'displayLarge',
                large: 'displayXLarge',
                xxxLarge: 'displayXXLarge',
            },
        },
    };

    return (
        <>
            <div
                onClick={handlePlay}
                className={cx(styles.videoPlayerYT, className, {
                    [styles.isOpen]: isOpen,
                    [styles.embeddedEntry]: embeddedEntry,
                    [styles.heroVideo]: heroVideo,
                    [styles.isActive]: isActive,
                    [styles.isCentered]: richTextHeadline || centerPlayButton,
                    [styles.centerPlayButton]: centerPlayButton,
                })}
            >
                <div className={styles.background}>
                    {videoTextureMp4?.url && videoTextureWebm?.url ? (
                        <Video
                            className={styles.image}
                            playState={isOpen ? 'pause' : 'play'}
                            poster={videoTexturePoster?.url}
                            threshold={threshold}
                            sources={[
                                videoTextureWebm.url,
                                videoTextureMp4.url,
                            ]}
                        />
                    ) : image?.url && imageLarge?.url ? (
                        <Img
                            className={styles.image}
                            priority={heroVideo}
                            src={image.url}
                            fallbackImageWidth={732}
                            alt={image.description || ''}
                            customSources={[
                                {
                                    breakpoint: 768,
                                    src: imageLarge.url,
                                    imageWidth: 1420,
                                },
                                {
                                    src: image.url,
                                    imageWidth: 732,
                                },
                            ]}
                        />
                    ) : null}
                </div>
                <Stagger className={styles.foreground} threshold={0.2}>
                    {eyebrow && (
                        <StaggerChild order={0} className={styles.eyebrow}>
                            <Text
                                baseTheme="labelSmall"
                                themes={{ large: 'labelLarge' }}
                            >
                                {eyebrow}
                            </Text>
                        </StaggerChild>
                    )}
                    {richTextHeadline ? (
                        <UltraBoldHeading
                            className={styles.headlineSandwich}
                            baseTheme={
                                headlineSizes[richTextHeadlineSize].baseTheme
                            }
                            themes={{
                                medium: headlineSizes[richTextHeadlineSize]
                                    .themes.medium,
                                large: headlineSizes[richTextHeadlineSize]
                                    .themes.large,
                                xxxLarge:
                                    headlineSizes[richTextHeadlineSize].themes
                                        .xxxLarge,
                            }}
                            style={{
                                color: `var(--colors-${richTextHeadlineColor})`,
                            }}
                            icon={
                                richTextHeadlineIcon === 'morningStar'
                                    ? 'morningStar'
                                    : null
                            }
                            headline={richTextHeadline}
                        />
                    ) : (
                        <>
                            {!embeddedEntry && (
                                <StaggerChild
                                    order={1}
                                    className={styles.headline}
                                >
                                    <Text {...textStyleDefault}>
                                        {headline}
                                    </Text>
                                </StaggerChild>
                            )}
                            {embeddedEntry && (
                                <StaggerChild
                                    order={1}
                                    className={styles.headline}
                                >
                                    <Text {...textStyleEmbeddedEntry}>
                                        {headline}
                                    </Text>
                                </StaggerChild>
                            )}
                        </>
                    )}
                    <StaggerChild order={2} className={styles.playVideo}>
                        <ButtonCircle
                            theme={
                                richTextHeadline &&
                                richTextHeadlineColor !== 'cream'
                                    ? 'large-thick-blue'
                                    : 'large-thick-cream'
                            }
                            iconType="playVideo"
                        >
                            <div className="sr-only">
                                Open Video Overlay - {headline}
                            </div>
                        </ButtonCircle>
                    </StaggerChild>
                </Stagger>
            </div>
            <VideoPlayerYTPortal
                headline={headline}
                youtubeVideoId={youtubeVideoId}
                isOpen={isOpen}
                handleClose={handleClose}
            />
        </>
    );
};

VideoPlayerYT.propTypes = {
    className: PropTypes.string,
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    youtubeVideoId: PropTypes.string,
    image: PropTypes.shape({
        description: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
    imageLarge: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
    index: PropTypes.number,
    videoTextureMp4: PropTypes.object,
    videoTextureWebm: PropTypes.object,
    videoTexturePoster: PropTypes.object,
    embeddedEntry: PropTypes.bool,
    heroVideo: PropTypes.bool,
    isActive: PropTypes.bool,
    richTextHeadline: PropTypes.object,
    richTextHeadlineSize: PropTypes.string,
    richTextHeadlineColor: PropTypes.string,
    richTextHeadlineIcon: PropTypes.string,
    centerPlayButton: PropTypes.bool,
    threshold: PropTypes.number,
};

export default VideoPlayerYT;
