export const fragment = /* GraphQL */ `
    fragment CardAnimatedIconFragment on CardAnimatedIcon {
        entryTitle
        headline
        copy {
            json
        }
        icon
        link {
            ...EntryLink
        }
    }
`;
