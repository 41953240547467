import React from 'react';

import cx from 'classnames';
import { m } from 'framer-motion';
import PropTypes from 'prop-types';

import { useTheme } from 'hooks/useTheme';

import styles from './SharpnessBackground.module.scss';

// Framer Motion has a bug with animating css var colors
// https://github.com/framer/motion/issues/546

const spring = {
    type: 'spring',
    damping: 60,
    stiffness: 160,
};

const SharpnessBackground = ({ activeProduct }) => {
    const [globalTheme] = useTheme();

    return (
        <m.div
            className={cx(styles.root, styles[`theme--${globalTheme}`])}
            animate={{
                backgroundColor: activeProduct?.backgroundColor || '',
            }}
            transition={spring}
        />
    );
};

SharpnessBackground.propTypes = {
    activeProduct: PropTypes.object,
};

export default SharpnessBackground;
