'use client';

import React, { useContext, useRef, useState } from 'react';
import { useDebounce, useWindowSize } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ButtonCircle from 'components/ui/ButtonCircle';
import carouselContext from 'components/ui/CarouselProvider/CarouselContext';
import { MODE } from 'components/ui/CarouselProvider/carouselConstants';
import Text from 'components/ui/Text';

import Card from './Card';
import Slide from './Slide';

import styles from './BlockCarouselShowcaseWithCard.module.scss';

const { PAUSED, PLAYING, STOPPED } = MODE;

const CarouselShowcaseWithCard = ({ flushTop, flushBottom, navTheme }) => {
    const cc = useContext(carouselContext);
    const [dir, setDir] = useState('');
    const [cardHeight, setCardHeight] = useState(0);

    const isSlideShow = cc.slides?.length > 1;

    const cardsRef = useRef(null);

    const { width } = useWindowSize();

    useDebounce(
        () => {
            if (!cardsRef.current) return;

            const cards = cardsRef?.current?.children;

            const cardHeights = [...cards]?.map(slide => {
                return slide.getBoundingClientRect().height;
            });

            setCardHeight(Math.max(...cardHeights));
        },
        100,
        [width]
    );

    const handleNextSlide = () => {
        setDir('next');
        cc.nextSlide();
    };

    const handlePrevSlide = () => {
        setDir('prev');
        cc.prevSlide();
    };

    return (
        <section
            className={cx(styles.root, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.isContinuous]: cc.isContinuous,
                [styles[PLAYING]]: cc.mode === PLAYING,
                [styles[PAUSED]]: cc.mode === PAUSED,
                [styles.isSwitching]: cc.isContinuous
                    ? cc.seconds === 0 && !cc.whileInterrupt
                    : cc.seconds === 0 && cc.mode !== STOPPED,
            })}
        >
            <div className={styles.inner}>
                <div className={styles.slides}>
                    {cc.slides?.map((slide, i) => (
                        <Slide
                            className={styles.slide}
                            key={i}
                            {...slide}
                            isActive={cc.activeIndex === i}
                            isPrev={cc.prevIndex === i}
                            timerDuration={cc.timerDuration}
                            isSlideShow={isSlideShow}
                        />
                    ))}
                </div>
                <div className={styles.cards}>
                    <div
                        className={styles.cardWrapper}
                        style={{ '--cards-height': `${cardHeight}px` }}
                        ref={cardsRef}
                    >
                        {cc.slides?.map((slide, i) => (
                            <Card
                                key={i}
                                {...slide}
                                dirNext={dir === 'next'}
                                isActive={cc.activeIndex === i}
                                isPrev={cc.prevIndex === i}
                                timerDuration={cc.timerDuration}
                            />
                        ))}
                    </div>
                    {isSlideShow && (
                        <div
                            className={cx(styles.navWrapper, {
                                [styles['navWrapper-cream']]:
                                    navTheme === 'white',
                            })}
                        >
                            <div className={styles.nav}>
                                <ButtonCircle
                                    onClick={handlePrevSlide}
                                    className={styles.navItem}
                                    theme={
                                        navTheme === 'blue'
                                            ? 'small-fill-blue'
                                            : 'small-fill-cream'
                                    }
                                    iconType="chevronLeft"
                                >
                                    <span className="sr-only">
                                        Previous Slide
                                    </span>
                                </ButtonCircle>
                                <Text
                                    baseTheme="labelSmall"
                                    className={styles.navCount}
                                >
                                    {`${cc.activeIndex + 1}/${cc.totalSlides}`}
                                </Text>
                                <ButtonCircle
                                    onClick={handleNextSlide}
                                    className={styles.navItem}
                                    theme={
                                        navTheme === 'blue'
                                            ? 'small-fill-blue'
                                            : 'small-fill-cream'
                                    }
                                    iconType="chevronRight"
                                >
                                    <span className="sr-only">Next Slide</span>
                                </ButtonCircle>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

CarouselShowcaseWithCard.propTypes = {
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    navTheme: PropTypes.string,
};

export default CarouselShowcaseWithCard;
