export const fragment = /* GraphQL */ `
    fragment CardLatestFragment on CardLatest {
        eyebrow
        headline
        cta {
            ...CTA
        }
        image {
            ...Asset
        }
        textColor
    }
`;
