'use client';

import { useSearchParams } from 'next/navigation';
import querystate from 'querystate';

function useQueryState(defaultState) {
    const params = useSearchParams();
    let state;
    if (typeof window === 'undefined') {
        const urlSearchParams = new URLSearchParams(defaultState);
        state = querystate(`?${urlSearchParams.toString()}`, {
            autoApply: false,
        });
    } else {
        state = querystate(`?${params.toString()}`, {
            autoApply: false,
        });
    }

    function setObject(obj) {
        state.set(obj);
        Object.keys(state.all()).forEach(key => {
            if (!obj[key]) {
                state.remove(key);
            }
        });
        state.apply();
    }

    return [
        typeof window === 'undefined' ? defaultState : state.all(),
        setObject,
    ];
}

export default useQueryState;
