import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    iconFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockSubNavCardGroup from './BlockSubNavCardGroup';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    ${iconFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockSubNavCardGroup(preview: $preview, id: $id) {
            __typename
            sys {
                id
            }
            sectionsCollection(limit: 30) {
                items {
                    title
                    icon {
                        ... on Icon {
                            ...Icon
                        }
                    }
                    copy {
                        json
                    }
                    cardsCollection(limit: 30) {
                        items {
                            image {
                                ...Asset
                            }
                            imageLarge {
                                ...Asset
                            }
                            cta {
                                ... on Cta {
                                    ...CTA
                                }
                            }
                        }
                    }
                }
            }
            footnote {
                json
            }
            legend {
                headline
                legendItemsCollection(limit: 5) {
                    items {
                        __typename
                        sys {
                            id
                        }
                        ... on BlockSubNavCardGroupLegendItem {
                            text
                            colorHex
                            shape
                        }
                    }
                }
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockSubNavCardGroup },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockSubNavCardGroup;
}

const BlockSubNavCardGroupServer = async ({ id }) => {
    const block = await fetchBlock(id);

    const sections = block?.sectionsCollection?.items;

    if (!sections) {
        console.warn('BlockSubNavCardGroup: No sections found');
        return;
    }

    return <BlockSubNavCardGroup {...block} sections={sections} />;
};

export default BlockSubNavCardGroupServer;
