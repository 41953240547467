import React from 'react';

import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import styles from './PlantCodePicker.module.scss';

const DEFAULT = 'DEFAULT';

const PlantCodePicker = ({ register, watch, fieldName, options = [] }) => {
    watch(fieldName);

    return (
        <div className={styles.root}>
            <Text baseTheme="headingXSmall">
                <select
                    {...register(fieldName)}
                    className={styles.select}
                    title="Select the product code"
                    aria-label="Select the product code"
                    defaultValue={DEFAULT}
                >
                    <option value={DEFAULT} disabled hidden>
                        Select one
                    </option>
                    {options.map(plantCode => (
                        <option value={plantCode} key={plantCode}>
                            {plantCode}
                        </option>
                    ))}
                </select>
            </Text>
        </div>
    );
};

PlantCodePicker.propTypes = {
    register: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    fieldName: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
};

export default PlantCodePicker;
