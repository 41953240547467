import React from 'react';

import PropTypes from 'prop-types';

import ButtonCircle from 'components/ui/ButtonCircle';
import Img from 'components/ui/Img';
import EntryLink from 'components/ui/Link/EntryLink';
import Text from 'components/ui/Text';

import { getColor } from 'utils';

import styles from './CardCampaign.module.scss';

const CardCampaign = ({
    headline,
    description,
    image,
    bgColor,
    color,
    chevronTheme,
    link,
}) => {
    bgColor ??= 'orange';
    color ??= 'cream';
    chevronTheme ??= 'cream';

    return (
        <EntryLink entry={link}>
            <article
                className={styles.campaign}
                style={{
                    backgroundColor: getColor(bgColor),
                    color: getColor(color),
                }}
            >
                <div className={styles.imageContainer}>
                    <Img
                        className={styles.image}
                        src={image.url}
                        fallbackImageWidth={250}
                        alt={image.description || ''}
                        customSources={[
                            {
                                src: image.url,
                                imageWidth: 250,
                            },
                        ]}
                    />
                </div>
                <Text
                    baseTheme="displayXSmall"
                    themes={{ large: 'displaySmall' }}
                    className={styles.headline}
                >
                    {headline}
                </Text>
                <Text
                    className={styles.description}
                    baseTheme="bodyMedium"
                    themes={{ xLarge: 'bodyLarge' }}
                >
                    {description}
                </Text>
                <ButtonCircle
                    theme={
                        chevronTheme === 'cream'
                            ? 'small-fill-creamdark'
                            : 'small-fill-blue'
                    }
                    iconType="chevronRight"
                />
            </article>
        </EntryLink>
    );
};

CardCampaign.propTypes = {
    headline: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.shape({
        description: PropTypes.string,
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
    }).isRequired,
    link: PropTypes.object.isRequired,
    bgColor: PropTypes.string,
    color: PropTypes.string,
    chevronTheme: PropTypes.oneOf(['cream', 'blue']),
};

export default CardCampaign;
