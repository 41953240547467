import React from 'react';

import { graphRequest } from 'services/contentful';

import { assetFragment } from 'lib/graphql/fragments';

import BlockTimeline from './BlockTimeline';

const query = /* GraphQL */ `
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockTimeline(preview: $preview, id: $id) {
            __typename
            anchorId
            headline
            eyebrow
            flushTop
            itemsCollection {
                items {
                    headline
                    copy {
                        json
                    }
                    year
                    image {
                        ...Asset
                    }
                    imageLarge {
                        ...Asset
                    }
                }
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockTimeline },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockTimeline;
}

const BlockTimelineServer = async ({ id }) => {
    const block = await fetchBlock(id);
    return <BlockTimeline items={block.itemsCollection.items} {...block} />;
};

export default BlockTimelineServer;
