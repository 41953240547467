import React from 'react';

import { graphRequest } from 'services/contentful';

import { assetFragment } from 'lib/graphql/fragments';

import BlockProductPairings from './BlockProductPairings';
import PreviewBlockProductPairings from './PreviewBlockProductPairings';

const query = /* GraphQL */ `
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockProductPairings(preview: $preview, id: $id) {
            __typename
            anchorId
            title
            flushTop
            flushBottom
            eyebrow
            heading {
                json
            }
            copy {
                json
            }
            pairings
            portraitImage {
                ...Asset
            }
            landscapeImage {
                ...Asset
            }
            anchorId
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockProductPairings },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockProductPairings;
}

const BlockProductPairingsServer = async ({ id, preview }) => {
    const block = await fetchBlock(id);

    return preview ? (
        <PreviewBlockProductPairings {...block} />
    ) : (
        <BlockProductPairings {...block} />
    );
};

export default BlockProductPairingsServer;
