'use client';

import React from 'react';

import { useContentfulLiveUpdates } from '@contentful/live-preview/react';

import BlockIssueBriefGoals from './BlockIssueBriefGoals';

const Preview = props => {
    const updatedProps = useContentfulLiveUpdates(props);
    return <BlockIssueBriefGoals {...updatedProps} />;
};

export default Preview;
