import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button';
import ButtonCTA from 'components/ui/ButtonCTA';
import Icon from 'components/ui/Icon';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import Text from 'components/ui/Text';

import styles from './TextLockup.module.scss';

const TextLockup = ({
    eyebrow,
    headline,
    richHeadline,
    bodyCopy,
    cta,
    ctaSecondary,
    className = null,
    theme,
    icon,
}) => {
    theme ??= 'Default';

    return (
        <Stagger
            className={cx(styles.textLockup, className, {
                [styles.hasRichHeadline]: richHeadline,
            })}
        >
            {icon?.icon?.url && (
                <StaggerChild order={0}>
                    <Icon src={icon?.icon?.url} contained />
                </StaggerChild>
            )}
            {eyebrow && (
                <StaggerChild order={0} className={styles.eyebrow}>
                    <Text
                        baseTheme="labelSmall"
                        themes={{
                            xLarge: 'labelLarge',
                        }}
                    >
                        {eyebrow}
                    </Text>
                </StaggerChild>
            )}
            {headline && !richHeadline && (
                <StaggerChild order={1} className={styles.headline}>
                    <Text
                        baseTheme="displayXSmall"
                        themes={{
                            large: 'displayMedium',
                            xxLarge: 'displayLarge',
                        }}
                    >
                        {headline}
                    </Text>
                </StaggerChild>
            )}
            {richHeadline?.content && (
                <StaggerChild order={1} className={styles.headline}>
                    <Text
                        baseTheme="displayXSmall"
                        themes={{
                            large: 'displayMedium',
                            xLarge: 'displayLarge',
                        }}
                    >
                        {richHeadline?.json.content.map((line, i) => {
                            if (line.content[0].value === '') {
                                return null;
                            }
                            return (
                                <span className={styles.line} key={i}>
                                    {line.content[0].value}
                                </span>
                            );
                        })}
                    </Text>
                </StaggerChild>
            )}
            {bodyCopy && (
                <StaggerChild order={2} className={styles.copy}>
                    <Text
                        baseTheme="bodySmall"
                        themes={{
                            xLarge: 'bodyMedium',
                        }}
                    >
                        {bodyCopy}
                    </Text>
                </StaggerChild>
            )}

            {cta?.text && cta?.link && !ctaSecondary && (
                <StaggerChild order={3} className={styles.buttonWrapper}>
                    <Button
                        className={styles.cta}
                        theme={
                            theme !== 'Default'
                                ? 'outlinedCream'
                                : 'outlinedBlue'
                        }
                        entry={cta.link}
                    >
                        {cta.text}
                    </Button>
                </StaggerChild>
            )}
            {(ctaSecondary?.link || ctaSecondary?.assetDownloadLink) && (
                <StaggerChild order={3} className={styles.buttonWrapper}>
                    <ButtonCTA {...ctaSecondary} />
                </StaggerChild>
            )}
        </Stagger>
    );
};

TextLockup.propTypes = {
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    richHeadline: PropTypes.object,
    bodyCopy: PropTypes.string,
    cta: PropTypes.object,
    ctaSecondary: PropTypes.object,
    className: PropTypes.string,
    theme: PropTypes.oneOf([
        'Default',
        'Holiday Blue',
        'Special Holiday Blue',
        'Special Holiday Rust',
        'Special Holiday Squash',
    ]),
    icon: PropTypes.object,
};

export default TextLockup;
