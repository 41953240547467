import { jsonLdScriptProps } from 'react-schemaorg';

import { richTextToHtml } from './utils';

const richTextToString = ({ json: richText }) => {
    // https://developers.google.com/search/docs/advanced/structured-data/faqpage#answer
    // Google Search displays the following HTML tags; all other tags are ignored:
    // <h1> through <h6>, <br>, <ol>, <ul>, <li>, <a>, <p>, <div>, <b>, <strong>, <i>, and <em>.
    let html;

    try {
        html = richTextToHtml(richText);
    } catch (err) {
        console.error(html);

        html =
            richText?.content?.[0]?.content?.[0]?.value ||
            richText?.['en-US']?.content?.[0]?.content?.[0]?.value;
    }

    return html;
};

const toJsonLd = faq => {
    return {
        '@type': 'Question',
        name: faq.headline,
        acceptedAnswer: {
            '@type': 'Answer',
            text: richTextToString(faq.bodyCopy),
        },
    };
};

const FAQPageJsonLd = items => {
    const jsonLd = {
        ...{ '@context': 'https://schema.org', '@type': 'FAQPage' },
        mainEntity: items
            .filter(item => item?.headline)
            .map(faq => toJsonLd(faq)),
    };

    return jsonLdScriptProps(jsonLd);
};

export default FAQPageJsonLd;
