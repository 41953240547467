import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import Img from 'components/ui/Img';
import EntryLink from 'components/ui/Link/EntryLink';
import RichText from 'components/ui/RichText/Server';
import Text from 'components/ui/Text';

import { getColor } from 'utils';

import styles from './CardLatest.module.scss';

const richTextOverrides = {
    renderNode: {
        [BLOCKS.HEADING_3]: (node, children) => {
            return (
                <Text
                    as="h3"
                    className={styles.splitHeadline}
                    baseTheme="displayMedium"
                    themes={{ large: 'displayLargeFluid' }}
                >
                    {children}
                </Text>
            );
        },
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return <span>{children}</span>;
        },
    },
};

const CardLatest = ({
    eyebrow,
    headline,
    splitHeadline,
    textColor,
    image,
    cta,
}) => {
    textColor ??= 'blue';

    return (
        <EntryLink className={styles.cardLinkWrap} entry={cta?.link}>
            <article
                className={cx(styles.cardLatest, {
                    [styles.withBackground]: splitHeadline,
                })}
                style={{ color: getColor(textColor) }}
            >
                <div className={styles.imageContainer}>
                    {image?.url && (
                        <Img
                            className={styles.image}
                            src={image.url}
                            fallbackImageWidth={1024}
                            alt={image.description || ''}
                            width={image?.width}
                            height={image?.height}
                            customSources={[
                                {
                                    breakpoint: 1920,
                                    src: image.url,
                                    imageWidth: 1920 * 0.5,
                                },
                                {
                                    breakpoint: 1440,
                                    src: image.url,
                                    imageWidth: 1440 * 0.5,
                                },
                                {
                                    breakpoint: 1024,
                                    src: image.url,
                                    imageWidth: 1024 * 0.5,
                                },
                                {
                                    breakpoint: 768,
                                    src: image.url,
                                    imageWidth: 768 * 0.75,
                                },
                                {
                                    src: image.url,
                                    imageWidth: 768,
                                },
                            ]}
                        />
                    )}
                </div>
                <div className={styles.inner}>
                    {splitHeadline && (
                        <RichText
                            richText={splitHeadline}
                            overrides={richTextOverrides}
                        />
                    )}
                    {eyebrow && (
                        <Text
                            baseTheme="labelSmall"
                            themes={{ xxLarge: 'labelLarge' }}
                            className={styles.eyebrow}
                            as="h5"
                        >
                            {eyebrow}
                        </Text>
                    )}
                    {headline && (
                        <Text
                            className={styles.headline}
                            baseTheme="bodyMedium"
                            themes={{
                                large: 'bodyMediumLarge',
                                xxLarge: 'bodyLarge',
                            }}
                            as="p"
                        >
                            {headline}
                        </Text>
                    )}
                </div>
            </article>
        </EntryLink>
    );
};

CardLatest.propTypes = {
    cta: PropTypes.object,
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    image: PropTypes.shape({
        description: PropTypes.string,
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
    }),
    splitHeadline: PropTypes.object,
    textColor: PropTypes.string,
};

export default CardLatest;
