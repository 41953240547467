import React from 'react';

import { graphRequest } from 'services/contentful';

import { assetFragment } from 'lib/graphql/fragments';

import BlockCommitmentChart from './BlockCommitmentChart';

const query = /* GraphQL */ `
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockCommitmentChart(preview: $preview, id: $id) {
            __typename
            anchorId
            headline
            bodyCopy
            image {
                ...Asset
            }
            imageLarge {
                ...Asset
            }
            theme
            chartHeadline
            chartCopy
            standardsList
            anchorId
            flushTop
            flushBottom
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockCommitmentChart },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockCommitmentChart;
}

const BlockCommitmentChartServer = async ({ id }) => {
    const block = await fetchBlock(id);

    return <BlockCommitmentChart {...block} />;
};

export default BlockCommitmentChartServer;
