import React from 'react';

import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Icon from 'components/ui/Icon';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';

import styles from './Description.module.scss';

const headlineOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="h3"
                    baseTheme="displaySmall"
                    themes={{ large: 'displayMedium' }}
                >
                    {children}
                </Text>
            );
        },
    },
};

const bodyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    baseTheme="bodySmall"
                    themes={{ large: 'bodyMedium' }}
                >
                    {children}
                </Text>
            );
        },
    },
};

const Description = ({ headline, svgIcon, eyebrow, bodyCopy, cta }) => {
    return (
        <div className={styles.description}>
            {svgIcon?.icon?.url && (
                <Icon
                    className={styles.icon}
                    role="presentation"
                    src={svgIcon?.icon?.url}
                />
            )}
            {eyebrow && (
                <div className={styles.eyebrow}>
                    <Text as="p" baseTheme="labelLarge">
                        {eyebrow}
                    </Text>
                </div>
            )}
            {headline && (
                <div className={styles.headline}>
                    <RichText
                        richText={headline}
                        overrides={headlineOverrides}
                    />
                </div>
            )}
            {bodyCopy && (
                <div className={styles.bodyCopy}>
                    <RichText richText={bodyCopy} overrides={bodyOverrides} />
                </div>
            )}
            {cta && <ButtonCTA className={styles.cta} {...cta} />}
        </div>
    );
};

Description.propTypes = {
    eyebrow: PropTypes.string,
    headline: PropTypes.object.isRequired,
    svgIcon: PropTypes.object,
    bodyCopy: PropTypes.object.isRequired,
    cta: PropTypes.object.isRequired,
};

export default Description;
