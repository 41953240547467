import React from 'react';

import { assetFragment } from '/lib/graphql/fragments';

import { graphRequest } from 'services/contentful';

import BlockCardGroup from 'components/blocks/BlockCardGroup/BlockCardGroup';
import { fragment as cardIconStatFragment } from 'components/cards/CardIconStat';

const query = /* GraphQL */ `
    ${assetFragment}
    ${cardIconStatFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockCardGroupSimple(preview: $preview, id: $id) {
            __typename
            anchorId
            flushTop
            cardsCollection(limit: 20) {
                items {
                    __typename
                    ... on CardIconStat {
                        ...CardIconStatFragment
                    }
                }
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockCardGroupSimple },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockCardGroupSimple;
}

const BlockCardGroupSimpleServer = async ({ id }) => {
    const block = await fetchBlock(id);
    const cards = block.cardsCollection.items;
    return <BlockCardGroup {...block} cards={cards} />;
};

export default BlockCardGroupSimpleServer;
