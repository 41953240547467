import React from 'react';

import { graphRequest } from 'services/contentful';

import { assetFragment } from 'lib/graphql/fragments';

import BlockHero from './BlockHero';

const query = /* GraphQL */ `
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockHero(preview: $preview, id: $id) {
            __typename
            anchorId
            eyebrow
            richHeadline {
                json
            }
            richTagline {
                json
            }
            image {
                ...Asset
            }
            imageLarge {
                ...Asset
            }
            videoTextureWebM {
                ...Asset
            }
            videoTextureMp4 {
                ...Asset
            }
            videoTextureLargeWebM {
                ...Asset
            }
            videoTextureLargeMp4 {
                ...Asset
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockHero },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockHero;
}

const BlockHeroServer = async ({ id }) => {
    const block = await fetchBlock(id);

    return (
        <BlockHero
            {...block}
            richTagline={block.richTagline}
            richHeadline={block.richHeadline}
        />
    );
};

export default BlockHeroServer;
