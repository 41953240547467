export const fragment = /* GraphQL */ `
    fragment CardPolaroidFragment on CardPolaroid {
        date
        headline
        headline
        copy {
            json
        }
        cta {
            ...CTA
        }
        image {
            ...Asset
        }
    }
`;
