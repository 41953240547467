import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Carousel from 'components/ui/Carousel';

import styles from './CardGroupCarousel.module.scss';

const CardGroupCarousel = ({
    cardMap,
    cards,
    theme,
    className,
    navigationTheme,
    ...rest
}) => {
    theme ??= 'Latest';

    const cleanCards = cards.filter(card => {
        const isRenderable =
            (cardMap[card?.__typename || 'Default'] || cardMap['Default']) !==
            null;

        if (!isRenderable && card?.__typename) {
            console.warn(
                `Card is not renderable __typename: ${card?.__typename}`
            );
        }

        return isRenderable;
    });

    const renderCards = () => {
        return cleanCards.map((card, idx) => {
            const Card =
                cardMap[card.__typename || 'Default'] || cardMap['Default'];

            if (Card == null) {
                console.warn(`Card Missing or Drafted`);
                return null;
            }

            const cardProps =
                theme === 'Recipe Products'
                    ? { ...card, headline: card.displayName || card.headline }
                    : { ...card, bgColor: card.backgroundColor };

            return (
                <div
                    key={idx}
                    className={cx(styles.item, {
                        [styles.cardIconStat]:
                            card.__typename === 'CardIconStat',
                        [styles.cardSmall]: card.__typename === 'CardSmall',
                    })}
                >
                    <div className={styles.inner}>
                        <Card
                            {...cardProps}
                            theme={theme}
                            index={idx}
                            {...rest}
                        />
                    </div>
                </div>
            );
        });
    };

    return (
        <div
            className={cx(styles.root, className, {
                [styles.threeUpFull]: theme === 'Campaign',
                [styles.threeUpContained]:
                    cleanCards.length < 4 && theme !== 'Campaign',
                [styles.centered]:
                    cleanCards.length < 3 ||
                    (theme === 'Recipe Products' && cleanCards.length === 1),
                [styles.inlineIntro]: theme === 'Inline Intro',
                [styles.recipes]: theme === 'Recipes',
                [styles.products]: theme === 'Products',
                [styles.recipeProducts]: theme === 'Recipe Products',
                [styles.blockJams]: theme === 'BlockJams',
                [styles.holiday]: theme === 'Holiday',
            })}
        >
            <Carousel
                className={styles.carousel}
                navigationTheme={navigationTheme}
                slideClassName={styles.slide}
                autoScroll={false}
                inlineIntro={theme === 'Inline Intro'}
                hideScrollBars
            >
                {renderCards()}
            </Carousel>
        </div>
    );
};

CardGroupCarousel.propTypes = {
    className: PropTypes.string,
    cards: PropTypes.array.isRequired,
    cardMap: PropTypes.object,
    theme: PropTypes.string,
    navigationTheme: PropTypes.oneOf(['blue', 'cream', 'orange']),
};

export default CardGroupCarousel;
