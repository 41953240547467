import React from 'react';

import { graphRequest } from 'services/contentful';

import { fragment as cardProductFragment } from 'components/cards/CardProduct/fragment';
import { fragment as cardSimpleFragment } from 'components/cards/CardSimple/fragment';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockSimpleCards from './BlockSimpleCards';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    ${cardProductFragment}
    ${cardSimpleFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockSimpleCards(preview: $preview, id: $id) {
            __typename
            anchorId
            title
            eyebrow
            headline
            cardsCollection(limit: 20) {
                items {
                    __typename
                    ... on Product {
                        ...CardProductFragment
                    }
                    ... on CardSimple {
                        ...CardSimpleFragment
                    }
                }
            }
            cta {
                ...CTA
            }
            flushTop
            flushBottom
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockSimpleCards },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockSimpleCards;
}

const BlockSimpleCardsServer = async ({ id }) => {
    const block = await fetchBlock(id);
    const cards = block.cardsCollection.items;

    return <BlockSimpleCards {...block} cards={cards} />;
};

export default BlockSimpleCardsServer;
