import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import AgeBug from '../AgeBug';

import styles from './SharpnessAgeBugs.module.scss';

const SharpnessAgeBugs = ({ items, activeProduct }) => {
    return (
        <div className={styles.root}>
            {items?.map((item, i) => {
                return (
                    <div
                        key={i}
                        className={cx(styles.inner, {
                            [styles.isActive]:
                                activeProduct?.sys.id === item.sys.id,
                        })}
                    >
                        <span>
                            <AgeBug
                                duration={item.agedTime}
                                agedUnit={item.agedUnit}
                            />
                        </span>
                    </div>
                );
            })}
        </div>
    );
};

SharpnessAgeBugs.propTypes = {
    items: PropTypes.array,
    activeProduct: PropTypes.object,
};

export default SharpnessAgeBugs;
