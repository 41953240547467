import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Link from 'components/ui/Link';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import styles from './CardLocation.module.scss';

const logoMap = {
    Market: 'market',
    Creamery: 'creamery',
};

const CardLocation = ({
    location,
    logo,
    address,
    linkToGoogleMaps,
    linkToReservation,
    hours,
    descriptionLine1,
    descriptionLine2,
    descriptionLine3,
    linkToLearnMore,
    linkToMenu,
    contactName,
    contactEmail,
    contactNumber,
}) => {
    return (
        <Stagger
            className={cx(styles.locationCard, {
                [styles.market]: logoMap[location] === 'market',
            })}
        >
            {location && !logo && (
                <StaggerChild order={0} className={styles.logoWrapper}>
                    <SvgIcon type={logoMap[location]} />
                </StaggerChild>
            )}
            {logo?.url && (
                <StaggerChild order={0} className={styles.logoWrapper}>
                    <img src={logo.url} alt="" />
                </StaggerChild>
            )}
            {address && linkToGoogleMaps && (
                <StaggerChild order={1} className={styles.address}>
                    <Link href={linkToGoogleMaps}>
                        <Text
                            baseTheme="bodySmall"
                            themes={{ large: 'bodyMedium' }}
                        >
                            {address}
                        </Text>
                    </Link>
                </StaggerChild>
            )}
            {linkToReservation && (
                <StaggerChild order={2}>
                    <ButtonCTA
                        className={styles.reservationButton}
                        {...linkToReservation}
                        text="Make A Reservation"
                        style="outlined-blue-blue"
                    />
                </StaggerChild>
            )}
            <div className={styles.hoursWrapper}>
                {hours && (
                    <StaggerChild order={2}>
                        <Text
                            className={styles.hoursLabel}
                            baseTheme="labelLarge"
                        >
                            Hours
                        </Text>
                    </StaggerChild>
                )}
                <StaggerChild order={2}>
                    <ul className={styles.hoursList}>
                        {hours &&
                            hours.map(({ days = '', hours = '' }, idx) => (
                                <li key={idx}>
                                    <div className={styles.timeSpan}>
                                        <Text baseTheme="headingXSmall">
                                            {days}
                                        </Text>
                                        <Text baseTheme="headingXSmall">
                                            {hours?.replace(/\|/g, '\n')}
                                        </Text>
                                    </div>
                                </li>
                            ))}
                    </ul>
                </StaggerChild>
                <StaggerChild order={2}>
                    <Text baseTheme="bodyXSmall">
                        {descriptionLine1 && descriptionLine1}
                        {descriptionLine2 && (
                            <>
                                <br />
                                {descriptionLine2}
                            </>
                        )}
                        {descriptionLine3 && (
                            <>
                                <br />
                                {descriptionLine3}
                            </>
                        )}
                    </Text>
                </StaggerChild>
            </div>
            <StaggerChild order={2} className={styles.contactWrapper}>
                {(contactName || contactNumber) && (
                    <Text
                        className={styles.contactLabel}
                        baseTheme="labelLarge"
                    >
                        Contact
                    </Text>
                )}

                {contactName && (
                    <Text
                        className={styles.contactName}
                        baseTheme="headingXSmall"
                    >
                        {contactName}
                    </Text>
                )}

                {contactEmail && (
                    <Link
                        className={styles.contactEmail}
                        href={`mailto:${contactEmail}`}
                    >
                        <Text baseTheme="headingXSmall">{contactEmail}</Text>
                    </Link>
                )}
                {contactNumber && (
                    <Link
                        className={styles.contactNumber}
                        href={`tel:${contactNumber}`}
                    >
                        <Text baseTheme="headingXSmall">{contactNumber}</Text>
                    </Link>
                )}
            </StaggerChild>
            <StaggerChild order={2}>
                <div className={styles.buttonWrapper}>
                    <ButtonCTA
                        className={styles.learnMoreLink}
                        link={linkToLearnMore}
                        text="Learn more"
                        style="filled-blue-ocean"
                    />
                    {linkToMenu?.assetDownloadLink && (
                        <ButtonCTA
                            className={styles.menuButton}
                            {...linkToMenu}
                        />
                    )}
                </div>
            </StaggerChild>
        </Stagger>
    );
};

CardLocation.propTypes = {
    address: PropTypes.string,
    contactEmail: PropTypes.string,
    contactName: PropTypes.string,
    contactNumber: PropTypes.string,
    descriptionLine1: PropTypes.string,
    descriptionLine2: PropTypes.string,
    descriptionLine3: PropTypes.string,
    hours: PropTypes.array,
    linkToGoogleMaps: PropTypes.string,
    linkToLearnMore: PropTypes.object,
    linkToMenu: PropTypes.object,
    linkToReservation: PropTypes.string,
    location: PropTypes.oneOf(['Market', 'Creamery']),
    logo: PropTypes.object,
};

export default CardLocation;
