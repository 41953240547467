import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './RichTextWordJalousie.module.scss';

const RichTextWordJalousie = ({
    richTextObject,
    reveal = false,
    className,
}) => {
    function getWords(paragraph) {
        if (Array.isArray(paragraph?.content)) {
            const marks = paragraph.content[0]?.marks;
            return paragraph.content[0]?.value
                ?.split(' ')
                .filter(word => Boolean(word))
                .map((word, i) => (
                    <span
                        key={i}
                        className={cx(
                            styles.word,
                            marks.map(m => styles[m.type])
                        )}
                    >
                        <span className={styles.wordInner}>{word}</span>
                    </span>
                ));
        }
        return null;
    }

    return (
        <div className={cx(className, { [styles.reveal]: reveal })}>
            {richTextObject?.json?.content.map((paragraph, i) => (
                <p key={i} className={styles.paragraph}>
                    {i > 0 ? ' ' : ''}
                    {getWords(paragraph)}
                </p>
            ))}
        </div>
    );
};

RichTextWordJalousie.propTypes = {
    richTextObject: PropTypes.object,
    reveal: PropTypes.bool,
    className: PropTypes.string,
};

export default RichTextWordJalousie;
