import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import Icon from 'components/ui/Icon';
import RichText from 'components/ui/RichText';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import TouchSwipe from 'utils/TouchSwipe';

import styles from './CardSwipe.module.scss';

const iconMap = {
    Tree: 'treeRule',
    Anchor: 'anchorRule',
    Cow: 'cow',
    Loaf: 'loaf',
    People: 'people',
    Trophy: 'trophy',
    Heart: 'heart',
};

const CardSwipe = ({
    eyebrow,
    copy,
    icon,
    icon2,
    className,
    isActive,
    isPrev,
    dirNext,
    onClick,
    onTap,
    onSwipedLeft,
    onSwipedRight,
}) => {
    const richTextOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text
                        as="p"
                        className={styles.paragraph}
                        baseTheme="bodyMedium"
                        themes={{ medium: 'bodyXLarge' }}
                    >
                        {children}
                    </Text>
                );
            },
        },
    };
    return (
        <article
            className={cx(styles.cardSwipe, className, {
                [styles.isActive]: isActive,
                [styles.isPrev]: isPrev,
                [styles.dirNext]: dirNext,
            })}
            onClick={onClick}
        >
            <TouchSwipe
                className={styles.swiper}
                onTap={onTap}
                onSwipedLeft={onSwipedLeft}
                onSwipedRight={onSwipedRight}
            >
                <div className={styles.inner}>
                    {eyebrow && (
                        <Text
                            className={styles.eyebrow}
                            baseTheme="labelMedium"
                            themes={{ medium: 'labelLarge' }}
                            as="h3"
                        >
                            {eyebrow}
                        </Text>
                    )}
                    {copy && (
                        <RichText
                            richText={copy}
                            overrides={richTextOverrides}
                        />
                    )}
                    {icon2?.icon?.url ? (
                        <div className={styles.iconContainer}>
                            <Icon src={icon2.icon.url} />
                        </div>
                    ) : (
                        <>
                            {icon && (
                                <div
                                    className={cx(
                                        styles.icon,
                                        styles[iconMap[icon]]
                                    )}
                                >
                                    <SvgIcon type={iconMap[icon]} />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </TouchSwipe>
        </article>
    );
};

CardSwipe.propTypes = {
    eyebrow: PropTypes.string,
    copy: PropTypes.object,
    icon: PropTypes.string,
    icon2: PropTypes.object,
    dirNext: PropTypes.bool,
    className: PropTypes.string,
    isActive: PropTypes.bool,
    isPrev: PropTypes.bool,
    isShow: PropTypes.bool,
    onClick: PropTypes.func,
    onTap: PropTypes.func,
    onSwipedLeft: PropTypes.func,
    onSwipedRight: PropTypes.func,
};

export default CardSwipe;
