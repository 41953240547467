import React from 'react';

import { graphRequest } from 'services/contentful';

import { Block } from 'app/templates/renderBlocks';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockSharpnessStory from './BlockSharpnessStory';

export const sharpnessStoryFragment = /* GraphQL */ `
    fragment SharpnessStoryFragment on SharpnessStory {
        closeLink {
            __typename
            ... on Page {
                slug
            }
        }
        masthead {
            sys {
                id
            }
        }
        itemsCollection(limit: 20) {
            items {
                sys {
                    id
                }
                image {
                    ...Asset
                }
                product
                headline
                copy {
                    json
                }
                detailCopy {
                    json
                }
                backgroundColor
                vintageYear
                agedUnit
                agedTime
                flavorProfileData
                cta {
                    ... on Cta {
                        ...CTA
                    }
                }
            }
        }
    }
`;

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    ${sharpnessStoryFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockSharpnessStory(preview: $preview, id: $id) {
            __typename
            anchorId
            pageSlug
            eyebrow
            sharpnessStory {
                ...SharpnessStoryFragment
            }
            sharpnessStoryPage {
                slug
            }
            cta {
                ... on Cta {
                    ...CTA
                }
            }
            richCopy {
                json
            }
            richHeadline {
                json
            }
            image {
                ...Asset
            }
            imageLarge {
                ...Asset
            }
            flushBottom
            flushTop
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockSharpnessStory },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockSharpnessStory;
}

const BlockSharpnessStoryServer = async ({ id }) => {
    const block = await fetchBlock(id);
    return (
        <BlockSharpnessStory
            {...block}
            masthead={
                block?.sharpnessStory?.masthead && (
                    <Block
                        id={block?.sharpnessStory.masthead.sys.id}
                        type="BlockFullWidthMedia"
                        theme="Sharpness Story"
                    />
                )
            }
        />
    );
};

export default BlockSharpnessStoryServer;
