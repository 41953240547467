'use client';

import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useIntersection } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { PreviousPathnameContext } from 'components/globals/PreviousPathnameProvider';

import Text from 'components/ui/Text';

import { wait, waitFrame } from 'utils';
import { blockRootProps } from 'utils';

import Logo from './Logo';
import RichTextWordJalousie from './RichTextWordJalousie';
import RichTextWordReveal from './RichTextWordReveal';
import ScrollIndicator from './ScrollIndicator';

import styles from './BlockHero.module.scss';

function normalize(value, min, max) {
    if (value < min) return 0;
    if (value > max) return 1;
    return (value - min) / (max - min);
}

function easeInOutQuad(x) {
    return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
}

function easeOutQuad(x) {
    return 1 - (1 - x) * (1 - x);
}

const BlockHero = ({
    __typename,
    anchorId,
    richTagline,
    richHeadline,
    eyebrow,
    imageLarge,
    videoTextureLargeWebM,
    videoTextureLargeMp4,
    image,
    videoTextureWebM,
    videoTextureMp4,
}) => {
    const root = useRef(null);
    const previousPathname = useContext(PreviousPathnameContext);
    const isInitialLoad = !previousPathname;
    const [state, setState] = useState(isInitialLoad ? 'idle' : 'scroll');
    const [loaderProgress, setLoaderProgress] = useState(isInitialLoad ? 0 : 1);
    const [jalousieVisible, setJalousieVisible] = useState(false);
    const [scrollIndicatorDuration, setScrollIndicatorDuration] = useState(15);
    const [viewportRelated, setViewportRelated] = useState({});
    const [showTagline, setShowTagline] = useState(false);

    const intersection = useIntersection(root, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    const scrollHandler = useCallback(() => {
        const el = root.current;
        // Don't run scroll logic if out of view or ref isn't defined
        if (!el || (intersection && intersection.isIntersecting === false)) {
            return;
        }
        const elHtml = document.querySelector(':root');
        const { height } = el.getBoundingClientRect();
        const progress = window.scrollY / (0.7 * height);
        const progressContentStart = 1;
        const coverProgress = easeInOutQuad(normalize(progress, 0, 0.6));
        const videoProgress = easeInOutQuad(normalize(progress, 0, 0.2));
        const logoProgress = easeInOutQuad(normalize(progress, 0, 0.5));
        const logoOpacityProgress = normalize(progress, 0.1, 0.3);
        const scrollIndicatorProgress = normalize(progress, 0, 0.2);
        const jalousieProgress = easeOutQuad(normalize(progress, 0.35, 0.45));
        el.style.setProperty('--cover-progress', coverProgress);
        el.style.setProperty('--video-progress', videoProgress);
        el.style.setProperty('--logo-progress', logoProgress);
        el.style.setProperty('--logo-opacity-progress', logoOpacityProgress);
        el.style.setProperty('--jalousie-progress', jalousieProgress);
        setScrollIndicatorDuration(15 - 12 * scrollIndicatorProgress);
        setJalousieVisible(progress >= 0.4);

        el.style.setProperty(
            '--logo-visibility',
            progress >= 0.5 ? 'hidden' : 'visible'
        );
        elHtml.style.setProperty(
            '--landing-header-logo-visibility',
            progress >= 0.5 ? 'visible' : 'hidden'
        );
        elHtml.style.setProperty(
            '--landing-mobile-header-logo-top-visibility',
            progress >= 0.5 ? 'hidden' : 'visible'
        );

        elHtml.style.setProperty(
            '--landing-header-transform-hidden',
            progress >= progressContentStart
                ? 'translateY(-100%)'
                : 'translateY(0)'
        );
        elHtml.style.setProperty(
            '--landing-header-background',
            progress >= progressContentStart
                ? 'var(--colors-blue)'
                : 'transparent'
        );
    }, [intersection]);

    useEffect(() => {
        if (state === 'idle') {
            waitFrame().then(() => {
                setState('loader');
            });
        } else if (state === 'loader') {
            wait(750).then(() => {
                setLoaderProgress(1);
                wait(1250).then(() => {
                    setState('reveal');
                });
            });
        } else if (state === 'reveal') {
            document
                .querySelector(':root')
                .style.setProperty(
                    '--landing-header-transform-visible',
                    'translateY(0)'
                );
            wait(750).then(() => {
                setShowTagline(true);
            });
            wait(2000).then(() => {
                scrollHandler();
                waitFrame().then(() => {
                    setState('scroll');
                });
            });
        } else if (state === 'scroll') {
            document
                .querySelector(':root')
                .style.setProperty(
                    '--landing-header-transform-visible',
                    'translateY(0)'
                );
            window.addEventListener('scroll', scrollHandler);
            scrollHandler();
            setShowTagline(true);
        }
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, [scrollHandler, state]);

    useEffect(() => {
        const resizeHandler = () => {
            const ref = root.current;
            const { width, height } = ref.getBoundingClientRect();
            let morningStarScale;
            let fullScale;
            let offset;
            let poster;
            let mp4;
            let webm;
            if (width >= 1280) {
                // Desktop layout width in Figma: 1440px
                const scaleMult = width / 1440;
                // Preloader logo scale:
                // Width of morningstar image (without padding): 467px
                // Width of morningstar in Figma: 176px
                // Scale: 176/467 = 0.3768736617
                morningStarScale = 0.3768736617 * scaleMult;
                // Full bleed logo scale:
                // Width of logotype image: 1920px
                // Width of logotype in Figma: 1184px
                // Scale: 1184/1920 = 0.6166666667
                fullScale = 0.6166666667 * scaleMult;
            } else {
                // Mobile layout width in Figma: 375px
                const scaleMult = width / 375;
                // Preloader logo scale:
                // Width of morningstar image (without padding): 467px
                // Width of morningstar in Figma: 88px
                // Scale: 88/467 = 0.1884368308
                morningStarScale = 0.1884368308 * scaleMult;
                // Full bleed logo scale:
                // Width of logotype image: 1920px
                // Width of logotype in Figma: 343px
                // Scale: 343/1920 = 0.1786458333
                fullScale = 0.1786458333 * scaleMult;
            }
            if (width > height) {
                offset = -70;
                poster = imageLarge;
                webm = videoTextureLargeWebM;
                mp4 = videoTextureLargeMp4;
            } else {
                offset = -height / 6; // In portrait orientation, elements are shifted up
                poster = image;
                webm = videoTextureWebM;
                mp4 = videoTextureMp4;
            }
            setViewportRelated({
                ['--morningstar-scale']: morningStarScale,
                ['--full-scale']: fullScale,
                ['--hero-height']: `${height}px`,
                ['--vertical-offset']: `${offset}px`,
                poster,
                webm,
                mp4,
            });
        };
        resizeHandler();
        addEventListener('resize', resizeHandler);
        return () => {
            removeEventListener('resize', resizeHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleScrollClick = () => {
        scrollTo({
            left: 0,
            top: window.innerHeight * 0.7,
            behavior: 'smooth',
        });
    };

    const { poster, webm, mp4, ...cssVars } = viewportRelated;

    const posterSrc = poster?.url;
    const webmSrc = webm?.url;
    const mp4Src = mp4?.url;

    const hasVideo = !!posterSrc && !!webmSrc && !!mp4Src;

    return (
        <section
            className={cx(styles.blockHero, styles[`blockHero-${state}`], {
                [styles.isInView]: intersection && intersection.isIntersecting,
            })}
            style={cssVars}
            ref={root}
            {...blockRootProps(anchorId, __typename)}
        >
            <div className={styles.videoContainer}>
                {hasVideo && (
                    <video
                        className={styles.video}
                        autoPlay
                        loop
                        muted
                        playsInline
                        poster={
                            posterSrc
                                ? `${posterSrc}?w=1200&fm=webp&q=80`
                                : null
                        }
                        key={posterSrc}
                    >
                        <source src={webmSrc} type="video/webm" />
                        <source src={mp4Src} type="video/mp4" />
                    </video>
                )}
                <div className={styles.cover}>
                    <img
                        alt=""
                        loading="eager"
                        // eslint-disable-next-line react/no-unknown-property
                        fetchPriority="high"
                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMjYwIDMyNjAiPgogIDxwYXRoIGZpbGw9IiMwNDFBNDgiIGQ9Ik0zMjU5Ljc2LjI4VjI1ODBMMTYzMCAxNjMwLjUgMCAyNTgwVi4yOHoiLz4KPC9zdmc+Cg=="
                    />
                </div>
            </div>
            <div className={styles.logoContainer}>
                <div className={styles.logoWrapper}>
                    <Logo
                        progress={loaderProgress}
                        className={styles.logo}
                        classNameMornigStar={styles.morningStar}
                        classNameLogoType={styles.logoType}
                    />
                    <Text
                        as="div"
                        baseTheme="displaySmall"
                        themes={{ large: 'displayMedium' }}
                        className={styles.tagline}
                    >
                        <RichTextWordReveal
                            richTextObject={richTagline}
                            className={styles.taglineContent}
                            reveal={showTagline}
                            staggerDelay={0.3}
                            fadeDuration={0.6}
                        />
                    </Text>
                </div>
            </div>
            <div className={styles.secondaryHero}>
                <Text
                    as="div"
                    baseTheme="headingSmall"
                    themes={{ large: 'headingLarge' }}
                    className={cx(styles.eyebrow, {
                        [styles.reveal]: jalousieVisible,
                    })}
                >
                    {eyebrow}
                </Text>
                <Text
                    as="div"
                    baseTheme="displayLarge"
                    themes={{
                        large: 'displayXLarge',
                        xxxLarge: 'displayXXLarge',
                    }}
                    className={styles.headline}
                >
                    <RichTextWordJalousie
                        richTextObject={richHeadline}
                        className={styles.headlineContent}
                        reveal={jalousieVisible}
                    />
                </Text>
            </div>
            <ScrollIndicator
                className={styles.scrollIndicator}
                onClick={handleScrollClick}
                duration={scrollIndicatorDuration}
            />
        </section>
    );
};

const PropTypeMediaAsset = PropTypes.shape({
    url: PropTypes.string,
});

BlockHero.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    previousAsPath: PropTypes.string,
    richTagline: PropTypes.object,
    richHeadline: PropTypes.object,
    eyebrow: PropTypes.string,
    image: PropTypeMediaAsset,
    imageLarge: PropTypeMediaAsset,
    videoTextureMp4: PropTypeMediaAsset,
    videoTextureWebM: PropTypeMediaAsset,
    videoTextureLargeMp4: PropTypeMediaAsset,
    videoTextureLargeWebM: PropTypeMediaAsset,
};

export default BlockHero;
