import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import styles from './Fields.module.scss';

const FieldText = ({
    field,
    required,
    placeholder,
    type,
    validation,
    errors,
    register,
}) => {
    return (
        <>
            <Text baseTheme="headingXSmall" className={styles.field}>
                <input
                    id={field}
                    {...register(field, {
                        required: required,
                        pattern: validation && validation,
                    })}
                    type={type.input}
                    placeholder={placeholder}
                    className={cx(styles.input, {
                        [styles.hasError]: errors?.[field],
                    })}
                />
            </Text>
            <div className={styles.error}>
                {errors?.[field] && (
                    <Text baseTheme="bodyXSmall">
                        {errors?.[field].type === 'required'
                            ? 'This field is required'
                            : `Please enter a valid ${type.display}`}
                    </Text>
                )}
            </div>
        </>
    );
};

FieldText.propTypes = {
    errors: PropTypes.object,
    field: PropTypes.string,
    placeholder: PropTypes.string,
    register: PropTypes.func,
    required: PropTypes.bool,
    type: PropTypes.object,
    validation: PropTypes.object,
};

export default FieldText;
