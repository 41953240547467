'use client';

import React, { useCallback, useEffect, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import BlockTwoColumnTextAlt from 'components/blocks/BlockTwoColumnTextAlt/BlockTwoColumnTextAlt';
import SharpnessStory from 'components/overlays/SharpnessStory';
import SharpnessCloseButton from 'components/overlays/SharpnessStory/SharpnessCloseButton';
import ButtonCTA from 'components/ui/ButtonCTA';
import Img from 'components/ui/Img';
import Portal from 'components/ui/Portal';
import RichText from 'components/ui/RichText';
import TextEyebrow from 'components/ui/TextEyebrow';

import { useTheme } from 'hooks/useTheme';
import { blockRootProps } from 'utils';

import styles from './BlockSharpnessStory.module.scss';

const eyebrowColorMap = {
    'makers-reserve': {
        eyebrow: 'makersReserveGoldAlt',
        headline: 'makersReserveBlack',
    },
};

const BlockSharpnessStory = ({
    __typename,
    anchorId,
    eyebrow,
    richHeadline,
    richCopy,
    sharpnessStory,
    sharpnessStoryPage,
    image,
    imageLarge,
    flushTop,
    flushBottom,
    cta,
    masthead,
    textColor,
}) => {
    flushTop ??= true;
    flushBottom ??= true;
    textColor ??= 'cream';

    const [isOpen, setOpen] = useState(false);
    const [isReveal, setIsReveal] = useState(false);
    const [globalTheme] = useTheme();

    const handleOpen = () => {
        window.history.pushState(null, null, `/${sharpnessStoryPage.slug}`);

        setOpen(true);
        setTimeout(() => {
            window.requestAnimationFrame(() => {
                setIsReveal(true);
            });
        }, 0);
    };

    const handleClose = useCallback(() => {
        window.history.pushState(
            null,
            null,
            `/${sharpnessStory.closeLink.slug}`
        );
        setIsReveal(false);
    }, [sharpnessStory]);

    const handleKeyDown = useCallback(() => {
        if (event.keyCode === 27) {
            handleClose();
        }
    }, [handleClose]);

    const onCloseTransitionEnd = e => {
        if (e.propertyName === 'transform' && !isReveal) setOpen(false);
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false);
        return () => {
            document.removeEventListener('keydown', handleKeyDown, false);
        };
    }, [handleKeyDown]);

    const renderCTA = () => {
        return (
            <>
                {(cta?.link || cta?.assetDownloadLink) && (
                    <ButtonCTA className={styles.cta} {...cta} />
                )}
                {sharpnessStory && (
                    <ButtonCTA
                        className={styles.cta}
                        text="Explore"
                        style={
                            globalTheme === 'makers-reserve'
                                ? 'makersFilled-gold-cream'
                                : 'filled-cream-white'
                        }
                        iconType="explore"
                        onClick={handleOpen}
                    />
                )}
            </>
        );
    };

    const renderInnerContent = () => {
        return (
            <div className={styles.innerAspect}>
                <div className={styles.aspectBox}>
                    {image?.url && (
                        <Img
                            className={styles.image}
                            src={image.url}
                            fallbackImageWidth={1280}
                            alt={image.description || ''}
                            width={image.width}
                            height={image.height}
                            customSources={[
                                {
                                    breakpoint: 1024,
                                    src: imageLarge?.url
                                        ? imageLarge.url
                                        : image.url,
                                    imageWidth: 1440,
                                },
                                {
                                    breakpoint: 768,
                                    src: imageLarge?.url
                                        ? imageLarge.url
                                        : image.url,
                                    imageWidth: 1080,
                                },
                                {
                                    src: image.url,
                                    imageWidth: 1280,
                                },
                            ]}
                        />
                    )}
                </div>
                <div className={styles.innerContent}>
                    <div className={styles.block}>
                        <div
                            className={styles.content}
                            style={{ color: `var(--colors-${textColor})` }}
                        >
                            {richHeadline && (
                                <TextEyebrow
                                    className={styles.eyebrow}
                                    eyebrow={eyebrow}
                                    richHeadline={richHeadline}
                                    theme={
                                        globalTheme === 'makers-reserve'
                                            ? 'makers-reserve'
                                            : 'displaySmallToLarge1'
                                    }
                                    globalTheme={globalTheme}
                                    eyebrowColor={
                                        eyebrowColorMap[globalTheme]?.eyebrow
                                    }
                                    headlineColor={
                                        eyebrowColorMap[globalTheme]?.headline
                                    }
                                />
                            )}
                            {richCopy?.json && (
                                <div className={styles.richCopyWrapper}>
                                    <RichText richText={richCopy?.json} />
                                </div>
                            )}
                            {renderCTA()}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <article
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.root, [styles[`theme--${globalTheme}`]], {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <div className={styles.inner}>
                {globalTheme !== 'makers-reserve' && renderInnerContent()}
                {globalTheme === 'makers-reserve' && (
                    <BlockTwoColumnTextAlt
                        eyebrow={eyebrow}
                        richHeadline={richHeadline}
                        bodyCopy={richCopy}
                        cta={
                            <ButtonCTA
                                className={styles.cta}
                                text="Explore"
                                style={'makersFilled-gold-cream'}
                                iconType="explore"
                                onClick={handleOpen}
                            />
                        }
                    />
                )}
            </div>
            {isOpen && sharpnessStory && (
                <Portal>
                    <div
                        className={cx(styles.overlay, {
                            [styles.isReveal]: isReveal,
                        })}
                        onTransitionEnd={onCloseTransitionEnd}
                    >
                        <SharpnessStory
                            {...sharpnessStory}
                            masthead={masthead}
                            items={sharpnessStory.itemsCollection.items}
                        />
                    </div>
                    <SharpnessCloseButton
                        onClose={handleClose}
                        className={cx(styles.overlayCloseButton, {
                            [styles.isReveal]: isReveal,
                        })}
                    />
                </Portal>
            )}
            {globalTheme === 'makers-reserve' && image?.url && (
                <Img
                    className={styles.inlineImage}
                    src={image.url}
                    fallbackImageWidth={1280}
                    alt={image.description || ''}
                    customSources={[
                        {
                            breakpoint: 1024,
                            src: imageLarge?.url ? imageLarge.url : image.url,
                            imageWidth: 1440,
                        },
                        {
                            src: image.url,
                            imageWidth: 1280,
                        },
                    ]}
                />
            )}
        </article>
    );
};

BlockSharpnessStory.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    eyebrow: PropTypes.string,
    richHeadline: PropTypes.object,
    richCopy: PropTypes.object,
    masthead: PropTypes.object,
    sharpnessStory: PropTypes.object,
    sharpnessStoryPage: PropTypes.object,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    cta: PropTypes.object,
    textColor: PropTypes.oneOf(['cream', 'blue']),
    image: PropTypes.shape({
        description: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
    imageLarge: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
};

export default BlockSharpnessStory;
