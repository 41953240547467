import React from 'react';

import { graphRequest } from 'services/contentful';

import { fragment as cardSmallFragment } from 'components/cards/CardSmall';

import BlockCardTrioSmall from './BlockCardTrioSmall';

const query = /* GraphQL */ `
    ${cardSmallFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockCardTrioSmall(preview: $preview, id: $id) {
            __typename
            anchorId
            headline
            eyebrow
            cardsCollection(limit: 10) {
                items {
                    ...CardSmallFragment
                }
            }
            flushTop
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockCardTrioSmall },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockCardTrioSmall;
}

const BlockCardTrioSmallServer = async ({ id }) => {
    const block = await fetchBlock(id);

    return (
        <BlockCardTrioSmall {...block} cards={block.cardsCollection.items} />
    );
};

export default BlockCardTrioSmallServer;
