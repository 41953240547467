'use client';

import React from 'react';

import cx from 'clsx';
import PropTypes from 'prop-types';

import Img from 'components/ui/Img';

import styles from './BlockOverflowCarouselOverflowSlide.module.scss';

const typenameMap = {
    BlockOverflowCarouselOverflowSlide: 'isOverflow',
    BlockOverflowCarouselContainedSlide: 'isContained',
};

const originMap = {
    'Bottom Center': 'isBottomCenter',
    'Bottom Left': 'isBottomLeft',
    'Bottom Right': 'isBottomRight',
};

const BlockOverflowCarouselOverflowSlide = ({
    __typename,
    imageAnimationOrigin,
    image,
    isActive,
    isPrev,
}) => {
    imageAnimationOrigin ??= 'Bottom Center';

    return (
        <article
            className={cx(
                styles.root,
                styles[typenameMap[__typename]],
                styles[originMap[imageAnimationOrigin]],
                {
                    [styles.isActive]: isActive,
                    [styles.isPrev]: isPrev,
                }
            )}
        >
            <div className={styles.column}>
                <div className={styles.imageOverflow}>
                    <div className={styles.imageWrapper}>
                        {image?.url && (
                            <Img
                                className={styles.image}
                                src={image.url}
                                fallbackImageWidth={297 * 2}
                                alt={image.description || ''}
                                width={image?.width}
                                height={image?.height}
                                customSources={[
                                    {
                                        breakpoint: 1920,
                                        src: image.url,
                                        imageWidth: 1280,
                                    },
                                    {
                                        breakpoint: 768,
                                        src: image.url,
                                        imageWidth: 1200,
                                    },
                                    {
                                        src: image.url,
                                        imageWidth: 900,
                                    },
                                ]}
                            />
                        )}
                    </div>
                </div>
            </div>
        </article>
    );
};

BlockOverflowCarouselOverflowSlide.propTypes = {
    __typename: PropTypes.string,
    image: PropTypes.shape({
        description: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
    imageAnimationOrigin: PropTypes.oneOf([
        'Bottom Center',
        'Bottom Left',
        'Bottom Right',
    ]),
    isActive: PropTypes.bool,
    isPrev: PropTypes.bool,
};

export default BlockOverflowCarouselOverflowSlide;
