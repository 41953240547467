import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';

import cx from 'clsx';
import PropTypes from 'prop-types';

import Img from 'components/ui/Img';

import TouchSwipe from 'utils/TouchSwipe';

import styles from './SlideShow.module.scss';

const maxNumberIndices = 3;

const initialDelay = 1000;
const timerDuration = 5000;
const crossFadeThreshold = 1.25;
const animationDuration = timerDuration * crossFadeThreshold;

const SlideShow = ({ slides, dotsOnLeft }) => {
    const ref = useRef(null);
    const timerRef = useRef(null);

    const initialIndex = slides.length - 1;
    const [activeIndex, setActiveIndex] = useState(initialIndex);

    const [isInView, setIsInView] = useState(false);
    const [isIntroComplete, setIsIntroComplete] = useState(false);
    const [userEngaged, setUserEngaged] = useState(false);
    const [activeIndices, setActiveIndices] = useState([
        { initial: true, index: initialIndex },
    ]);

    const isSlideShow = slides.length > 1;

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    const handlePlay = useCallback(
        isUserControl => {
            setActiveIndex(prevActivIndex => {
                return prevActivIndex < slides.length - 1
                    ? prevActivIndex + 1
                    : 0;
            });

            if (isUserControl) {
                setUserEngaged(true);
                clearTimeout(timerRef.current);
            } else {
                clearTimeout(timerRef.current);
                timerRef.current = setTimeout(() => {
                    handlePlay(false);
                }, timerDuration);
            }
        },
        [slides.length]
    );

    const handlePlayPrev = () => {
        clearTimeout(timerRef.current);
        setActiveIndex(prevActivIndex => {
            return prevActivIndex > 0 ? prevActivIndex - 1 : slides.length - 1;
        });
    };

    useEffect(() => {
        if (intersection?.isIntersecting) {
            setIsInView(true);
        }
    }, [intersection]);

    useEffect(() => {
        if (isInView) {
            setTimeout(() => {
                handlePlay(false);
                setIsIntroComplete(true);
            }, initialDelay);
        }

        return () => {
            clearTimeout(timerRef.current);
        };
    }, [isInView, handlePlay]);

    useEffect(() => {
        if (isIntroComplete) {
            setActiveIndices(prevIndices => {
                if (prevIndices.length < maxNumberIndices) {
                    // If there are fewer than maxNumberIndices indices, just add the new index
                    return [
                        ...prevIndices,
                        { index: activeIndex, user: userEngaged ? true : null },
                    ];
                } else {
                    // If there are already maxNumberIndices indices, remove the oldest one and add the new one
                    return [
                        ...prevIndices.slice(1),
                        { index: activeIndex, user: userEngaged ? true : null },
                    ];
                }
            });
        }
    }, [activeIndex, isIntroComplete, userEngaged]);

    return (
        <div
            ref={ref}
            className={cx(styles.root, {
                [styles.isReady]: isIntroComplete,
            })}
            style={{
                '--duration': `${animationDuration}ms`,
            }}
        >
            {isSlideShow && (
                <>
                    <div className={styles.track}>
                        <TouchSwipe
                            onTap={
                                isIntroComplete ? () => handlePlay(true) : null
                            }
                            onSwipedLeft={
                                isIntroComplete ? () => handlePlay(true) : null
                            }
                            onSwipedRight={
                                isIntroComplete ? handlePlayPrev : null
                            }
                            mouse
                        >
                            {activeIndices.map(({ initial, user, index }) => (
                                <div
                                    key={index}
                                    className={cx(styles.slide, {
                                        [styles.initial]: initial,
                                        [styles.user]: user,
                                    })}
                                >
                                    <Img
                                        className={styles.image}
                                        src={slides[index].image.url}
                                        customSources={[
                                            {
                                                breakpoint: 1440,
                                                src: slides[index].imageLarge
                                                    .url,
                                                imageWidth: 1440 * 1.5,
                                            },
                                            {
                                                breakpoint: 1024,
                                                src: slides[index].imageLarge
                                                    .url,
                                                imageWidth: 1024 * 1.5,
                                            },
                                            {
                                                breakpoint: 768,
                                                src: slides[index].imageLarge
                                                    .url,
                                                imageWidth: 768 * 1.5,
                                            },
                                            {
                                                src: slides[index].image.url,
                                                imageWidth: 768,
                                            },
                                        ]}
                                    />
                                </div>
                            ))}
                        </TouchSwipe>
                    </div>
                    <div
                        className={cx(styles.dots, {
                            [styles.dotsOnLeft]: dotsOnLeft,
                        })}
                    >
                        {slides.map((_, index) => {
                            return (
                                <button
                                    key={index}
                                    className={cx(styles.dot, {
                                        [styles.active]: index === activeIndex,
                                    })}
                                    onClick={
                                        isIntroComplete
                                            ? () => {
                                                  setUserEngaged(true);
                                                  setActiveIndex(index);
                                                  clearTimeout(
                                                      timerRef.current
                                                  );
                                              }
                                            : null
                                    }
                                />
                            );
                        })}
                    </div>
                </>
            )}
            {!isSlideShow && (
                <div className={styles.track}>
                    <div className={cx(styles.slide, styles.initial)}>
                        <Img
                            className={styles.image}
                            src={slides[0].image.url}
                            customSources={[
                                {
                                    breakpoint: 1440,
                                    src: slides[0].imageLarge.url,
                                    imageWidth: 1440 * 1.5,
                                },
                                {
                                    breakpoint: 1024,
                                    src: slides[0].imageLarge.url,
                                    imageWidth: 1024 * 1.5,
                                },
                                {
                                    breakpoint: 768,
                                    src: slides[0].imageLarge.url,
                                    imageWidth: 768 * 1.5,
                                },
                                {
                                    src: slides[0].image.url,
                                    imageWidth: 768,
                                },
                            ]}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

SlideShow.propTypes = {
    slides: PropTypes.array.isRequired,
    dotsOnLeft: PropTypes.bool,
};

export default SlideShow;
