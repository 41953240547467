import React from 'react';

import { graphRequest } from 'services/contentful';

import { Block } from 'app/templates/renderBlocks';

import { fragment as videoPlayerYTFragment } from 'components/ui/VideoPlayerYT/fragment';

import { assetFragment } from 'lib/graphql/fragments';

import BlockHeroVideo from './BlockHeroVideo';
import PreviewBlockHeroVideo from './PreviewBlockHeroVideo';

const query = /* GraphQL */ `
    ${assetFragment}
    ${videoPlayerYTFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockHeroVideo(preview: $preview, id: $id) {
            __typename
            anchorId
            video {
                ...VideoPlayerYTFragment
            }
            callout {
                sys {
                    id
                }
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockHeroVideo },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockHeroVideo;
}

const BlockHeroVideoServer = async ({ id, preview }) => {
    const block = await fetchBlock(id);

    return preview ? (
        <PreviewBlockHeroVideo
            {...block}
            callout={
                block?.callout && (
                    <Block
                        id={block.callout.sys.id}
                        type="BlockCallout"
                        preview
                    />
                )
            }
        />
    ) : (
        <BlockHeroVideo
            {...block}
            callout={
                block?.callout && (
                    <Block id={block.callout.sys.id} type="BlockCallout" />
                )
            }
        />
    );
};

export default BlockHeroVideoServer;
