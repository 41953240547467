import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockCallout from './BlockCallout';
import PreviewBlockCallout from './PreviewBlockCallout';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockCallout(preview: $preview, id: $id) {
            __typename
            anchorId
            sys {
                id
            }
            richHeadline {
                json
            }
            eyebrow
            copy
            image {
                ...Asset
            }
            imageLarge {
                ...Asset
            }
            theme
            colorTheme
            hasNotch
            cta {
                ... on Cta {
                    ...CTA
                }
            }
            ctaSecondary {
                ... on Cta {
                    ...CTA
                }
            }
            ctaOnly
            roundedCorners
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockCallout },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockCallout;
}

const BlockCalloutServer = async ({ id, preview, firstBlock }) => {
    const block = await fetchBlock(id);

    return preview ? (
        <PreviewBlockCallout {...block} firstBlock={firstBlock} />
    ) : (
        <BlockCallout {...block} firstBlock={firstBlock} />
    );
};

export default BlockCalloutServer;
