import React, { useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import CountUp from 'components/ui/CountUp';
import Text from 'components/ui/Text';

import styles from './Graph.module.scss';

const Bar = ({ inView, i, chartItem }) => {
    const [countUp, setCountUp] = useState(false);

    return (
        <div className={cx(styles.barWrapper, styles[`barWrapper${i + 1}`])}>
            <div
                className={cx(styles.chartBar, {
                    [styles.animate]: inView,
                })}
                style={{
                    '--animation-order': i + 1,
                }}
                onAnimationStart={() => setCountUp(true)}
            >
                <div className={styles.percentWrapper}>
                    <Text
                        className={styles.percentage}
                        baseTheme={'displayXSmall'}
                        themes={{
                            large: 'displaySmall',
                        }}
                    >
                        <div className={styles.countUpHeadline}>
                            <CountUp
                                end={chartItem.percentage}
                                duration={countUp ? 0 : 3000}
                                startOnMount={false}
                                decimals={1}
                                start={0}
                            />
                            <span>%</span>
                        </div>
                    </Text>
                </div>
            </div>
        </div>
    );
};

Bar.propTypes = {
    i: PropTypes.number,
    inView: PropTypes.bool,
    chartItem: PropTypes.object,
};
export default Bar;
