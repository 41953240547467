import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockTwoColumnGrid from './BlockTwoColumnGrid';

const query = /* GraphQL */ `
    ${ctaFragment}
    ${linkFragment}
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockTwoColumnGrid(preview: $preview, id: $id) {
            __typename
            eyebrow
            headline {
                json
            }
            body {
                json
            }
            cta {
                ...CTA
            }
            image {
                ...Asset
            }
            imagePosition
            flushTop
            flushBottom
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockTwoColumnGrid },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockTwoColumnGrid;
}

const BlockTwoColumnGridServer = async ({ id }) => {
    const block = await fetchBlock(id);
    return <BlockTwoColumnGrid {...block} />;
};

export default BlockTwoColumnGridServer;
