import React from 'react';

import { graphRequest } from 'services/contentful';

import { assetFragment } from 'lib/graphql/fragments';

import BlockWhereToBuy from './BlockWhereToBuy';

const query = /* GraphQL */ `
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockWhereToBuy(preview: $preview, id: $id) {
            __typename
            bodyCopy
            imagesCollection {
                items {
                    ... on BlockWhereToBuyImage {
                        image {
                            ...Asset
                        }
                        scale
                    }
                }
            }
            backgroundColor
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockWhereToBuy },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockWhereToBuy;
}

const BlockWhereToBuyServer = async ({ id }) => {
    const block = await fetchBlock(id);
    return <BlockWhereToBuy {...block} images={block.imagesCollection.items} />;
};

export default BlockWhereToBuyServer;
