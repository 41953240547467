import React from 'react';

import PropTypes from 'prop-types';

import CarouselProvider from 'components/ui/CarouselProvider';

import { blockRootProps } from 'utils';

import FlipDeck from './FlipDeck';

const BlockFlipDeck = ({
    __typename,
    anchorId,
    slides,
    timerDuration,
    isContinuous,
    flushTop,
    flushBottom,
    ...descriptionProps
}) => {
    timerDuration ??= 7;
    return (
        <div {...blockRootProps(anchorId, __typename)}>
            <CarouselProvider
                slides={slides}
                timerDuration={timerDuration}
                isContinuous={isContinuous}
            >
                <FlipDeck
                    flushTop={flushTop}
                    flushBottom={flushBottom}
                    {...descriptionProps}
                />
            </CarouselProvider>
        </div>
    );
};

BlockFlipDeck.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    slides: PropTypes.array,
    timerDuration: PropTypes.number,
    isContinuous: PropTypes.bool,
};

export default BlockFlipDeck;
