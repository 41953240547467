import React from 'react';

import { graphRequest } from 'services/contentful';

import { fragment as cardAnimatedIconFragment } from 'components/cards/CardAnimatedIcon/fragment';
import { fragment as cardCampaignFragment } from 'components/cards/CardCampaign/fragment';
import { fragment as cardDownloadFragment } from 'components/cards/CardDownload/fragment';
import { fragment as cardDualAspectNewsFragment } from 'components/cards/CardDualAspectNews/fragment';
import { fragment as cardIconStatFragment } from 'components/cards/CardIconStat';
import { fragment as cardLatestFragment } from 'components/cards/CardLatest/fragment';
import { fragment as cardLeadershipFragment } from 'components/cards/CardLeadership';
import { fragment as cardNewsDetailFragment } from 'components/cards/CardNewsDetail';
import { fragment as cardPolaroidFragment } from 'components/cards/CardPolaroid/fragment';
import { fragment as cardProductFragment } from 'components/cards/CardProduct/fragment';
import { fragment as cardRecipeFragment } from 'components/cards/CardRecipe/fragment';
import { fragment as cardSmallFragment } from 'components/cards/CardSmall';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockCardGroup from './BlockCardGroup';

const query = /* GraphQL */ `
    ${ctaFragment}
    ${linkFragment}
    ${cardDualAspectNewsFragment}
    ${cardAnimatedIconFragment}
    ${cardCampaignFragment}
    ${cardRecipeFragment}
    ${cardProductFragment}
    ${cardLeadershipFragment}
    ${cardDownloadFragment}
    ${cardLatestFragment}
    ${cardNewsDetailFragment}
    ${cardIconStatFragment}
    ${cardSmallFragment}
    ${cardPolaroidFragment}
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockCardGroup(preview: $preview, id: $id) {
            __typename
            anchorId
            title
            eyebrow
            headline
            flushTop
            flushBottom
            theme
            backgroundColor
            inlineIntroTextColor
            copy {
                json
            }
            navigationTheme
            cta {
                ...CTA
            }
            cardsCollection(limit: 20) {
                items {
                    __typename
                    ... on CardDualAspectNews {
                        ...CardDualAspectNewsFragment
                    }
                    ... on CardAnimatedIcon {
                        ...CardAnimatedIconFragment
                    }
                    ... on CardCampaign {
                        ...CardCampaignFragment
                    }
                    ... on Recipe {
                        ...CardRecipeFragment
                    }
                    ... on Product {
                        ...CardProductFragment
                    }
                    ... on CardLeadership {
                        ...CardLeadershipFragment
                    }
                    ... on CardLatest {
                        ...CardLatestFragment
                    }
                    ... on CardDownload {
                        ...CardDownloadFragment
                    }
                    ... on News {
                        ...CardNewsDetailFragment
                    }
                    ... on CardIconStat {
                        ...CardIconStatFragment
                    }
                    ... on CardSmall {
                        ...CardSmallFragment
                    }
                    ... on CardPolaroid {
                        ...CardPolaroidFragment
                    }
                }
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockCardGroup },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockCardGroup;
}

const BlockCardGroupServer = async ({ id }) => {
    const block = await fetchBlock(id);
    const cards = block.cardsCollection.items;
    return <BlockCardGroup {...block} cards={cards} />;
};

export default BlockCardGroupServer;
