import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockCTAGroup from './BlockCTAGroup';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockCtaGroup(preview: $preview, id: $id) {
            __typename
            anchorId
            eyebrow
            headline {
                json
            }
            copy {
                json
            }
            ctAsCollection {
                items {
                    ... on Cta {
                        ...CTA
                    }
                }
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockCtaGroup },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockCtaGroup;
}

const BlockCTAGroupServer = async ({ id }) => {
    const block = await fetchBlock(id);
    return <BlockCTAGroup {...block} ctas={block.ctAsCollection?.items} />;
};

export default BlockCTAGroupServer;
