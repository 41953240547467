import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';

import { blockRootProps } from 'utils';

import styles from './BlockTwoColumnRichText.module.scss';

const richTextOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    baseTheme="bodySmall"
                    themes={{ large: 'bodyMedium' }}
                    className={styles.richTextParagraph}
                >
                    {children}
                </Text>
            );
        },
        [BLOCKS.HEADING_3]: (node, children) => {
            return (
                <Text
                    as="h3"
                    baseTheme="headingMedium"
                    themes={{ large: 'headingLarge' }}
                    className={styles.richTextHeadingThree}
                >
                    {children}
                </Text>
            );
        },
        [BLOCKS.HEADING_4]: (node, children) => {
            return (
                <Text
                    as="h4"
                    baseTheme="labelLarge"
                    className={styles.richTextHeadingFour}
                >
                    {children}
                </Text>
            );
        },
    },
};

const BlockTwoColumnRichText = ({
    __typename,
    anchorId,
    richText,
    image,
    imageLarge,
    flushTop,
    imageOnLeft,
    bgTheme,
    className,
}) => {
    imageOnLeft ??= true;

    const renderImage = () => {
        const sources = [
            {
                src: image?.url,
                imageWidth: 500,
            },
        ];

        if (imageLarge?.url) {
            sources.unshift({
                breakpoint: 768,
                src: imageLarge.url,
                imageWidth: 800,
            });
        }

        return (
            <div className={styles.imageWrap}>
                {image?.url && (
                    <Img
                        className={styles.image}
                        src={image.url}
                        fallbackImageWidth={732}
                        alt={image.description || ''}
                        customSources={sources}
                    />
                )}
            </div>
        );
    };
    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.blockTwoColumnRichText, className, {
                [styles.flushTop]: flushTop,
                [styles.imageOnLeft]: imageOnLeft,
            })}
        >
            <div
                className={cx(styles.inner, {
                    [styles.whiteBackground]: bgTheme === 'white',
                })}
            >
                <div className={styles.column}>{renderImage()}</div>
                <div className={styles.column}>
                    {richText && (
                        <RichText
                            richText={richText}
                            overrides={richTextOverrides}
                        />
                    )}
                </div>
            </div>
        </section>
    );
};

BlockTwoColumnRichText.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    imageOnLeft: PropTypes.bool,
    flushTop: PropTypes.bool,
    className: PropTypes.string,
    image: PropTypes.shape({
        description: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
    imageLarge: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
    richText: PropTypes.object,
    bgTheme: PropTypes.oneOf(['blue', 'white']),
};

export default BlockTwoColumnRichText;
