import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Img from 'components/ui/Img';

import { blockRootProps } from 'utils';

import styles from './BlockImage.module.scss';

const BlockImage = ({
    __typename,
    anchorId,
    className,
    image,
    imageLarge,
    flushTop,
    flushBottom,
    fullWidth,
    twoColumn,
    embeddedEntry,
    priority,
    roundedTop,
    roundedBottom,
}) => {
    roundedTop ??= true;
    roundedBottom ??= true;

    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.blockImageFlex, className, {
                [styles.embeddedEntry]: embeddedEntry,
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.fullWidth]: fullWidth,
                [styles.twoColumn]: twoColumn,
                [styles.roundedTop]: roundedTop,
                [styles.roundedBottom]: roundedBottom,
            })}
        >
            <div className={styles.blockImage}>
                {image?.url && imageLarge?.url && (
                    <Img
                        className={styles.image}
                        src={image.url}
                        width={image.width}
                        height={image.height}
                        alt={image.description || ''}
                        priority={priority}
                        customSources={[
                            {
                                breakpoint: 1920,
                                src: imageLarge.url,
                                imageWidth: 1920 * 0.75,
                            },
                            {
                                breakpoint: 1440,
                                src: imageLarge.url,
                                imageWidth: 1440 * 0.75,
                            },
                            {
                                breakpoint: 1024,
                                src: imageLarge.url,
                                imageWidth: 1024 * 0.75,
                            },
                            {
                                breakpoint: 768,
                                src: imageLarge.url,
                                imageWidth: 768 * 1.5,
                            },
                            {
                                src: image.url,
                                imageWidth: 768,
                            },
                        ]}
                    />
                )}
                {image?.url && !imageLarge && (
                    <Img
                        className={styles.image}
                        src={image.url}
                        fallbackImageWidth={732}
                        alt={image.description || ''}
                        priority={priority}
                        customSources={[
                            {
                                src: image.url,
                                imageWidth: 1280,
                            },
                        ]}
                    />
                )}
            </div>
        </section>
    );
};

BlockImage.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    className: PropTypes.string,
    embeddedEntry: PropTypes.bool,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
    fullWidth: PropTypes.bool,
    image: PropTypes.shape({
        description: PropTypes.string,
        height: PropTypes.number,
        width: PropTypes.number,
        url: PropTypes.string,
    }),
    imageLarge: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
        url: PropTypes.string,
    }),
    priority: PropTypes.bool,
    roundedBottom: PropTypes.bool,
    roundedTop: PropTypes.bool,
    twoColumn: PropTypes.bool,
};

export default BlockImage;
