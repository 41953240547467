import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button';
import Img from 'components/ui/Img';
import Text from 'components/ui/Text';

import styles from './CardDownload.module.scss';

const CardDownload = ({ headline, cta, image }) => {
    if (!(headline && cta && image)) {
        return null;
    }

    return (
        <div className={cx(styles.downloadCard)}>
            <div className={cx(styles.downloadCardInner)}>
                <div className={styles.imageWrapper}>
                    <Img
                        className={styles.image}
                        src={image.url}
                        fallbackImageWidth={732}
                        alt={image.description || ''}
                    />
                </div>
                <div className={styles.lockUp}>
                    <Text className={styles.headline} baseTheme="headingSmall">
                        {headline}
                    </Text>
                    <div className={styles.ctaWrapper}>
                        <Button
                            className={styles.cta}
                            theme="outlinedBlue"
                            download={true}
                            href={cta.assetDownloadLink.url}
                            iconLeading="download"
                        >
                            download
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

CardDownload.propTypes = {
    headline: PropTypes.string.isRequired,
    cta: PropTypes.object,
    image: PropTypes.shape({
        description: PropTypes.string,
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
    }).isRequired,
};

export default CardDownload;
