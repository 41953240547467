import React from 'react';

import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import Button from 'components/ui/Button';
import RichText from 'components/ui/RichText';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import { blockRootProps } from 'utils';

import styles from './BlockDonationForm.module.scss';

const richTextOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    className={styles.paragraph}
                    baseTheme="bodyMedium"
                    themes={{ medium: 'bodyLarge' }}
                >
                    {children}
                </Text>
            );
        },
    },
};
const BlockDonationForm = ({
    __typename,
    anchorId,
    headline,
    subHeadline,
    cta,
}) => {
    return (
        <div {...blockRootProps(anchorId, __typename)} className={styles.root}>
            <SvgIcon type="heartHandshake" className={styles.icon} />

            <div className={styles.headline}>
                <Text
                    baseTheme="displaySmall"
                    themes={{ medium: 'displayLarge' }}
                    as="h1"
                >
                    {headline}
                </Text>
            </div>
            {subHeadline && (
                <div className={styles.richText}>
                    <RichText
                        richText={subHeadline}
                        overrides={richTextOverrides}
                    />
                </div>
            )}
            {cta?.text && cta?.link && (
                <Button
                    className={styles.cta}
                    theme="outlinedBlue"
                    entry={cta.link}
                >
                    {cta.text}
                </Button>
            )}
        </div>
    );
};

BlockDonationForm.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    headline: PropTypes.string.isRequired,
    subHeadline: PropTypes.object,
    cta: PropTypes.object,
};

export default BlockDonationForm;
