import React from 'react';

import { graphRequest } from 'services/contentful';

import { assetFragment } from 'lib/graphql/fragments';

import BlockProductPairingsSlides from './BlockProductPairingsSlides';
import PreviewBlockProductPairingsSlides from './PreviewBlockProductPairingsSlides';

const query = /* GraphQL */ `
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockProductPairingsSlides(preview: $preview, id: $id) {
            __typename
            anchorId
            title
            eyebrow
            headline {
                json
            }
            copy {
                json
            }
            portraitImage {
                ...Asset
            }
            landscapeImage {
                ...Asset
            }
            slidesCollection(limit: 20) {
                items {
                    title
                    eyebrow
                    heading {
                        json
                    }
                    copy {
                        json
                    }
                    pairings
                    portraitImage {
                        ...Asset
                    }
                    landscapeImage {
                        ...Asset
                    }
                    flushTop
                    flushBottom
                }
            }
            flushTop
            flushBottom
            textColor
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockProductPairingsSlides },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockProductPairingsSlides;
}

const BlockProductPairingsSlidesServer = async ({ id, preview }) => {
    const block = await fetchBlock(id);
    const slides = block.slidesCollection.items;

    return preview ? (
        <PreviewBlockProductPairingsSlides {...block} slides={slides} />
    ) : (
        <BlockProductPairingsSlides {...block} slides={slides} />
    );
};

export default BlockProductPairingsSlidesServer;
