'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import CardCTA from 'components/cards/CardCTA';

import useMediaQuery from 'hooks/useMediaQuery';
import { blockRootProps } from 'utils';

import GridRow from './GridRow';

import styles from './BlockGrid.module.scss';

const BlockGrid = ({
    __typename,
    anchorId,
    gridRow,
    flushTop,
    flushBottom,
    flushBottomMobile,
    fullWidth,
    ctaCard,
    roundedTop,
    roundedBottom,
    backgroundColor,
}) => {
    flushTop ??= true;
    roundedTop ??= true;
    roundedBottom ??= true;

    const isMedium = useMediaQuery('(min-width: 768px)');

    return (
        <div
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.blockGrid, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.flushBottomMobile]: flushBottomMobile,
                [styles.fullWidth]: fullWidth,
                [styles.roundedTop]: roundedTop,
                [styles.roundedBottom]: roundedBottom,
            })}
            style={{
                '--background-color': backgroundColor
                    ? `var(--colors-${backgroundColor})`
                    : 'transparent',
            }}
        >
            <div className={styles.blockGridInner}>
                {gridRow?.map((gridItem, index) => {
                    return <GridRow key={index} {...gridItem} />;
                })}
                <div className={styles.ctaCardWrapper}>
                    {ctaCard && <CardCTA isLandscape={isMedium} {...ctaCard} />}
                </div>
            </div>
        </div>
    );
};
BlockGrid.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    ctaCard: PropTypes.object,
    flushBottom: PropTypes.bool,
    flushBottomMobile: PropTypes.bool,
    flushTop: PropTypes.bool,
    fullWidth: PropTypes.bool,
    gridRow: PropTypes.array,
    roundedBottom: PropTypes.bool,
    roundedTop: PropTypes.bool,
    backgroundColor: PropTypes.string,
};

export default BlockGrid;
