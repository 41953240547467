import React from 'react';

import cx from 'clsx';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import Img from 'components/ui/Img';
import EntryLink from 'components/ui/Link/EntryLink';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';

import styles from './CardDualAspectNews.module.scss';

const copyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => (
            <Text
                className={styles.copyText}
                baseTheme="bodySmall"
                themes={{ large: 'bodyMedium' }}
            >
                {children}
            </Text>
        ),
    },
};

const CardDualAspectNews = ({ headline, copy, image, link, index }) => {
    if (!link) {
        console.warn('CardDualAspectNews: Missing link prop');
        return null;
    }

    return (
        <EntryLink
            entry={link}
            className={cx(styles.root, {
                [styles.isEven]: index % 2 === 0,
            })}
        >
            <article className={styles.inner}>
                {image?.url && (
                    <div className={styles.imageContainer}>
                        <Img
                            className={styles.image}
                            src={image.url}
                            fallbackImageWidth={768}
                            alt={image.description || ''}
                            customSources={[
                                {
                                    breakpoint: 768,
                                    src: image.url,
                                    imageWidth: 1280,
                                },
                                {
                                    src: image.url,
                                    imageWidth: 768,
                                },
                            ]}
                        />
                    </div>
                )}
                {headline && (
                    <Text
                        className={styles.headline}
                        baseTheme="displayXXSmall"
                        themes={{
                            large: 'displayXSmall',
                        }}
                    >
                        {headline}
                    </Text>
                )}
                {copy && (
                    <div className={styles.copy}>
                        <RichText richText={copy} overrides={copyOverrides} />
                    </div>
                )}
            </article>
        </EntryLink>
    );
};

CardDualAspectNews.propTypes = {
    headline: PropTypes.string,
    copy: PropTypes.object,
    image: PropTypes.object,
    link: PropTypes.object.isRequired,
    index: PropTypes.number,
};

export default CardDualAspectNews;
