'use client';

import React, { useEffect } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Img from 'components/ui/Img';
import Video from 'components/ui/Video';

import styles from './BlockMediaAccordionItemMedia.module.scss';

const BlockMediaAccordionItemMedia = ({
    image,
    videoTextureMp4,
    videoTextureWebm,
    youtubeVideoId,
    isActive,
    isPrevious,
    onVideoClick,
}) => {
    const [isMounted, setIsMounted] = React.useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    return (
        <div
            className={cx(styles.root, {
                [styles.isMounted]: isMounted,
                [styles.isActive]: isActive,
                [styles.isPrevious]: isPrevious,
            })}
        >
            <Img
                className={styles.media}
                src={image.url}
                fallbackImageWidth={768}
                alt={image.description || ''}
                customSources={[
                    {
                        breakpoint: 1920,
                        src: image.url,
                        imageWidth: 1920 * 1.5,
                    },
                    {
                        breakpoint: 1440,
                        src: image.url,
                        imageWidth: 1440 * 1.5,
                    },
                    {
                        breakpoint: 1024,
                        src: image.url,
                        imageWidth: 1024 * 1.5,
                    },
                    {
                        breakpoint: 768,
                        src: image.url,
                        imageWidth: 768 * 1.5,
                    },
                    {
                        src: image.url,
                        imageWidth: 768,
                    },
                ]}
            />
            {videoTextureMp4?.url && videoTextureWebm?.url && (
                <Video
                    className={styles.media}
                    poster={image.url}
                    sources={[videoTextureWebm.url, videoTextureMp4.url]}
                />
            )}
            {youtubeVideoId && (
                <button
                    className={styles.videoPlayButton}
                    onClick={onVideoClick}
                >
                    <div className={styles.playIcon}>
                        <svg
                            width="68"
                            height="69"
                            viewBox="0 0 68 69"
                            fill="none"
                        >
                            <rect
                                x="1.5"
                                y="2.25"
                                width="65"
                                height="65"
                                rx="32.5"
                                stroke="#FFFCE6"
                                strokeWidth="3"
                            />
                            <path
                                d="M42 34.2499L29.25 41.6111L29.25 26.8887L42 34.2499Z"
                                fill="#FFFCE6"
                            />
                        </svg>
                    </div>
                </button>
            )}
        </div>
    );
};

BlockMediaAccordionItemMedia.propTypes = {
    image: PropTypes.object,
    videoTextureMp4: PropTypes.object,
    videoTextureWebm: PropTypes.object,
    youtubeVideoId: PropTypes.string,
    isActive: PropTypes.bool,
    isPrevious: PropTypes.bool,
    onVideoClick: PropTypes.func,
};

export default BlockMediaAccordionItemMedia;
