import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'components/ui/Icon';
import Link from 'components/ui/Link';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';
import TextEyebrow from 'components/ui/TextEyebrow';

import { blockRootProps } from 'utils';

import styles from './BlockDownloadableResources.module.scss';

const BlockDownloadableResources = ({
    __typename,
    anchorId,
    headline,
    resources,
    backgroundColor,
    flushTop,
    flushBottom,
}) => {
    const backgroundColors = {
        White: 'white',
        Default: 'default',
    };

    if (!resources || resources?.length < 1) {
        return null;
    }

    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(
                styles.root,
                styles[
                    `background-${
                        backgroundColors[backgroundColor || 'Default'] ||
                        'default'
                    }`
                ],
                {
                    [styles.flushTop]: flushTop,
                    [styles.flushBottom]: flushBottom,
                }
            )}
        >
            <div className={styles.inner}>
                {headline && (
                    <div className={styles.headline}>
                        <TextEyebrow headline={headline} theme="display" />
                    </div>
                )}
                <div className={styles.resources}>
                    {resources.map((resource, idx) => (
                        <div className={styles.resource} key={idx}>
                            {resource.icon && (
                                <div className={styles.resourceIconBox}>
                                    <div
                                        className={cx(
                                            styles.resourceIconWrapper
                                        )}
                                    >
                                        <Icon src={resource.icon?.icon.url} />
                                    </div>
                                </div>
                            )}
                            {resource.title && (
                                <Text
                                    className={styles.resourceTitle}
                                    baseTheme="headingSmall"
                                    as="h4"
                                >
                                    {resource.title}
                                </Text>
                            )}
                            <ul className={styles.downloadList}>
                                {resource.downloadsCollection.items.map(
                                    (download, idx) => (
                                        <li
                                            className={styles.download}
                                            key={idx}
                                        >
                                            <Link
                                                href={
                                                    download.assetDownloadLink
                                                        ?.url
                                                }
                                                className={styles.innerDownload}
                                            >
                                                <Text
                                                    baseTheme="labelMedium"
                                                    themes={{
                                                        large: 'labelLarge',
                                                    }}
                                                    className={
                                                        styles.downloadTitle
                                                    }
                                                >
                                                    {download.text}
                                                </Text>
                                                <div
                                                    className={
                                                        styles.downloadIconWrapper
                                                    }
                                                >
                                                    <SvgIcon type="download" />
                                                </div>
                                            </Link>
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

BlockDownloadableResources.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    headline: PropTypes.string,
    resources: PropTypes.shape(
        PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                icon: PropTypes.shape({
                    icon: PropTypes.shape({
                        url: PropTypes.string,
                    }),
                }),
                downloadsCollection: PropTypes.shape({
                    items: PropTypes.arrayOf(
                        PropTypes.shape({
                            assetDownloadLink: PropTypes.shape({
                                url: PropTypes.string,
                            }),
                            text: PropTypes.string,
                        })
                    ),
                }),
            })
        )
    ),
    backgroundColor: PropTypes.string,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
};

export default BlockDownloadableResources;
