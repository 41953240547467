'use client';

import React, { createRef, useState } from 'react';
import { useMeasure } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Award from 'components/ui/Award';
import Img from 'components/ui/Img';
import EntryLink from 'components/ui/Link/EntryLink';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import useIntersection from 'hooks/useIntersection';
import { useTextAutoContrast } from 'hooks/useTextAutoContrast';
import { useTheme } from 'hooks/useTheme';
import { getColor } from 'utils';

import styles from './CardProduct.module.scss';

const iconMap = {
    New: 'newPdpRibbonDark',
};

const CardProduct = ({
    __typename,
    priority,
    category,
    textColor,
    color,
    displayName,
    eyebrow,
    image,
    icon,
    slug,
    award,
}) => {
    color ??= 'blue';
    textColor ??= 'Auto';
    __typename ??= 'Product';
    priority ??= false;

    let isDark = useTextAutoContrast(color) === 'dark';
    if (textColor === 'Light') {
        isDark = false;
    } else if (textColor === 'Dark') {
        isDark = true;
    }

    const ref = createRef();
    const [theme] = useTheme();
    const [cardRef, { width }] = useMeasure();

    const [visible, setVisibility] = useState(priority);

    useIntersection(
        ref,
        isIntersecting => {
            isIntersecting ? setVisibility(true) : setVisibility(false);
        },
        {
            root: null,
            rootMargin: '0px',
            threshold: 0,
        }
    );

    if (!category) {
        return null;
    }

    return (
        <EntryLink
            entry={{ slug, __typename, category }}
            className={styles.cardLinkWrap}
        >
            <article
                ref={ref}
                style={{
                    '--card-background-color': getColor(color),
                    '--card-width': `${width}px`,
                }}
                className={cx(styles.card, styles[`theme--${theme}`])}
            >
                <div
                    ref={cardRef}
                    className={cx(styles.cardInner, {
                        [styles[`cardInner--dark-text`]]: isDark, // WCAG Contrast Level AAA large text
                    })}
                >
                    <div className={styles.cardContent}>
                        {iconMap[icon] && (
                            <div className={styles.iconPlacement}>
                                <div className={styles.icon}>
                                    <SvgIcon type={iconMap[icon]} />
                                </div>
                            </div>
                        )}
                        {image?.url && (
                            <div
                                className={cx(styles.imageContainer, {
                                    [styles[`imageContainer--portrait`]]:
                                        image.height > image.width,
                                })}
                            >
                                <figure
                                    className={cx(styles.image, {
                                        [styles.isVisible]: visible,
                                    })}
                                >
                                    <Img
                                        src={image.url}
                                        fallbackImageWidth={450}
                                        alt={
                                            image.description ||
                                            displayName ||
                                            ''
                                        }
                                        priority={priority}
                                        customSources={[
                                            {
                                                breakpoint: 1440,
                                                src: image.url,
                                                imageWidth: 450,
                                            },
                                            {
                                                breakpoint: 1024,
                                                src: image.url,
                                                imageWidth: 300,
                                            },
                                            {
                                                breakpoint: 768,
                                                src: image.url,
                                                imageWidth: 400,
                                            },
                                            {
                                                src: image.url,
                                                imageWidth: 240,
                                            },
                                        ]}
                                    />
                                    {theme == 'makers-reserve' && award && (
                                        <figure className={styles.award}>
                                            <Award richText={award} />
                                        </figure>
                                    )}
                                </figure>
                            </div>
                        )}
                        <div className={styles.textGroup}>
                            <Text.Theme
                                config={{
                                    'makers-reserve': {
                                        baseTheme: 'labelSmallAlt',
                                        themes: {
                                            xxLarge: 'labelMediumAlt',
                                        },
                                    },
                                    default: {
                                        baseTheme: 'labelSmall',
                                    },
                                }}
                                className={styles.eyebrow}
                            >
                                {eyebrow}
                            </Text.Theme>
                            <Text.Theme
                                config={{
                                    'makers-reserve': {
                                        baseTheme: 'parkinsonSmall',
                                        themes: {
                                            xxLarge: 'parkinsonMedium',
                                        },
                                    },
                                    default: {
                                        baseTheme: 'bodyLarge',
                                        themes: {
                                            large: 'bodyMedium',
                                            xxLarge: 'bodyLarge',
                                        },
                                    },
                                }}
                                className={styles.cta}
                            >
                                {displayName}
                            </Text.Theme>
                        </div>
                    </div>
                </div>
            </article>
        </EntryLink>
    );
};

CardProduct.propTypes = {
    __typename: PropTypes.string,
    award: PropTypes.object,
    category: PropTypes.object.isRequired,
    textColor: PropTypes.string,
    color: PropTypes.string,
    priority: PropTypes.bool,
    displayName: PropTypes.string,
    eyebrow: PropTypes.string,
    icon: PropTypes.string,
    image: PropTypes.shape({
        description: PropTypes.string,
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
    }),
    slug: PropTypes.string.isRequired,
};

export default CardProduct;
