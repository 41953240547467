import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './RichTextWordReveal.module.scss';

const RichTextWordReveal = ({
    richTextObject,
    staggerDelay = 0.25,
    fadeDuration = 0.3,
    reveal,
    className,
}) => {
    let wordCount = 0;

    function getWords(paragraph) {
        if (Array.isArray(paragraph?.content)) {
            return paragraph.content[0]?.value
                ?.split(' ')
                .filter(word => Boolean(word))
                .map((word, i) => (
                    <span
                        key={i}
                        className={styles.word}
                        style={{ ['--word-index']: wordCount++ }}
                    >
                        {(i > 0 ? ' ' : '') + word}
                    </span>
                ));
        }
        return null;
    }

    const rootClass = cx(styles.root, className, {
        [styles.reveal]: reveal,
    });

    const rootStyle = {
        ['--stagger-delay']: staggerDelay,
        ['--fade-duration']: fadeDuration,
    };

    return (
        <div className={rootClass} style={rootStyle}>
            {richTextObject?.json.content.map((paragraph, i) => (
                <p key={i}>
                    {i > 0 ? ' ' : ''}
                    {getWords(paragraph)}
                </p>
            ))}
        </div>
    );
};

RichTextWordReveal.propTypes = {
    richTextObject: PropTypes.object,
    staggerDelay: PropTypes.number,
    fadeDuration: PropTypes.number,
    reveal: PropTypes.bool,
    className: PropTypes.string,
};

export default RichTextWordReveal;
