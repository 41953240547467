import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    iconFragment,
    linkFragment,
    richtextLinkFragment,
} from 'lib/graphql/fragments';

import BlockInlineList from './BlockInlineList';

const query = /* GraphQL */ `
    query getBlock($id: String!, $preview: Boolean!) {
        blockCareersGrid(preview: $preview, id: $id) {
            __typename
            anchorId
            gridCollection(limit: 24) {
                items {
                    ... on CareersGridRow {
                        headline
                        gridRowCollection(limit: 10) {
                            items {
                                sys {
                                    id
                                }
                            }
                        }
                    }
                }
            }
            theme
            backgroundColor
            flushTop
            flushBottom
            lastBlock
        }
    }
`;

const queryCareersGridItem = /* GraphQL */ `
    ${iconFragment}
    ${assetFragment}
    ${linkFragment}
    ${ctaFragment}
    ${richtextLinkFragment}
    query getCareersGridItem($id: String!, $preview: Boolean!) {
        careersGridItem(preview: $preview, id: $id) {
            __typename
            sys {
                id
            }
            headline
            copy
            copyRichText {
                json
                links {
                    entries {
                        inline {
                            ...RichTextLink
                        }
                        hyperlink {
                            ...RichTextLink
                        }
                    }
                    assets {
                        hyperlink {
                            ...Asset
                        }
                    }
                }
            }
            icon {
                ... on Icon {
                    ...Icon
                }
            }
            image {
                ...Asset
            }
            cta {
                ... on Cta {
                    ...CTA
                }
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockCareersGrid },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockCareersGrid;
}

async function fetchCareersGridItem(id) {
    const {
        body: {
            data: { careersGridItem },
        },
    } = await graphRequest({
        query: queryCareersGridItem,
        variables: { id },
    });

    return careersGridItem;
}

const BlockInlineListServer = async ({ id }) => {
    const block = await fetchBlock(id);

    const colIds = [];

    block?.gridCollection?.items.forEach(row => {
        row.gridRowCollection.items.forEach(col => {
            colIds.push(col?.sys?.id);
        });
    });

    const cols = await Promise.all(colIds.map(id => fetchCareersGridItem(id)));

    block.gridCollection.items = block.gridCollection.items.map(row => {
        row.gridRowCollection.items = row.gridRowCollection.items.map(col => {
            return cols.find(item => item?.sys?.id === col?.sys.id);
        });
        return row;
    });

    return <BlockInlineList {...block} grid={block.gridCollection.items} />;
};

export default BlockInlineListServer;
