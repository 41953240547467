import React, { Suspense } from 'react';

import { graphRequest } from 'services/contentful';

import BlockPromotionPod from './BlockPromotionPod';

const query = /* GraphQL */ `
    query getBlock($id: String!, $preview: Boolean!) {
        blockPromotionPod(preview: $preview, id: $id) {
            __typename
            entryTitle
            promotionPodId
            klaviyoPropertyId
            klaviyoPropertyValue
            headline {
                json
            }
            copy
            flushTop
            flushBottom
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockPromotionPod },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockPromotionPod;
}

const BlockPromotionPodServer = async ({ id }) => {
    const block = await fetchBlock(id);
    return (
        <Suspense>
            <BlockPromotionPod {...block} />
        </Suspense>
    );
};

export default BlockPromotionPodServer;
