'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import Text from 'components/ui/Text';

import { blockRootProps } from 'utils';

import styles from './BlockTwoColumnGrid.module.scss';

const richTextBodyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text as="p" baseTheme="bodyMedium">
                    {children}
                </Text>
            );
        },
    },
};

const BlockTwoColumnGrid = ({
    __typename,
    eyebrow,
    headline,
    body,
    cta,
    image,
    imagePosition,
    flushTop,
    flushBottom,
}) => {
    imagePosition ??= 'Left';

    const richTextHeadlineOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text
                        as="h3"
                        baseTheme="displaySmall"
                        themes={{ xLarge: 'displayMedium' }}
                    >
                        {children}
                    </Text>
                );
            },
        },
    };

    return (
        <div
            {...blockRootProps(null, __typename)}
            className={cx(styles.root, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.imageRight]: imagePosition.toLowerCase() === 'Right',
            })}
        >
            {image?.url && (
                <Img
                    className={styles.image}
                    src={image.url}
                    fallbackImageWidth={768}
                />
            )}
            <Stagger className={styles.card}>
                {eyebrow && (
                    <StaggerChild className={styles.eyebrow} order={0}>
                        <Text as="p" baseTheme="labelLarge">
                            {eyebrow}
                        </Text>
                    </StaggerChild>
                )}
                {headline && (
                    <StaggerChild className={styles.headline} order={0}>
                        {typeof headline === 'string' ? (
                            <Text
                                as="h3"
                                baseTheme="displaySmall"
                                themes={{ xLarge: 'displayMedium' }}
                            >
                                {headline}
                            </Text>
                        ) : (
                            <RichText
                                overrides={richTextHeadlineOverrides}
                                richText={headline}
                            />
                        )}
                    </StaggerChild>
                )}
                {body && (
                    <StaggerChild className={styles.body} order={1}>
                        {typeof body === 'string' ? (
                            <Text as="p" baseTheme="bodyMedium">
                                {body}
                            </Text>
                        ) : (
                            <RichText
                                overrides={richTextBodyOverrides}
                                richText={body}
                            />
                        )}
                    </StaggerChild>
                )}
                {cta && (
                    <StaggerChild className={styles.cta} order={1}>
                        <ButtonCTA {...cta} />
                    </StaggerChild>
                )}
            </Stagger>
        </div>
    );
};

BlockTwoColumnGrid.propTypes = {
    __typename: PropTypes.string,
    body: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    cta: PropTypes.object,
    eyebrow: PropTypes.string,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
    headline: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
        .isRequired,
    image: PropTypes.shape({
        url: PropTypes.string,
    }),
    imagePosition: PropTypes.oneOf(['Left', 'Right']),
};

export default BlockTwoColumnGrid;
