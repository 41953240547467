import React from 'react';

import PropTypes from 'prop-types';

const search = `<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.70575 6L0 1.55564L1.64713 0L8 6L1.64713 12L0 10.4444L4.70575 6Z" fill="currentColor"/>
</svg>
`;

const Search = ({ title, className }) => {
    return (
        <span
            title={title}
            className={className}
            dangerouslySetInnerHTML={{ __html: search }}
        />
    );
};

Search.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
};

export default Search;
