import { useEffect } from 'react';

function useIntersection(
    ref,
    callback,
    options,
    // eslint-disable-next-line no-unused-vars
    dependencies = [],
    triggerOnce = true
) {
    useEffect(() => {
        const refCurrent = ref.current;
        if (refCurrent == null) {
            return;
        }

        const observer = new IntersectionObserver(([entry]) => {
            if (typeof callback === 'function') {
                if (triggerOnce && entry.isIntersecting) {
                    observer.unobserve(refCurrent);
                }
                callback(entry.isIntersecting, entry);
            }
        }, options);

        observer.observe(refCurrent);

        return () => {
            observer.unobserve(refCurrent);
        };
    }, [callback, options, ref, triggerOnce]);
}

export default useIntersection;
