'use client';

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import FormAlert from 'components/ui/FormAlert';
import ReCaptchaLegal from 'components/ui/ReCaptchaLegal';
import { BLOCKS } from 'components/ui/RichText';
import RichText from 'components/ui/RichText/Server';
import Spinner from 'components/ui/Spinner';
import Text from 'components/ui/Text';

import useSubscribe, { formStates } from 'hooks/useSubscribe';
import { getColor } from 'utils';
import { toSnakeCase } from 'utils';

import Fields from './Fields';

import styles from './SignUpForm.module.scss';

const consentOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text as="p" baseTheme="bodySmall">
                    {children}
                </Text>
            );
        },
    },
};

const disclaimerOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text as="p" baseTheme="bodyXSmall">
                    {children}
                </Text>
            );
        },
    },
};

const FIELD_MAP = {
    text: 'text',
    address: 'mail_address',
    city: 'location[city]',
    'zip code': 'location[zip]',
    state: 'location[region]',
    'email address': 'email_address',
    date: 'date',
    'phone number': 'tel',
};

const SignUpForm = ({
    fields,
    consentAgreement,
    disclaimer,
    klaviyoSourceEventName,
    newSignUpOnly,
    submitButtonText,
    alertSubscriptionExists,
    alertSubmitSuccess,
    color,
    submitButtonStyle = 'filled-blue-cream',
}) => {
    submitButtonText ??= 'Submit';
    alertSubscriptionExists ??= 'Oops, looks like you’re already subscribed.';
    alertSubmitSuccess ??= 'You’re all set! Thanks for entering.';
    color ??= 'var(--colors-blue)';

    const [isCheckboxFocus, setIsCheckboxFocus] = useState(false);

    const CAMPAIGN = klaviyoSourceEventName || '';

    const {
        getValues,
        register,
        handleSubmit,
        formState,
        formState: { errors },
        watch,
    } = useForm({});

    const { isError, isSuccess, isSubmitting, isSubscribed, subscribe } =
        useSubscribe(CAMPAIGN, newSignUpOnly);

    const fieldsWithId = fields
        .filter(field => !!FIELD_MAP[field.type])
        .map(field => {
            const id =
                field.type === 'text'
                    ? toSnakeCase(field.title)
                    : FIELD_MAP[field.type];
            return { ...field, field: id };
        });

    useEffect(() => {
        if (formState === formStates.SUCCESS) {
            if (window) {
                document.getElementById(`${CAMPAIGN}-alerts`)?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }
    }, [formState, CAMPAIGN]);

    const hasFullyConsented = () => {
        return getValues('terms');
    };

    watch('terms');

    return (
        <div
            className={styles.root}
            style={{
                '--color': getColor(color),
            }}
        >
            <form
                className={styles.form}
                onSubmit={handleSubmit(data => {
                    delete data.terms;
                    subscribe(data);
                })}
            >
                <Fields
                    fields={fieldsWithId}
                    register={register}
                    watch={watch}
                    errors={errors}
                    getValues={getValues}
                />
                <div className={styles.optInsAndSubmit}>
                    <ul className={styles.optIns}>
                        <li className={styles.optIn}>
                            <Text
                                baseTheme="bodySmall"
                                className={styles.checkbox}
                            >
                                <label
                                    className={cx(
                                        styles.checkbox,
                                        styles.label,
                                        {
                                            [styles.isFocus]: isCheckboxFocus,
                                        }
                                    )}
                                >
                                    <input
                                        {...register('terms', {
                                            required: true,
                                        })}
                                        type="checkbox"
                                        value="privacy policy"
                                        onFocus={() => setIsCheckboxFocus(true)}
                                        onBlur={() => setIsCheckboxFocus(false)}
                                    />
                                    <span className={styles.checkmark} />
                                    {consentAgreement && (
                                        <RichText
                                            overrides={consentOverrides}
                                            richText={consentAgreement}
                                        />
                                    )}
                                </label>
                            </Text>
                        </li>
                    </ul>
                    <div className={styles.submitWrapper}>
                        <ButtonCTA
                            type="submit"
                            text={submitButtonText}
                            disabled={
                                !hasFullyConsented() ||
                                isSubmitting ||
                                isSuccess ||
                                isSubscribed
                            }
                            className={styles.submit}
                            style={submitButtonStyle}
                        />
                        {isSubmitting && (
                            <div className={styles.submitSpinner}>
                                <Spinner />
                            </div>
                        )}
                    </div>
                </div>
                <div id={`${CAMPAIGN}-alerts`} className={styles.alerts}>
                    {isError && (
                        <FormAlert
                            message="Please check the form for errors"
                            type="error"
                        />
                    )}
                    {isSubscribed && (
                        <FormAlert
                            message={alertSubscriptionExists}
                            type="error"
                        />
                    )}
                    {isSuccess && (
                        <FormAlert
                            message={alertSubmitSuccess}
                            type="success"
                        />
                    )}
                </div>
                <div className={styles.rules}>
                    {disclaimer && (
                        <RichText
                            overrides={disclaimerOverrides}
                            richText={disclaimer}
                        />
                    )}
                    <ReCaptchaLegal />
                </div>
            </form>
        </div>
    );
};

SignUpForm.propTypes = {
    consentAgreement: PropTypes.object,
    disclaimer: PropTypes.object,
    eyebrow: PropTypes.string,
    fields: PropTypes.array,
    klaviyoSourceEventName: PropTypes.string,
    newSignUpOnly: PropTypes.bool,
    submitButtonText: PropTypes.string,
    alertSubscriptionExists: PropTypes.string,
    alertSubmitSuccess: PropTypes.string,
    color: PropTypes.string,
    submitButtonStyle: PropTypes.string,
};

export default SignUpForm;
