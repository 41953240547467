import React from 'react';

import { graphRequest } from 'services/contentful';

import { fragment as cardStoryFragment } from 'components/cards/CardStory/fragment';
import { fragment as videoPlayerYTFragment } from 'components/ui/VideoPlayerYT/fragment';

import { assetFragment } from 'lib/graphql/fragments';

import BlockNews from './BlockNews';
import PreviewBlockNews from './PreviewBlockNews';

const query = /* GraphQL */ `
    ${assetFragment}
    ${videoPlayerYTFragment}
    ${cardStoryFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockNews(preview: $preview, id: $id) {
            __typename
            anchorId
            headline
            eyebrow
            video {
                ...VideoPlayerYTFragment
            }
            storiesCollection(limit: 10) {
                items {
                    ...CardStoryFragment
                }
            }
            flushTop
            flushBottom
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockNews },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockNews;
}

const BlockNewsServer = async ({ id, preview }) => {
    const block = await fetchBlock(id);
    const stories = block.storiesCollection.items;

    return preview ? (
        <PreviewBlockNews {...block} stories={stories} />
    ) : (
        <BlockNews {...block} stories={stories} />
    );
};

export default BlockNewsServer;
