import React from 'react';

import PropTypes from 'prop-types';

const BorderDivotSvg2px = ({ className, color, side }) => {
    return (
        <>
            {side === 'top' && (
                <svg
                    className={className}
                    width="54px"
                    height="17px"
                    viewBox="0 0 54 17"
                >
                    <polyline
                        fill="none"
                        stroke={color}
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        points="0,1 11.4,1 26.4,15.3 40.7,1 54,1 "
                    />
                </svg>
            )}
            {side === 'right' && (
                <svg
                    className={className}
                    width="17px"
                    height="54px"
                    viewBox="0 0 17 54"
                >
                    <polyline
                        fill="none"
                        stroke={color}
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        points="16,0 16,11.4 1.7,26.4 16,40.7 16,54 "
                    />
                </svg>
            )}
            {side === 'bottom' && (
                <svg
                    className={className}
                    width="54px"
                    height="17px"
                    viewBox="0 0 54 17"
                >
                    <polyline
                        fill="none"
                        stroke={color}
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        points="54,16 42.7,16 27.7,1.7 13.4,16 0,16 "
                    />
                </svg>
            )}
            {side === 'left' && (
                <svg
                    className={className}
                    width="17px"
                    height="54px"
                    viewBox="0 0 17 54"
                >
                    <polyline
                        fill="none"
                        stroke={color}
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        points="1,54 1,42.7 15.3,27.7 1,13.4 1,0 "
                    />
                </svg>
            )}
        </>
    );
};

BorderDivotSvg2px.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    side: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};

export default BorderDivotSvg2px;
