'use client';

import React from 'react';

import PropTypes from 'prop-types';

import ButtonCircle from 'components/ui/ButtonCircle';
import Carousel from 'components/ui/Carousel';
import EntryLink from 'components/ui/Link/EntryLink';
import Text from 'components/ui/Text';

import useMediaQuery from 'hooks/useMediaQuery';
import { blockRootProps } from 'utils';

import styles from './BlockRelatedPages.module.scss';

const jaggedTopSmall = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 375 10">
        <path
            fill="currentColor"
            d="M0 0v10h9.373L0 0ZM18.785 0 9.412 10h37.436L37.475 0 28.13 9.97 18.785 0ZM56.26 0l-9.373 10h37.475L74.989 0l-9.365 9.99L56.26 0ZM93.774 0 84.4 10h37.437l-9.373-10-9.345 9.97L93.774 0ZM131.249 0l-9.373 10h18.746l-9.373-10ZM150.016 0l-9.373 10h18.746l-9.373-10ZM168.801 0l-9.373 10h37.436l-9.373-10-9.345 9.97L168.801 0ZM206.276 0l-9.373 10h37.475l-9.373-10-9.365 9.99L206.276 0ZM243.79 0l-9.374 10h37.437L262.48 0l-9.345 9.97L243.79 0ZM281.265 0l-9.373 10h37.492l-9.373-10-9.373 10-9.373-10ZM318.796 0l-9.373 10h37.437l-9.374-10-9.345 9.97L318.796 0ZM356.271 0l-9.373 10L375 9.97V0l-9.365 9.99L356.271 0Z"
        />
    </svg>
);

const jaggedTopLarge = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1440 12">
        <path
            fill="currentColor"
            d="M0 0v12h11.61L0 0ZM23.268 0l-11.61 12h46.37L46.419 0 34.844 11.964 23.268 0ZM58.077 12l11.61-12 11.599 11.989L92.886 0l11.609 12H58.077ZM116.153 0l-11.61 12h46.371l-11.61-12-11.575 11.964L116.153 0ZM150.962 12l11.61-12 11.61 12h-23.22ZM185.818 0l-11.61 12h23.22l-11.61-12ZM197.476 12l11.61-12 11.575 11.964L232.237 0l11.61 12h-46.371ZM255.505 0l-11.61 12h46.418l-11.61-12-11.599 11.989L255.505 0ZM290.361 12l11.61-12 11.576 11.964L325.122 0l11.61 12h-46.371ZM348.39 0l-11.61 12h46.44L371.61 0 360 12 348.39 0ZM383.268 12l11.61-12 11.576 11.964L418.029 0l11.61 12h-46.371ZM441.297 0l-11.61 12h46.418L464.496 0l-11.6 11.989L441.297 0ZM476.153 12l11.61-12 11.576 11.964L510.914 0l11.61 12h-46.371ZM534.182 0l-11.61 12h23.22l-11.61-12ZM545.818 12l11.61-12 11.61 12h-23.22ZM580.696 0l-11.61 12h46.371l-11.61-12-11.576 11.964L580.696 0ZM615.505 12l11.61-12 11.599 11.989L650.313 0l11.611 12h-46.419ZM673.581 0l-11.61 12h46.371l-11.61-12-11.575 11.964L673.581 0ZM708.39 12 720 0l11.61 12h-23.22ZM743.268 0l-11.61 12h46.371l-11.61-12-11.576 11.964L743.268 0ZM778.077 12l11.61-12 11.599 11.989L812.885 0l11.61 12h-46.418ZM836.153 0l-11.61 12h46.371l-11.61-12-11.575 11.964L836.153 0ZM870.962 12l11.61-12 11.61 12h-23.22ZM905.818 0l-11.61 12h23.22l-11.61-12ZM917.476 12l11.61-12 11.575 11.964L952.237 0l11.61 12h-46.371ZM975.505 0l-11.61 12h46.415L998.703 0l-11.599 11.989L975.505 0ZM1010.36 12l11.61-12 11.58 11.964L1045.12 0l11.61 12h-46.37ZM1068.39 0l-11.61 12h46.44l-11.61-12L1080 12l-11.61-12ZM1103.27 12l11.61-12 11.57 11.964L1138.03 0l11.61 12h-46.37ZM1161.3 0l-11.61 12h46.42L1184.5 0l-11.6 11.989L1161.3 0ZM1196.15 12l11.61-12 11.58 11.964L1230.91 0l11.61 12h-46.37ZM1254.18 0l-11.61 12h23.22l-11.61-12ZM1265.82 12l11.61-12 11.61 12h-23.22ZM1300.7 0l-11.61 12h46.37l-11.61-12-11.58 11.964L1300.7 0ZM1335.5 12l11.61-12 11.6 11.989L1370.31 0l11.61 12h-46.42ZM1393.58 0l-11.61 12h46.37l-11.61-12-11.57 11.964L1393.58 0ZM1428.39 12 1440 0v12h-11.61Z"
        />
    </svg>
);

const BlockRelatedPages = ({ __typename, headline, links, showBorder }) => {
    headline ??= 'More to Explore';
    showBorder ??= true;

    const isLarge = useMediaQuery('(min-width: 1024px)', false);

    const renderTop = () => (isLarge ? jaggedTopLarge() : jaggedTopSmall());

    return (
        <>
            {showBorder && (
                <span className={styles.jaggedTop}>{renderTop()}</span>
            )}
            <div {...blockRootProps(null, __typename)} className={styles.root}>
                <div className={styles.header}>
                    <Text
                        className={styles.headline}
                        as="p"
                        baseTheme="displayXSmall"
                    >
                        {headline}
                    </Text>
                </div>
                {links && (
                    <Carousel
                        className={styles.carousel}
                        slideClassName={styles.slide}
                        hideScrollBars
                        hasNavigation={false}
                    >
                        {links.map((item, index) => (
                            <EntryLink
                                key={index}
                                className={styles.link}
                                entry={item.link}
                            >
                                <Text
                                    className={styles.linkText}
                                    baseTheme="bodySmall"
                                    themes={{ medium: 'bodyMedium' }}
                                >
                                    {item.text}
                                </Text>
                                <ButtonCircle
                                    as="span"
                                    className={styles.linkIcon}
                                    theme="small-fill-creamdark"
                                    iconType="chevronRight"
                                />
                            </EntryLink>
                        ))}
                    </Carousel>
                )}
            </div>
        </>
    );
};

BlockRelatedPages.propTypes = {
    __typename: PropTypes.string,
    headline: PropTypes.string,
    links: PropTypes.array.isRequired,
    showBorder: PropTypes.bool,
};

export default BlockRelatedPages;
