'use client';

import React, { useEffect } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import VideoPlayerYT from 'components/ui/VideoPlayerYT';

import { blockRootProps } from 'utils';

import styles from './BlockVideo.module.scss';

const BlockVideo = ({
    __typename,
    anchorId,
    video,
    flushTop,
    flushBottom,
    embeddedEntry,
    insert,
}) => {
    useEffect(() => {
        if (!video) {
            dispatchWarning('No video provided. Video required for render.');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatchWarning = warning => {
        if (warning) {
            console.warn(warning);
        }
    };

    return (
        <div
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.videoBlock, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.embeddedEntry]: embeddedEntry,
                [styles.insert]: insert,
            })}
        >
            <div className={styles.inner}>
                <VideoPlayerYT {...video} embeddedEntry={embeddedEntry} />
            </div>
        </div>
    );
};

BlockVideo.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    video: PropTypes.object,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    embeddedEntry: PropTypes.bool,
    insert: PropTypes.bool,
};

export default BlockVideo;
