import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import styles from './FormAlert.module.scss';

const Alert = ({ message, type }) => {
    if (!message && !type) {
        return null;
    }

    return (
        <div className={cx(styles.root, styles[type])}>
            <Text
                baseTheme="bodySmall"
                themes={{
                    large: 'bodyMedium',
                }}
            >
                {message}
            </Text>
        </div>
    );
};

Alert.propTypes = {
    message: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['success', 'error']).isRequired,
};

export default Alert;
