import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    iconFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockDownloadableResources from './BlockDownloadableResources';

const query = /* GraphQL */ `
    ${iconFragment}
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockDownloadableResources(preview: $preview, id: $id) {
            __typename
            anchorId
            headline
            resourcesCollection(limit: 20) {
                items {
                    title
                    icon {
                        ... on Icon {
                            ...Icon
                        }
                    }
                    downloadsCollection(limit: 20) {
                        items {
                            ...CTA
                        }
                    }
                    theme
                }
            }
            backgroundColor
            flushTop
            flushBottom
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockDownloadableResources },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockDownloadableResources;
}

const BlockDownloadableResourcesServer = async ({ id }) => {
    const block = await fetchBlock(id);

    return (
        <BlockDownloadableResources
            {...block}
            resources={block.resourcesCollection.items}
        />
    );
};

export default BlockDownloadableResourcesServer;
