import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import TextEyebrow from 'components/ui/TextEyebrow';

import styles from './CardColumnContent.module.scss';

const CardColumnContent = ({
    eyebrow,
    headline,
    richBodyCopy,
    cta,
    cta2,
    image,
    imageLarge,
    backgroundTheme,
}) => {
    backgroundTheme ??= 'white';

    return (
        <Stagger
            className={cx(styles.columnContent, {
                [styles.creamBackground]: backgroundTheme === 'cream',
            })}
        >
            <div className={styles.columnContentInner}>
                {headline && (
                    <StaggerChild order={0} className={styles.eyebrowWrapper}>
                        <TextEyebrow
                            eyebrow={eyebrow ? eyebrow : ''}
                            headline={headline}
                        />
                    </StaggerChild>
                )}
                {richBodyCopy && (
                    <StaggerChild order={1} className={styles.textWrapper}>
                        <RichText richText={richBodyCopy} />
                    </StaggerChild>
                )}
                {cta && (
                    <StaggerChild
                        order={2}
                        className={cx(styles.buttonWrapper, {
                            [styles.ctaDuo]: cta?.text && cta2?.text,
                        })}
                    >
                        {cta && <ButtonCTA className={styles.cta} {...cta} />}
                        {cta2 && (
                            <ButtonCTA
                                className={cx(styles.cta, styles.cta2)}
                                {...cta2}
                            />
                        )}
                    </StaggerChild>
                )}
            </div>
            {image?.url && imageLarge?.url && (
                <StaggerChild order={3} className={styles.imgWrapper}>
                    <Img
                        className={styles.image}
                        src={image.url}
                        fallbackImageWidth={500}
                        alt={image.description || ''}
                        customSources={[
                            {
                                breakpoint: 768,
                                src: imageLarge.url,
                                imageWidth: imageLarge.width,
                            },
                            {
                                src: image.url,
                                imageWidth: image.width,
                            },
                        ]}
                    />
                </StaggerChild>
            )}
        </Stagger>
    );
};

CardColumnContent.propTypes = {
    eyebrow: PropTypes.string,
    headline: PropTypes.string.isRequired,
    hasArrowIcon: PropTypes.bool,
    richBodyCopy: PropTypes.object,
    cta: PropTypes.object,
    cta2: PropTypes.object,
    backgroundTheme: PropTypes.string,
    image: PropTypes.shape({
        description: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
    imageLarge: PropTypes.shape({
        description: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
};

export default CardColumnContent;
