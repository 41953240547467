'use client';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';

import cx from 'clsx';

import ButtonCTA from 'components/ui/ButtonCTA';
import carouselContext from 'components/ui/CarouselProvider/CarouselContext';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';

import { getColor } from 'utils';

import OverflowCarouselNav from './OverflowCarouselNav';
import OverflowCarouselText from './OverflowCarouselText';
import OverflowSlide from './OverflowSlide';

import styles from './BlockOverflowCarousel.module.scss';

const OverflowCarousel = () => {
    const ref = useRef(null);
    const [isInView, setIsInView] = useState(false);

    const context = useContext(carouselContext);

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    useEffect(() => {
        if (intersection?.isIntersecting) {
            setIsInView(true);
        }
    }, [intersection]);

    const activeSlide = context.slides?.[context.activeIndex];
    const backgroundColor = activeSlide?.backgroundColor;

    return (
        <section
            ref={ref}
            className={cx(styles.root, {
                [styles.isInView]: isInView,
            })}
            style={{
                '--background-color': getColor(backgroundColor),
            }}
        >
            <div className={styles.inner}>
                <div className={styles.slides}>
                    {context.slides?.map((slide, i) => (
                        <OverflowSlide
                            key={i}
                            {...slide}
                            isActive={context.activeIndex === i}
                            isPrev={context.prevIndex === i}
                        />
                    ))}
                </div>
                <Stagger className={styles.content}>
                    <div className={styles.text}>
                        <OverflowCarouselText />
                    </div>
                    <div className={styles.buttons}>
                        {context.slides?.map((slide, i) =>
                            slide.cta ? (
                                <StaggerChild
                                    key={i}
                                    order={2}
                                    className={styles.button}
                                >
                                    <div
                                        className={cx(styles.buttonTransition, {
                                            [styles.isActive]:
                                                context.activeIndex === i,
                                        })}
                                    >
                                        <ButtonCTA
                                            {...slide.cta}
                                            style="filled-blue-cream"
                                            tabIndex={
                                                context.activeIndex === i
                                                    ? 0
                                                    : -1
                                            }
                                        />
                                    </div>
                                </StaggerChild>
                            ) : null
                        )}
                    </div>
                    <div className={styles.nav}>
                        <OverflowCarouselNav isInView={isInView} />
                    </div>
                </Stagger>
            </div>
        </section>
    );
};

export default OverflowCarousel;
