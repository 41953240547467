'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Icon from 'components/ui/Icon';
import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import Text from 'components/ui/Text';

import { blockRootProps } from 'utils';

import RichHeadline from './RichHeadline';

import styles from './BlockTwoColumnImage.module.scss';

const richTextOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    baseTheme="bodySmall"
                    themes={{ large: 'bodyMedium' }}
                >
                    {children}
                </Text>
            );
        },
        [BLOCKS.UL_LIST]: (node, children) => {
            return <ul className={styles.richUnorderedList}>{children}</ul>;
        },
    },
};

const BlockTwoColumnImage = ({
    __typename,
    anchorId,
    eyebrow,
    headline,
    richHeadline,
    bodyCopy,
    richText,
    theme,
    leftImage,
    className,
    image,
    cta,
    ctas,
    icon,
    flushTop,
    flushBottom,
    backgroundColor,
}) => {
    backgroundColor ??= 'creamDark';

    return (
        <article
            {...blockRootProps(anchorId, __typename)}
            style={{ backgroundColor: `var(--colors-${backgroundColor})` }}
            className={cx(styles.blockTwoColumnFlex, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <div
                className={cx(
                    styles.blockTwoColumn,
                    className,
                    styles[`theme--${theme}`],
                    {
                        [styles.blockTwoColumnReversed]: leftImage,
                    }
                )}
            >
                <div className={styles.column}>
                    {image?.url && (
                        <Img
                            className={styles.image}
                            src={image.url}
                            fallbackImageWidth={500}
                            alt={image.description || ''}
                            customSources={[
                                {
                                    breakpoint: 768,
                                    src: image.url,
                                    imageWidth: 1024,
                                },
                                {
                                    src: image.url,
                                    imageWidth: 732,
                                },
                            ]}
                        />
                    )}
                </div>
                <div className={styles.column}>
                    <Stagger className={styles.lockup}>
                        {icon && (
                            <StaggerChild
                                order={0}
                                className={cx(styles.iconWrapper)}
                            >
                                <Icon src={icon?.icon.url} />
                            </StaggerChild>
                        )}
                        {eyebrow && (
                            <StaggerChild order={0} className={styles.eyebrow}>
                                <Text
                                    baseTheme="labelMedium"
                                    themes={{
                                        large: 'labelLarge',
                                    }}
                                >
                                    {eyebrow}
                                </Text>
                            </StaggerChild>
                        )}
                        {headline && !richHeadline && (
                            <StaggerChild order={0} className={styles.headline}>
                                <Text
                                    baseTheme="displayXSmall"
                                    themes={{
                                        large: 'displayMedium',
                                    }}
                                >
                                    {headline}
                                </Text>
                            </StaggerChild>
                        )}
                        {richHeadline && (
                            <StaggerChild
                                order={0}
                                className={styles.richHeadline}
                            >
                                <RichHeadline richText={richHeadline} />
                            </StaggerChild>
                        )}
                        {bodyCopy && !richText && (
                            <StaggerChild order={1} className={styles.copy}>
                                <Text
                                    baseTheme="bodySmall"
                                    themes={{
                                        large: 'bodyMedium',
                                    }}
                                    fixWidows
                                >
                                    {bodyCopy}
                                </Text>
                            </StaggerChild>
                        )}
                        {richText && (
                            <StaggerChild order={1} className={styles.copy}>
                                <RichText
                                    richText={richText}
                                    overrides={richTextOverrides}
                                />
                            </StaggerChild>
                        )}
                        <StaggerChild
                            order={2}
                            className={cx(styles.buttonWrapper, {
                                [styles.multiButton]: ctas?.length,
                            })}
                        >
                            {ctas?.length ? (
                                <>
                                    {ctas?.map((cta, idx) => (
                                        <ButtonCTA
                                            key={idx}
                                            className={styles.cta}
                                            {...cta}
                                        />
                                    ))}
                                </>
                            ) : (
                                <>
                                    {cta && (
                                        <ButtonCTA
                                            className={styles.cta}
                                            {...cta}
                                        />
                                    )}
                                </>
                            )}
                        </StaggerChild>
                    </Stagger>
                </div>
            </div>
        </article>
    );
};

BlockTwoColumnImage.propTypes = {
    __typename: PropTypes.string,
    eyebrow: PropTypes.string,
    anchorId: PropTypes.string,
    backgroundColor: PropTypes.string,
    bodyCopy: PropTypes.string,
    className: PropTypes.string,
    cta: PropTypes.shape({
        __typename: PropTypes.string,
        assetDownloadLink: PropTypes.object,
        cta: PropTypes.shape({
            text: PropTypes.string,
        }),
        link: PropTypes.object,
        text: PropTypes.string,
    }),
    ctas: PropTypes.array,
    icon: PropTypes.shape({
        icon: PropTypes.shape({
            url: PropTypes.string,
        }),
    }),
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
    headline: PropTypes.string,
    image: PropTypes.object,
    leftImage: PropTypes.bool,
    richHeadline: PropTypes.object,
    richText: PropTypes.object,
    theme: PropTypes.string,
};

export default BlockTwoColumnImage;
