import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';

import ButtonCTA from 'components/ui/ButtonCTA';
import Icon from 'components/ui/Icon';
import Text from 'components/ui/Text';

import Accordion from './Accordion';
import ContactContext from './Context';
import { animationStates } from './constants';

import styles from './BlockContactForm.module.scss';

const {
    IDLE,
    GRID_EXIT,
    GRID_ENTER,
    DETAIL_EXIT,
    DETAIL,
    DETAIL_ENTER,
    DETAIL_FADE,
} = animationStates;

export const Grid = () => {
    const [disabled, toggleDisabled] = useState(false);

    const { animationState, setContactReason, contactReason, gridConfig } =
        useContext(ContactContext);

    const exit = animationState === GRID_EXIT;
    const enter = animationState === GRID_ENTER;
    const idle = animationState === IDLE;
    const hidden = !exit && !enter && !idle;

    const handleClick = reason => {
        if (!disabled) {
            toggleDisabled(true);
            setContactReason(reason);
        }
    };

    useEffect(() => {
        if (contactReason === null && disabled) {
            toggleDisabled(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactReason]);

    return (
        <div
            role="radiogroup"
            aria-label="Contact reasons"
            className={cx(styles.grid, {
                [styles.exit]: exit,
                [styles.hidden]: hidden,
            })}
        >
            {gridConfig?.map(({ title, icon }, i) => {
                return (
                    <button
                        className={cx(styles.card, disabled && styles.disabled)}
                        key={i}
                        tabIndex={0}
                        role="radio"
                        aria-checked={false}
                        aria-label={title}
                        disabled={disabled}
                        onClick={() => handleClick(i)}
                    >
                        <div
                            className={cx(styles.content, {
                                [styles.exit]: exit && i !== contactReason,
                            })}
                        >
                            <Icon className={styles.icon} src={icon?.src} />
                            <Text
                                className={styles.label}
                                baseTheme="bodySmall"
                                themes={{ medium: 'headingMedium' }}
                            >
                                {title}
                            </Text>
                        </div>
                    </button>
                );
            })}
        </div>
    );
};

export const Detail = () => {
    const { animationState, steps, activeStep, handleReset, stepsLength } =
        useContext(ContactContext);

    const enter = animationState === DETAIL_ENTER;
    const exit = animationState === DETAIL_EXIT;
    const idle = animationState === DETAIL;
    const fade = animationState === DETAIL_FADE;
    const hidden = !idle && !exit && !enter && !fade;

    return (
        <div
            className={cx(styles.detail, {
                [styles.hidden]: hidden,
                [styles.fade]: fade,
            })}
        >
            {steps?.map(
                (item, i) =>
                    i <= activeStep && (
                        <Accordion
                            key={i}
                            title={item.title}
                            index={i}
                            pagination={`${i + 1}/${stepsLength}`}
                            enter={enter}
                            exit={exit}
                            idle={idle || fade}
                        >
                            {item.render && item?.render()}
                        </Accordion>
                    )
            )}
            <div
                className={cx(styles.resetButton, {
                    [styles.isVisible]: idle || fade,
                })}
            >
                <ButtonCTA
                    text="Start Over"
                    style="filled-blue-ocean"
                    onClick={handleReset}
                />
            </div>
        </div>
    );
};
