import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Icon from 'components/ui/Icon';
import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText/Server';
import Text from 'components/ui/Text';

import styles from './InlineListRow.module.scss';

const InlineListRow = ({ gridRow }) => {
    const gridLength = gridRow?.length;

    return (
        <div className={styles.gridRow}>
            {gridRow &&
                gridRow.map((item, id) => {
                    return (
                        <div
                            className={cx(styles.gridItem, {
                                [styles.gridThreeItem]: gridLength === 3,
                            })}
                            key={id}
                        >
                            <div className={styles.lockup}>
                                {item.icon && !item?.image?.url && (
                                    <div className={styles.iconBox}>
                                        <div className={cx(styles.iconWrapper)}>
                                            <Icon src={item?.icon?.icon?.url} />
                                        </div>
                                    </div>
                                )}

                                {item?.image?.url && (
                                    <div className={styles.imageContainer}>
                                        <Img
                                            className={styles.image}
                                            src={item.image.url}
                                            alt={item.image.description || ''}
                                            fallbackImageWidth={768}
                                            customSources={[
                                                {
                                                    breakpoint: 1440,
                                                    src: item.image.url,
                                                    imageWidth: 768 * 1.5,
                                                },
                                                {
                                                    src: item.image.url,
                                                    imageWidth: 768,
                                                },
                                            ]}
                                        />
                                    </div>
                                )}

                                {item.headline && (
                                    <Text
                                        className={cx(styles.headline)}
                                        baseTheme="labelLarge"
                                    >
                                        {item.headline}
                                    </Text>
                                )}

                                {item.copyRichText ? (
                                    <div className={styles.copy}>
                                        <RichText
                                            richText={item.copyRichText}
                                        />
                                    </div>
                                ) : item.copy ? (
                                    <Text
                                        className={styles.copy}
                                        baseTheme={'bodySmall'}
                                        themes={{
                                            xLarge: 'bodyMedium',
                                        }}
                                    >
                                        {item.copy}
                                    </Text>
                                ) : null}

                                {item.cta && (
                                    <ButtonCTA
                                        className={styles.cta}
                                        {...item.cta}
                                        style="filled-blue-ocean"
                                    />
                                )}
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

InlineListRow.propTypes = {
    gridRow: PropTypes.array,
};

export default InlineListRow;
