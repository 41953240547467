import React, { Suspense } from 'react';

import { graphRequest } from 'services/contentful';

import { assetFragment } from 'lib/graphql/fragments';

import BlockFoodServiceProductList from './BlockFoodServiceProductList';

const query = /* GraphQL */ `
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockFoodserviceProducts(preview: $preview, id: $id) {
            __typename
            anchorId
            theme
            headline
            eyebrow
            copy {
                json
            }
            useCategorySort
            productsCollection(limit: 100) {
                items {
                    ... on FoodServiceProduct {
                        sys {
                            id
                        }
                        displayName
                        pageTitle
                        slug
                        searchKeywords
                        attributes
                        category {
                            attributes
                            slug
                        }
                        cardImage {
                            ...Asset
                        }
                        color
                        eyebrow
                        image {
                            ...Asset
                        }
                        theme
                        award
                    }
                }
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockFoodserviceProducts },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockFoodserviceProducts;
}

const BlockFoodServiceProductListServer = async ({ id, searchParams }) => {
    const block = await fetchBlock(id);

    return (
        <Suspense>
            <BlockFoodServiceProductList
                {...block}
                searchParams={searchParams}
                products={block.productsCollection.items}
            />
        </Suspense>
    );
};

export default BlockFoodServiceProductListServer;
