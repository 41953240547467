import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockTwoColumnCarousel from './BlockTwoColumnCarousel';

const query = /* GraphQL */ `
    ${ctaFragment}
    ${linkFragment}
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockTwoColumnCarousel(preview: $preview, id: $id) {
            __typename
            eyebrow
            headline
            copy
            cta {
                ...CTA
            }
            imagesCollection {
                items {
                    ...Asset
                }
            }
            flushTop
            flushBottom
            imageOnLeft
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockTwoColumnCarousel },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockTwoColumnCarousel;
}

const BlockTwoColumnCarouselServer = async ({ id }) => {
    const block = await fetchBlock(id);
    return (
        <BlockTwoColumnCarousel
            {...block}
            images={block.imagesCollection.items}
        />
    );
};

export default BlockTwoColumnCarouselServer;
