'use client';

import React, { Fragment } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import CardProductCategory from 'components/cards/CardProductCategory';
import EntryLink from 'components/ui/Link/EntryLink';

import { blockRootProps } from 'utils';

import styles from './BlockCardTrioInline.module.scss';

const renderCard = card => {
    return (
        <div className={styles.productCard}>
            <EntryLink entry={card.cta?.link}>
                <CardProductCategory
                    backgroundColor={
                        card.productCategoryCard?.productBackgroundColor
                    }
                    image={card.productCategoryCard?.productImage}
                    title={card.cta?.text}
                    theme="card"
                />
            </EntryLink>
        </div>
    );
};

const BlockCardTrioInline = ({
    __typename,
    anchorId,
    cards,
    flushTop,
    flushBottom,
    className,
}) => {
    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.blockCardTrioInline, className, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <div className={styles.inner}>
                <ul className={styles.cards}>
                    {cards &&
                        cards?.map((card, i) => {
                            if (i < cards.length - 1) {
                                return (
                                    <Fragment key={i}>
                                        <li className={styles.listItem}>
                                            {renderCard(card)}
                                        </li>
                                        <li
                                            className={styles.listItemGap}
                                            role="presentation"
                                        />
                                    </Fragment>
                                );
                            }
                            return (
                                <li key={i} className={styles.listItem}>
                                    {renderCard(card)}
                                </li>
                            );
                        })}
                </ul>
            </div>
        </section>
    );
};

BlockCardTrioInline.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    cards: PropTypes.array,
    className: PropTypes.string,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
};

export default BlockCardTrioInline;
