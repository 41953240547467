import React from 'react';

import { graphRequest } from 'services/contentful';

import { assetFragment } from 'lib/graphql/fragments';

import BlockIssueBriefGoals from './BlockIssueBriefGoals';
import PreviewBlockIssueBriefGoals from './PreviewBlockIssueBriefGoals';

const query = /* GraphQL */ `
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockIssueBriefGoals(preview: $preview, id: $id) {
            __typename
            anchorId
            headline
            headlineImage {
                ...Asset
            }
            fineprint
            goalsCollection {
                items {
                    headline
                    subHeadline
                    description
                    richText {
                        json
                    }
                    image {
                        ...Asset
                    }
                }
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockIssueBriefGoals },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockIssueBriefGoals;
}

const BlockIssueBriefGoalsServer = async ({ id, preview }) => {
    const block = await fetchBlock(id);
    const goals = block.goalsCollection.items;

    return preview ? (
        <PreviewBlockIssueBriefGoals {...block} goals={goals} />
    ) : (
        <BlockIssueBriefGoals {...block} goals={goals} />
    );
};

export default BlockIssueBriefGoalsServer;
