export const fragment = /* GraphQL */ `
    fragment CardLocationFragment on CardLocation {
        location
        logo {
            ...Asset
        }
        address
        linkToGoogleMaps
        linkToReservation
        hoursCollection(limit: 4) {
            items {
                ... on LocationHours {
                    days
                    hours
                }
            }
        }
        descriptionLine1
        descriptionLine2
        descriptionLine3
        linkToLearnMore {
            ...EntryLink
        }
        linkToMenu {
            ...CTA
        }
        contactName
        contactEmail
        contactNumber
    }
`;
