import React from 'react';

import { graphRequest } from 'services/contentful';

import { Block } from 'app/templates/renderBlocks';

import { sharpnessStoryFragment } from 'components/blocks/BlockSharpnessStory';
import SharpnessStory from 'components/overlays/SharpnessStory';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    ${sharpnessStoryFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockSharpnessStoryProxy(preview: $preview, id: $id) {
            __typename
            anchorId
            sharpnessStory {
                ...SharpnessStoryFragment
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockSharpnessStoryProxy },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockSharpnessStoryProxy;
}

const BlockPromotionalServer = async ({ id }) => {
    const block = await fetchBlock(id);

    const { sharpnessStory } = block;

    return (
        <SharpnessStory
            {...sharpnessStory}
            masthead={
                sharpnessStory?.masthead && (
                    <Block
                        id={block?.sharpnessStory.masthead.sys.id}
                        type="BlockFullWidthMedia"
                        theme="Sharpness Story"
                    />
                )
            }
            items={sharpnessStory.itemsCollection.items}
            isPage
        />
    );
};

export default BlockPromotionalServer;
