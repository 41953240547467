'use client';

import React, { useRef, useState } from 'react';

import cx from 'classnames';
import { m } from 'framer-motion';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button';
import SvgIcon from 'components/ui/SvgIcon';

import styles from './Spinner.module.scss';

const Spinner = ({ children, className }) => {
    const constraintsRef = useRef(null);
    const [rotation, setRotation] = useState(0);

    const onDrag = (event, info) => {
        setRotation(rotation + info.delta.x);
    };

    return (
        <m.div className={styles.spinnerWrapper} ref={constraintsRef}>
            <m.div
                className={styles.image}
                drag="x"
                dragElastic={0}
                onDrag={onDrag}
                dragMomentum={false}
                dragConstraints={constraintsRef}
            >
                <m.div
                    className={className}
                    animate={{ rotate: rotation }}
                    transition={{
                        ease: 'easeOut',
                        duration: 1.5,
                    }}
                >
                    {children}
                </m.div>
                <div className={styles.buttonWrapper}>
                    <Button className={styles.button}>
                        <span
                            className={cx(styles.buttonIcon, styles.arrowLeft)}
                        >
                            <SvgIcon type="arrowLeft" />
                        </span>
                        Drag to Explore
                        <span
                            className={cx(styles.buttonIcon, styles.arrowRight)}
                        >
                            <SvgIcon type="arrowRight" />
                        </span>
                    </Button>
                </div>
            </m.div>
        </m.div>
    );
};

Spinner.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

export default Spinner;
