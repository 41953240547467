import React from 'react';

import { graphRequest } from 'services/contentful';

import SignUpForm from 'components/globals/SignUpForm';

import { assetFragment } from 'lib/graphql/fragments';

import BlockSignUpForm from './BlockSignUpForm';

const query = /* GraphQL */ `
    ${assetFragment}
    fragment Link on Entry {
        __typename
        sys {
            id
        }
        ... on ExternalLink {
            url
            target
        }
        ... on LinkEvent {
            url
        }
        ... on ManualLink {
            as
            href
        }
        ... on News {
            slug
        }
        ... on Page {
            slug
        }
        ... on Faq {
            slug
            category {
                slug
            }
        }
        ... on Product {
            category {
                slug
            }
            slug
        }
        ... on Recipe {
            slug
        }
        ... on SharpnessStory {
            closeLink {
                slug
            }
        }
        ... on XolaReservationOverlay {
            xolaButtonId
            type
        }
    }
    query getBlock($id: String!, $preview: Boolean!) {
        blockSignUpForm(preview: $preview, id: $id) {
            __typename
            eyebrow
            headline
            body {
                json
                links {
                    entries {
                        block {
                            ...Link
                        }
                        inline {
                            ...Link
                        }
                        hyperlink {
                            ...Link
                        }
                    }
                    assets {
                        hyperlink {
                            __typename
                            ...Asset
                        }
                        block {
                            __typename
                            ...Asset
                        }
                    }
                }
            }
            form {
                sys {
                    id
                }
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockSignUpForm },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockSignUpForm;
}

const BlockSignUpFormServer = async ({ id }) => {
    const block = await fetchBlock(id);
    const formId = block?.form?.sys?.id;

    return (
        <BlockSignUpForm
            {...block}
            signUpForm={formId && <SignUpForm id={formId} />}
        />
    );
};

export default BlockSignUpFormServer;
