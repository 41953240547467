import React from 'react';

import PropTypes from 'prop-types';

import Img from 'components/ui/Img';
import Text from 'components/ui/Text';

import styles from './CardLeadership.module.scss';

export const fragment = /* GraphQL */ `
    fragment CardLeadershipFragment on CardLeadership {
        name
        position
        image {
            ...Asset
        }
    }
`;

const CardLeadership = ({ name, position, image }) => {
    return (
        <article className={styles.leadership}>
            <div className={styles.imageContainer}>
                <Img
                    className={styles.image}
                    src={image.url}
                    fallbackImageWidth={480}
                    alt={image.description || ''}
                    width={image?.width}
                    height={image?.height}
                    customSources={[{ src: image.url, imageWidth: 480 }]}
                />
            </div>
            <Text
                baseTheme="headingMedium"
                themes={{ large: 'headingLarge' }}
                className={styles.name}
                as="h5"
            >
                {name}
            </Text>
            <Text
                className={styles.position}
                baseTheme="labelSmall"
                themes={{ large: 'labelLarge' }}
                as="p"
            >
                {position}
            </Text>
        </article>
    );
};

CardLeadership.propTypes = {
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    image: PropTypes.shape({
        description: PropTypes.string,
        url: PropTypes.string.isRequired,
        height: PropTypes.number,
        width: PropTypes.number,
    }).isRequired,
};

export default CardLeadership;
