import React from 'react';

import PropTypes from 'prop-types';

import CarouselProvider from 'components/ui/CarouselProvider';

import { blockRootProps } from 'utils';

import CarouselHero from './CarouselHero';

const BlockCarouselHero = ({
    __typename,
    anchorId,
    slides,
    timerDuration,
    isContinuous,
}) => {
    timerDuration ??= 7;

    return (
        <div {...blockRootProps(anchorId, __typename)}>
            <CarouselProvider
                slides={slides}
                timerDuration={timerDuration}
                isContinuous={isContinuous}
            >
                <CarouselHero />
            </CarouselProvider>
        </div>
    );
};

BlockCarouselHero.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    timerDuration: PropTypes.number,
    slides: PropTypes.array,
    isContinuous: PropTypes.bool,
};

export default BlockCarouselHero;
