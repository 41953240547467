import React, { useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button';
import Grid from 'components/ui/Grid';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import FilterCheckbox from './FilterCheckbox';
import FilterSelect from './FilterSelect';

import styles from './Filter.module.scss';

const Filter = ({
    children,
    onSearch,
    onClear,
    theme = 'default',
    headline,
    eyebrow,
    initialSearchValue = '',
    handleInputKeyDown = () => {},
    clickableSearch = false,
    showClear = false,
}) => {
    const [searchValue, updateSearch] = useState(initialSearchValue);
    const ref = useRef();

    const handleSearch = evt => {
        // track search for clearing
        updateSearch(evt.target.value);
        // handle search in parent
        !clickableSearch && onSearch(evt);
    };

    const handleClear = () => {
        // clear search input
        updateSearch('');
        // clear in parent
        onClear();
    };

    useEffect(() => {
        updateSearch(initialSearchValue);
    }, [initialSearchValue]);

    const renderContent = () => {
        return (
            <>
                <div className={styles.search}>
                    <Text as="label" baseTheme="bodyMedium">
                        <input
                            ref={ref}
                            name="search"
                            placeholder={
                                initialSearchValue.length
                                    ? initialSearchValue
                                    : 'Search'
                            }
                            type="text"
                            onChange={handleSearch}
                            onKeyDown={handleInputKeyDown}
                            value={searchValue}
                        />
                        <div
                            className={cx(styles.searchIcon, {
                                [styles.clickable]: clickableSearch,
                            })}
                            onClick={() =>
                                clickableSearch ? onSearch(ref) : null
                            }
                        >
                            <SvgIcon type="search" />
                        </div>
                    </Text>
                </div>
                {onClear && typeof onClear === 'function' ? (
                    <div
                        className={cx(styles.clearWrapper, {
                            [styles.isHidden]: !showClear,
                        })}
                    >
                        <Button
                            onClick={handleClear}
                            theme={''}
                            className={styles.clearBtn}
                        >
                            <Text
                                className={styles.clear}
                                as="span"
                                baseTheme="labelSmallAlt"
                            >
                                Clear
                            </Text>
                        </Button>
                    </div>
                ) : null}
                <div className={styles.filters}>{children}</div>
            </>
        );
    };

    return (
        <>
            {eyebrow && headline && (
                <div
                    className={cx(styles.filterMasthead, [
                        styles[`theme--${theme}`],
                    ])}
                >
                    {eyebrow && (
                        <Text
                            baseTheme="labelSmall"
                            themes={{ large: 'labelLarge' }}
                            className={styles.filterMastheadEyebrow}
                        >
                            {eyebrow}
                        </Text>
                    )}
                    {headline && (
                        <Text
                            className={styles.filterMastheadHeadline}
                            baseTheme="displaySmall"
                            themes={{ large: 'displayLarge' }}
                            as="h1"
                        >
                            {headline}
                        </Text>
                    )}
                </div>
            )}
            <div className={cx(styles.filter, [styles[`theme--${theme}`]])}>
                {theme === 'block' ? (
                    <div className={styles.block}>{renderContent()}</div>
                ) : (
                    <Grid>{renderContent()}</Grid>
                )}
            </div>
        </>
    );
};

Filter.Select = FilterSelect;
Filter.Checkbox = FilterCheckbox;

Filter.propTypes = {
    children: PropTypes.node,
    onSearch: PropTypes.func,
    onClear: PropTypes.func,
    theme: PropTypes.oneOf(['default', 'all', 'block', 'foodService'])
        .isRequired,
    headline: PropTypes.string,
    eyebrow: PropTypes.string,
    initialSearchValue: PropTypes.string,
    handleInputKeyDown: PropTypes.func,
    showClear: PropTypes.bool,
    clickableSearch: PropTypes.bool,
    // ^ toogles search behavior between onChange (filter) or onClick (submit)
};

export default Filter;
