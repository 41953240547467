'use client';

import React, { useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ButtonCircle from 'components/ui/ButtonCircle';
import Img from 'components/ui/Img';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import { blockRootProps } from 'utils';

import CardSwipe from './CardSwipe';

import styles from './BlockCardSwipe.module.scss';

const BlockCardSwipe = ({
    __typename,
    anchorId,
    cards,
    cover,
    image,
    imageLarge,
    className,
    icon,
}) => {
    const hasIcon = icon?.url;

    const ref = useRef(null);

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    const [isInView, setIsInView] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [prevIndex, setPrevIndex] = useState(activeIndex);
    const [dir, setDir] = useState('');
    const total = cards.length - 1;

    useEffect(() => {
        setIsInView(!!intersection?.isIntersecting);
    }, [intersection]);

    const slideChange = dir => {
        setPrevIndex(activeIndex);
        if (dir === 1) {
            setActiveIndex(activeIndex + 1 > total ? 0 : activeIndex + 1);
        } else if (dir === -1) {
            setActiveIndex(activeIndex - 1 < 0 ? total : activeIndex - 1);
        }
    };

    const handleNext = () => {
        slideChange(1);
        setDir('next');
    };

    const handlePrev = () => {
        slideChange(-1);
        setDir('prev');
    };

    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            ref={ref}
            className={cx(styles.blockCardSwipe, className, {
                [styles.isInView]: isInView,
            })}
        >
            {image?.url && imageLarge?.url && (
                <Img
                    className={styles.image}
                    src={image.url}
                    fallbackImageWidth={752}
                    alt={image.description || ''}
                    customSources={[
                        {
                            breakpoint: 1024,
                            src: imageLarge.url,
                            imageWidth: 2400,
                        },
                        {
                            breakpoint: 768,
                            src: imageLarge.url,
                            imageWidth: 1007,
                        },
                        {
                            src: image.url,
                            imageWidth: 752,
                        },
                    ]}
                />
            )}

            <div className={styles.inner}>
                {hasIcon ? (
                    <div className={styles.iconContainer}>
                        <img
                            className={styles.customIcon}
                            src={icon.url}
                            alt=""
                        />
                    </div>
                ) : (
                    <>
                        {cover && cover === 'Our Culture Logo' && (
                            <div
                                className={cx(styles.cover, styles.ourCulture)}
                            >
                                <SvgIcon type="ourCulture" />
                            </div>
                        )}
                    </>
                )}

                <div className={styles.cards}>
                    {cards &&
                        cards.map((card, i) => {
                            return (
                                <CardSwipe
                                    key={i}
                                    isActive={activeIndex === i}
                                    isPrev={prevIndex === i}
                                    dirNext={dir === 'next'}
                                    onClick={handleNext}
                                    onTap={handleNext}
                                    onSwipedLeft={handleNext}
                                    onSwipedRight={handlePrev}
                                    {...card}
                                />
                            );
                        })}
                </div>
                <div className={styles.nav}>
                    <ButtonCircle
                        onClick={handlePrev}
                        className={styles.navItem}
                        theme="small-fill-white"
                        iconType="chevronLeft"
                    >
                        <span className="sr-only">Previous Card</span>
                    </ButtonCircle>
                    <Text baseTheme="labelSmall" className={styles.navCount}>
                        {`${activeIndex + 1}/${total + 1}`}
                    </Text>
                    <ButtonCircle
                        onClick={handleNext}
                        className={styles.navItem}
                        theme="small-fill-white"
                        iconType="chevronRight"
                    >
                        <span className="sr-only">Next Card</span>
                    </ButtonCircle>
                </div>
            </div>
        </section>
    );
};

BlockCardSwipe.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    cards: PropTypes.array,
    cover: PropTypes.string,
    icon: PropTypes.object,
    timerDuration: PropTypes.number,
    className: PropTypes.string,
    image: PropTypes.shape({
        description: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
    imageLarge: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
};

export default BlockCardSwipe;
