import React from 'react';
import { IMask, IMaskInput } from 'react-imask';

import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import styles from './Time.module.scss';

const MASK_OPTIONS = {
    overwrite: true,
    autofix: true,
    mask: 'HH:MM',
    blocks: {
        HH: {
            mask: IMask.MaskedRange,
            placeholderChar: 'HH',
            from: 0,
            to: 23,
            maxLength: 2,
        },
        MM: {
            mask: IMask.MaskedRange,
            placeholderChar: 'MM',
            from: 0,
            to: 59,
            maxLength: 2,
        },
    },
};

const Time = ({ fieldName, register, watch, setValue }) => {
    watch(fieldName);
    const { ref, ...rest } = register(fieldName);

    return (
        <div className={styles.root}>
            <Text baseTheme="headingXSmall">
                <IMaskInput
                    {...rest}
                    {...MASK_OPTIONS}
                    unmask={true}
                    inputRef={ref}
                    className={styles.input}
                    // eslint-disable-next-line no-unused-vars
                    onAccept={(value, _mask) => {
                        setValue(fieldName, value);
                    }}
                    placeholder="##:##"
                />
            </Text>
        </div>
    );
};

Time.propTypes = {
    fieldName: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
};

export default Time;
