export const animationStates = {
    IDLE: 'idle',
    GRID_EXIT: 'grid_exit',
    GRID_ENTER: 'grid_enter',
    DETAIL_ENTER: 'detail_enter',
    DETAIL: 'detail',
    DETAIL_EXIT: 'detail_exit',
    DETAIL_FADE: 'detail_fade',
    END: 'end',
};

export const FEEDBACK_TYPES = {
    INQUIRY: 'Ask a product question',
    COMPLAINT: 'Report an issue with a product',
    VISIT: 'Ask about visiting us',
    SUPPORT: 'Get support for our online shop',
    PRAISE: 'Send a compliment',
    OTHER: 'Something else',
};

export const MARKETING_LABELS = {
    FIRST_TIME_BUYER: {
        'Ask a product question':
            'Are you a recent first time buyer of Tillamook products?',
        'Report an issue with a product':
            'Was this your first time purchasing a Tillamook product?',
        'Send a compliment':
            'Are you a recent first time buyer of Tillamook products?',
        'Something else':
            'Are you a recent first time buyer of Tillamook products?',
    },
    WHERE_PURCHASED: {
        'Ask a product question':
            "What's your go-to store for Tillamook products?",
        'Report an issue with a product':
            'Where did you purchase this product?',
        'Send a compliment': "What's your go-to store for Tillamook products?",
        'Something else': "What's your go-to store for Tillamook products?",
    },
};

export const timingBase = 750; // milliseconds or .75 seconds
