'use client';

import React, { useContext, useEffect } from 'react';

import cx from 'clsx';
import PropTypes from 'prop-types';

import ButtonCircle from 'components/ui/ButtonCircle';
import carouselContext from 'components/ui/CarouselProvider/CarouselContext';
import { MODE } from 'components/ui/CarouselProvider/carouselConstants';
import Text from 'components/ui/Text';

import styles from './OverflowCarouselNav.module.scss';

const { PLAYING, STOPPED } = MODE;

const OverflowCarouselNav = ({ isInView }) => {
    const context = useContext(carouselContext);

    const dashLength = 90;
    const increment = dashLength / context.timerDuration;
    const strokeDashoffset = dashLength - context.seconds * increment;

    useEffect(() => {
        const handleKeyUp = event => {
            switch (event.key) {
                case 'ArrowLeft':
                    context.prevSlide();
                    break;
                case 'ArrowRight':
                    context.nextSlide();
                    break;
                default:
                    break;
            }
        };

        if (isInView) {
            window.addEventListener('keyup', handleKeyUp);
        } else {
            window.removeEventListener('keyup', handleKeyUp);
        }

        return () => {
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [context, isInView]);

    return (
        <div
            className={cx(styles.root, {
                [styles.isContinuous]: context.isContinuous,
                [styles[PLAYING]]: context.mode === PLAYING,
                [styles.isSwitching]: context.isContinuous
                    ? context.seconds === 0 && !context.whileInterrupt
                    : context.seconds === 0 && context.mode !== STOPPED,
            })}
        >
            <ButtonCircle
                onClick={context.prevSlide}
                className={styles.navItem}
                theme="small-fill-blue"
                iconType="chevronLeft"
                tabIndex={isInView ? 0 : -1}
            >
                <span className="sr-only">Previous Slide</span>
            </ButtonCircle>
            <Text baseTheme="labelSmall" className={styles.navCount}>
                {`${context.activeIndex + 1}/${context.totalSlides}`}
            </Text>
            <ButtonCircle
                onClick={context.nextSlide}
                className={styles.navItem}
                theme="small-fill-blue"
                iconType="chevronRight"
                tabIndex={isInView ? 0 : -1}
            >
                <span className="sr-only">Next Slide</span>
                <span className={styles.navItemAnimation}>
                    <svg width="31px" height="31px" viewBox="0 0 31 31">
                        <circle
                            style={{
                                strokeDashoffset,
                            }}
                            className={styles.strokeCircle}
                            fill="none"
                            stroke="var(--colors-cream)"
                            strokeWidth="2"
                            cx="15.5"
                            cy="15.5"
                            r="14.5"
                            strokeDasharray="90"
                            strokeDashoffset="90"
                            strokeMiterlimit="0"
                        />
                    </svg>
                </span>
            </ButtonCircle>
        </div>
    );
};

OverflowCarouselNav.propTypes = {
    isInView: PropTypes.bool,
};

export default OverflowCarouselNav;
