'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import Button from 'components/ui/Button';
import RichText from 'components/ui/RichText';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import { blockRootProps } from 'utils';

import styles from './BlockTwoColumnText.module.scss';

const BlockTwoColumnText = ({
    __typename,
    anchorId,
    eyebrow,
    richHeadline,
    bodyCopy,
    tip,
    cta,
    className,
    flushTop,
    flushBottom,
    theme,
}) => {
    theme ??= 'default';

    const richTextOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text
                        as="p"
                        className={styles.paragraph}
                        baseTheme="bodyMedium"
                    >
                        {children}
                    </Text>
                );
            },
            [BLOCKS.HEADING_3]: (node, children) => {
                return (
                    <Text
                        as="h3"
                        className={styles.heading3}
                        baseTheme="headingSmall"
                        themes={{
                            medium:
                                theme === 'large'
                                    ? 'headingMedium'
                                    : 'headingLarge',
                        }}
                    >
                        {children}
                    </Text>
                );
            },
        },
    };
    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.twoColumnTextFlex, className, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <div className={styles.twoColumnText}>
                {richHeadline && (
                    <div
                        className={cx(styles.column, {
                            [styles.largeHeading]: theme === 'large',
                        })}
                    >
                        <Stagger className={styles.richEyebrow}>
                            {eyebrow && (
                                <StaggerChild
                                    order={0}
                                    className={styles.eyebrow}
                                >
                                    <Text
                                        baseTheme={'labelSmall'}
                                        themes={{ medium: 'labelLarge' }}
                                        as="h3"
                                    >
                                        {eyebrow}
                                    </Text>
                                </StaggerChild>
                            )}
                            <StaggerChild order={1} className={styles.headline}>
                                <Text
                                    as="h2"
                                    baseTheme={
                                        theme === 'large'
                                            ? 'displayMedium'
                                            : 'displaySmall'
                                    }
                                    themes={
                                        theme === 'small'
                                            ? {
                                                  medium: 'displayMedium',
                                              }
                                            : theme === 'large'
                                            ? {
                                                  large: 'displayXLarge',
                                                  medium: 'displayLarge',
                                              }
                                            : {
                                                  medium: 'displayLarge',
                                              }
                                    }
                                >
                                    {richHeadline.json.content.map(
                                        (line, i) => {
                                            if (line.content[0].value === '') {
                                                return null;
                                            }
                                            return (
                                                <span
                                                    className={styles.line}
                                                    key={i}
                                                >
                                                    {line.content[0].value}
                                                </span>
                                            );
                                        }
                                    )}
                                </Text>
                            </StaggerChild>
                        </Stagger>
                    </div>
                )}
                <div
                    className={cx(styles.column, {
                        [styles.largeHeading]: theme === 'large',
                    })}
                >
                    <div className={styles.columnInner}>
                        {bodyCopy && (
                            <RichText
                                richText={bodyCopy}
                                overrides={richTextOverrides}
                            />
                        )}
                        {tip && (
                            <div className={styles.tillatipFlex}>
                                <div className={styles.tillatip}>
                                    <div className={styles.tillatipIcon}>
                                        <SvgIcon type="lightbulb" />
                                    </div>
                                    <Text
                                        className={styles.tillatipLabel}
                                        baseTheme="labelSmall"
                                    >
                                        Tilla Tip:
                                    </Text>
                                    <Text baseTheme="bodyXSmall"> {tip}</Text>
                                </div>
                            </div>
                        )}
                        {cta && (
                            <div className={styles.buttonWrapper}>
                                {cta.assetDownloadLink ? (
                                    <Button
                                        download
                                        className={styles.cta}
                                        theme="outlinedBlue"
                                        iconLeading="download"
                                        href={cta.assetDownloadLink.file?.url}
                                    >
                                        {cta.text}
                                    </Button>
                                ) : (
                                    <Button
                                        className={styles.cta}
                                        theme="outlinedBlue"
                                        entry={cta.link}
                                    >
                                        {cta.text}
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

BlockTwoColumnText.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    bodyCopy: PropTypes.object,
    className: PropTypes.string,
    cta: PropTypes.shape({
        assetDownloadLink: PropTypes.bool,
        link: PropTypes.object,
        text: PropTypes.string,
    }),
    eyebrow: PropTypes.string,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
    richHeadline: PropTypes.object.isRequired,
    theme: PropTypes.string,
    tip: PropTypes.string,
};

export default BlockTwoColumnText;
