import React from 'react';

import PropTypes from 'prop-types';

import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';

import styles from './Goal.module.scss';

const Goal = ({ subHeadline, headline, image, bodyCopy, richText }) => {
    return (
        <div className={styles.goal}>
            <div className={styles.imageBlock}>
                <Img
                    src={image?.url}
                    fallbackImageWidth={225}
                    alt={subHeadline}
                    customSources={[
                        { breakpoint: 768, imageWidth: 160 },
                        { breakpoint: 320, imageWidth: 140 },
                        { src: image?.url, imageWidth: 225 },
                    ]}
                />
            </div>
            {subHeadline && (
                <Text
                    as="h1"
                    baseTheme="labelSmall"
                    themes={{ large: 'labelLarge' }}
                    className={styles.subHeadline}
                >
                    {subHeadline}
                </Text>
            )}
            {headline && (
                <Text
                    as="h2"
                    baseTheme="bodyLarge"
                    themes={{ large: 'bodyXLarge' }}
                    className={styles.headline}
                >
                    {headline}
                </Text>
            )}
            {bodyCopy && (
                <Text
                    as="span"
                    baseTheme="bodySmall"
                    themes={{ large: 'bodyMedium' }}
                    className={styles.bodyCopy}
                >
                    {bodyCopy}
                </Text>
            )}
            {richText && (
                <RichText className={styles.bodyCopy} richText={richText} />
            )}
        </div>
    );
};

Goal.propTypes = {
    bodyCopy: PropTypes.string,
    headline: PropTypes.string,
    image: PropTypes.object,
    richText: PropTypes.object,
    subHeadline: PropTypes.string,
};

export default Goal;
