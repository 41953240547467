import React from 'react';

import { graphRequest } from 'services/contentful';

import BlockGlobal from './BlockGlobal';

const query = /* GraphQL */ `
    query getBlock($id: String!, $preview: Boolean!) {
        blockGlobal(preview: $preview, id: $id) {
            __typename
            anchorId
            flushTop
            block
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockGlobal },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockGlobal;
}

const BlockGlobalServer = async ({ id }) => {
    const block = await fetchBlock(id);
    return <BlockGlobal {...block} />;
};

export default BlockGlobalServer;
