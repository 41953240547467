import React from 'react';

import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';

import styles from './BlockAnchorProductList.module.scss';

const BlockAnchorProductList = ({
    image,
    imageLarge,
    headline,
    copy,
    richText,
    anchorName,
}) => {
    const richTextOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text
                        as="p"
                        className={styles.paragraph}
                        baseTheme="bodySmall"
                        themes={{ large: 'headingXSmall' }}
                    >
                        {children}
                    </Text>
                );
            },
            [BLOCKS.HEADING_2]: (node, children) => {
                return (
                    <Text
                        as="h3"
                        className={styles.heading3Alt}
                        baseTheme="bodyLarge"
                    >
                        {children}
                    </Text>
                );
            },
            [BLOCKS.HEADING_3]: (node, children) => {
                return (
                    <Text
                        as="h3"
                        className={styles.heading3}
                        baseTheme="bodyLarge"
                    >
                        {children}
                    </Text>
                );
            },
            [BLOCKS.HEADING_4]: (node, children) => {
                return (
                    <Text
                        as="h4"
                        className={styles.heading4}
                        baseTheme="labelMedium"
                    >
                        {children}
                    </Text>
                );
            },
        },
    };

    const renderImage = () => {
        const sources = [
            {
                src: image?.url,
                imageWidth: 500,
            },
        ];

        if (imageLarge?.url) {
            sources.unshift({
                breakpoint: 768,
                src: imageLarge.url,
                imageWidth: 800,
            });
        }

        return (
            image?.url && (
                <Img
                    className={styles.image}
                    src={image?.url}
                    fallbackImageWidth={500}
                    alt={image?.description || ''}
                    customSources={sources}
                />
            )
        );
    };

    return (
        <article
            id={anchorName ? `${anchorName}` : ''}
            className={styles.blockAnchorProductList}
        >
            <div className={styles.introLockup}>
                {headline?.json.content.length && copy && (
                    <div className={styles.textLockup}>
                        <div className={styles.headline}>
                            {headline.json.content.map((line, i) => {
                                if (line.content[0].value === '') {
                                    return null;
                                }
                                return (
                                    <Text
                                        key={i}
                                        as="h3"
                                        baseTheme="displaySmall"
                                        themes={{
                                            medium: 'displayMedium',
                                        }}
                                    >
                                        {line.content[0].value}
                                    </Text>
                                );
                            })}
                        </div>
                        {copy && (
                            <Text
                                as="h5"
                                baseTheme="bodyXSmall"
                                themes={{ large: 'bodyMedium' }}
                                className={styles.copy}
                            >
                                {copy}
                            </Text>
                        )}
                    </div>
                )}
                {renderImage()}
            </div>

            {richText && (
                <div className={styles.richText}>
                    <RichText
                        richText={richText}
                        overrides={richTextOverrides}
                    />
                </div>
            )}
        </article>
    );
};

BlockAnchorProductList.propTypes = {
    headline: PropTypes.object,
    copy: PropTypes.string,
    anchorName: PropTypes.string,
    image: PropTypes.shape({
        description: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
    imageLarge: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
    richText: PropTypes.object,
};

export default BlockAnchorProductList;
