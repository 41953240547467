import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Icon from 'components/ui/Icon';
import Text from 'components/ui/Text';

import styles from './CardCTA.module.scss';

const CardCTA = ({ headline, cta, isLandscape, icon }) => {
    isLandscape ??= false;

    if (!(headline && cta?.link && cta?.text)) {
        return null;
    }

    return (
        <div className={cx(styles.cta, isLandscape && styles.landscapeCTA)}>
            <div
                className={cx(
                    styles.ctaInner,
                    isLandscape && styles.landscapeCTAInner
                )}
            >
                {icon && (
                    <div className={styles.iconWrapper}>
                        <Icon src={icon?.icon?.url} />
                    </div>
                )}
                <Text className={styles.headline} baseTheme="headingLarge">
                    {headline}
                </Text>
                <ButtonCTA {...cta} />
            </div>
        </div>
    );
};

CardCTA.propTypes = {
    headline: PropTypes.string.isRequired,
    cta: PropTypes.shape({
        link: PropTypes.object.isRequired,
        text: PropTypes.string.isRequired,
    }).isRequired,
    isLandscape: PropTypes.bool,
    icon: PropTypes.object,
};

export default CardCTA;
