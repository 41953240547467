'use client';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { checkColorContrast } from '@tillamook/brand/lib/colors';
import cx from 'classnames';
import { usePathname } from 'next/navigation';
import PropTypes from 'prop-types';

import SharpnessStory from 'components/overlays/SharpnessStory';
import SharpnessCloseButton from 'components/overlays/SharpnessStory/SharpnessCloseButton';
import ButtonCTA from 'components/ui/ButtonCTA';
import FlavorProfileGraph from 'components/ui/FlavorProfileGraph';
import Portal from 'components/ui/Portal';
import RichText, { BLOCKS } from 'components/ui/RichText';
import Text from 'components/ui/Text';
import TextEyebrow from 'components/ui/TextEyebrow';

import { useTheme } from 'hooks/useTheme';
import { getColor } from 'utils';

import styles from './BlockProductSharpnessStory.module.scss';

let closeRoute = false;

const richTextCopy = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    baseTheme="bodyXSmall"
                    themes={{
                        large: 'bodySmall',
                    }}
                    className={styles.copy}
                >
                    {children}
                </Text>
            );
        },
        [BLOCKS.UL_LIST]: (node, children) => {
            return <ul className={styles.list}>{children}</ul>;
        },
        [BLOCKS.LIST_ITEM]: (node, children) => {
            return <li className={styles.listItem}>{children}</li>;
        },
    },
};

const eyebrowColorMap = {
    'makers-reserve': {
        eyebrowColor: 'makersReserveGoldAlt',
        headlineColor: 'cream',
    },
    default: {
        eyebrowColor: 'cream',
        headlineColor: 'cream',
    },
    defaultDark: {
        eyebrowColor: 'blue',
        headlineColor: 'blue',
    },
};

const BlockProductSharpnessStory = ({
    eyebrow = '',
    headline,
    copy,
    ctaText,
    sharpnessStory,
    sharpnessStoryItem,
    sharpnessStoryPage,
    flushTop,
    flushBottom,
    backgroundColor,
    masthead,
}) => {
    const [isOpen, setOpen] = useState(false);
    const [isReveal, setIsReveal] = useState(false);
    const [globalTheme] = useTheme();
    const pathname = usePathname();

    const isDark = useMemo(
        () => checkColorContrast(backgroundColor) < 2,
        [backgroundColor]
    );

    const handleOpen = () => {
        closeRoute = pathname;
        window.history.pushState(null, null, `/${sharpnessStoryPage.slug}`);
        setOpen(true);

        setTimeout(() => {
            window.requestAnimationFrame(() => {
                setIsReveal(true);
            });
        }, 0);
    };

    const handleClose = useCallback(() => {
        window.history.pushState(
            null,
            null,
            closeRoute || `/${sharpnessStory.closeLink.slug}`
        );
        setIsReveal(false);
    }, [sharpnessStory]);

    const handleKeyDown = useCallback(() => {
        if (event.keyCode === 27) {
            handleClose();
        }
    }, [handleClose]);

    const onCloseTransitionEnd = e => {
        if (e.propertyName === 'transform' && !isReveal) setOpen(false);
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false);
        return () => {
            document.removeEventListener('keydown', handleKeyDown, false);
        };
    }, [handleKeyDown]);

    return (
        <article
            className={cx(styles.root, [styles[`theme--${globalTheme}`]], {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.isDark]: isDark,
            })}
            style={{ '--background-color': getColor(backgroundColor) }}
        >
            <div className={styles.inner}>
                <div className={styles.innerAspect}>
                    <div className={styles.aspectBox}>
                        <FlavorProfileGraph
                            isDark={isDark}
                            values={sharpnessStoryItem?.flavorProfileData}
                        />
                    </div>
                </div>
                <div className={styles.innerContent}>
                    <div className={styles.block}>
                        <div className={styles.content}>
                            {headline && (
                                <TextEyebrow
                                    className={styles.eyebrow}
                                    eyebrow={eyebrow}
                                    headline={headline}
                                    theme={
                                        globalTheme === 'makers-reserve'
                                            ? 'makers-reserve'
                                            : 'ultrabold'
                                    }
                                    {...(isDark
                                        ? eyebrowColorMap.defaultDark
                                        : eyebrowColorMap[globalTheme])}
                                    globalTheme={globalTheme}
                                />
                            )}
                            {copy && (
                                <RichText
                                    className={styles.copy}
                                    richText={copy}
                                    overrides={richTextCopy}
                                />
                            )}
                            {ctaText && (
                                <ButtonCTA
                                    className={styles.cta}
                                    text={ctaText}
                                    style={
                                        globalTheme === 'makers-reserve'
                                            ? 'makersFilled-gold-cream'
                                            : 'filled-blue-cream'
                                    }
                                    onClick={handleOpen}
                                />
                            )}
                        </div>
                    </div>
                    {isOpen && (
                        <Portal>
                            <div
                                className={cx(styles.overlay, {
                                    [styles.isReveal]: isReveal,
                                })}
                                onTransitionEnd={onCloseTransitionEnd}
                            >
                                <SharpnessStory
                                    {...sharpnessStory}
                                    masthead={masthead}
                                    items={sharpnessStory.itemsCollection.items}
                                />
                            </div>
                            <SharpnessCloseButton
                                onClose={handleClose}
                                className={cx(styles.overlayCloseButton, {
                                    [styles.isReveal]: isReveal,
                                })}
                            />
                        </Portal>
                    )}
                </div>
            </div>
        </article>
    );
};

BlockProductSharpnessStory.propTypes = {
    backgroundColor: PropTypes.string,
    eyebrow: PropTypes.string,
    headline: PropTypes.object,
    copy: PropTypes.object,
    sharpnessStory: PropTypes.object,
    ctaText: PropTypes.string,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    sharpnessStoryItem: PropTypes.shape({
        flavorProfileData: PropTypes.array.isRequired,
    }),
    sharpnessStoryPage: PropTypes.shape({ slug: PropTypes.string.isRequired }),
    image: PropTypes.shape({
        description: PropTypes.string,
        url: PropTypes.string,
    }),
    imageLarge: PropTypes.shape({
        description: PropTypes.string,
        url: PropTypes.string,
    }),
    masthead: PropTypes.object,
};

export default BlockProductSharpnessStory;
