import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockSectionHeading from './BlockSectionHeading';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockEyebrow(preview: $preview, id: $id) {
            __typename
            anchorId
            eyebrow
            richHeadline {
                json
            }
            flushTop
            flushBottom
            copy: richText {
                json
            }
            cta {
                ... on Cta {
                    ...CTA
                }
            }
            theme
            textAlign
            backgroundColor: backgroundColorCustom
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockEyebrow },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockEyebrow;
}

const BlockSectionHeadingServer = async ({ id, firstBlock }) => {
    const block = await fetchBlock(id);
    return <BlockSectionHeading {...block} firstBlock={firstBlock} />;
};

export default BlockSectionHeadingServer;
