'use client';

import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import { blockRootProps } from 'utils';

import styles from './BlockHeader.module.scss';

const BlockHeader = ({ __typename, anchorId, headline, subheadline }) => {
    useEffect(() => {
        if (!headline) {
            dispatchWarning(
                'No headline provided. Headline required for render.'
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatchWarning = warning => {
        if (warning) {
            console.warn(warning);
        }
    };

    if (!headline) {
        return null;
    }

    return (
        <header
            {...blockRootProps(anchorId, __typename)}
            className={styles.blockHeader}
        >
            <Text
                as="h1"
                baseTheme="displayXSmall"
                themes={{ large: 'displayLarge' }}
                className={styles.headline}
            >
                {headline}
            </Text>

            {subheadline && (
                <Text
                    as="h2"
                    baseTheme="headingXSmall"
                    themes={{ large: 'headingMedium' }}
                    className={styles.subheadline}
                >
                    {subheadline}
                </Text>
            )}
        </header>
    );
};

BlockHeader.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    headline: PropTypes.string.isRequired,
    subheadline: PropTypes.string,
};

export default BlockHeader;
