'use client';

import React from 'react';

import ButtonCTA from '/components/ui/ButtonCTA';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Slideshow from 'components/ui/Slideshow';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import Text from 'components/ui/Text';

import { blockRootProps } from 'utils';

import styles from './BlockTwoColumnCarousel.module.scss';

const BlockTwoColumnCarousel = ({
    __typename,
    eyebrow,
    headline,
    copy,
    images,
    cta,
    lastBlock,
    flushTop,
    flushBottom,
    imageOnLeft,
    children,
    theme,
}) => {
    theme ??= 'default';

    return (
        <div
            {...blockRootProps(null, __typename)}
            className={cx(styles.root, styles[theme], {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.lastBlock]: lastBlock,
                [styles.imageOnLeft]: imageOnLeft,
            })}
        >
            <div className={styles.inner}>
                <div className={styles.column}>
                    <Slideshow images={images} />
                </div>
                <div className={styles.column}>
                    <Stagger className={styles.lockup}>
                        {eyebrow && (
                            <StaggerChild order={0} className={styles.eyebrow}>
                                <Text
                                    baseTheme="labelSmall"
                                    themes={{
                                        large: 'labelMedium',
                                    }}
                                >
                                    {eyebrow}
                                </Text>
                            </StaggerChild>
                        )}
                        {headline && (
                            <StaggerChild order={1} className={styles.headline}>
                                <Text
                                    as="h2"
                                    baseTheme={
                                        theme === 'recipes' ||
                                        theme === 'recipeDetail'
                                            ? 'headingMedium'
                                            : 'displayXSmall'
                                    }
                                    themes={{
                                        large:
                                            theme === 'recipes' ||
                                            theme === 'recipeDetail'
                                                ? 'headingXLarge'
                                                : 'displaySmall',
                                    }}
                                >
                                    {headline}
                                </Text>
                            </StaggerChild>
                        )}
                        {copy && (
                            <StaggerChild order={2} className={styles.copy}>
                                <Text
                                    baseTheme="bodySmall"
                                    themes={{
                                        large: 'bodyMedium',
                                    }}
                                    fixWidows
                                >
                                    {copy}
                                </Text>
                            </StaggerChild>
                        )}
                        {children && (
                            <StaggerChild order={2} className={styles.copy}>
                                <Text
                                    baseTheme="bodySmall"
                                    themes={{
                                        large: 'bodyLarge',
                                    }}
                                    fixWidows
                                >
                                    {children}
                                </Text>
                            </StaggerChild>
                        )}
                        {/* Render from component slot */}
                        {React.isValidElement(cta) && (
                            <div className={styles.buttonWrapper}>{cta}</div>
                        )}
                        {/* Render from model */}
                        {!React.isValidElement(cta) &&
                            (cta?.link || cta?.assetDownloadLink) && (
                                <div className={styles.buttonWrapper}>
                                    <ButtonCTA
                                        className={styles.cta}
                                        {...cta}
                                    />
                                </div>
                            )}
                    </Stagger>
                </div>
            </div>
        </div>
    );
};

BlockTwoColumnCarousel.propTypes = {
    __typename: PropTypes.string,
    children: PropTypes.node,
    copy: PropTypes.string,
    cta: PropTypes.object,
    eyebrow: PropTypes.string,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
    headline: PropTypes.string,
    imageOnLeft: PropTypes.bool,
    images: PropTypes.array,
    lastBlock: PropTypes.bool,
    theme: PropTypes.string,
};

export default BlockTwoColumnCarousel;
