import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockRelatedPages from './BlockRelatedPages';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockRelatedPages(preview: $preview, id: $id) {
            __typename
            headline
            showBorder
            linksCollection(limit: 20) {
                items {
                    ... on Cta {
                        ...CTA
                    }
                }
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockRelatedPages },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockRelatedPages;
}

const BlockRelatedPagesServer = async ({ id }) => {
    const block = await fetchBlock(id);

    return <BlockRelatedPages {...block} links={block.linksCollection.items} />;
};

export default BlockRelatedPagesServer;
