import React, { useContext, useEffect, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useMeasure, usePrevious } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import { useTheme } from 'hooks/useTheme';
import useWait from 'hooks/useWait';

import ContactContext from '../Context';

import styles from './Accordion.module.scss';

const Accordion = ({
    title,
    children,
    index,
    pagination,
    exit,
    enter,
    idle,
}) => {
    const [isOpen, setOpen] = useState(false);
    const [isDecreasing, setIsDecreasing] = useState(false);
    const [theme] = useTheme();

    const { timingBase, activeStep, handleReset, setActiveStep } =
        useContext(ContactContext);

    const wait = useWait();

    const [renderButtonTitle, setRenderButtonTitle] = useState(title);
    const previousTitle = usePrevious(title);

    const [buttonDelay, setButtonDelay] = useState(0);

    const [renderPanelChildren, setRenderPanelChildren] = useState(children);
    const previousChildren = usePrevious(children);

    const [heightRef, { height }] = useMeasure();

    const isActive = activeStep === index;

    const handleToggleClick = i => {
        setIsDecreasing(activeStep > i);

        if (i === 0) {
            handleReset();
        } else {
            setActiveStep(i);
        }
    };

    useEffect(() => {
        setOpen(isActive);
    }, [isActive]);

    useEffect(() => {
        if (title === previousTitle) return;

        wait(timingBase).then(() => {
            setRenderButtonTitle(title);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, previousTitle]);

    useEffect(() => {
        if (children === previousChildren) return;

        wait(timingBase).then(() => {
            setRenderPanelChildren(children);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children, previousChildren]);

    useEffect(() => {
        setButtonDelay(
            isDecreasing
                ? `${timingBase}ms`
                : isActive
                ? `${timingBase + index * 250}ms`
                : `${timingBase * 0.25}ms`
        );
    }, [index, isActive, isDecreasing, timingBase]);

    return (
        <div
            className={cx(styles.accordion, styles[`theme--${theme}`], {
                [styles.enter]: enter,
                [styles.exit]: exit,
                [styles.idle]: idle,
                [styles.previous]: index < activeStep,
                [styles.upcoming]: index >= activeStep,
            })}
        >
            <div className={styles.accordionInner}>
                <SwitchTransition>
                    <CSSTransition
                        key={title !== previousTitle}
                        timeout={timingBase}
                        appear
                        classNames={{
                            appear: styles['accordionToggle-appear'],
                            enter: styles['accordionToggle-enter'],
                            enterActive: styles['accordionToggle-enter-active'],
                            exit: styles['accordionToggle-exit'],
                            exitActive: styles['accordionToggle-exit-active'],
                        }}
                    >
                        <button
                            onClick={() => handleToggleClick(index)}
                            className={styles.accordionToggle}
                            style={{
                                '--button-delay': buttonDelay,
                            }}
                        >
                            {pagination && (
                                <Text.Theme
                                    className={styles.pagination}
                                    config={{
                                        default: {
                                            baseTheme: 'labelMedium',
                                            themes: { large: 'labelLarge' },
                                        },
                                    }}
                                >
                                    {pagination}
                                </Text.Theme>
                            )}
                            <Text.Theme
                                className={styles.label}
                                config={{
                                    default: {
                                        baseTheme: 'labelMedium',
                                        themes: { large: 'labelLarge' },
                                    },
                                }}
                            >
                                {renderButtonTitle}
                            </Text.Theme>
                        </button>
                    </CSSTransition>
                </SwitchTransition>

                <div
                    className={cx(styles.panel, {
                        [styles.open]: isOpen,
                        [styles.close]: !isOpen,
                    })}
                    style={{ height: isOpen ? height : 0 }}
                >
                    <div className={styles.panelInner}>
                        <div ref={heightRef} className={styles.panelContent}>
                            {renderPanelChildren}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Accordion.propTypes = {
    activeStep: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    index: PropTypes.number,
    isActive: PropTypes.bool,
    pagination: PropTypes.string,
    setActiveStep: PropTypes.func,
    title: PropTypes.string,
    handleReset: PropTypes.func,
    exit: PropTypes.bool,
    enter: PropTypes.bool,
    idle: PropTypes.bool,
};

export default Accordion;
