import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Img from 'components/ui/Img';
import Text from 'components/ui/Text';

import { blockRootProps } from 'utils';

import styles from './BlockFeaturedArticle.module.scss';

const BlockFeaturedArticle = ({
    __typename,
    anchorId,
    eyebrow,
    headline,
    image,
    imageLarge,
    cta,
    flushTop,
    flushBottom,
    className,
}) => {
    flushTop ??= true;
    flushBottom ??= false;

    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.root, className, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <div className={styles.inner}>
                <div className={styles.content}>
                    <div className={styles.background}>
                        {image?.url && imageLarge?.url && (
                            <Img
                                className={styles.image}
                                src={image.url}
                                alt={image.description || ''}
                                customSources={[
                                    {
                                        breakpoint: 1920,
                                        src: imageLarge.url,
                                        imageWidth: 1920 * 1.5,
                                    },
                                    {
                                        breakpoint: 1440,
                                        src: imageLarge.url,
                                        imageWidth: 1440 * 1.5,
                                    },
                                    {
                                        breakpoint: 1024,
                                        src: imageLarge.url,
                                        imageWidth: 1024 * 1.5,
                                    },
                                    {
                                        breakpoint: 768,
                                        src: imageLarge.url,
                                        imageWidth: 768 * 1.5,
                                    },
                                    {
                                        src: image.url,
                                        imageWidth: 768,
                                    },
                                ]}
                            />
                        )}
                        {image?.url && !imageLarge && (
                            <Img
                                className={styles.image}
                                src={image.url}
                                alt={image.description || ''}
                                customSources={[
                                    {
                                        breakpoint: 1920,
                                        src: image.url,
                                        imageWidth: 1920 * 1.5,
                                    },
                                    {
                                        breakpoint: 1440,
                                        src: image.url,
                                        imageWidth: 1440 * 1.5,
                                    },
                                    {
                                        breakpoint: 1024,
                                        src: image.url,
                                        imageWidth: 1024 * 1.5,
                                    },
                                    {
                                        breakpoint: 768,
                                        src: image.url,
                                        imageWidth: 768 * 1.5,
                                    },
                                    {
                                        src: image.url,
                                        imageWidth: 768,
                                    },
                                ]}
                            />
                        )}
                    </div>
                    <div className={styles.foreground}>
                        <Text
                            className={styles.eyebrow}
                            baseTheme="labelSmall"
                            themes={{ large: 'labelLarge' }}
                        >
                            {eyebrow}
                        </Text>
                        <Text
                            className={styles.headline}
                            baseTheme="displaySmall"
                            themes={{ large: 'displayLarge' }}
                        >
                            {headline?.json.content?.map((line, i) => {
                                if (line.content[0].value === '') {
                                    return null;
                                }
                                return (
                                    <span className={styles.line} key={i}>
                                        {line.content[0].value}
                                    </span>
                                );
                            })}
                        </Text>
                        <ButtonCTA className={styles.cta} {...cta} />
                    </div>
                </div>
            </div>
        </section>
    );
};

BlockFeaturedArticle.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    eyebrow: PropTypes.string,
    headline: PropTypes.object,
    image: PropTypes.shape({
        description: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
    imageLarge: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
    cta: PropTypes.shape({
        link: PropTypes.object,
        text: PropTypes.string,
        assetDownloadLink: PropTypes.bool,
    }),
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    className: PropTypes.string,
};

export default BlockFeaturedArticle;
