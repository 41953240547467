'use client';

import React from 'react';

import PropTypes from 'prop-types';

import { BLOCKS } from 'components/ui/RichText';
import RichText from 'components/ui/RichText/Server';
import Text from 'components/ui/Text';

import { blockRootProps } from 'utils';

import styles from './BlockSignUpForm.module.scss';

const BlockSignUpForm = ({
    __typename,
    eyebrow,
    headline,
    body,
    signUpForm,
}) => {
    const bodyOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text
                        as="p"
                        baseTheme="bodyMedium"
                        themes={{ large: 'bodyLarge' }}
                    >
                        {children}
                    </Text>
                );
            },
        },
    };

    return (
        <div {...blockRootProps(null, __typename)} className={styles.root}>
            <div className={styles.header}>
                {eyebrow && (
                    <Text
                        baseTheme="labelMedium"
                        themes={{ large: 'labelLarge' }}
                        className={styles.eyebrow}
                    >
                        {eyebrow}
                    </Text>
                )}
                {headline && (
                    <Text
                        baseTheme="displaySmall"
                        themes={{ large: 'displayMedium' }}
                        className={styles.headline}
                    >
                        {headline}
                    </Text>
                )}
                {body && (
                    <RichText
                        className={styles.body}
                        overrides={bodyOverrides}
                        richText={body}
                    />
                )}
            </div>
            {signUpForm}
        </div>
    );
};

BlockSignUpForm.propTypes = {
    __typename: PropTypes.string,
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    body: PropTypes.object,
    signUpForm: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
};

export default BlockSignUpForm;
