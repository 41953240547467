'use client';

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import useWait from 'hooks/useWait';
import { blockRootProps } from 'utils';

import ContactForm from './ContactForm';
import ContactContext from './Context';
import Form from './Form';
import CTA from './Form/CTA';
import ProductCode from './ProductCode';
import ProductsNav from './ProductsNav';
import Card from './ProductsNav/Card';
import { FEEDBACK_TYPES, animationStates, timingBase } from './constants';

const {
    IDLE,
    GRID_EXIT,
    GRID_ENTER,
    DETAIL_EXIT,
    DETAIL,
    DETAIL_ENTER,
    DETAIL_FADE,
    END,
} = animationStates;

const ContactProvider = ({
    __typename,
    productCategories,
    socialLinks,
    consumerFeedbackInformation,
    lastBlock,
    products,
}) => {
    const { retailers, plantCodes } = consumerFeedbackInformation;
    const [animationState, setAnimationState] = useState(IDLE);
    const [contactReason, setContactReason] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [stepsLength, setStepsLength] = useState(0);
    const [formState, setFormState] = useState({});

    const wait = useWait();

    const handleUpdateForm = state => {
        setFormState({ ...formState, ...state });
    };

    const handleFormSuccess = () => {
        setAnimationState(DETAIL_FADE);
    };

    const handleReset = () => {
        setAnimationState(DETAIL_EXIT);
    };

    const config = [
        {
            title: FEEDBACK_TYPES.INQUIRY,
            icon: {
                src: 'https://images.ctfassets.net/j8tkpy1gjhi5/2ahiGuxWji1oYwHcKhNUa3/ffcff03a0094d4bf8c5d3e06209e1942/Babyloaf.svg',
            },
            steps: [
                {
                    title: 'Ask a product question',
                },
                {
                    title:
                        formState.product && activeStep !== 1
                            ? `${formState.product?.pageTitle} ${formState.size?.size}`
                            : 'Pick a product',
                    render: () => (
                        <ProductsNav
                            productCategories={productCategories}
                            tabIndex={activeStep < stepsLength - 1 ? 0 : -1}
                            products={products}
                            onUpdate={state => (
                                handleUpdateForm(state),
                                setActiveStep(stepsLength - 1)
                            )}
                            additionalCategory={() => (
                                <Card
                                    image="https://images.ctfassets.net/j8tkpy1gjhi5/S8K7B60gCYxh2d5A8vuGC/bda280fb8622e31d4c6907b183dfa44d/Contact-Us-General-Question.png"
                                    onClick={state => (
                                        handleUpdateForm(state),
                                        setActiveStep(stepsLength - 1)
                                    )}
                                >
                                    General Question
                                </Card>
                            )}
                        />
                    ),
                },
                {
                    title: 'Tell us the rest',
                    render: () => (
                        <Form
                            formState={formState}
                            onSuccess={handleFormSuccess}
                        />
                    ),
                },
            ],
        },

        {
            title: FEEDBACK_TYPES.COMPLAINT,
            icon: {
                src: 'https://images.ctfassets.net/j8tkpy1gjhi5/47RUou8NWFzm0S2z91h9Q/65b386953b1d8399d1de624840dda6de/ConsumerQuote.svg',
            },

            steps: [
                {
                    title: 'Share product feedback',
                },
                {
                    title:
                        formState.product && activeStep !== 1
                            ? `${formState.product?.pageTitle} ${formState.size?.size}`
                            : 'Pick a product',
                    render: () => (
                        <ProductsNav
                            productCategories={productCategories}
                            products={products}
                            tabIndex={activeStep < stepsLength - 2 ? 0 : -1}
                            onUpdate={state => (
                                handleUpdateForm(state),
                                setActiveStep(stepsLength - 2)
                            )}
                        />
                    ),
                },
                {
                    title: 'Product Code',
                    render: () => (
                        <ProductCode
                            setActiveStep={setActiveStep}
                            onUpdate={handleUpdateForm}
                            formState={formState}
                            tabIndex={activeStep < stepsLength - 1 ? 0 : -1}
                        />
                    ),
                },
                {
                    title: 'Tell us the rest',
                    render: () => (
                        <Form
                            formState={formState}
                            onSuccess={handleFormSuccess}
                        />
                    ),
                },
            ],
        },
        {
            title: FEEDBACK_TYPES.VISIT,
            icon: {
                src: 'https://images.ctfassets.net/j8tkpy1gjhi5/5TehcyTSFfL6Byhl6NciLB/a3f6039bd45ab5edd1c059ac0cdfe095/Oregon.svg',
            },

            steps: [
                {
                    title: 'Ask about visiting us',
                },
                {
                    title: 'Tell us more',
                    render: () => (
                        <Form
                            formState={formState}
                            onSuccess={handleFormSuccess}
                            cta={() => (
                                <CTA
                                    headline="Get more info about visiting Tillamook."
                                    theme="visitUs"
                                />
                            )}
                        />
                    ),
                },
            ],
        },

        {
            title: FEEDBACK_TYPES.SUPPORT,
            icon: {
                src: 'https://images.ctfassets.net/j8tkpy1gjhi5/7JxKd0Bl4yQEqJgAq5IPqE/33b6c874c7eddace11cd35bfdab3afd8/Storefront.svg',
            },

            steps: [
                { title: 'Get support for our online shop' },
                {
                    title: 'Tell us more',
                    render: () => (
                        <Form
                            formState={formState}
                            onSuccess={handleFormSuccess}
                            defaultMessage="Please share your order number if you have it!"
                        />
                    ),
                },
            ],
        },
        {
            title: FEEDBACK_TYPES.PRAISE,
            icon: {
                src: 'https://images.ctfassets.net/j8tkpy1gjhi5/67sKYNrn3ltIa9KovSefYM/94e322bc19de73a3550221efd1537aab/Heart.svg',
            },
            steps: [
                { title: 'Send a compliment' },
                {
                    title: 'Tell us more',
                    render: () => (
                        <Form
                            formState={formState}
                            onSuccess={handleFormSuccess}
                            cta={() => (
                                <CTA
                                    headline="We’d love to hear from you."
                                    theme="compliment"
                                    socialLinks={socialLinks}
                                />
                            )}
                            defaultMessage="If you want to compliment a specific product, we’d love to know which one and where you found it!"
                        />
                    ),
                },
            ],
        },
        {
            title: FEEDBACK_TYPES.OTHER,
            icon: {
                src: 'https://images.ctfassets.net/j8tkpy1gjhi5/1GNzhmiFMZ4NI9ctT3CQC3/c9f9c62985d398fa2aece1d2c75ce7aa/Quote.svg',
            },

            steps: [
                { title: 'something else' },
                {
                    title: 'Tell us more',
                    render: () => (
                        <Form
                            formState={formState}
                            onSuccess={handleFormSuccess}
                        />
                    ),
                },
            ],
        },
    ];

    const gridConfig = config.map(({ title, icon }) => ({
        title,
        icon,
    }));

    useEffect(() => {
        if (animationState === GRID_EXIT) {
            // Wait for Grid to exit
            wait(timingBase * 1.25).then(() => {
                // Trigger the Detail to enter
                setAnimationState(DETAIL_ENTER);
            });
        }

        if (animationState === DETAIL_ENTER) {
            // Wait for Detail to enter
            wait(timingBase).then(() => {
                // Trigger idle detail
                setAnimationState(DETAIL);
            });
        }

        if (animationState === DETAIL_EXIT) {
            // Wait for Detail to exit
            wait(timingBase).then(() => {
                // Trigger the Grid to enter
                setAnimationState(GRID_ENTER);
            });
        }

        if (animationState === GRID_ENTER) {
            // *Cannot* clear contact reason until after the Detail exit
            // Detail children are dependent upon the contact reason
            setContactReason(null);

            // Wait for the Grid to enter
            wait(timingBase * 1.25).then(() => {
                // Trigger the Idle state
                setAnimationState(IDLE);
            });
        }

        if (animationState === DETAIL_FADE) {
            // Wait for Detail to fade out
            wait(timingBase * 0.5).then(() => {
                // Trigger the End state
                setAnimationState(END);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animationState]);

    useEffect(() => {
        if (contactReason !== null) {
            setStepsLength(config[contactReason]?.steps?.length);

            setFormState({
                ...formState,
                contactReason: config[contactReason]?.title,
            });

            setActiveStep(1);

            setAnimationState(GRID_EXIT);
        }

        if (contactReason === null) {
            Object.keys(formState)?.length > 0 && setFormState({});
            stepsLength > 0 && setStepsLength(0);
            activeStep > 0 && setActiveStep(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactReason]);

    return (
        <div {...blockRootProps(null, __typename)}>
            <ContactContext.Provider
                value={{
                    animationState,
                    timingBase: timingBase,
                    contactReason,
                    activeStep,
                    stepsLength,
                    steps: config[contactReason]?.steps || [],
                    gridConfig,
                    setContactReason,
                    setActiveStep,
                    handleReset,
                    retailers,
                    plantCodes,
                }}
            >
                <ContactForm socialLinks={socialLinks} lastBlock={lastBlock} />
            </ContactContext.Provider>
        </div>
    );
};

ContactProvider.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    consumerFeedbackInformation: PropTypes.shape({
        complaintCategoryRules: PropTypes.object,
        developmentLastImportUniqueId: PropTypes.number,
        plantCodes: PropTypes.object.isRequired,
        productionLastImportUniqueId: PropTypes.number,
        retailers: PropTypes.array.isRequired,
    }),
    lastBlock: PropTypes.bool,
    productCategories: PropTypes.array,
    products: PropTypes.array,
    socialLinks: PropTypes.array,
};

export default ContactProvider;
