import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import { EMAIL_REGEX } from 'utils';

import FieldDate from './FieldDate';
import FieldEmail from './FieldEmail';
import FieldState from './FieldState';
import FieldText from './FieldText';

import styles from './Fields.module.scss';

const fieldTypes = {
    text: 'text',
    'email address': 'email',
    state: 'state',
    'zip code': 'text',
    date: 'date',
    'phone number': 'tel',
};

const fieldMap = {
    date: FieldDate,
    state: FieldState,
    email: FieldEmail,
    text: FieldText,
    tel: FieldText,
};

const getFieldComponent = type => {
    return fieldMap[type] || fieldMap.text;
};

const validation = {
    email: EMAIL_REGEX,
    zip_code: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
    phone_number: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
};

const Fields = ({ fields, ...formProps }) => {
    return (
        <div className={styles.root}>
            <div className={cx(styles.campaignForm, styles.grid)}>
                {fields.map((field, index) => {
                    const inputType = fieldTypes[field.type];
                    const fieldId = field.field;

                    const FieldComponent = getFieldComponent(inputType);

                    return (
                        <div
                            key={`${fieldId}-${index}`}
                            className={cx(
                                styles.fieldWrapper,
                                styles[field.theme]
                            )}
                        >
                            <Text
                                htmlFor={fieldId}
                                as="label"
                                baseTheme="labelSmall"
                            >
                                {field.title}
                                {field.required && '*'}
                            </Text>
                            <FieldComponent
                                {...field}
                                {...formProps}
                                validation={validation?.[fieldId]}
                                type={{ display: field.type, input: inputType }}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

Fields.propTypes = {
    errors: PropTypes.object,
    fields: PropTypes.array,
    register: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
};

export default Fields;
