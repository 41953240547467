'use client';

/* eslint-disable @next/next/no-img-element */
import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import CardProduct from 'components/cards/CardProduct';
import CardSimple from 'components/cards/CardSimple';
import TextEyebrow from 'components/ui/TextEyebrow';

import { useTheme } from 'hooks/useTheme';
import { blockRootProps } from 'utils';

import styles from './BlockSimpleCards.module.scss';

const BlockSimpleCards = ({
    __typename,
    anchorId,
    cards,
    headline,
    eyebrow,
    flushTop,
    flushBottom,
}) => {
    const [globalTheme] = useTheme();

    return (
        <div
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.root, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles['theme--makers-reserve']]:
                    globalTheme === 'makers-reserve',
            })}
        >
            <TextEyebrow
                className={styles.eyebrow}
                headline={headline}
                eyebrow={eyebrow}
                globalTheme={globalTheme}
                theme={globalTheme === 'makers-reserve' ? null : 'ultrabold'}
                eyebrowColor={
                    globalTheme === 'makers-reserve'
                        ? 'makersReserveGold'
                        : 'blue'
                }
                headlineColor={globalTheme === 'makers-reserve' ? null : 'blue'}
            />
            <ul className={styles.productList}>
                {cards?.map((card, i) => {
                    return (
                        <li
                            key={i}
                            className={cx(styles.listItem, {
                                [styles.productItem]:
                                    card.__typename === 'Product',
                            })}
                        >
                            {card.__typename === 'Product' ? (
                                <CardProduct
                                    color={card.color}
                                    category={card.category}
                                    eyebrow={card.eyebrow}
                                    image={card.image}
                                    displayName={card.displayName}
                                    slug={card.slug}
                                    theme={card.theme}
                                    award={card.award}
                                />
                            ) : (
                                <CardSimple {...card} />
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

BlockSimpleCards.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    cards: PropTypes.array,
    headline: PropTypes.string,
    eyebrow: PropTypes.string,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
};

export default BlockSimpleCards;
