import React from 'react';

// import { BLOCKS } from '@contentful/rich-text-types';
// import Text from 'components/ui/Text';
import PropTypes from 'prop-types';

import RichText from 'components/ui/RichText';

import styles from './Award.module.scss';

const Award = ({ richText }) => {
    if (!richText) {
        return null;
    }

    return (
        <div className={styles.root}>
            <div className={styles.awardRichText}>
                <RichText richText={richText} />
            </div>
        </div>
    );
};

Award.propTypes = {
    richText: PropTypes.object,
};

export default Award;
