import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    iconFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockLinkList from './BlockLinkList';
import PreviewBlockLinkList from './PreviewBlockLinkList';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    ${iconFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockLinkList(preview: $preview, id: $id) {
            __typename
            anchorId
            eyebrow
            richHeadline {
                json
            }
            cta {
                ...CTA
            }
            linksCollection {
                items {
                    ... on Cta {
                        ...CTA
                    }
                    ... on LinkListItem {
                        __typename
                        headline {
                            json
                        }
                        bodyCopy {
                            json
                        }
                        link {
                            ... on ExternalLink {
                                url
                                target
                                __typename
                            }
                            ... on ManualLink {
                                as
                                href
                                __typename
                            }
                            ... on Page {
                                slug
                                __typename
                            }
                        }
                        icon {
                            ... on Icon {
                                ...Icon
                            }
                        }
                    }
                }
            }
            flushTop
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockLinkList },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockLinkList;
}

const BlockLinkListServer = async ({ id, preview }) => {
    const block = await fetchBlock(id);
    const links = block.linksCollection.items;

    return preview ? (
        <PreviewBlockLinkList {...block} links={links} />
    ) : (
        <BlockLinkList {...block} links={links} />
    );
};

export default BlockLinkListServer;
