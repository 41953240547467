'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { INLINES } from '@contentful/rich-text-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Icon from 'components/ui/Icon';
import Img from 'components/ui/Img';
import Link from 'components/ui/Link';
import EntryLink from 'components/ui/Link/EntryLink';
import RichText from 'components/ui/RichText';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import Text from 'components/ui/Text';
import TextEyebrow from 'components/ui/TextEyebrow';

import { blockRootProps } from 'utils';

import styles from './BlockProductHero.module.scss';

const richTextOverrides = {
    renderNode: {
        [INLINES.HYPERLINK]: node => {
            return (
                <Link href={node.data.uri}>
                    <b>{node.content[0].value}</b>
                </Link>
            );
        },
    },
    renderText: text =>
        text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
};

const BlockProductHero = ({
    __typename,
    anchorId,
    blockEyebrow,
    blockHeadline,
    cta,
    ctas,
    eyebrow,
    flushBottom,
    flushTop,
    headline,
    image,
    imageLarge,
    richText,
    paragraph,
    textOnRight,
    theme,
    eyebrowColor,
    headlineColor,
    hideCta,
    icon,
    firstBlock,
}) => {
    theme ??= 'Default';
    eyebrowColor ??= 'cream';
    headlineColor ??= 'cream';
    firstBlock ??= false;

    const multipleCTAs = !!ctas.length;

    if (!image?.url || !imageLarge?.url) {
        return null;
    }

    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.root, {
                [styles.featuredTheme]: theme === 'Featured',
                [styles.displayTheme]: theme === 'Display',
                [styles.hasCTA]: cta?.link,
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.textOnRight]: textOnRight,
            })}
            style={{
                '--eyebrow-color': `var(--colors-${eyebrowColor})`,
                '--headline-color': `var(--colors-${headlineColor})`,
            }}
        >
            <div className={styles.inner}>
                {blockEyebrow && blockHeadline && theme !== 'Display' && (
                    <TextEyebrow
                        eyebrow={blockEyebrow}
                        headline={blockHeadline}
                        className={styles.eyebrowBlock}
                        inView={firstBlock}
                        theme="display"
                    />
                )}
                <EntryLink
                    className={styles.contentWrapper}
                    entry={hideCta && cta?.link ? cta?.link : ''}
                >
                    <div className={styles.innerWrapperInner}>
                        <div className={styles.heroImgBlock}>
                            <Img
                                className={styles.img}
                                src={image.url}
                                fallbackImageWidth={768}
                                alt={image.description || ''}
                                width={image.width}
                                height={image.height}
                                priority={firstBlock}
                                customSources={[
                                    {
                                        breakpoint: 768,
                                        src: imageLarge.url,
                                        imageWidth:
                                            theme === 'Display' ? 2560 : 1420,
                                    },
                                    {
                                        src: image.url,
                                        imageWidth: 768,
                                    },
                                ]}
                            />
                        </div>
                        <div className={styles.innerWrapper}>
                            <Stagger
                                className={styles.textBlock}
                                inView={firstBlock}
                            >
                                {icon?.icon?.url && (
                                    <StaggerChild
                                        order={0}
                                        className={styles.mainIcon}
                                    >
                                        <Icon src={icon.icon.url} />
                                    </StaggerChild>
                                )}
                                {eyebrow && (
                                    <StaggerChild
                                        order={1}
                                        className={styles.eyebrow}
                                    >
                                        <Text
                                            baseTheme={'labelSmall'}
                                            themes={{
                                                large: 'labelLarge',
                                            }}
                                            as="h3"
                                        >
                                            {eyebrow}
                                        </Text>
                                    </StaggerChild>
                                )}
                                <StaggerChild
                                    order={2}
                                    className={styles.headlineWrapper}
                                >
                                    <Text
                                        baseTheme={
                                            theme === 'Display' ||
                                            theme === 'Featured'
                                                ? 'displaySmall'
                                                : 'displayXSmall'
                                        }
                                        themes={{
                                            large: 'displayMedium',
                                            xxLarge:
                                                theme === 'Display'
                                                    ? 'displayLargeFluid'
                                                    : null,
                                        }}
                                        className={styles.headline}
                                    >
                                        {headline}
                                    </Text>
                                </StaggerChild>
                                <div
                                    className={cx(
                                        styles.innerEyebrow,
                                        styles.eyebrowWrapper,
                                        {
                                            [styles.featuredLogo]:
                                                theme === 'Featured',
                                        }
                                    )}
                                >
                                    {richText && (
                                        <StaggerChild order={3}>
                                            <div className={styles.richText}>
                                                <RichText
                                                    richText={richText}
                                                    overrides={
                                                        richTextOverrides
                                                    }
                                                />
                                            </div>
                                        </StaggerChild>
                                    )}
                                    {!richText && paragraph && (
                                        <StaggerChild order={3}>
                                            <Text
                                                baseTheme={'bodySmall'}
                                                className={styles.innerHeadline}
                                                as="h3"
                                            >
                                                {paragraph}
                                            </Text>
                                        </StaggerChild>
                                    )}

                                    {!hideCta && (
                                        <StaggerChild
                                            order={paragraph ? 5 : 4}
                                            className={cx(
                                                styles.buttonWrapper,
                                                {
                                                    [styles.multiButton]:
                                                        multipleCTAs,
                                                }
                                            )}
                                        >
                                            {multipleCTAs ? (
                                                <>
                                                    {ctas?.map((cta, i) => (
                                                        <ButtonCTA
                                                            key={i}
                                                            {...cta}
                                                        />
                                                    ))}
                                                </>
                                            ) : (
                                                <ButtonCTA {...cta} />
                                            )}
                                        </StaggerChild>
                                    )}
                                </div>
                            </Stagger>
                        </div>
                    </div>
                </EntryLink>
            </div>
        </section>
    );
};

BlockProductHero.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    blockEyebrow: PropTypes.string,
    blockHeadline: PropTypes.string,
    cta: PropTypes.object,
    ctas: PropTypes.array,
    eyebrow: PropTypes.string,
    eyebrowColor: PropTypes.string,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
    headline: PropTypes.string,
    headlineColor: PropTypes.string,
    hideCta: PropTypes.bool,
    icon: PropTypes.object,
    image: PropTypes.object,
    imageLarge: PropTypes.object,
    paragraph: PropTypes.string,
    richText: PropTypes.object,
    textOnRight: PropTypes.bool,
    firstBlock: PropTypes.bool,
    lastBlock: PropTypes.bool,
    theme: PropTypes.oneOf(['Featured', 'Default', 'Display']),
};

export default BlockProductHero;
