'use client';

import React, { useContext } from 'react';

import cx from 'classnames';

import ButtonCTA from 'components/ui/ButtonCTA';
import ButtonCircle from 'components/ui/ButtonCircle';
import carouselContext from 'components/ui/CarouselProvider/CarouselContext';
import { MODE } from 'components/ui/CarouselProvider/carouselConstants';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import Text from 'components/ui/Text';

import CarouselShowcaseSlide from './CarouselShowcaseSlide';
import GradientPanel from './GradientPanel';

import styles from './BlockCarouselShowcase.module.scss';

const { PAUSED, PLAYING, STOPPED } = MODE;

const CarouselShowcase = () => {
    const context = useContext(carouselContext);

    const dashLength = 90;
    const increment = dashLength / context.timerDuration;
    const strokeDashoffset = dashLength - context.seconds * increment;
    return (
        <section
            className={cx(styles.root, {
                [styles.isContinuous]: context.isContinuous,
                [styles[PLAYING]]: context.mode === PLAYING,
                [styles[PAUSED]]: context.mode === PAUSED,
                [styles.isSwitching]: context.isContinuous
                    ? context.seconds === 0 && !context.whileInterrupt
                    : context.seconds === 0 && context.mode !== STOPPED,
            })}
        >
            <div className={styles.bg} role="presentation">
                {context.slides?.map((slide, i) => (
                    <GradientPanel
                        key={i}
                        {...slide}
                        isActive={context.activeIndex === i}
                        isPrev={context.prevIndex === i}
                    />
                ))}
            </div>
            <div className={styles.inner}>
                {context.slides?.map((slide, i) => (
                    <CarouselShowcaseSlide
                        key={i}
                        {...slide}
                        isActive={context.activeIndex === i}
                        isPrev={context.prevIndex === i}
                        timerDuration={context.timerDuration}
                    />
                ))}
                <div className={styles.column}>
                    <Stagger className={styles.ctaLockup}>
                        <StaggerChild
                            order={2}
                            className={styles.buttonWrapper}
                        >
                            {context.slides?.[context.activeIndex]?.cta && (
                                <ButtonCTA
                                    {...context.slides?.[context.activeIndex]
                                        ?.cta}
                                />
                            )}
                        </StaggerChild>
                        <div className={styles.nav}>
                            <ButtonCircle
                                onClick={context.prevSlide}
                                className={styles.navItem}
                                theme="small-fill-white"
                                iconType="chevronLeft"
                            >
                                <span className="sr-only">Previous Slide</span>
                            </ButtonCircle>
                            <Text
                                baseTheme="labelSmall"
                                className={styles.navCount}
                            >
                                {`${context.activeIndex + 1}/${
                                    context.totalSlides
                                }`}
                            </Text>
                            <ButtonCircle
                                onClick={context.nextSlide}
                                className={styles.navItem}
                                theme="small-fill-white"
                                iconType="chevronRight"
                            >
                                <span className="sr-only">Next Slide</span>
                                <span className={styles.navItemAnimation}>
                                    <svg
                                        width="31px"
                                        height="31px"
                                        viewBox="0 0 31 31"
                                    >
                                        <circle
                                            style={{
                                                strokeDashoffset,
                                            }}
                                            className={styles.strokeCircle}
                                            fill="none"
                                            stroke="var(--colors-blue)"
                                            strokeWidth="2"
                                            cx="15.5"
                                            cy="15.5"
                                            r="14.5"
                                            strokeDasharray="90"
                                            strokeDashoffset="90"
                                            strokeMiterlimit="0"
                                        />
                                    </svg>
                                </span>
                            </ButtonCircle>
                        </div>
                    </Stagger>
                </div>
            </div>
        </section>
    );
};

export default CarouselShowcase;
