export const fragment = /* GraphQL */ `
    fragment CardDownloadFragment on CardDownload {
        headline
        cta {
            ...CTA
        }
        image {
            ...Asset
        }
    }
`;
