import React from 'react';

import PropTypes from 'prop-types';

import ButtonCircle from 'components/ui/ButtonCircle';
import Icon from 'components/ui/Icon';
import EntryLink from 'components/ui/Link/EntryLink';
import { BLOCKS } from 'components/ui/RichText';
import RichText from 'components/ui/RichText/Server';
import Text from 'components/ui/Text';

import styles from './Item.module.scss';

const headlineOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    className={styles.linkListHeadline}
                    baseTheme="headingXSmall2"
                    fixWidows
                >
                    {children}
                </Text>
            );
        },
    },
};

const bodyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    className={styles.linkListBody}
                    baseTheme="bodyXSmall"
                    themes={{ medium: 'bodySmall' }}
                    fixWidows
                >
                    {children}
                </Text>
            );
        },
    },
};

const Item = ({ ctaItem, linkListItem }) => {
    const renderCta = () => {
        const { text, link } = ctaItem || {};
        return (
            <EntryLink className={styles.cta} entry={link}>
                <Text
                    baseTheme="bodyXSmall"
                    themes={{
                        medium: 'bodySmall',
                    }}
                    className={styles.ctaText}
                >
                    {text}
                </Text>
                <ButtonCircle
                    className={styles.circleButton}
                    theme="small-fill-blue"
                    iconType="chevronRight"
                />
            </EntryLink>
        );
    };

    const renderLinkListItem = () => {
        const { headline, bodyCopy, link, icon } = linkListItem || {};

        return (
            <EntryLink className={styles.linkListItem} entry={link}>
                {icon && (
                    <Icon src={icon.icon.url} className={styles.linkListIcon} />
                )}
                <div className={styles.linkListText}>
                    <div className={styles.linkListHeadlineContainer}>
                        {icon && (
                            <Icon
                                src={icon.icon.url}
                                className={styles.linkListIcon}
                            />
                        )}
                        {headline && (
                            <RichText
                                overrides={headlineOverrides}
                                richText={headline}
                            />
                        )}
                        {link && (
                            <ButtonCircle
                                className={styles.circleButton}
                                theme="small-fill-blue"
                                iconType="chevronRight"
                            />
                        )}
                    </div>
                    {bodyCopy && (
                        <RichText
                            overrides={bodyOverrides}
                            richText={bodyCopy}
                        />
                    )}
                </div>
                {link && (
                    <ButtonCircle
                        className={styles.circleButton}
                        theme="small-fill-blue"
                        iconType="chevronRight"
                    />
                )}
            </EntryLink>
        );
    };

    const renderItem = () =>
        ctaItem ? renderCta() : linkListItem ? renderLinkListItem() : null;

    return <li className={styles.root}>{renderItem()}</li>;
};

Item.propTypes = {
    ctaItem: PropTypes.shape({
        link: PropTypes.object,
        text: PropTypes.string,
        theme: PropTypes.string,
    }),
    linkListItem: PropTypes.shape({
        bodyCopy: PropTypes.object,
        headline: PropTypes.object,
        icon: PropTypes.object,
        link: PropTypes.object,
    }),
};

export default Item;
