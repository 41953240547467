'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import Divot from 'components/globals/Divot';

import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';
import TextEyebrow from 'components/ui/TextEyebrow';

import { useTheme } from 'hooks/useTheme';
import { blockRootProps } from 'utils';

import Banner from './layouts/Banner';

import styles from './BlockCallout.module.scss';

const layoutsMap = {
    Square: 'square',
    Banner: Banner,
    'Banner (Simple)': 'bannerSimple',
    Card: 'card',
    Masthead: 'masthead',
    Recommended: 'recommended',
    Hero: 'hero',
};

const colorThemeMap = {
    'Blue Background': { background: 'blue', color: 'cream' },
    'Cream Background': { background: 'cream', color: 'blue' },
};

const headlineOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="h2"
                    baseTheme="bodyMedium"
                    themes={{
                        large: 'bodyLarge',
                    }}
                    className={styles.simpleHeadline}
                >
                    {children}
                </Text>
            );
        },
    },
};

const BlockCallout = ({
    __typename,
    anchorId,
    eyebrow,
    richHeadline,
    copy,
    image,
    imageLarge,
    className,
    buttonComponentSlot,
    cta,
    ctaSecondary,
    ctaOnly,
    theme,
    hasNotch,
    colorTheme,
    roundedCorners,
    firstBlock,
}) => {
    theme ??= 'Card';
    hasNotch ??= false;
    colorTheme ??= 'Blue Background';
    roundedCorners ??= true;
    firstBlock ??= false;

    const [globalTheme] = useTheme();

    const hasImage = image?.url && imageLarge?.url;
    const isBannerSimple = layoutsMap[theme] === 'bannerSimple';
    const isMasthead = layoutsMap[theme] === 'masthead';
    const isHero = layoutsMap[theme] === 'hero';

    // gradually breakout these callouts into variant components
    if (typeof layoutsMap[theme] !== 'string') {
        const Variant = layoutsMap[theme];
        return (
            <div {...blockRootProps(anchorId, __typename)}>
                <Variant
                    eyebrow={eyebrow}
                    richHeadline={richHeadline}
                    copy={copy}
                    image={image}
                    imageLarge={imageLarge}
                    className={className}
                    buttonComponentSlot={buttonComponentSlot}
                    cta={cta}
                    ctaSecondary={ctaSecondary}
                    ctaOnly={ctaOnly}
                    theme={theme}
                    hasNotch={hasNotch}
                    colorTheme={colorTheme}
                    roundedCorners={roundedCorners}
                    globalTheme={globalTheme}
                    firstBlock={firstBlock}
                />
            </div>
        );
    }

    return (
        <article
            {...blockRootProps(anchorId, __typename)}
            className={cx(
                styles.calloutWrapperFlex,
                styles.calloutWrapper,
                styles[layoutsMap[theme]],
                styles[globalTheme],
                className,
                {
                    [styles.hasNotch]: hasNotch,
                    [styles.withCopy]: copy,
                    [styles.withImage]: hasImage,
                    [styles.banner]: layoutsMap[theme] === 'bannerSimple',
                    [styles.roundedCorners]: roundedCorners,
                }
            )}
        >
            <div className={styles.calloutWrapper}>
                {hasNotch && colorTheme === 'Blue Background' && (
                    <Divot theme="blue" className={styles.divot} />
                )}
                {hasNotch && colorTheme === 'Cream Background' && (
                    <Divot theme="cream" className={styles.divot} />
                )}
                {hasImage && theme === 'Square' && (
                    <div className={styles.imageContainer}>
                        <Img
                            className={styles.image}
                            src={image.url}
                            alt={image.description || ''}
                            width={image.width}
                            height={image.height}
                            customSources={[
                                {
                                    breakpoint: 1920,
                                    src: imageLarge.url,
                                    imageWidth: 1920 * 0.75,
                                },
                                {
                                    breakpoint: 1440,
                                    src: imageLarge.url,
                                    imageWidth: 1440 * 0.75,
                                },
                                {
                                    breakpoint: 1024,
                                    src: imageLarge.url,
                                    imageWidth: 1024 * 0.75,
                                },
                                {
                                    breakpoint: 768,
                                    src: imageLarge.url,
                                    imageWidth: 768 * 1.5,
                                },
                                {
                                    src: image.url,
                                    imageWidth: 768,
                                },
                            ]}
                        />
                    </div>
                )}
                <div
                    className={styles.callout}
                    style={{
                        color: `var(--colors-${colorThemeMap[colorTheme].color})`,
                        backgroundColor: `var(--colors-${colorThemeMap[colorTheme].background})`,
                    }}
                >
                    <div className={styles.inner}>
                        <div className={styles.section}>
                            {eyebrow && isBannerSimple && (
                                <Text
                                    as="h3"
                                    baseTheme="labelSmall"
                                    themes={{
                                        large: 'labelLarge',
                                    }}
                                    className={styles.simpleEyebrow}
                                >
                                    {eyebrow}
                                </Text>
                            )}
                            {richHeadline && (
                                <>
                                    {isBannerSimple && (
                                        <RichText
                                            richText={richHeadline}
                                            overrides={headlineOverrides}
                                        />
                                    )}
                                    {isMasthead && (
                                        <TextEyebrow
                                            inView={firstBlock}
                                            className={styles.eyebrow}
                                            eyebrow={eyebrow ? eyebrow : ''}
                                            richHeadline={richHeadline}
                                            theme="masthead"
                                            headlineAs="h1"
                                        />
                                    )}
                                    {isHero && (
                                        <TextEyebrow
                                            inView={firstBlock}
                                            className={styles.eyebrow}
                                            eyebrow={eyebrow ? eyebrow : ''}
                                            richHeadline={richHeadline}
                                            theme="hero"
                                            headlineAs="h1"
                                        />
                                    )}
                                    {!isBannerSimple &&
                                        !isMasthead &&
                                        !isHero && (
                                            <TextEyebrow
                                                inView={firstBlock}
                                                className={styles.eyebrow}
                                                eyebrow={eyebrow ? eyebrow : ''}
                                                richHeadline={richHeadline}
                                                theme={
                                                    theme === 'Square'
                                                        ? 'displaySmall'
                                                        : 'normal'
                                                }
                                            />
                                        )}
                                </>
                            )}
                            {buttonComponentSlot &&
                                layoutsMap[theme] === 'banner' && (
                                    <div className={styles.buttonComponentSlot}>
                                        {buttonComponentSlot}
                                    </div>
                                )}
                        </div>
                        {copy && (
                            <div className={styles.section}>
                                <Text
                                    className={styles.copy}
                                    baseTheme="bodySmall"
                                    themes={{ large: 'bodyMedium' }}
                                >
                                    {copy}
                                </Text>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </article>
    );
};

BlockCallout.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    buttonComponentSlot: PropTypes.node,
    className: PropTypes.string,
    colorTheme: PropTypes.string,
    copy: PropTypes.string,
    cta: PropTypes.object,
    ctaSecondary: PropTypes.object,
    ctaOnly: PropTypes.bool,
    eyebrow: PropTypes.string,
    hasNotch: PropTypes.bool,
    image: PropTypes.shape({
        description: PropTypes.string,
        height: PropTypes.number,
        width: PropTypes.number,
        url: PropTypes.string,
    }),
    imageLarge: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
    richHeadline: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    roundedCorners: PropTypes.bool,
    theme: PropTypes.string,
    firstBlock: PropTypes.bool,
};

export default BlockCallout;
