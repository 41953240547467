import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import RichText from 'components/ui/RichText';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import styles from './CardSmall.module.scss';

const iconMap = {
    Milk: 'milkJar',
    Berries: 'berries',
    Nuts: 'nuts',
};

export const fragment = /* GraphQL */ `
    fragment CardSmallFragment on CardSmall {
        icon
        copy {
            json
        }
        theme
    }
`;

const richTextOverrides = theme => {
    return {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text
                        as="p"
                        className={styles.paragraph}
                        baseTheme={
                            theme === 'Large Icon' ? 'bodyLarge' : 'bodyMedium'
                        }
                    >
                        {children}
                    </Text>
                );
            },
        },
    };
};

const CardSmall = ({ icon, copy, theme }) => {
    theme ??= 'Default';

    return (
        <div className={styles.cardLinkWrap}>
            <article
                className={cx(styles.cardSmall, {
                    [styles.largeIcon]: theme === 'Large Icon',
                })}
            >
                <div className={styles.inner}>
                    {icon && (
                        <div className={cx(styles.icon, styles[iconMap[icon]])}>
                            <SvgIcon type={iconMap[icon]} />
                        </div>
                    )}
                    {copy && (
                        <div className={styles.copy}>
                            <RichText
                                richText={copy}
                                overrides={richTextOverrides(theme)}
                            />
                        </div>
                    )}
                </div>
            </article>
        </div>
    );
};

CardSmall.propTypes = {
    icon: PropTypes.string,
    stat: PropTypes.string,
    copy: PropTypes.object,
    cta: PropTypes.object,
    theme: PropTypes.oneOf(['Default', 'Large Icon']),
};

export default CardSmall;
