'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Img from 'components/ui/Img';

import { blockRootProps } from 'utils';

import TextLockup from './TextLockup';

import styles from './BlockHeroTwoColumn.module.scss';

const BlockHeroTwoColumn = ({
    __typename,
    anchorId,
    eyebrow,
    headline,
    richHeadline,
    bodyCopy,
    cta,
    ctaSecondary,
    image,
    imageLarge,
    theme,
    flushTop,
    icon,
}) => {
    theme ??= 'Default';

    const textLockupProps = {
        eyebrow,
        headline,
        richHeadline,
        bodyCopy,
        cta,
        ctaSecondary,
        theme,
        icon,
    };

    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.root, {
                [styles.reversed]: true,
                [styles.flushTop]: flushTop,
                [styles.hasRichHeadline]: richHeadline,
                [styles.holidayBlue]: theme === 'Holiday Blue',
                [styles.holidayRust]: theme === 'Holiday Rust',
                [styles.holidaySquash]: theme === 'Holiday Squash',
                [styles.tillamookBlue]: theme === 'Tillamook Blue',
            })}
        >
            <div className={styles.inner}>
                <div className={styles.column}>
                    {image?.url && imageLarge?.url && (
                        <Img
                            className={styles.image}
                            src={image.url}
                            width={image?.width}
                            height={image?.height}
                            alt={image?.description || ''}
                            priority={false}
                            customSources={[
                                {
                                    breakpoint: 1920,
                                    src: imageLarge.url,
                                    imageWidth: 1920 * 0.75,
                                },
                                {
                                    breakpoint: 1440,
                                    src: imageLarge.url,
                                    imageWidth: 1440 * 0.75,
                                },
                                {
                                    breakpoint: 1024,
                                    src: imageLarge.url,
                                    imageWidth: 1024 * 0.75,
                                },
                                {
                                    breakpoint: 768,
                                    src: imageLarge.url,
                                    imageWidth: 768 * 1.5,
                                },
                                {
                                    src: image.url,
                                    imageWidth: 768,
                                },
                            ]}
                        />
                    )}
                </div>
                <div className={styles.column}>
                    <div className={styles.columnInner}>
                        <TextLockup
                            {...textLockupProps}
                            className={styles.lockup}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

BlockHeroTwoColumn.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    richHeadline: PropTypes.object,
    bodyCopy: PropTypes.string,
    cta: PropTypes.object,
    ctaSecondary: PropTypes.object,
    image: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
        description: PropTypes.string,
    }),
    imageLarge: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
        description: PropTypes.string,
    }),
    theme: PropTypes.oneOf([
        'Default',
        'Holiday Blue',
        'Holiday Rust',
        'Holiday Squash',
        'Tillamook Blue',
    ]),
    flushTop: PropTypes.bool,
    icon: PropTypes.object,
};

export default BlockHeroTwoColumn;
