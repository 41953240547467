'use client';

import React, { createRef, useEffect, useState } from 'react';
import { useIntersection, useMeasure } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Img from 'components/ui/Img';
import EntryLink from 'components/ui/Link/EntryLink';
import Text from 'components/ui/Text';

import { useTheme } from 'hooks/useTheme';

import styles from './CardSimple.module.scss';

const CardSimple = ({ title, eyebrow, image, link }) => {
    const ref = createRef();

    const [theme] = useTheme();
    const [cardRef, { width }] = useMeasure();

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    const [visible, setVisibility] = useState(false);

    useEffect(() => {
        intersection && intersection.isIntersecting
            ? setVisibility(true)
            : setVisibility(false);
    }, [intersection, intersection?.isIntersecting]);

    return (
        <EntryLink entry={link} className={styles.cardLinkWrap}>
            <article
                ref={ref}
                style={{
                    '--card-width': `${width}px`,
                }}
                className={cx(styles.card, styles[`theme--${theme}`])}
            >
                <div ref={cardRef} className={cx(styles.cardInner, {})}>
                    <div className={styles.cardContent}>
                        {image?.url && (
                            <div className={styles.imageContainer}>
                                <figure
                                    className={cx(styles.image, {
                                        [styles.isVisible]: visible,
                                    })}
                                >
                                    <Img
                                        src={image.url}
                                        fallbackImageWidth={600}
                                        alt={title}
                                        width={image.width}
                                        height={image.height}
                                    />
                                </figure>
                            </div>
                        )}
                        <div className={styles.textGroup}>
                            <Text.Theme
                                config={{
                                    'makers-reserve': {
                                        baseTheme: 'labelSmallAlt',
                                        themes: {
                                            xxLarge: 'labelMediumAlt',
                                        },
                                    },
                                    default: {
                                        baseTheme: 'labelSmall',
                                    },
                                }}
                                className={styles.eyebrow}
                            >
                                {eyebrow}
                            </Text.Theme>
                            <Text.Theme
                                config={{
                                    'makers-reserve': {
                                        baseTheme: 'parkinsonSmall',
                                        themes: {
                                            xxLarge: 'parkinsonMedium',
                                        },
                                    },
                                    default: {
                                        baseTheme: 'bodyLarge',
                                        themes: {
                                            large: 'bodyMedium',
                                            xxLarge: 'bodyLarge',
                                        },
                                    },
                                }}
                                className={styles.cta}
                            >
                                {title}
                            </Text.Theme>
                        </div>
                    </div>
                </div>
            </article>
        </EntryLink>
    );
};

CardSimple.propTypes = {
    title: PropTypes.string,
    eyebrow: PropTypes.string,
    image: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
    }),
    link: PropTypes.object.isRequired,
};

export default CardSimple;
