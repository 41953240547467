import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import styles from './Fields.module.scss';

const FieldEmail = ({
    required,
    placeholder = 'John@Tillamook.com',
    errors,
    register,
    validation,
}) => {
    return (
        <>
            <Text baseTheme="headingXSmall" className={styles.field}>
                <input
                    id="email_address"
                    {...register('email_address', {
                        required: required,
                        pattern: validation && validation,
                    })}
                    type="email"
                    placeholder={placeholder}
                    className={cx(styles.input, {
                        [styles.hasError]: errors.email_address,
                    })}
                />
            </Text>
            <div className={styles.error}>
                {errors.email_address && (
                    <Text baseTheme="bodyXSmall">
                        {errors?.email_address.type === 'required'
                            ? 'This field is required'
                            : 'Please enter a valid email address'}
                    </Text>
                )}
            </div>
        </>
    );
};

FieldEmail.propTypes = {
    errors: PropTypes.object,
    placeholder: PropTypes.string,
    register: PropTypes.func,
    required: PropTypes.any,
    validation: PropTypes.object,
};

export default FieldEmail;
