'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Icon from 'components/ui/Icon';
import Img from 'components/ui/Img';
import Parallax from 'components/ui/Parallax';
import RichText from 'components/ui/RichText';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import Text from 'components/ui/Text';
import Video from 'components/ui/Video';
import VideoPlayerYT from 'components/ui/VideoPlayerYT';

import { blockRootProps, getColor } from 'utils';

import styles from './BlockSmallMediaWithText.module.scss';

const richTextBodyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    baseTheme="bodySmall"
                    themes={{ large: 'bodyMedium', xLarge: 'bodyLarge' }}
                >
                    {children}
                </Text>
            );
        },
    },
};

const richTextHeadlineOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="h3"
                    baseTheme="displaySmall"
                    themes={{ large: 'displayMedium', xLarge: 'displayLarge' }}
                >
                    {children}
                </Text>
            );
        },
    },
};

const themeMap = {
    'Landscape Image': 'imageLandscape',
    'Portrait Image': 'imagePortrait',
};

const BlockSmallMediaWithText = ({
    __typename,
    eyebrow,
    headline,
    body,
    cta,
    image,
    mediaPosition,
    theme,
    videoTextureMp4,
    videoTextureWebm,
    videoPoster,
    icon,
    youtubeVideoId,
    textAlignment,
    flushTop,
    flushBottom,
    backgroundColor,
}) => {
    mediaPosition ??= 'Left';
    textAlignment ??= 'Left';
    theme ??= 'Landscape Image';

    const hasImage = image?.url;
    const hasVideo = youtubeVideoId;
    const hasTexturalVideo =
        videoTextureMp4?.url && videoTextureWebm?.url && !hasVideo;

    return (
        <section
            style={
                backgroundColor && {
                    '--background-color': getColor(backgroundColor),
                }
            }
            {...blockRootProps(null, __typename)}
            className={cx(styles.root, styles[themeMap[theme]], {
                [styles.imageRight]: mediaPosition.toLowerCase() === 'right',
                [styles.textAlignLeft]: textAlignment?.toLowerCase() === 'left',
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <div className={styles.inner}>
                <div className={styles.media}>
                    {hasImage && (
                        <Parallax className={styles.parallaxImage}>
                            <Img
                                className={styles.image}
                                src={image?.url}
                                width={image?.width}
                                height={image?.height}
                            />
                        </Parallax>
                    )}
                    {hasVideo && (
                        <div className={styles.videoPlayerAspect}>
                            <div className={styles.videoPlayer}>
                                <VideoPlayerYT
                                    centerPlayButton
                                    heroVideo={true}
                                    youtubeVideoId={youtubeVideoId}
                                    videoTexturePoster={videoPoster || null}
                                    videoTextureMp4={videoTextureMp4 || null}
                                    videoTextureWebm={videoTextureWebm || null}
                                    threshold={0.25}
                                />
                            </div>
                        </div>
                    )}
                    {hasTexturalVideo && (
                        <Video
                            poster={videoPoster?.url}
                            sources={[
                                videoTextureWebm.url,
                                videoTextureMp4.url,
                            ]}
                        />
                    )}
                </div>
                <Stagger className={styles.card}>
                    {icon && (
                        <StaggerChild className={styles.icon} order={0}>
                            <Icon src={icon.icon.url} />
                        </StaggerChild>
                    )}
                    {eyebrow && (
                        <StaggerChild className={styles.eyebrow} order={0}>
                            <Text as="p" baseTheme="labelLarge">
                                {eyebrow}
                            </Text>
                        </StaggerChild>
                    )}
                    {headline && (
                        <StaggerChild className={styles.headline} order={0}>
                            {typeof headline === 'string' ? (
                                <Text
                                    as="h2"
                                    baseTheme="displayXSmall"
                                    themes={{ xLarge: 'displayMedium' }}
                                >
                                    {headline}
                                </Text>
                            ) : (
                                <RichText
                                    overrides={richTextHeadlineOverrides}
                                    richText={headline}
                                />
                            )}
                        </StaggerChild>
                    )}

                    {body && (
                        <StaggerChild className={styles.body} order={1}>
                            <RichText
                                overrides={richTextBodyOverrides}
                                richText={body}
                            />
                        </StaggerChild>
                    )}
                    {cta && (
                        <StaggerChild className={styles.cta} order={1}>
                            <span className={styles.buttonWrapper}>
                                <ButtonCTA
                                    className={styles.ctaLink}
                                    {...cta}
                                />
                            </span>
                        </StaggerChild>
                    )}
                </Stagger>
            </div>
        </section>
    );
};

BlockSmallMediaWithText.propTypes = {
    __typename: PropTypes.string,
    backgroundColor: PropTypes.string,
    body: PropTypes.object,
    cta: PropTypes.object,
    displayArrowIcon: PropTypes.bool,
    eyebrow: PropTypes.string,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
    headline: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    icon: PropTypes.object,
    image: PropTypes.shape({
        height: PropTypes.number,
        url: PropTypes.string,
        width: PropTypes.number,
    }),
    mediaPosition: PropTypes.oneOf(['Left', 'Right']),
    theme: PropTypes.oneOf(['Landscape Image', 'Portrait Image']),
    textAlignment: PropTypes.oneOf(['Left', 'Center']),
    videoPoster: PropTypes.shape({
        height: PropTypes.number,
        url: PropTypes.string,
        width: PropTypes.number,
    }),
    videoTextureMp4: PropTypes.shape({
        url: PropTypes.string,
    }),
    videoTextureWebm: PropTypes.shape({
        url: PropTypes.string,
    }),
    youtubeVideoId: PropTypes.string,
};

export default BlockSmallMediaWithText;
