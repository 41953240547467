import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockHeroTwoColumn from './BlockHeroTwoColumn';

const query = /* GraphQL */ `
    ${ctaFragment}
    ${linkFragment}
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockHeroTwoColumn(preview: $preview, id: $id) {
            __typename
            anchorId
            eyebrow
            headline
            richHeadline {
                json
            }
            bodyCopy
            cta {
                ... on Cta {
                    ...CTA
                }
            }
            ctaSecondary {
                ... on Cta {
                    ...CTA
                }
            }
            image {
                ...Asset
            }
            imageLarge {
                ...Asset
            }
            theme
            flushTop
            icon {
                icon {
                    ...Asset
                }
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockHeroTwoColumn },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockHeroTwoColumn;
}

const BlockHeroTwoColumnServer = async ({ id }) => {
    const block = await fetchBlock(id);
    return <BlockHeroTwoColumn {...block} richHeadline={block.richHeadline} />;
};

export default BlockHeroTwoColumnServer;
