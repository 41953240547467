'use client';

import React, { useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import RichText from 'components/ui/RichText';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import styles from './BlockMediaAccordionItem.module.scss';

const headlineOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="span"
                    baseTheme="headingSmall2"
                    themes={{ xLarge: 'headingMedium2' }}
                >
                    {children}
                </Text>
            );
        },
    },
};

const BlockMediaAccordionItem = ({
    headline,
    copy,
    cta,
    youtubeVideoId,
    onClick,
    onVideoButtonClick,
    isActive,
}) => {
    const heightRef = useRef(null);
    const [height, setHeight] = React.useState(0);
    const [isMounted, setIsMounted] = useState(false);

    const showInitialHeight = isActive && !isMounted; // used to prevent layout thrash on load for first item open
    const activeHeight = isActive ? height : 0;

    useEffect(() => {
        const currentHeightRef = heightRef.current;

        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setHeight(entry.contentRect.height);
            }
        });

        if (currentHeightRef) {
            resizeObserver.observe(heightRef.current);
        }

        return () => {
            if (currentHeightRef) {
                resizeObserver.unobserve(currentHeightRef);
            }
        };
    }, []);

    const handleFocus = () => {
        // open panel when any contents are focused
        // does not work for browser search, but works for buttons and links
        onClick(true);
    };

    useEffect(() => {
        // used to prevent layout thrash on load for first item open
        setIsMounted(true);
    }, []);

    return (
        <div
            data-aria-accordion-heading
            className={cx(styles.root, {
                [styles.isMounted]: isMounted,
                [styles.isActive]: isActive,
            })}
        >
            <div className={styles.summary}>
                <button
                    aria-expanded={isActive}
                    className={styles.headline}
                    onClick={onClick}
                >
                    {headline && (
                        <RichText
                            richText={headline}
                            overrides={headlineOverrides}
                        />
                    )}
                    <span className={styles.headlineIcon}>
                        <SvgIcon
                            type="chevronDown"
                            className={styles.headlineIconChevron}
                        />
                    </span>
                </button>
            </div>
            <div
                className={styles.content}
                style={{
                    height: showInitialHeight ? 'auto' : activeHeight,
                }}
                onFocus={handleFocus}
                data-aria-accordion-panel
            >
                <div ref={heightRef}>
                    <div className={styles.copy}>
                        {copy && <RichText richText={copy} />}
                        {cta && !youtubeVideoId && (
                            <ButtonCTA className={styles.cta} {...cta} />
                        )}
                        {youtubeVideoId && (
                            <ButtonCTA
                                className={styles.videoCta}
                                text="Watch video"
                                style="filled-blue-ocean"
                                onClick={onVideoButtonClick}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

BlockMediaAccordionItem.propTypes = {
    headline: PropTypes.object,
    copy: PropTypes.object,
    cta: PropTypes.object,
    youtubeVideoId: PropTypes.string,
    onClick: PropTypes.func,
    onVideoButtonClick: PropTypes.func,
    isActive: PropTypes.bool,
};

export default BlockMediaAccordionItem;
