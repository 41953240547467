import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Img from 'components/ui/Img';
import Link from 'components/ui/Link';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import styles from './CardRecipe.module.scss';

const CardRecipe = ({
    cardImage,
    cardImageLarge,
    category,
    cookTime,
    prepTime,
    slug,
    title,
    backgroundColor,
    textAlignLeft,
    theme,
}) => {
    cookTime ??= 0;
    prepTime ??= 0;

    return (
        <article
            className={cx(
                styles.root,
                backgroundColor && styles[`background-${backgroundColor}`],
                {
                    [styles.textAlignLeft]: textAlignLeft,
                    [styles.blockJams]: theme === 'BlockJams',
                }
            )}
        >
            <Link href={`/recipes/${slug}`} className={styles.inner}>
                <div className={styles.aspectBox}>
                    {cardImage?.url && (
                        <Img
                            className={styles.image}
                            src={cardImage.url}
                            alt={cardImage.description || ''}
                            customSources={[
                                {
                                    breakpoint: 1440,
                                    src: cardImageLarge?.url
                                        ? cardImageLarge.url
                                        : cardImage.url,
                                    imageWidth: 1440 * 0.5,
                                },
                                {
                                    breakpoint: 1024,
                                    src: cardImageLarge?.url
                                        ? cardImageLarge.url
                                        : cardImage.url,
                                    imageWidth: 1024 * 0.5,
                                },
                                {
                                    breakpoint: 768,
                                    src: cardImageLarge?.url
                                        ? cardImageLarge.url
                                        : cardImage.url,
                                    imageWidth: 768 * 0.75,
                                },
                                {
                                    src: cardImage.url,
                                    imageWidth: 768,
                                },
                            ]}
                        />
                    )}
                </div>
                <div className={styles.infoWrapper}>
                    <Text className={styles.category} baseTheme="labelMedium">
                        {category.title}
                    </Text>
                    <div className={styles.headlineWrapper}>
                        <Text
                            className={styles.headline}
                            baseTheme="headingSmall"
                            as="h5"
                        >
                            {title}
                        </Text>
                        <div className={styles.infoLockup}>
                            <SvgIcon className={styles.timeIcon} type="clock" />
                            <Text
                                className={styles.timeLabel}
                                baseTheme="labelMedium"
                            >
                                {prepTime + cookTime}MIN
                            </Text>
                        </div>
                    </div>
                </div>
            </Link>
        </article>
    );
};

CardRecipe.propTypes = {
    backgroundColor: PropTypes.string,
    cardImage: PropTypes.shape({
        description: PropTypes.string,
        url: PropTypes.string,
    }),
    cardImageLarge: PropTypes.shape({
        url: PropTypes.string,
    }),
    category: PropTypes.shape({
        title: PropTypes.string,
    }),
    cookTime: PropTypes.number,
    prepTime: PropTypes.number,
    slug: PropTypes.string.isRequired,
    textAlignLeft: PropTypes.string,
    title: PropTypes.string,
    theme: PropTypes.string,
};

export default CardRecipe;
