import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockMediaAccordion from './BlockMediaAccordion';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockMediaAccordion(preview: $preview, id: $id) {
            __typename
            itemsCollection(limit: 5) {
                items {
                    headline {
                        json
                    }
                    copy {
                        json
                    }
                    image {
                        ...Asset
                    }
                    videoTextureMp4 {
                        ...Asset
                    }
                    videoTextureWebm {
                        ...Asset
                    }
                    youtubeVideoId
                    cta {
                        ...CTA
                    }
                }
            }
            mediaOnLeft
            backgroundColor
            flushTop
            flushBottom
            anchorId
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockMediaAccordion },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockMediaAccordion;
}

const BlockMediaAccordionServer = async ({ id, flushTop, flushBottom }) => {
    const block = await fetchBlock(id);

    return (
        <BlockMediaAccordion
            {...block}
            items={block?.itemsCollection?.items}
            flushTop={flushTop ?? block?.flushTop}
            flushBottom={flushBottom ?? block?.flushBottom}
        />
    );
};

export default BlockMediaAccordionServer;
