'use client';

import React, { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Icon from 'components/ui/Icon';
import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';

import { useTheme } from 'hooks/useTheme';
import { blockRootProps } from 'utils';

import styles from './BlockDeckSlider.module.scss';

const richTextCoverHeadingOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text.Theme
                    as="h3"
                    className={styles.slideHeading}
                    config={{
                        'makers-reserve': {
                            baseTheme: 'parkinsonMedium',
                            themes: { large: 'parkinsonXLarge' },
                        },
                        default: {
                            baseTheme: 'displayXSmall',
                            themes: { large: 'displayLarge' },
                        },
                    }}
                >
                    {children}
                </Text.Theme>
            );
        },
    },
};

const richTextHeadingOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text.Theme
                    as="h3"
                    className={styles.slideHeading}
                    config={{
                        'makers-reserve': {
                            baseTheme: 'parkinsonMedium',
                            themes: { large: 'parkinsonLarge' },
                        },
                        default: {
                            baseTheme: 'displayXSmall',
                            themes: { large: 'displayLarge' },
                        },
                    }}
                >
                    {children}
                </Text.Theme>
            );
        },
    },
};

const richTextBodyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    className={styles.slideBody}
                    baseTheme="bodyXSmall"
                    themes={{
                        large: 'bodySmall',
                    }}
                    fixWidows
                >
                    {children}
                </Text>
            );
        },
    },
};

const Tab = ({ title, ...rest }) => {
    return (
        <button className={styles.slideTab} {...rest}>
            <Text
                as="h2"
                className={styles.slideTabText}
                baseTheme="labelSmallAlt"
                themes={{ large: 'labelSmallAlt' }}
            >
                {title}
            </Text>
        </button>
    );
};

Tab.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

const BlockDeckSlider = ({
    __typename,
    anchorId,
    coverHeading,
    coverCopy,
    coverCtaText,
    slides,
    coverTitle,
    coverTabButtonColor,
    coverBackgroundImage,
    coverBackgroundImagePortrait,
    coverTextColor,
    coverTitleColor,
    slideTitleColor,
}) => {
    coverTextColor ??= 'cream';
    coverTitleColor ??= 'cream';
    slideTitleColor ??= 'cream';

    const ref = useRef(null);

    const [globalTheme] = useTheme();

    const [currentIndex, setIndex] = useState(0);
    const [currentHoverIndex, setHoverIndex] = useState(0);

    const { width } = useWindowSize(0);

    useEffect(() => {
        ref?.current?.style.setProperty('--screenWidth', width);
    }, [width]);

    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            ref={ref}
            className={cx(styles.root, styles[`theme--${globalTheme}`])}
            style={{
                '--slideTotal': slides?.length || 0,
            }}
        >
            <div className={styles.slides}>
                <div
                    style={{
                        '--coverTabButtonColor': coverTabButtonColor,
                        '--titleColor': `var(--colors-${coverTitleColor})`,
                    }}
                    className={cx(styles.slide, {
                        [styles[`slide__current`]]: 0 === currentIndex,
                    })}
                >
                    <figure className={styles.coverImage}>
                        {coverBackgroundImage?.url &&
                            coverBackgroundImagePortrait?.url && (
                                <Img
                                    className={styles.image}
                                    src={coverBackgroundImagePortrait.url}
                                    fallbackImageWidth={900}
                                    alt={
                                        coverBackgroundImagePortrait.description ||
                                        ''
                                    }
                                    customSources={[
                                        {
                                            breakpoint: 1024,
                                            src: coverBackgroundImage.url,
                                            imageWidth: 1600,
                                        },
                                        {
                                            breakpoint: 768,
                                            src: coverBackgroundImage.url,
                                            imageWidth: 1440,
                                        },
                                        {
                                            src: coverBackgroundImagePortrait.url,
                                            imageWidth: 900,
                                        },
                                    ]}
                                />
                            )}
                    </figure>
                    <div
                        className={styles.slideContent}
                        style={{
                            '--textColor': coverTextColor
                                ? `var(--colors-${coverTextColor})`
                                : ' `var(--colors-cream)`',
                        }}
                    >
                        {coverTitle && (
                            <Text.Theme
                                as="h4"
                                className={cx(
                                    styles.coverEyebrow,
                                    styles.slideEyebrow
                                )}
                                config={{
                                    'makers-reserve': {
                                        baseTheme: 'labelSmallAlt',
                                        themes: { large: 'labelSmallAlt' },
                                    },
                                    default: {
                                        baseTheme: 'labelSmallAlt',
                                        themes: { large: 'labelSmallAlt' },
                                    },
                                }}
                            >
                                {coverTitle}
                            </Text.Theme>
                        )}

                        <RichText
                            overrides={richTextCoverHeadingOverrides}
                            richText={coverHeading}
                        />
                        <RichText
                            overrides={richTextBodyOverrides}
                            richText={coverCopy}
                        />
                        <ButtonCTA
                            className={styles.ctaButton}
                            text={coverCtaText}
                            style={
                                globalTheme === 'makers-reserve'
                                    ? 'makersFilled-cream-black'
                                    : 'filled-blue-cream'
                            }
                            onClick={() => {
                                setIndex(1);
                            }}
                            onMouseOver={() => {
                                setHoverIndex(null);
                            }}
                            onMouseOut={() => {
                                setHoverIndex(null);
                            }}
                        />
                        <Text
                            className={styles.touchCta}
                            baseTheme="labelSmallAlt"
                        >
                            Swipe
                        </Text>
                    </div>
                    <Tab
                        title={coverTitle}
                        onClick={() => {
                            setIndex(0);
                        }}
                    />
                </div>
                <ol className={styles.steps}>
                    {slides &&
                        slides?.map(
                            (
                                {
                                    eyebrow,
                                    heading,
                                    body,
                                    backgroundColor,
                                    textColor,
                                    slideTitle,
                                    icon2,
                                },
                                i
                            ) => (
                                <li
                                    key={`step-${i}`}
                                    style={{
                                        '--backgroundColor': backgroundColor,
                                        '--textColor': textColor
                                            ? `var(--colors-${textColor})`
                                            : ' `var(--colors-cream)`',
                                        '--slide': i + 1,
                                        '--titleColor': `var(--colors-${slideTitleColor})`,
                                        index: '',
                                    }}
                                    className={cx(styles.slide, {
                                        [styles[`slide__active`]]:
                                            i < currentIndex,
                                        [styles[`slide__current`]]:
                                            i === currentIndex - 1,
                                        [styles[`slide__hover`]]:
                                            i === currentHoverIndex - 1 &&
                                            i !== currentIndex - 1,
                                    })}
                                >
                                    <div className={styles.slideIconContainer}>
                                        {icon2?.icon?.url && (
                                            <div className={styles.slideIcon}>
                                                <Icon
                                                    className={styles.slideIcon}
                                                    src={icon2.icon.url}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.slideContent}>
                                        <Text
                                            as="h2"
                                            className={styles.slideEyebrow}
                                            baseTheme="labelSmallAlt"
                                            themes={{
                                                large: 'labelMediumAlt',
                                            }}
                                        >
                                            {eyebrow}
                                        </Text>
                                        <RichText
                                            overrides={richTextHeadingOverrides}
                                            richText={heading}
                                        />
                                        <RichText
                                            overrides={richTextBodyOverrides}
                                            richText={body}
                                        />
                                    </div>
                                    <Tab
                                        title={`${`${i + 1}`.padStart(
                                            2,
                                            '0'
                                        )} | ${slideTitle || ''}`}
                                        onClick={() => {
                                            setIndex(i + 1);
                                        }}
                                        onMouseOver={() => {
                                            setHoverIndex(i + 1);
                                        }}
                                        onMouseOut={() => {
                                            setHoverIndex(null);
                                        }}
                                    />
                                </li>
                            )
                        )}
                </ol>
            </div>
        </section>
    );
};

BlockDeckSlider.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    coverEyebrow: PropTypes.string,
    coverHeading: PropTypes.object.isRequired,
    coverCopy: PropTypes.object.isRequired,
    coverCtaText: PropTypes.string.isRequired,
    coverCtaTheme: PropTypes.string,
    coverTextColor: PropTypes.string,
    slides: PropTypes.array.isRequired,
    coverTabButtonColor: PropTypes.string,
    coverBackgroundImage: PropTypes.object,
    coverBackgroundImagePortrait: PropTypes.object,
    coverTitle: PropTypes.string.isRequired,
    coverTitleColor: PropTypes.oneOf(['cream', 'blue']),
    slideTitleColor: PropTypes.oneOf(['cream', 'blue']),
};

export default BlockDeckSlider;
