import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import TextEyebrow from 'components/ui/TextEyebrow';

import { blockRootProps } from 'utils';

import styles from './BlockDownloadList.module.scss';

const BlockDownloadList = ({
    __typename,
    anchorId,
    headline,
    downloads,
    className,
    firstBlock,
}) => {
    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.blockDownloadList, className)}
        >
            <div className={styles.inner}>
                <div className={styles.eyebrow}>
                    <TextEyebrow
                        headline={headline}
                        theme="display"
                        fixWidows={false}
                        firstBlock={firstBlock}
                    />
                </div>
                <ul className={styles.list}>
                    {downloads?.length &&
                        downloads?.map((cta, i) => (
                            <li className={styles.listItem} key={i}>
                                <ButtonCTA {...cta} />
                            </li>
                        ))}
                </ul>
            </div>
        </section>
    );
};

BlockDownloadList.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    headline: PropTypes.string,
    downloads: PropTypes.array,
    className: PropTypes.string,
    firstBlock: PropTypes.bool,
};

export default BlockDownloadList;
