import React, { useEffect, useRef, useState, useTransition } from 'react';
import { useClickAway, useMedia } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import styles from './Search.module.scss';

const Search = ({ query, refine, clear, setIsActive, className, tabIndex }) => {
    const ref = useRef();
    const [input, setInput] = useState('');
    const [, startTransition] = useTransition();
    const isMedium = useMedia('(min-width: 768px)');
    const showSearch = query.length !== 0 && isMedium;

    useEffect(() => {
        if (query === '') {
            setInput('');
        }
    }, [query]);

    const handleSearch = evt => {
        setInput(evt.target.value); // High priority
        startTransition(() => {
            refine(evt.target.value);
        });
    };

    useClickAway(ref, () => {
        setIsActive(false);
    });

    return (
        <div className={cx(styles.root, className)}>
            <Text
                as="label"
                baseTheme="bodySmall"
                themes={{ large: 'bodyMedium' }}
            >
                <input
                    ref={ref}
                    onClick={() => setIsActive(true)}
                    name="search"
                    placeholder={'Search all products'}
                    type="text"
                    onChange={handleSearch}
                    value={input}
                    autoComplete="off"
                    tabIndex={tabIndex}
                />
                <div
                    className={cx(styles.searchIcon)}
                    onClick={showSearch ? () => clear() : () => {}}
                >
                    <SvgIcon type={showSearch ? 'close' : 'search'} />
                </div>
            </Text>
        </div>
    );
};

Search.propTypes = {
    className: PropTypes.string,
    isActive: PropTypes.bool,
    query: PropTypes.string,
    refine: PropTypes.func,
    clear: PropTypes.func,
    setIsActive: PropTypes.func,
    tabIndex: PropTypes.number,
};

export default Search;
