import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockLocation from './BlockLocation';

const query = /* GraphQL */ `
    ${ctaFragment}
    ${linkFragment}
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockLocation(preview: $preview, id: $id) {
            __typename
            anchorId
            location {
                location
                logo {
                    ...Asset
                }
                address
                linkToGoogleMaps
                linkToReservation
                hoursCollection {
                    items {
                        days
                        hours
                    }
                }
                descriptionLine1
                descriptionLine2
                descriptionLine3
                contactName
                contactEmail
                contactNumber
                linkToMenu {
                    ...CTA
                }
                customLink {
                    ...CTA
                }
                contactName2
                contactNumber2
            }
            theme
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockLocation },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockLocation;
}

const BlockLocationServer = async ({ id }) => {
    const block = await fetchBlock(id);
    return <BlockLocation {...block} />;
};

export default BlockLocationServer;
