export const fragment = /* GraphQL */ `
    fragment CardRecipeFragment on Recipe {
        title
        cookTime
        prepTime
        slug
        category {
            title
        }
        cardImage {
            ...Asset
        }
        cardImageLarge {
            ...Asset
        }
    }
`;
