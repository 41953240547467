import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import BorderDivot from './BorderDivot';

import styles from './DecorativeBorder.module.scss';

const DecorativeBorder = ({
    className = null,
    color = `var(--colors-makersReserveGoldAlt)`,
    borderWidth = 1,
}) => {
    return (
        <div
            className={cx(styles.root, className, {
                [styles.borderWidth2px]: borderWidth === 2,
            })}
            role="presentation"
        >
            <div
                className={cx(styles.corner, styles.cornerTopLeft)}
                style={{ borderColor: color }}
            />
            <div
                className={cx(styles.corner, styles.cornerTopRight)}
                style={{ borderColor: color }}
            />
            <div
                className={cx(styles.corner, styles.cornerBottomLeft)}
                style={{ borderColor: color }}
            />
            <div
                className={cx(styles.corner, styles.cornerBottomRight)}
                style={{ borderColor: color }}
            />
            <BorderDivot
                borderWidth={borderWidth}
                className={cx(styles.border, styles.borderTop)}
                color={color}
                side="top"
            />
            <BorderDivot
                borderWidth={borderWidth}
                className={cx(styles.border, styles.borderRight)}
                color={color}
                side="right"
            />
            <BorderDivot
                borderWidth={borderWidth}
                className={cx(styles.border, styles.borderBottom)}
                color={color}
                side="bottom"
            />
            <BorderDivot
                borderWidth={borderWidth}
                className={cx(styles.border, styles.borderLeft)}
                color={color}
                side="left"
            />
        </div>
    );
};

DecorativeBorder.propTypes = {
    className: PropTypes.string,
    borderWidth: PropTypes.oneOf([1, 2]),
    color: PropTypes.string,
};

export default DecorativeBorder;
