import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';

import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Icon from 'components/ui/Icon';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import useWait from 'hooks/useWait';
import { scrollToOffset } from 'utils';
import { cleanupContentfulData } from 'utils/contentful-resolve-response';
import highlightText from 'utils/highLightText';

import DatePicker from '../Form/DatePicker';
import PlantCodePicker from '../Form/PlantCodePicker';
import Time from '../Form/Time';

import styles from './ProductCode.module.scss';

const DEFAULT = 'DEFAULT';

const REGULAR_PLANT_CODE_COPY = `
Look for the production code as a
<b>two or three character code</b> contained
within the product code.`;

const COMPLEX_PLANT_CODE_COPY = `
Look for the production code as a <b>four to five 
character code with a dash</b> contained within the product code.`;

/*

codeInformation is a JSON object field that belongs to the codeIllustration content model.

{
    "plantCodes": [
        "1645"
    ],
    "displayTime": false
}

[plantCodes] is a list of plant codes that should be displayed in the plantCode dropdown.

[displayTime] is either a bool or an array of strings.
If it's a bool, we will use a regex to highlight the time part from the example. In case 
it's an array, we will highlight an exact match of the time value if found in the array.
*/

const timeMatcher = (str, displayTime) => {
    let matched = '';

    if (Array.isArray(displayTime)) {
        matched = displayTime.find(example => str.includes(example));
    } else {
        const regex = /([0-1][0-9]|2[0-3]):[0-5][0-9]/;
        const matches = str.match(regex);
        matched = matches ? matches?.[0] : '';
    }

    return matched;
};

const plantCodeMatcher = (str, options) => {
    const regexes = options.map(phrase => new RegExp(`(${phrase})`, 'g'));
    const mainRegex = new RegExp(
        regexes.map(regex => regex.source).join('|'),
        'gi'
    );
    const matched = str.match(mainRegex);
    const plantCode = options.find(option => matched?.[0].includes(option));

    return plantCode ? plantCode : '';
};

const bestByDateMatcher = str => {
    const regex =
        /(?:\bBY\b|\bBB\b)[:\s]+([A-Z0-9]{2,3}(?:\s|\/|\W)\d{1,2}(?:\W+\d{2,4})?)|(?:\bBB)([A-Z0-9]{2,3}\d{1,2}\d{2,4})|(?:^)+([A-Z]{3}(?:\s|\/|\W)\d{1,2}(?:\W+\d{2,4})?)$|^((0[1-9]|1[0-2])\/([0-2][0-9]|3[0-1])\/(\d{2}))/i;
    const matched = str.match(regex);
    const date = matched?.[1] || matched?.[2] || matched?.[3] || matched?.[4];
    // ^ first match is for BY or BB with spaces
    // second is for BB with no spaces, like BB01162023
    // third matches APR 16 2022, with the min of 3 chars to not match the TL plant code
    // fourth matches 04/16/2022 at the beginning of the line

    return date ? date : '';
};

const isComplexPlantCode = plantCode => {
    const regex = /^(\d{2,2}-\d{2,4})$/;
    return regex.test(plantCode);
};

const ProductCode = ({ setActiveStep, formState, onUpdate, tabIndex }) => {
    const { register, getValues, setValue, watch } = useForm({
        defaultValues: {
            time: '',
            plantCode: DEFAULT,
            bestByMonth: DEFAULT,
            bestByDay: DEFAULT,
            bestByYear: DEFAULT,
        },
    });

    watch(['plantCode', 'time', 'bestByMonth', 'bestByDay', 'bestByYear']);

    const ref = useRef(null);
    const wait = useWait();

    const handleSubmit = submit => {
        setActiveStep(3);

        wait(10).then(() => {
            scrollToOffset(ref?.current, -300, 'smooth');
        });

        plantCodes?.length === 1 && setValue('plantCode', plantCodes[0]);
        const values = getValues();

        onUpdate({ productCode: submit ? values : '' });
    };

    const codeIllustration =
        formState?.packagingCodeExample &&
        cleanupContentfulData(
            formState?.packagingCodeExample?.illustration || {}
        );

    const codeExamples = formState?.packagingCodeExample?.codeExamples;
    const codeInformation = formState?.packagingCodeExample?.codeInformation;
    const plantCodes = codeInformation?.plantCodes;
    const displayTime = codeInformation?.displayTime;
    // const itemNumber = formState?.size?.itemNumber;
    const plantCodeCopy = isComplexPlantCode(plantCodes?.[0])
        ? COMPLEX_PLANT_CODE_COPY
        : REGULAR_PLANT_CODE_COPY;

    // console.log(
    //     'codeInformation',
    //     codeInformation,
    //     plantCodes,
    //     displayTime,
    //     itemNumber
    // );

    return (
        <div ref={ref} className={styles.root}>
            <div className={styles.inner}>
                <div className={styles.header}>
                    <div className={styles.headerText}>
                        <Text
                            baseTheme="displayXSmall"
                            themes={{ medium: 'displaySmall' }}
                            as="h1"
                        >
                            Find your product code
                        </Text>
                        <Text
                            baseTheme="bodyMedium"
                            themes={{ medium: 'bodyMedium' }}
                            as="h2"
                        >
                            The Product Code tells us the batch your product
                            came from. This information helps our Quality team
                            track issues and make improvements. If you no longer
                            have the product packaging, feel free to skip this
                            step.
                        </Text>
                    </div>
                    {codeIllustration?.url && (
                        <Icon src={codeIllustration.url} />
                    )}
                </div>

                {plantCodes?.length > 1 && (
                    <div className={styles.examplesContainer}>
                        <div className={styles.examplesHeadline}>
                            <Text
                                baseTheme="bodyMedium"
                                themes={{ medium: 'bodyLarge' }}
                                as="p"
                            >
                                Which <b>production code</b> is displayed on the
                                product?
                            </Text>
                        </div>

                        <PlantCodePicker
                            register={register}
                            watch={watch}
                            fieldName="plantCode"
                            options={plantCodes}
                        />

                        <div className={styles.examplesList}>
                            <div className={styles.help}>
                                <div className={styles.howToFindIt}>
                                    <SvgIcon
                                        type="magnifier"
                                        className={styles.magnifier}
                                    />
                                    <Text
                                        baseTheme="labelMedium"
                                        themes={{ medium: 'labelLarge' }}
                                        as="p"
                                    >
                                        How to find it
                                    </Text>
                                </div>

                                <Text
                                    baseTheme="bodySmall"
                                    themes={{ small: 'bodySmall' }}
                                    as="p"
                                    dangerouslySetInnerHTML={{
                                        __html: plantCodeCopy,
                                    }}
                                />
                            </div>

                            <div className={styles.examples}>
                                <Text
                                    baseTheme="labelMedium"
                                    themes={{ medium: 'labelLarge' }}
                                    as="p"
                                >
                                    Example
                                </Text>

                                {codeExamples?.map((item, i) => (
                                    <div
                                        key={i}
                                        className={styles.examplesItem}
                                    >
                                        {item.map((line, i) => (
                                            <Text
                                                key={i}
                                                dangerouslySetInnerHTML={{
                                                    __html: highlightText(
                                                        line,
                                                        plantCodeMatcher(
                                                            line,
                                                            plantCodes
                                                        ),
                                                        'em'
                                                    ),
                                                }}
                                            />
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}

                <div className={styles.examplesContainer}>
                    <div className={styles.examplesHeadline}>
                        <Text
                            baseTheme="bodyMedium"
                            themes={{ medium: 'bodyLarge' }}
                            as="p"
                        >
                            What is the <b>‘best by’ date</b>?
                        </Text>
                    </div>

                    <DatePicker
                        register={register}
                        watch={watch}
                        fieldNameMonth="bestByMonth"
                        fieldNameDay="bestByDay"
                        fieldNameYear="bestByYear"
                    />

                    <div className={styles.examplesList}>
                        <div className={styles.help}>
                            <div className={styles.howToFindIt}>
                                <SvgIcon
                                    type="magnifier"
                                    className={styles.magnifier}
                                />
                                <Text
                                    baseTheme="labelMedium"
                                    themes={{ medium: 'labelLarge' }}
                                    as="p"
                                >
                                    How to find it
                                </Text>
                            </div>

                            <Text
                                baseTheme="bodySmall"
                                themes={{ small: 'bodySmall' }}
                                as="p"
                            >
                                The best by date will include the
                                <b>month, day and year</b> preceded by the words
                                “BEST IF USED BY”.
                            </Text>
                        </div>

                        <div className={styles.examples}>
                            <Text
                                baseTheme="labelMedium"
                                themes={{ medium: 'labelLarge' }}
                                as="p"
                            >
                                Example
                            </Text>

                            {codeExamples?.map((item, i) => (
                                <div key={i} className={styles.examplesItem}>
                                    {item.map((line, i) => (
                                        <Text
                                            key={i}
                                            dangerouslySetInnerHTML={{
                                                __html: highlightText(
                                                    line,
                                                    bestByDateMatcher(line),
                                                    'em'
                                                ),
                                            }}
                                        />
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {displayTime && (
                    <div className={styles.examplesContainer}>
                        <div className={styles.examplesHeadline}>
                            <Text
                                baseTheme="bodyMedium"
                                themes={{ medium: 'bodyLarge' }}
                                as="p"
                            >
                                What <b>time</b> is shown on the product code?
                            </Text>
                        </div>

                        <Time
                            register={register}
                            watch={watch}
                            setValue={setValue}
                            fieldName="time"
                        />

                        <div className={styles.examplesList}>
                            <div className={styles.help}>
                                <div className={styles.howToFindIt}>
                                    <SvgIcon
                                        type="magnifier"
                                        className={styles.magnifier}
                                    />
                                    <Text
                                        baseTheme="labelMedium"
                                        themes={{ medium: 'labelLarge' }}
                                        as="p"
                                    >
                                        How to find it
                                    </Text>
                                </div>

                                <Text
                                    baseTheme="bodySmall"
                                    themes={{ small: 'bodySmall' }}
                                    as="p"
                                >
                                    The time is displayed as
                                    <b>
                                        two pairs of numbers separated by a
                                        colon.
                                    </b>
                                </Text>
                            </div>

                            <div className={styles.examples}>
                                <Text
                                    baseTheme="labelMedium"
                                    themes={{ medium: 'labelLarge' }}
                                    as="p"
                                >
                                    Example
                                </Text>

                                {codeExamples?.map((item, i) => (
                                    <div
                                        key={i}
                                        className={styles.examplesItem}
                                    >
                                        {item.map((line, i) => (
                                            <Text
                                                key={i}
                                                dangerouslySetInnerHTML={{
                                                    __html: highlightText(
                                                        line,
                                                        timeMatcher(
                                                            line,
                                                            displayTime
                                                        ),
                                                        'em'
                                                    ),
                                                }}
                                            />
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}

                <div className={styles.buttonContainer}>
                    <ButtonCTA
                        text="Submit"
                        style="filled-blue-ocean"
                        tabIndex={tabIndex}
                        onClick={() => handleSubmit(true)}
                    />

                    <ButtonCTA
                        text="Skip this Step"
                        style="outlined-blue-blue"
                        tabIndex={tabIndex}
                        onClick={() => handleSubmit(false)}
                    />
                </div>
            </div>
        </div>
    );
};

ProductCode.propTypes = {
    formState: PropTypes.shape({
        packagingCodeExample: PropTypes.shape({
            codeExamples: PropTypes.array,
            illustration: PropTypes.object,
            codeInformation: PropTypes.object,
        }),
        size: PropTypes.shape({
            itemNumber: PropTypes.string,
            size: PropTypes.string,
            sku: PropTypes.number,
        }),
        product: PropTypes.object,
    }),
    onUpdate: PropTypes.func,
    setActiveStep: PropTypes.func,
    tabIndex: PropTypes.number,
};

export default ProductCode;
