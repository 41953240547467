import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button';
import EntryLink from 'components/ui/Link/EntryLink';
import SvgIcon from 'components/ui/SvgIcon';

import styles from './SharpnessCloseButton.module.scss';

const SharpnessCloseButton = ({
    onClose,
    scrollDirection,
    className,
    closeLink,
}) => {
    return (
        <>
            {!onClose && (
                <div
                    className={cx(styles.root, {
                        [styles.headerHidden]: scrollDirection === 'down',
                    })}
                >
                    <div className={styles.inner}>
                        <div className={cx(styles.close, className)}>
                            {!onClose && (
                                <EntryLink entry={closeLink}>
                                    <SvgIcon type="close" />
                                    <span className="sr-only">Close</span>
                                </EntryLink>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {onClose && (
                <div className={styles.portalInner}>
                    <div className={cx(styles.root, styles.isOverlay)}>
                        <div className={styles.inner}>
                            <div className={cx(styles.close, className)}>
                                <Button onClick={onClose}>
                                    <SvgIcon type="close" />
                                    <span className="sr-only">Close</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

SharpnessCloseButton.propTypes = {
    closeLink: PropTypes.object,
    onClose: PropTypes.func,
    scrollDirection: PropTypes.string,
    className: PropTypes.string,
};

export default SharpnessCloseButton;
