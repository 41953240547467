export const fragment = /* GraphQL */ `
    fragment CardCampaignFragment on CardCampaign {
        entryTitle
        headline
        description
        backgroundColor
        color
        chevronTheme
        image {
            ...Asset
        }
        link {
            ...EntryLink
        }
    }
`;
