export const fragment = /* GraphQL */ `
    fragment CardColumnContentFragment on CardColumnContent {
        entryTitle
        eyebrow
        headline
        richBodyCopy {
            json
        }
        image {
            ...Asset
        }
        imageLarge {
            ...Asset
        }
        cta {
            ...CTA
        }
        cta2 {
            ...CTA
        }
        backgroundTheme
    }
`;
