import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockTwoColumnTextAlt from './BlockTwoColumnTextAlt';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockTwoColumnTextAlt(preview: $preview, id: $id) {
            __typename
            anchorId
            title
            eyebrow
            richHeadline {
                json
            }
            bodyCopy {
                json
            }
            cta {
                ...CTA
            }
            theme
            backgroundColor
            flushTop
            flushBottom
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockTwoColumnTextAlt },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockTwoColumnTextAlt;
}

const BlockTwoColumnTextAltServer = async ({ id }) => {
    const block = await fetchBlock(id);
    return <BlockTwoColumnTextAlt {...block} />;
};

export default BlockTwoColumnTextAltServer;
