import React, { Suspense } from 'react';

import { graphRequest } from 'services/contentful';

import { assetFragment } from 'lib/graphql/fragments';

import BlockProducts from './BlockProducts';

const query = /* GraphQL */ `
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockProducts(preview: $preview, id: $id) {
            __typename
            anchorId
            title
            eyebrow
            headline
            productsCollection(limit: 200) {
                items {
                    sys {
                        id
                    }
                    __typename
                    slug
                    displayName
                    eyebrow
                    color
                    attributes
                    displayName
                    category {
                        slug
                        title
                        attributes
                    }
                    image {
                        ...Asset
                    }
                    icon
                }
            }
            useCategorySort
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockProducts },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockProducts;
}

const BlockProductsServer = async ({ id, firstBlock }) => {
    const block = await fetchBlock(id);
    const products = block.productsCollection.items;
    return (
        <Suspense>
            <BlockProducts
                {...block}
                firstBlock={firstBlock}
                products={products}
            />
        </Suspense>
    );
};

export default BlockProductsServer;
