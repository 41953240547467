import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockProductHero from './BlockProductHero';

const query = /* GraphQL */ `
    ${ctaFragment}
    ${assetFragment}
    ${linkFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockProductHero(preview: $preview, id: $id) {
            __typename
            anchorId
            blockEyebrow
            blockHeadline
            cta {
                ... on Cta {
                    ...CTA
                }
            }
            ctasCollection {
                items {
                    ... on Cta {
                        ...CTA
                    }
                }
            }
            eyebrow
            eyebrowColor
            flushBottom
            flushTop
            headline
            headlineColor
            hideCta
            icon {
                icon {
                    ...Asset
                }
            }
            image {
                ...Asset
            }
            imageLarge {
                ...Asset
            }
            paragraph
            richText {
                json
            }
            textOnRight
            theme
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockProductHero },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockProductHero;
}

const BlockProductHeroServer = async ({ id, firstBlock, lastBlock }) => {
    const block = await fetchBlock(id);
    return (
        <BlockProductHero
            {...block}
            ctas={block.ctasCollection.items}
            firstBlock={firstBlock}
            lastBlock={lastBlock}
        />
    );
};

export default BlockProductHeroServer;
