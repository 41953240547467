import React from 'react';

import { graphRequest } from 'services/contentful';

import { Block } from 'app/templates/renderBlocks';

import { sharpnessStoryFragment } from 'components/blocks/BlockSharpnessStory';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockProductSharpnessStory from './BlockProductSharpnessStory';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    ${sharpnessStoryFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockProductSharpnessStory(preview: $preview, id: $id) {
            sharpnessStoryItem {
                product
                image {
                    ...Asset
                }
                headline
                copy {
                    json
                }
                detailCopy {
                    json
                }
                backgroundColor
                vintageYear
                agedUnit
                agedTime
                flavorProfileData
                cta {
                    ...CTA
                }
            }
            sharpnessStory {
                ...SharpnessStoryFragment
            }
            sharpnessStoryPage {
                slug
            }
            backgroundColor
            eyebrow
            headline {
                json
            }
            copy {
                json
            }
            ctaText
            flushBottom
            flushTop
            anchorId
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockProductSharpnessStory },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockProductSharpnessStory;
}

const BlockProductSharpnessStoryServer = async ({ id }) => {
    const block = await fetchBlock(id);
    return (
        <BlockProductSharpnessStory
            {...block}
            masthead={
                block?.sharpnessStory?.masthead && (
                    <Block
                        id={block?.sharpnessStory.masthead.sys.id}
                        type="BlockFullWidthMedia"
                        theme="Sharpness Story"
                    />
                )
            }
        />
    );
};

export default BlockProductSharpnessStoryServer;
