import React from 'react';

import { graphRequest } from 'services/contentful';

import { fragment as cardColumnContentFragment } from 'components/cards/CardColumnContent/fragment';
import { fragment as cardLocationFragment } from 'components/cards/CardLocation/fragment';
import { fragment as cardTestimonialFragment } from 'components/cards/CardTestimonial/fragment';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
    richtextLinkFragment,
} from 'lib/graphql/fragments';

import BlockSlideShow from './BlockSlideShow';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    ${cardColumnContentFragment}
    ${cardLocationFragment}
    ${cardTestimonialFragment}
    ${richtextLinkFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockImageOffsetContent(preview: $preview, id: $id) {
            __typename
            anchorId
            slideshow {
                title
                slidesCollection(limit: 20) {
                    items {
                        title
                        image {
                            ...Asset
                        }
                        imageLarge {
                            ...Asset
                        }
                    }
                }
            }
            offsetContent {
                __typename
                ... on CardLocation {
                    ...CardLocationFragment
                }
                ... on CardColumnContent {
                    ...CardColumnContentFragment
                }
            }
            richBodyCopy {
                json
                links {
                    entries {
                        block {
                            ...RichTextLink
                        }
                        inline {
                            ...RichTextLink
                        }
                        hyperlink {
                            ...RichTextLink
                        }
                    }
                }
            }
            testimonial {
                ... on CardTestimonial {
                    ...CardTestimonialFragment
                }
            }
            creameryDiningHours {
                eyebrow
                richHeadline {
                    json
                }
                listCollection {
                    items {
                        icon
                        label
                        value
                    }
                }
            }
            offsetLeft
            backgroundTheme
            anchorId
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockImageOffsetContent },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockImageOffsetContent;
}

const BlockSlideShowServer = async ({ id }) => {
    const block = await fetchBlock(id);
    const slideshow = block?.slideshow?.slidesCollection?.items;

    return <BlockSlideShow {...block} slideshow={slideshow} />;
};

export default BlockSlideShowServer;
