import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockAccordion from './BlockAccordion';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    fragment RichTextLink on Entry {
        __typename
        sys {
            id
        }
        ... on ExternalLink {
            url
            target
        }
        ... on LinkEvent {
            url
        }
        ... on ManualLink {
            as
            href
        }
        ... on News {
            slug
        }
        ... on Page {
            slug
        }
    }
    query getBlock($id: String!, $preview: Boolean!) {
        blockAccordion(preview: $preview, id: $id) {
            __typename
            anchorId
            title
            eyebrow
            headline
            bodyCopy
            cta {
                ... on Cta {
                    ...CTA
                }
            }
            accordionItemsCollection(limit: 10) {
                items {
                    headline
                    bodyCopy {
                        json
                        links {
                            entries {
                                block {
                                    ...RichTextLink
                                }
                                inline {
                                    ...RichTextLink
                                }
                                hyperlink {
                                    ...RichTextLink
                                }
                            }
                        }
                    }
                }
            }
            groupsCollection(limit: 10) {
                items {
                    title
                    headline
                    itemsCollection(limit: 10) {
                        items {
                            headline
                            bodyCopy {
                                json
                            }
                        }
                    }
                }
            }
            download {
                ...Asset
            }
            theme
            flushTop
            flushBottom
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockAccordion },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockAccordion;
}

const BlockAccordionServer = async ({ id }) => {
    const block = await fetchBlock(id);

    return (
        <BlockAccordion
            {...block}
            accordionItems={block.accordionItemsCollection.items}
            groups={block.groupsCollection.items}
        />
    );
};

export default BlockAccordionServer;
