import React from 'react';

import PropTypes from 'prop-types';

import Img from 'components/ui/Img';
import Link from 'components/ui/Link';
import Text from 'components/ui/Text';

import { getReadableMonth } from 'utils';

import styles from './CardNewsDetail.module.scss';

export const fragment = /* GraphQL */ `
    fragment CardNewsDetailFragment on News {
        slug
        title
        date
        cardImage {
            ...Asset
        }
    }
`;

const CardNewsDetail = ({ title, slug, cardImage, date }) => {
    const formattedDate = date ? new Date(date) : null;

    return (
        <article className={styles.listItem}>
            <Link className={styles.inner} href={`/news/${slug}`}>
                <figure className={styles.aspectBox}>
                    {cardImage?.url && (
                        <Img
                            className={styles.image}
                            src={cardImage.url}
                            fallbackImageWidth={1030}
                            alt={cardImage.description || ''}
                            customSources={[
                                {
                                    breakpoint: 768,
                                    src: cardImage.url,
                                    imageWidth: 1030,
                                },
                                {
                                    src: cardImage.url,
                                    imageWidth: 768,
                                },
                            ]}
                        />
                    )}
                </figure>
                <figcaption className={styles.textBlock}>
                    {formattedDate && (
                        <Text
                            baseTheme="bodySmall"
                            themes={{ medium: 'bodyMedium' }}
                            className={styles.date}
                            as="p"
                        >
                            {`${getReadableMonth(
                                formattedDate.getUTCMonth()
                            )} ${formattedDate.getUTCDate()}, ${formattedDate.getUTCFullYear()}`}
                        </Text>
                    )}
                    {title && (
                        <Text
                            baseTheme="headingSmall"
                            themes={{ medium: 'headingMedium' }}
                            className={styles.headline}
                            as="h3"
                        >
                            {title}
                        </Text>
                    )}
                </figcaption>
            </Link>
        </article>
    );
};

CardNewsDetail.propTypes = {
    cardImage: PropTypes.shape({
        description: PropTypes.string,
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
    }),
    slug: PropTypes.string.isRequired,
    title: PropTypes.string,
    date: PropTypes.string,
};

export default CardNewsDetail;
