import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import CardTestimonial from 'components/cards/CardTestimonial';

import { blockRootProps } from 'utils';

import styles from './BlockTestimonial.module.scss';

const colorMap = {
    Blue: 'blue',
    White: 'white',
    Default: 'none',
};

const themeMap = {
    'Small Quote': 'smallQuote',
    'Large Quote': 'largeQuote',
};

const BlockTestimonial = ({
    __typename,
    anchorId,
    testimonial,
    backgroundColor,
    theme,
    flushTop,
}) => {
    backgroundColor ??= 'Default';
    theme ??= 'smallQuote';
    flushTop ??= true;

    return (
        <div
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.blockTestimonial, {
                [styles.blueBackground]: colorMap[backgroundColor] === 'blue',
                [styles.whiteBackground]: colorMap[backgroundColor] === 'white',
                [styles.flushTop]: flushTop,
            })}
        >
            <div className={styles.blockTestimonialInner}>
                <CardTestimonial
                    className={styles.testimonial}
                    {...testimonial}
                    theme={themeMap[theme]}
                    backgroundColor={colorMap[backgroundColor]}
                />
            </div>
        </div>
    );
};

BlockTestimonial.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    testimonial: PropTypes.object.isRequired,
    secondaryTestimonial: PropTypes.object,
    backgroundColor: PropTypes.oneOf(['Default', 'Blue', 'White']),
    theme: PropTypes.oneOf(['Small Quote', 'Large Quote']),
    flushTop: PropTypes.bool,
};

export default BlockTestimonial;
