import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    iconFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockHotspot from './BlockHotspot';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    ${iconFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockHotspot(preview: $preview, id: $id) {
            __typename
            eyebrow
            headline
            anchorId
            icon {
                ... on Icon {
                    ...Icon
                }
            }
            bodyCopy {
                json
            }
            image {
                ...Asset
            }
            imageLarge {
                ...Asset
            }
            cta {
                ... on Cta {
                    ...CTA
                }
            }
            textColor
            hotspotButtonColor
            hotspotsCollection(limit: 10) {
                items {
                    eyebrow
                    headline
                    bodyCopy {
                        json
                    }
                    image {
                        ...Asset
                    }
                    cta {
                        ... on Cta {
                            ...CTA
                        }
                    }
                    position
                    desktopPosition
                }
            }
            flushTop
            flushBottom
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockHotspot },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockHotspot;
}

const BlockHotspotServer = async ({ id }) => {
    const block = await fetchBlock(id);
    const hotspots = block.hotspotsCollection.items;

    return <BlockHotspot {...block} hotspots={hotspots} />;
};

export default BlockHotspotServer;
