export const assetFragment = /* GraphQL */ `
    fragment Asset on Asset {
        sys {
            id
        }
        title
        description
        contentType
        url
        fileName
        size
        width
        height
    }
`;

export const iconFragment = /* GraphQL */ `
    fragment Icon on Icon {
        sys {
            id
        }
        title
        icon {
            sys {
                id
            }
            title
            description
            contentType
            url
            fileName
            size
            width
            height
        }
        id
    }
`;

export const restaurantFragment = /* GraphQL */ `
    fragment Restaurant on Restaurant {
        sys {
            id
        }
        title
        hide
        streetAddress
        city
        state
        zipCode
        coordinates {
            lat
            lon
        }
        phoneNumber
        featuredImage {
            ...Asset
        }
        imagesCollection(limit: 20) {
            items {
                ...Asset
            }
        }
        deliveryLinksCollection(limit: 20) {
            items {
                entryTitle
                url
            }
        }
        cuisine
        foodType
        website
        menuItemsCollection(limit: 20) {
            items {
                title
                description {
                    json
                }
            }
        }
        sirvedId
        sirvedData
    }
`;

export const linkFragment = /* GraphQL */ `
    fragment EntryLink on Entry {
        __typename
        ... on ExternalLink {
            url
            target
        }
        ... on LinkEvent {
            url
        }
        ... on ManualLink {
            as
            href
        }
        ... on News {
            slug
        }
        ... on Page {
            slug
        }
        ... on Product {
            category {
                slug
            }
            slug
        }
        ... on Recipe {
            slug
        }
        ... on Quiz {
            slug
        }
        ... on FoodServiceProduct {
            category {
                slug
            }
            slug
        }
        ... on SharpnessStory {
            closeLink {
                slug
            }
        }
        ... on XolaReservationOverlay {
            xolaButtonId
            type
        }
    }
`;

export const ctaFragment = /* GraphQL */ `
    fragment CTA on Cta {
        __typename
        sys {
            id
        }
        text
        iconType
        style
        assetDownloadLink {
            ...Asset
        }
        link {
            ...EntryLink
        }
    }
`;

export const richtextLinkFragment = /* GraphQL */ `
    fragment RichTextLink on Entry {
        __typename
        sys {
            id
        }
        ... on ExternalLink {
            url
            target
        }
        ... on LinkEvent {
            url
        }
        ... on ManualLink {
            as
            href
        }
        ... on News {
            slug
        }
        ... on Page {
            slug
        }
        ... on Faq {
            slug
            category {
                slug
            }
        }
        ... on Product {
            category {
                slug
            }
            slug
        }
        ... on Quiz {
            slug
        }
        ... on ProductCategory {
            slug
        }
        ... on Recipe {
            slug
        }
        ... on SharpnessStory {
            closeLink {
                slug
            }
        }
        ... on XolaReservationOverlay {
            xolaButtonId
            type
        }
    }
`;
