import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockFeaturedArticle from './BlockFeaturedArticle';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockFeaturedArticle(preview: $preview, id: $id) {
            __typename
            anchorId
            image {
                ...Asset
            }
            imageLarge {
                ...Asset
            }
            eyebrow
            headline {
                json
            }
            cta {
                ...CTA
            }
            flushTop
            flushBottom
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockFeaturedArticle },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockFeaturedArticle;
}

const BlockFeaturedArticleServer = async ({ id }) => {
    const block = await fetchBlock(id);

    return <BlockFeaturedArticle {...block} />;
};

export default BlockFeaturedArticleServer;
