import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Icon from 'components/ui/Icon';
import Text from 'components/ui/Text';

import styles from './CardTillamaps.module.scss';

const CardTillamaps = ({ headline, copy, cta, className, icon }) => {
    if (!(headline && cta?.link && cta?.text)) {
        return null;
    }

    return (
        <div className={cx(styles.card, className)}>
            <div className={styles.cardInner}>
                {icon?.icon?.url && (
                    <div className={styles.iconWrapper}>
                        <Icon src={icon.icon.url} />
                    </div>
                )}
                <Text className={styles.headline} baseTheme="headingMedium">
                    {headline}
                </Text>
                <Text className={styles.copy} baseTheme="bodyMedium">
                    {copy}
                </Text>
                <div className={styles.ctaWrapper}>
                    <ButtonCTA {...cta} />
                </div>
            </div>
        </div>
    );
};

CardTillamaps.propTypes = {
    headline: PropTypes.string.isRequired,
    copy: PropTypes.string,
    cta: PropTypes.shape({
        link: PropTypes.object.isRequired,
        text: PropTypes.string.isRequired,
    }).isRequired,
    className: PropTypes.string,
    icon: PropTypes.object,
};

export default CardTillamaps;
