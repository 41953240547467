'use client';

import React, { useEffect, useRef, useState } from 'react';
import { useIntersection, useWindowSize } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import ButtonCircle from 'components/ui/ButtonCircle';
import Img from 'components/ui/Img';
import EntryLink from 'components/ui/Link/EntryLink';
import RichText from 'components/ui/RichText';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import { useTheme } from 'hooks/useTheme';

import SharpnessHeadlineMakersReserve from './SharpnessHeadlineMakersReserve';

import AgeBug from '../AgeBug';

import styles from './SharpnessStoryItem.module.scss';

const richTextOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text.Theme
                    as="p"
                    config={{
                        'makers-reserve': {
                            baseTheme: 'parkinsonXSmall',
                            themes: { large: 'parkinsonSmall' },
                        },
                        default: {
                            baseTheme: 'bodyXSmall',
                            themes: { large: 'bodyMedium' },
                        },
                    }}
                >
                    {children}
                </Text.Theme>
            );
        },
        [BLOCKS.UL_LIST]: (node, children) => {
            return <ul className={styles.unorderedList}>{children}</ul>;
        },
    },
};

const richTextOverridesDetail = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text.Theme
                    as="p"
                    config={{
                        'makers-reserve': {
                            baseTheme: 'bodyXSmall',
                        },
                        default: {
                            baseTheme: 'bodyXSmall',
                            themes: { large: 'bodyMedium' },
                        },
                    }}
                >
                    {children}
                </Text.Theme>
            );
        },
        [BLOCKS.UL_LIST]: (node, children) => {
            return <ul className={styles.unorderedList}>{children}</ul>;
        },
    },
};

const SharpnessStoryItem = ({
    product,
    sys,
    image,
    headline,
    copy,
    detailCopy,
    cta,
    isInView,
    isMedium,
    isPage,
    onChange,
    vintageYear,
    agedUnit,
    agedTime,
    children,
}) => {
    const [globalTheme] = useTheme();
    const isMakersReserveSharpness = globalTheme === 'makers-reserve';

    const heightRef = useRef(null);
    const inViewRef = useRef(null);

    const [height, setHeight] = useState(0);
    const [expanded, setExpanded] = useState(false);

    const { width } = useWindowSize();

    const ratio = isMedium ? 0.6 : expanded ? 0.3 : 0.4;

    const intersection = useIntersection(inViewRef, {
        root: null,
        rootMargin: '0px',
        threshold: ratio,
    });

    useEffect(() => {
        if (
            intersection &&
            intersection.isIntersecting &&
            intersection.intersectionRatio > ratio
        ) {
            isInView(sys.id);
        }
    }, [intersection, isInView, ratio, sys.id]);

    const getHeight = () => {
        if (heightRef?.current) {
            setHeight(heightRef.current.offsetHeight);
        }
    };

    useEffect(() => {
        getHeight();
    }, [width]);

    const handleMoreDetailsClick = () => {
        getHeight();
        setExpanded(!expanded);
    };

    const handleTransitionEnd = e => {
        if (e.propertyName === 'height') onChange();
    };

    const renderCTA = () => {
        if (cta?.text && cta?.link) {
            return (
                <EntryLink className={styles.cta} entry={cta.link}>
                    <span className={styles.ctaInner}>
                        <Text
                            className={styles.ctaInnerText}
                            baseTheme="labelSmall"
                            themes={{ large: 'labelLarge' }}
                        >
                            {cta.text}
                        </Text>
                        <ButtonCircle
                            theme={
                                isMakersReserveSharpness
                                    ? 'small-fill-gold'
                                    : 'small-fill-cream'
                            }
                            iconType="chevronRight"
                        />
                    </span>
                </EntryLink>
            );
        }
    };

    const staggerConfig = {
        duration: 0.8,
        delay: 0.24,
    };

    const expandableCTACopy = {
        'makers-reserve': {
            collapsed: 'Details',
            expanded: 'Hide Details',
        },
        default: {
            collapsed: 'More Details',
            expanded: 'Hide Details',
        },
    };

    return (
        <div
            ref={inViewRef}
            className={cx(
                styles.root,
                styles[product],
                styles[`theme--${globalTheme}`],
                {
                    [styles.isPage]: isPage,
                }
            )}
        >
            <div className={styles.inner}>
                <Stagger>
                    <StaggerChild
                        className={styles.headlineWrapper}
                        order={0}
                        delay={staggerConfig.delay}
                        duration={staggerConfig.duration}
                    >
                        {product &&
                            product !== "Maker's Reserve" &&
                            !isMakersReserveSharpness && (
                                <Text
                                    className={styles.headline}
                                    baseTheme="displaySmall"
                                    themes={{ large: 'displayLarge' }}
                                >
                                    {headline ? headline : product}
                                </Text>
                            )}
                        {product &&
                            product === "Maker's Reserve" &&
                            !isMakersReserveSharpness && (
                                <div className={styles.makersLogo}>
                                    <SvgIcon
                                        className={styles.makersLogoIcon}
                                        type="tillaLogoMakers"
                                    />
                                </div>
                            )}
                        {isMakersReserveSharpness && (
                            <SharpnessHeadlineMakersReserve
                                headline={headline}
                                year={vintageYear}
                            />
                        )}
                        {copy && (
                            <div className={styles.copy}>
                                <RichText
                                    overrides={richTextOverrides}
                                    richText={copy}
                                />
                            </div>
                        )}
                    </StaggerChild>
                    {children}
                    <StaggerChild
                        className={styles.aspectWrapper}
                        order={1}
                        delay={staggerConfig.delay}
                        duration={staggerConfig.duration}
                    >
                        <div className={styles.aspect}>
                            {image?.url && (
                                <Img
                                    className={styles.image}
                                    src={image.url}
                                    fallbackImageWidth={1280}
                                    alt={image.description || ''}
                                    customSources={[
                                        {
                                            breakpoint: 1024,
                                            src: image.url,
                                            imageWidth: 1440,
                                        },
                                        {
                                            src: image.url,
                                            imageWidth: 1280,
                                        },
                                    ]}
                                />
                            )}
                            <div className={styles.ageBug}>
                                <span className={styles.ageBugIcon}>
                                    <AgeBug
                                        duration={agedTime}
                                        agedUnit={agedUnit}
                                    />
                                </span>
                            </div>
                        </div>
                    </StaggerChild>
                    <StaggerChild
                        order={2}
                        delay={staggerConfig.delay}
                        duration={staggerConfig.duration}
                    >
                        <div className={styles.ctas}>
                            {detailCopy && (
                                <div
                                    className={styles.cta}
                                    onClick={handleMoreDetailsClick}
                                >
                                    <span className={styles.ctaInner}>
                                        <Text
                                            className={styles.ctaInnerText}
                                            baseTheme="labelSmall"
                                            themes={{ large: 'labelLarge' }}
                                        >
                                            {expanded
                                                ? expandableCTACopy?.[
                                                      globalTheme
                                                  ]?.expanded
                                                : expandableCTACopy?.[
                                                      globalTheme
                                                  ]?.collapsed}
                                        </Text>
                                        {isMakersReserveSharpness ? (
                                            <ButtonCircle
                                                theme="small-fill-gold"
                                                iconType={
                                                    expanded ? 'minus' : 'plus'
                                                }
                                            />
                                        ) : (
                                            <ButtonCircle
                                                theme="small-fill-cream"
                                                iconType={
                                                    expanded ? 'minus' : 'plus'
                                                }
                                            />
                                        )}
                                    </span>
                                </div>
                            )}
                            {renderCTA()}
                        </div>
                        <div
                            className={styles.detailsCopy}
                            onTransitionEnd={handleTransitionEnd}
                            style={{
                                height: expanded ? height : 0,
                            }}
                        >
                            {detailCopy && (
                                <div
                                    ref={heightRef}
                                    className={styles.detailsCopyInnerHeight}
                                >
                                    <div className={styles.detailsCopyInner}>
                                        <RichText
                                            overrides={richTextOverridesDetail}
                                            richText={detailCopy}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </StaggerChild>
                </Stagger>
            </div>
        </div>
    );
};

SharpnessStoryItem.propTypes = {
    sys: PropTypes.object.isRequired,
    vintageYear: PropTypes.number,
    agedUnit: PropTypes.string,
    agedTime: PropTypes.number,
    product: PropTypes.string,
    headline: PropTypes.string,
    items: PropTypes.array,
    copy: PropTypes.object,
    detailCopy: PropTypes.object,
    isInView: PropTypes.func,
    cta: PropTypes.shape({
        assetDownloadLink: PropTypes.object,
        link: PropTypes.shape({
            contentTypeId: PropTypes.string,
        }),
        text: PropTypes.string,
    }),
    image: PropTypes.shape({
        description: PropTypes.string,
        url: PropTypes.string,
    }),
    isMedium: PropTypes.bool,
    isPage: PropTypes.bool,
    onChange: PropTypes.func,
    children: PropTypes.node,
};

export default SharpnessStoryItem;
