'use client';

import React, { useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import CardColumnContent from 'components/cards/CardColumnContent';
import CardLocation from 'components/cards/CardLocation';
import CardTestimonial from 'components/cards/CardTestimonial';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';

import { blockRootProps } from 'utils';

import CreameryDiningHours from './CreameryDiningHours';
import SlideShow from './SlideShow';

import styles from './BlockSlideShow.module.scss';

const CardMissing = ({ __typename }) => {
    return <p>No Card{__typename}</p>;
};

CardMissing.propTypes = {
    __typename: PropTypes.string,
};

const cardMap = {
    CardLocation,
    CardColumnContent,
    default: CardMissing,
};

const richTextOverrides = {
    renderNode: {
        [BLOCKS.HEADING_4]: (node, children) => {
            return (
                <Text
                    as="h4"
                    baseTheme="headingSmall"
                    themes={{ large: 'headingMedium' }}
                >
                    {children}
                </Text>
            );
        },
    },
};

const BlockImageOffsetContent = ({
    __typename,
    anchorId,
    slideshow,
    richBodyCopy,
    offsetContent,
    testimonial,
    offsetLeft,
    creameryDiningHours,
    backgroundTheme,
    className,
}) => {
    backgroundTheme ??= 'default';

    const [isInView, setIsInView] = useState(false);

    const Card =
        cardMap[offsetContent?.__typename || 'default'] || cardMap['default'];

    const ref = useRef(null);

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    useEffect(() => {
        if (intersection?.isIntersecting) {
            setIsInView(true);
        }
    }, [intersection]);

    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.imageOffsetContent, className, {
                [styles.offsetLeft]: offsetLeft,
                [styles.hasTestimonial]: testimonial,
                [styles.whiteBackground]: backgroundTheme === 'white',
                [styles.hasTestimonialWithoutImage]:
                    testimonial && !testimonial.authorImage,
            })}
        >
            {slideshow?.length > 0 && (
                <SlideShow slides={slideshow} dotsOnLeft={!offsetLeft} />
            )}
            <div className={styles.columns}>
                <div
                    className={cx(styles.column, styles.columnContent, {
                        [styles.noContent]:
                            !richBodyCopy &&
                            !testimonial &&
                            !creameryDiningHours,
                    })}
                >
                    {richBodyCopy && !testimonial && !creameryDiningHours && (
                        <RichText
                            richText={richBodyCopy}
                            overrides={richTextOverrides}
                        />
                    )}
                    {testimonial && !richBodyCopy && !creameryDiningHours && (
                        <CardTestimonial {...testimonial} />
                    )}
                    {creameryDiningHours && !testimonial && !richBodyCopy && (
                        <CreameryDiningHours
                            {...creameryDiningHours}
                            list={creameryDiningHours.listCollection.items}
                        />
                    )}
                </div>
                {offsetContent && (
                    <div
                        ref={ref}
                        className={cx(
                            styles.column,
                            styles.columnOffsetContent,
                            {
                                [styles.isInView]: isInView,
                            }
                        )}
                    >
                        <Card
                            {...offsetContent}
                            hours={offsetContent.hoursCollection?.items}
                        />
                    </div>
                )}
            </div>
        </section>
    );
};

BlockImageOffsetContent.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    slideshow: PropTypes.array,
    richBodyCopy: PropTypes.object,
    offsetContent: PropTypes.object,
    testimonial: PropTypes.object,
    offsetLeft: PropTypes.bool,
    backgroundTheme: PropTypes.oneOf(['default', 'white']),
    creameryDiningHours: PropTypes.object,
    className: PropTypes.string,
};

export default BlockImageOffsetContent;
