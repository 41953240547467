import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { getColor } from 'utils';

import styles from './GradientPanel.module.scss';

const GradientPanel = ({ className, isActive, isPrev, color }) => {
    color ??= 'orange';

    return (
        <div
            role="presentation"
            className={cx(styles.gradientPanel, className, {
                [styles.isActive]: isActive,
                [styles.isPrev]: isPrev,
            })}
            style={{
                '--slide-background-color': getColor(color),
            }}
        />
    );
};

GradientPanel.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    imageOnLeft: PropTypes.bool,
    isActive: PropTypes.bool,
    isPrev: PropTypes.bool,
};

export default GradientPanel;
