import React, { useRef } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import styles from '../RadioGroup.module.scss';

export function Radio(props) {
    const { setValue, fieldName, label, value, register, watch } = props;
    const radioRef = useRef(null);

    watch(fieldName);
    const { ref, ...rest } = register(fieldName);

    return (
        <li
            id={`${fieldName}-${value}-container`}
            className={cx(styles.option, {
                [styles.selected]: radioRef?.current?.checked,
            })}
            onClick={e => {
                e.stopPropagation();
                setValue(fieldName, radioRef?.current?.value);
            }}
            title={`Click to select “${value}”`}
        >
            <input
                id={`${fieldName}-${value}`}
                className={styles.radio}
                {...rest}
                ref={e => {
                    ref(e);
                    radioRef.current = e;
                }}
                type="radio"
                value={value}
                onClick={e => e.stopPropagation()}
                hidden
            />
            <SvgIcon
                className={styles.fauxRadio}
                type={
                    radioRef?.current?.checked
                        ? 'radioChecked'
                        : 'radioUnchecked'
                }
                onClick={e => {
                    e.stopPropagation();
                    setValue(fieldName, radioRef?.current?.value);
                }}
            />
            <Text
                as="label"
                baseTheme="bodySmall"
                htmlFor={`${fieldName}-${value}`}
                className={styles.label}
            >
                {label}
            </Text>
        </li>
    );
}

Radio.propTypes = {
    label: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
};

const RadioGroupForm = ({ fieldName, options, setValue, register, watch }) => {
    return (
        <ul className={styles.options}>
            {options.map((k, idx) => (
                <Radio
                    key={idx}
                    name={k}
                    value={k}
                    label={k}
                    setValue={setValue}
                    fieldName={fieldName}
                    register={register}
                    watch={watch}
                />
            ))}
        </ul>
    );
};

RadioGroupForm.propTypes = {
    fieldName: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    register: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
};

export default RadioGroupForm;
