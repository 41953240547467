'use client';

import React from 'react';

import { useContentfulLiveUpdates } from '@contentful/live-preview/react';

import BlockNews from './BlockNews';

const Preview = props => {
    const updatedProps = useContentfulLiveUpdates(props);
    return <BlockNews {...updatedProps} />;
};

export default Preview;
