import React from 'react';

const ContactContext = React.createContext({
    animationState: '',
    timingBase: 0,
    contactReason: null,
    activeStep: 0,
    stepsLength: 0,
    steps: [],
    gridConfig: [],
    setContactReason: () => {},
    setActiveStep: () => {},
    handleReset: () => {},
});

export default ContactContext;
