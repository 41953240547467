import React from 'react';

import PropTypes from 'prop-types';

import Icon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import styles from './Legend.module.scss';

const Legend = ({ headline, items }) => {
    return (
        <div className={styles.root}>
            <Text
                as="h4"
                baseTheme="headingXSmall2"
                themes={{
                    large: 'headingSmall2',
                }}
                className={styles.headline}
            >
                {headline}
            </Text>
            <ul>
                {items.map(({ text, colorHex, shape }, i) => (
                    <li key={i}>
                        <div
                            className={styles.icon}
                            style={{ color: colorHex }}
                        >
                            <Icon type={shape.toLowerCase()} />
                        </div>
                        <Text className={styles.label} baseTheme="bodySmall">
                            {text}
                        </Text>
                    </li>
                ))}
            </ul>
        </div>
    );
};

Legend.propTypes = {
    headline: PropTypes.string.isRequired,
    items: PropTypes.array,
};

export default Legend;
