import React from 'react';

import PropTypes from 'prop-types';

import AnimatedIconFarmers from './AnimatedIcons/Farmers';
import AnimatedIconPackaging from './AnimatedIcons/Packaging';
import AnimatedIconUtensils from './AnimatedIcons/Utensils';

import styles from './AnimatedIcon.module.scss';

const iconMap = {
    farmers: <AnimatedIconFarmers />,
    packaging: <AnimatedIconPackaging />,
    utensils: <AnimatedIconUtensils />,
};

const AnimatedIcon = ({ icon }) => {
    return <div className={styles.root}>{iconMap[icon]}</div>;
};

AnimatedIcon.propTypes = {
    icon: PropTypes.oneOf(['farmers', 'packaging', 'utensils']),
};

export default AnimatedIcon;
