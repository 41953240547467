import React from 'react';

import PropTypes from 'prop-types';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';

import { Block } from 'app/templates/renderBlocks';

import ButtonCTA from 'components/ui/ButtonCTA';
import Link from 'components/ui/Link';
import EntryLink from 'components/ui/Link/EntryLink';

import { resolveDocumentLinks } from 'utils';

import {
    Blockquote,
    BodyText,
    Bold,
    HeadingFive,
    HeadingFour,
    HeadingOne,
    HeadingSix,
    HeadingThree,
    HeadingTwo,
    Italic,
    ListItem,
    OrderedList,
    Underline,
    UnorderedList,
} from './defaults';

const defaultOptions = {
    renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
        [MARKS.UNDERLINE]: text => <Underline>{text}</Underline>,
    },
    renderNode: {
        [INLINES.ENTRY_HYPERLINK]: node => {
            return (
                <EntryLink entry={node?.data?.target}>
                    <Underline>{node.content[0].value}</Underline>
                </EntryLink>
            );
        },
        [INLINES.HYPERLINK]: node => {
            return (
                <Link href={node?.data?.uri}>
                    <Underline>{node.content[0].value}</Underline>
                </Link>
            );
        },
        [INLINES.ASSET_HYPERLINK]: node => {
            return (
                <Link href={node?.data?.target?.url}>
                    <Underline>{node.content[0].value}</Underline>
                </Link>
            );
        },
        [INLINES.EMBEDDED_ENTRY]: node => {
            const { contentTypeId = [], text } = node.data.target;
            switch (contentTypeId) {
                case 'cta':
                    return <ButtonCTA {...node.data.target} text={text} />;

                default:
                    console.warn(
                        `${contentTypeId} embedded entry needs rendering block`
                    );
                    return null;
            }
        },
        [BLOCKS.EMBEDDED_ENTRY]: node => {
            const { __typename, audioSources = [], text } = node.data.target;

            switch (__typename) {
                case 'blockVideo':
                    return (
                        <Block
                            {...node.data.target}
                            embeddedEntry
                            type="BlockVideo"
                        />
                    );
                case 'blockImage':
                    return (
                        <Block
                            {...node.data.target}
                            embeddedEntry
                            type="BlockImage"
                        />
                    );
                case 'blockAudio':
                    return <Block type="BlockAudio" sources={audioSources} />;
                case 'cta':
                    return <ButtonCTA {...node.data.target} text={text} />;

                default:
                    console.warn(
                        `${__typename} embedded entry needs rendering block`
                    );
                    return null;
            }
        },
        [BLOCKS.EMBEDDED_ASSET]: node => {
            if (!node.data.target) {
                return null;
            }
            const { title, description, contentType, width, url, fileName } =
                node.data.target;
            const mimeType = contentType;
            const mimeGroup = mimeType.split('/')[0];

            switch (mimeGroup) {
                case 'image':
                    return (
                        <img
                            title={title ? title : null}
                            alt={description ? description : null}
                            src={url}
                            width={width}
                        />
                    );
                case 'application':
                    return (
                        <a alt={description ? description : null} href={url}>
                            {title ? title : fileName}
                        </a>
                    );
                default:
                    console.log(
                        `${mimeType} embedded asset needs rendering element`
                    );
                    return null;
            }
        },
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return <BodyText>{children}</BodyText>;
        },
        [BLOCKS.HEADING_1]: (node, children) => {
            return <HeadingOne>{children}</HeadingOne>;
        },
        [BLOCKS.HEADING_2]: (node, children) => {
            return <HeadingTwo>{children}</HeadingTwo>;
        },
        [BLOCKS.HEADING_3]: (node, children) => {
            return <HeadingThree>{children}</HeadingThree>;
        },
        [BLOCKS.HEADING_4]: (node, children) => {
            return <HeadingFour>{children}</HeadingFour>;
        },
        [BLOCKS.HEADING_5]: (node, children) => {
            return <HeadingFive>{children}</HeadingFive>;
        },
        [BLOCKS.HEADING_6]: (node, children) => {
            return <HeadingSix>{children}</HeadingSix>;
        },
        [BLOCKS.QUOTE]: (node, children) => {
            return <Blockquote>{children}</Blockquote>;
        },
        [BLOCKS.UL_LIST]: (node, children) => {
            return <UnorderedList>{children}</UnorderedList>;
        },
        [BLOCKS.OL_LIST]: (node, children) => {
            return <OrderedList>{children}</OrderedList>;
        },
        [BLOCKS.LIST_ITEM]: (node, children) => {
            return <ListItem>{children}</ListItem>;
        },
    },
};

const RichText = ({
    richText,
    overrides = {
        renderText: null,
    },
}) => {
    const doc = resolveDocumentLinks(richText.json, richText.links);

    const options = {
        renderMark: {
            ...defaultOptions.renderMark,
            ...(overrides?.renderMark || {}),
        },
        renderNode: {
            ...defaultOptions.renderNode,
            ...(overrides?.renderNode || {}),
        },
        renderText: overrides?.renderText,
    };

    if (!doc) {
        return null;
    }

    return documentToReactComponents(doc, options);
};

RichText.propTypes = {
    richText: PropTypes.object,
    overrides: PropTypes.object,
};

export default RichText;
