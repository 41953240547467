import React from 'react';

import { graphRequest } from 'services/contentful';

import { assetFragment } from 'lib/graphql/fragments';

import BlockVideo from './BlockVideo';

const query = /* GraphQL */ `
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockVideo(preview: $preview, id: $id) {
            __typename
            anchorId
            flushTop
            flushBottom
            title
            video {
                ... on VideoPlayerYoutube {
                    headline
                    richTextHeadline {
                        json
                    }
                    richTextHeadlineSize
                    richTextHeadlineIcon
                    eyebrow
                    youtubeVideoId
                    image {
                        ...Asset
                    }
                    imageLarge {
                        ...Asset
                    }
                    videoTextureMp4 {
                        ...Asset
                    }
                    videoTextureWebm {
                        ...Asset
                    }
                    videoTexturePoster {
                        ...Asset
                    }
                }
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockVideo },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockVideo;
}

const BlockVideoServer = async ({ id }) => {
    const block = await fetchBlock(id);

    return <BlockVideo {...block} />;
};

export default BlockVideoServer;
