import React, { useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button';

import highlightText from 'utils/highLightText';

import Search from './Search';

import SvgIcon from '../SvgIcon';

import styles from './PlaceOfPurchase.module.scss';

const RETURN_KEYCODE = 13;
const ARROW_RIGHT_KEYCODE = 39;
const NUMBER_OF_SUGGESTIONS = 3;

const matchStores = (str, items) => {
    if (str.length === 0) return [];

    const results = items.filter(name =>
        name.match(new RegExp('^' + str, 'iu'))
    );

    return results;
};

const PlaceOfPurchase = ({
    register,
    retailers,
    fieldName,
    displaySuggestions = true,
    watch,
    setValue,
}) => {
    const [searchValue, setSearchValue] = useState('');
    const [bestMatches, setBestMatches] = useState([]);
    const [bestGuess, setBestGuess] = useState(null);
    const [autoComplete, setAutoComplete] = useState(true);
    const [collapsed, setCollapsed] = useState(true);
    const [icon, setIcon] = useState('search');

    const closeSuggestions = () => {
        setAutoComplete(false);
        setBestMatches([]);
        setBestGuess(null);
        setSearchValue('');
    };

    const handleInputKeyDown = e => {
        if ([RETURN_KEYCODE, ARROW_RIGHT_KEYCODE].includes(e.keyCode)) {
            e.preventDefault();

            if (bestGuess) {
                setIcon('close');
                setValue(fieldName, bestGuess);

                closeSuggestions();
            }
        }

        !autoComplete && setAutoComplete(true);
    };

    const handleArrowClick = e => {
        e.preventDefault();

        bestGuess && setValue(fieldName, bestGuess);
        fieldRef.current?.value?.length > 0 && setIcon('close');

        closeSuggestions();
    };

    const reset = e => {
        e.preventDefault();

        setValue(fieldName, '');
        setIcon('search');
        closeSuggestions();
    };

    useEffect(() => {
        if (autoComplete) {
            const matches = matchStores(searchValue, retailers);
            const bestOnes = matches.slice(0, NUMBER_OF_SUGGESTIONS + 1);

            setBestMatches(bestOnes);
            setBestGuess(bestOnes[0]);

            searchValue?.length > 0 ? setCollapsed(false) : setCollapsed(true);
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps*/
    }, [searchValue, retailers]);

    watch(fieldName);

    const fieldRef = useRef(null);
    const wrapperRef = useRef(null);

    const { ref, onChange, ...rest } = register(fieldName, {
        required: false,
    });

    useClickAway(wrapperRef, () => {
        bestGuess && setValue(fieldName, bestGuess);
        fieldRef.current?.value?.length > 0 && setIcon('close');

        closeSuggestions();
    });

    return (
        <div className={styles.root} ref={wrapperRef}>
            <div
                className={cx(styles.innerWrapper, {
                    [styles.collapsed]: collapsed,
                })}
            >
                <div className={styles.inputWrapper}>
                    <input
                        {...rest}
                        ref={e => {
                            ref(e);
                            fieldRef.current = e;
                        }}
                        title="Type to find a retailer"
                        placeholder="Type to find a retailer"
                        autoComplete="off"
                        type="text"
                        onChange={e => {
                            onChange(e);
                            setSearchValue(
                                e.target.value?.charAt(0).toUpperCase() +
                                    e.target.value?.substring(1)
                            );
                            e.target.value.length === 0 && setIcon('search');
                        }}
                        onKeyDown={handleInputKeyDown}
                        className={styles.input}
                        enterKeyHint="search"
                    />
                    {searchValue && (
                        <div
                            className={styles.topSuggestion}
                            onClick={handleArrowClick}
                            dangerouslySetInnerHTML={{
                                __html: highlightText(
                                    bestGuess,
                                    searchValue,
                                    'em'
                                ),
                            }}
                        />
                    )}
                    <div>
                        {icon !== 'search' ? (
                            <Button
                                theme="close"
                                onClick={reset}
                                className={styles.closeButton}
                                aria-label="Clear retailer name"
                            >
                                <SvgIcon
                                    type="close"
                                    title="Clear retailer name"
                                />
                            </Button>
                        ) : (
                            <Button
                                theme="none"
                                onClick={handleArrowClick}
                                aria-label="Select retailer name"
                                title="Select retailer name"
                            >
                                <Search />
                            </Button>
                        )}
                    </div>
                </div>
                {displaySuggestions && (
                    <div
                        className={cx(styles.suggestedStoresContainer, {
                            [styles.collapsed]:
                                collapsed || bestMatches.length < 2,
                        })}
                    >
                        <ul className={styles.suggestions}>
                            {bestMatches
                                .slice(1, NUMBER_OF_SUGGESTIONS + 1)
                                ?.map(store => (
                                    <li
                                        key={store}
                                        className={styles.store}
                                        title="Click to select this retailer"
                                        onClick={() => {
                                            closeSuggestions();
                                            setValue(fieldName, store);
                                            setIcon('close');
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: highlightText(
                                                store,
                                                searchValue,
                                                'em'
                                            ),
                                        }}
                                    />
                                ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

PlaceOfPurchase.propTypes = {
    register: PropTypes.func.isRequired,
    retailers: PropTypes.array.isRequired,
    fieldName: PropTypes.string.isRequired,
    displaySuggestions: PropTypes.bool,
    watch: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
};

export default PlaceOfPurchase;
