'use client';

import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useIntersection } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import carouselContext from 'components/ui/CarouselProvider/CarouselContext';
import { MODE } from 'components/ui/CarouselProvider/carouselConstants';

import Description from './Description';
import Image from './Image';
import Swiper from './Swiper';

import styles from './BlockFlipDeck.module.scss';

const { PAUSED, PLAYING, STOPPED } = MODE;

const FlipDeck = ({ flushTop, flushBottom, ...descriptionProps }) => {
    const ref = useRef(null);

    const [isForward, setIsForward] = useState(true);
    const [isInView, setIsInView] = useState(false);

    const cc = useContext(carouselContext);

    const intRatio = 0.5;
    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '0px',
        threshold: intRatio,
    });

    useEffect(() => {
        intersection && intersection.intersectionRatio > intRatio
            ? setIsInView(true)
            : setIsInView(false);
    }, [intersection]);

    const handleNextSlide = useCallback(() => {
        setIsForward(true);
        cc.nextSlide();
    }, [cc, setIsForward]);

    const handlePrevSlide = useCallback(() => {
        setIsForward(false);
        cc.nextSlide();
    }, [cc, setIsForward]);

    const handleKeydown = useCallback(() => {
        const rightArrow = event.keyCode === 39;
        const leftArrow = event.keyCode === 37;

        rightArrow === true && isInView && handlePrevSlide();
        leftArrow === true && isInView && handleNextSlide();
    }, [handleNextSlide, handlePrevSlide, isInView]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeydown, false);
        return () => {
            document.removeEventListener('keydown', handleKeydown, false);
        };
    }, [handleKeydown]);

    return (
        <section
            ref={ref}
            className={cx(styles.root, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.isContinuous]: cc.isContinuous,
                [styles[PLAYING]]: cc.mode === PLAYING,
                [styles[PAUSED]]: cc.mode === PAUSED,
                [styles.isSwitching]: cc.isContinuous
                    ? cc.seconds === 0 && !cc.whileInterrupt
                    : cc.seconds === 0 && cc.mode !== STOPPED,
            })}
        >
            <div className={styles.inner}>
                <div className={styles.col}>
                    <Swiper
                        className={styles.mediaWrapper}
                        tap={handlePrevSlide}
                        next={handleNextSlide}
                        prev={handlePrevSlide}
                        mouse
                    >
                        {cc.slides?.map((slide, i) => (
                            <Image
                                key={i}
                                {...slide}
                                isTop={cc.activeIndex === i}
                                // Check if last slide
                                isMiddle={
                                    cc.activeIndex < cc.totalSlides - 1
                                        ? cc.activeIndex + 1 === i
                                        : cc.activeIndex - 2 === i
                                }
                                // Check if last slide
                                isBottom={
                                    cc.activeIndex > 0
                                        ? cc.activeIndex - 1 === i
                                        : cc.activeIndex + 2 === i
                                }
                                isPrev={cc.prevIndex === i}
                                isForward={isForward}
                                alt={slide.image?.description || ''}
                            />
                        ))}
                    </Swiper>
                    <nav className={styles.nav}>
                        <div className={styles.dots}>
                            {cc.slides.map((slide, i) => {
                                return (
                                    <div
                                        key={i}
                                        className={cx(styles.dot, {
                                            [styles.active]:
                                                cc.activeIndex === i,
                                        })}
                                    />
                                );
                            })}
                        </div>
                    </nav>
                </div>
                <div className={styles.col}>
                    <Description {...descriptionProps} />
                </div>
            </div>
        </section>
    );
};

FlipDeck.propTypes = {
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
};

export default FlipDeck;
