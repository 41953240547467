'use client';

import React from 'react';
import { usePrevious } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import VideoPlayerYTPortal from 'components/ui/VideoPlayerYT/VideoPlayerYTPortal';

import { blockRootProps, getColor } from 'utils';

import Item from './Item';
import ItemMedia from './ItemMedia';

import styles from './BlockMediaAccordion.module.scss';

const BlockMediaAccordion = ({
    __typename,
    anchorId,
    mediaOnLeft,
    backgroundColor,
    flushTop,
    flushBottom,
    items,
}) => {
    mediaOnLeft ??= true;

    const [activeIndex, setActiveIndex] = React.useState(0);
    const previousIndex = usePrevious(activeIndex);
    const [isVideoOverlayOpen, setIsVideoOverlayOpen] = React.useState(false);

    const activeItem = items[activeIndex];

    const handleItemClick = index => {
        setActiveIndex(index);
    };

    const handleVideoClick = () => {
        setIsVideoOverlayOpen(true);
    };

    const handleClose = () => {
        setIsVideoOverlayOpen(false);
    };

    if (items.length < 2) {
        console.warn('BlockMediaAccordion must have at least 2 items');
        return null;
    }

    return (
        <>
            <section
                {...blockRootProps(anchorId, __typename)}
                className={cx(styles.root, {
                    [styles.flushTop]: flushTop,
                    [styles.flushBottom]: flushBottom,
                    [styles.mediaOnLeft]: mediaOnLeft,
                })}
                style={
                    backgroundColor && {
                        '--background-color': getColor(backgroundColor),
                    }
                }
            >
                <div className={styles.inner}>
                    <div className={styles.media}>
                        {items?.map((item, i) => (
                            <ItemMedia
                                key={i}
                                {...item}
                                isActive={i === activeIndex}
                                isPrevious={i === previousIndex}
                                onVideoClick={handleVideoClick}
                            />
                        ))}
                    </div>
                    {items.length > 1 && (
                        <div className={styles.accordion} data-aria-accordion>
                            {items?.map((item, i) => {
                                return (
                                    <Item
                                        key={i}
                                        {...item}
                                        index={i}
                                        isActive={i === activeIndex}
                                        onClick={() => {
                                            handleItemClick(i);
                                        }}
                                        onVideoButtonClick={handleVideoClick}
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>
            </section>
            {activeItem.youtubeVideoId && (
                <VideoPlayerYTPortal
                    headline={`Item ${activeIndex + 1}`}
                    youtubeVideoId={activeItem.youtubeVideoId}
                    isOpen={isVideoOverlayOpen}
                    handleClose={handleClose}
                />
            )}
        </>
    );
};

BlockMediaAccordion.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
    mediaOnLeft: PropTypes.bool,
    backgroundColor: PropTypes.string,
    items: PropTypes.array,
};

export default BlockMediaAccordion;
