'use client';

/* eslint-disable @next/next/no-img-element */
import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import DecorativeBorder from 'components/globals/DecorativeBorder';

import CardProduct from 'components/cards/CardProduct';
import ButtonCTA from 'components/ui/ButtonCTA';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';
import TextEyebrow from 'components/ui/TextEyebrow';

import useMediaQuery from 'hooks/useMediaQuery';
import { useTheme } from 'hooks/useTheme';
import { getColor } from 'utils';
import { blockRootProps } from 'utils';

import styles from './BlockSimpleProductList.module.scss';

const richTextOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text.Theme
                    as="p"
                    config={{
                        default: {
                            baseTheme: 'headingXSmall',
                        },
                    }}
                    className={styles.endCardText}
                >
                    {children}
                </Text.Theme>
            );
        },
    },
};

const BlockSimpleProductList = ({
    __typename,
    anchorId,
    products,
    headline,
    eyebrow,
    endCard,
    flushTop,
    flushBottom,
}) => {
    const [globalTheme] = useTheme();
    const isMedium = useMediaQuery('(min-width: 768px)');

    return (
        <div
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.root, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles['theme--makers-reserve']]:
                    globalTheme === 'makers-reserve',
            })}
        >
            <TextEyebrow
                className={styles.eyebrow}
                headline={headline}
                eyebrow={eyebrow}
                globalTheme={globalTheme}
                theme={globalTheme === 'makers-reserve' ? null : 'ultrabold'}
                eyebrowColor={
                    globalTheme === 'makers-reserve'
                        ? 'makersReserveGold'
                        : 'blue'
                }
                headlineColor={globalTheme === 'makers-reserve' ? null : 'blue'}
            />
            <ul className={styles.productList}>
                {products.map((product, i) => {
                    if (!product.category || !product.slug) {
                        // These are an absolute must
                        return null;
                    }
                    return (
                        <li className={styles.listItem} key={i}>
                            <CardProduct
                                color={product.color}
                                category={product.category}
                                eyebrow={product.eyebrow}
                                image={product.image}
                                displayName={product.displayName}
                                slug={product.slug}
                                theme={product.theme}
                                award={product.award}
                            />
                        </li>
                    );
                })}
                {endCard?.text && (
                    <li
                        key="end-card"
                        className={styles.listItem}
                        style={{
                            backgroundColor: `${
                                endCard?.backgroundColor
                                    ? getColor(endCard.backgroundColor)
                                    : getColor('blue')
                            }`,
                            color: `${
                                endCard?.textColor
                                    ? getColor(endCard.textColor)
                                    : getColor('cream')
                            }`,
                        }}
                    >
                        {globalTheme === 'makers-reserve' && (
                            <div
                                className={styles.makersReserveBorder}
                                role="presentation"
                            >
                                <div
                                    className={styles.makersReserveBorderInner}
                                >
                                    <DecorativeBorder
                                        borderWidth={isMedium ? 2 : 1}
                                    />
                                </div>
                            </div>
                        )}
                        <div className={styles.endCardContent}>
                            {!endCard.headline && (
                                <div className={styles.iconGroup}>
                                    <div className={styles.iconWrapper}>
                                        <img
                                            alt=""
                                            src={`/images/icons/morning-star.svg`}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className={styles.contentGroup}>
                                <div className={styles.headlineWrapper}>
                                    <Text
                                        as="h3"
                                        baseTheme={
                                            globalTheme === 'makers-reserve'
                                                ? 'parkinsonLarge'
                                                : 'headingLarge'
                                        }
                                        themes={{
                                            large:
                                                globalTheme === 'makers-reserve'
                                                    ? 'parkinsonXLarge'
                                                    : 'headingXLarge',
                                        }}
                                        className={styles.headline}
                                    >
                                        {endCard.headline}
                                    </Text>
                                </div>
                                <div className={styles.endCardBody}>
                                    <RichText
                                        richText={endCard.text}
                                        overrides={richTextOverrides}
                                        headline={endCard.headline}
                                    />
                                    {endCard?.cta && (
                                        <ButtonCTA
                                            className={styles.ctaButton}
                                            {...endCard.cta}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </li>
                )}
            </ul>
        </div>
    );
};

BlockSimpleProductList.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    products: PropTypes.array,
    headline: PropTypes.string,
    eyebrow: PropTypes.string,
    endCard: PropTypes.shape({
        headline: PropTypes.string,
        text: PropTypes.object,
        cta: PropTypes.object,
        backgroundColor: PropTypes.string,
        textColor: PropTypes.string,
    }),
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
};

export default BlockSimpleProductList;
