import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { blockRootProps } from 'utils';

import Location from './Location';

import styles from './BlockLocation.module.scss';

const BlockLocation = ({
    __typename,
    anchorId,
    className,
    location,
    theme,
}) => {
    theme ??= 'Transparent Background';

    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.blockLocation, className, {
                [styles.bgWhite]: theme === 'White Background',
            })}
        >
            <div className={styles.blockLocationInner}>
                <Location
                    hours={location.hoursCollection.items}
                    {...location}
                />
            </div>
        </section>
    );
};

BlockLocation.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    className: PropTypes.string,
    location: PropTypes.object,
    theme: PropTypes.oneOf(['White Background', 'Transparent Background']),
};

export default BlockLocation;
