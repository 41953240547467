import React from 'react';

import { graphRequest } from 'services/contentful';

import { assetFragment, iconFragment } from 'lib/graphql/fragments';

import BlockDeckSlider from './BlockDeckSlider';

const query = /* GraphQL */ `
    ${assetFragment}
    ${iconFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockDeckSlider(preview: $preview, id: $id) {
            __typename
            anchorId
            title
            coverTitle
            coverTitleColor
            coverHeading {
                json
            }
            coverCopy {
                json
            }
            coverTextColor
            coverCtaText
            coverForegroundColor
            coverTabButtonColor
            coverBackgroundImagePortrait {
                ...Asset
            }
            coverBackgroundImage {
                ...Asset
            }
            slideTitleColor
            slidesCollection(limit: 10) {
                items {
                    ... on BlockDeckSliderSlide {
                        icon2 {
                            ... on Icon {
                                ...Icon
                            }
                        }
                        eyebrow
                        slideTitle
                        heading {
                            json
                        }
                        body {
                            json
                        }
                        textColor
                        backgroundColor
                    }
                }
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockDeckSlider },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockDeckSlider;
}

const BlockDeckSliderServer = async ({ id }) => {
    const block = await fetchBlock(id);

    return <BlockDeckSlider {...block} slides={block.slidesCollection.items} />;
};

export default BlockDeckSliderServer;
