import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Divot.module.scss';

const DivotSvg = () => {
    return (
        <svg
            x="0px"
            y="0px"
            width="51.8px"
            height="25.3px"
            viewBox="0 0 51.8 25.3"
            enableBackground="new 0 0 51.8 25.3"
            xmlSpace="preserve"
        >
            <polygon
                points="40.5,0 25.9,14.6 11.3,0 0,0 0,25.3 51.8,25.3 51.8,0"
                fill="currentColor"
            />
        </svg>
    );
};

const Divot = ({ theme = 'blue', className }) => {
    return (
        <div
            className={cx(styles.divot, className, {
                [styles.blue]: theme === 'blue',
                [styles.blueMedium]: theme === 'blueMedium',
                [styles.blueDark]: theme === 'blueDark',
                [styles.cream]: theme === 'cream',
                [styles.creamDark]: theme === 'creamDark',
            })}
            aria-hidden="true"
            role="presentation"
        >
            <DivotSvg />
        </div>
    );
};

Divot.propTypes = {
    className: PropTypes.string,
    theme: PropTypes.string,
};

export default Divot;
