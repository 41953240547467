import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { blockRootProps, getColor } from 'utils';

import InlineListRow from './InlineListRow';

import styles from './BlockInlineList.module.scss';

const BlockInlineList = ({
    __typename,
    anchorId,
    grid,
    theme,
    backgroundColor,
    flushTop,
    flushBottom,
    lastBlock,
}) => {
    theme ??= 'default';
    backgroundColor ??= 'transparent';

    return (
        <div
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.root, {
                [styles.miniGrid]: theme === 'mini',
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.lastBlock]: lastBlock && !flushBottom,
            })}
            style={{
                '--background-color': getColor(backgroundColor),
            }}
        >
            <div className={styles.innerWrapper}>
                <div className={styles.gridContent}>
                    {grid &&
                        grid.map((gridRow, id) => {
                            return (
                                <InlineListRow
                                    gridRow={gridRow.gridRowCollection.items}
                                    theme={theme}
                                    key={id}
                                />
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

BlockInlineList.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    grid: PropTypes.array,
    theme: PropTypes.string,
    backgroundColor: PropTypes.string,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    lastBlock: PropTypes.bool,
};

export default BlockInlineList;
