'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Img from 'components/ui/Img';
import RichText, { BLOCKS } from 'components/ui/RichText';
import Text from 'components/ui/Text';
import TextEyebrow from 'components/ui/TextEyebrow';

import { useTheme } from 'hooks/useTheme';

import styles from './FlavorPairings.module.scss';

const richTextCopy = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    baseTheme="bodyXSmall"
                    themes={{
                        large: 'bodySmall',
                    }}
                    className={styles.copy}
                >
                    {children}
                </Text>
            );
        },
    },
};

const FlavorPairings = ({
    eyebrow,
    heading,
    copy,
    pairings,
    portraitImage,
    landscapeImage,
    controls,
    textColor,
}) => {
    eyebrow ??= 'pairings';
    textColor ??= 'cream';

    const [globalTheme] = useTheme();

    const eyebrowColorMap = {
        'makers-reserve': {
            eyebrowColor: 'makersReserveGoldAlt',
        },
        default: {
            eyebrowColor: textColor,
            headlineColor: textColor,
        },
    };

    return (
        <div
            style={{ '--textColor': `var(--colors-${textColor})` }}
            className={cx(styles.root, styles[`theme--${globalTheme}`])}
        >
            {landscapeImage?.url && portraitImage?.url && (
                <div className={styles.image}>
                    <Img
                        src={portraitImage.url}
                        fallbackImageWidth={768}
                        alt={portraitImage.description || ''}
                        customSources={[
                            {
                                breakpoint: 1920,
                                src: landscapeImage.url,
                                imageWidth: parseInt(1920 * 1.5),
                            },
                            {
                                breakpoint: 1440,
                                src: landscapeImage.url,
                                imageWidth: parseInt(1440 * 1.5),
                            },
                            {
                                breakpoint: 1024,
                                src: landscapeImage.url,
                                imageWidth: parseInt(1024 * 1.5, 10),
                            },
                            {
                                breakpoint: 768,
                                src: portraitImage.url,
                                imageWidth: parseInt(768 * 1.5),
                            },
                            {
                                src: portraitImage.url,
                                imageWidth: parseInt(767 * 1.5),
                            },
                        ]}
                    />
                </div>
            )}
            <div className={styles.inner}>
                <TextEyebrow
                    eyebrow={eyebrow}
                    headline={heading}
                    theme="ultrabold"
                    globalTheme={globalTheme}
                    eyebrowColor={textColor}
                    headlineColor={textColor}
                    {...eyebrowColorMap[globalTheme]}
                />
                <RichText richText={copy} overrides={richTextCopy} />
                <ul className={styles.unorderedList}>
                    {pairings.map((value, i) => (
                        <li key={i}>
                            <Text
                                as="p"
                                baseTheme="bodyXSmall"
                                themes={{
                                    large: 'bodySmall',
                                }}
                            >
                                {value}
                            </Text>
                        </li>
                    ))}
                </ul>
                {controls}
            </div>
        </div>
    );
};

FlavorPairings.propTypes = {
    pairings: PropTypes.array,
    eyebrow: PropTypes.string.isRequired,
    heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
    copy: PropTypes.object.isRequired,
    portraitImage: PropTypes.object.isRequired,
    landscapeImage: PropTypes.object.isRequired,
    controls: PropTypes.node,
    textColor: PropTypes.string,
};

export default FlavorPairings;
