import React from 'react';

import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import styles from './FilterCheckbox.module.scss';

const FilterBodyCheckbox = ({ label, checked, onChange }) => {
    return (
        <div className={styles.root}>
            <Text baseTheme="bodySmall" className={styles.checkbox}>
                <label className={styles.label}>
                    <div className={styles.checkmarkWrapper}>
                        <input
                            type="checkbox"
                            defaultChecked={checked}
                            onChange={onChange}
                            className={styles.input}
                        />
                        <span className={styles.checkmark} />
                    </div>
                    <span>{label}</span>
                </label>
            </Text>
        </div>
    );
};

FilterBodyCheckbox.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};

export default FilterBodyCheckbox;
