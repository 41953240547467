import React from 'react';

import PropTypes from 'prop-types';

import RadioGroupForm from './RadioGroupForm';

import styles from './RadioGroup.module.scss';

export const RadioGroup = ({
    fieldName,
    optionList,
    setValue,
    register,
    watch,
}) => {
    return (
        <div className={styles.root}>
            <RadioGroupForm
                setValue={setValue}
                options={optionList}
                fieldName={fieldName}
                register={register}
                watch={watch}
            />
        </div>
    );
};

RadioGroup.propTypes = {
    fieldName: PropTypes.string.isRequired,
    optionList: PropTypes.array.isRequired,
    setValue: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
};

export default RadioGroup;
