import React, { useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import styles from './Filter.module.scss';

const FilterBodySelect = ({
    keyValue,
    label,
    labelPrefix,
    filters,
    filtered,
    value,
    className,
    ...props
}) => {
    labelPrefix ??= 'All';

    const current = Object.values(filters[keyValue].options).find(
        option => option.slug === filtered[keyValue]
    );
    const [charCount, setCharCount] = useState();
    const select = useRef();
    useEffect(() => {
        if (select.current.options) {
            setCharCount(
                Math.max(
                    ...[...select.current.options].map(
                        option => option.innerText.length
                    )
                )
            );
        }
    }, [select, filters]);

    return (
        <div
            className={cx(styles.filterSelectWrapper, className, {
                [styles.hasSelection]: current && current.label,
            })}
        >
            {label && (
                <label htmlFor={keyValue} className={styles.filterLabel}>
                    <Text
                        as="span"
                        baseTheme="labelSmall"
                        themes={{ xxLarge: 'labelLarge' }}
                    >
                        {label}:
                    </Text>
                </label>
            )}
            <Text
                baseTheme="bodyMedium"
                as="div"
                style={{ minWidth: `${charCount * 0.6}em` }}
                className={styles.filterSelect}
            >
                <div className={styles.filterSelectDisplay} role="presentation">
                    {current && current.label
                        ? current.label
                        : labelPrefix
                        ? `${labelPrefix} ${label}`
                        : `${label}`}
                </div>
                <div className={styles.filterSelectIcon} role="presentation">
                    <SvgIcon type="chevronDown" />
                </div>
                <select
                    name={keyValue}
                    value={value}
                    ref={select}
                    className={styles.filterSelectElement}
                    {...props}
                >
                    <option value="" key={`${keyValue}-no-value`}>
                        All {label}
                    </option>
                    {Object.values({ ...filters[keyValue].options }).map(
                        option => {
                            if (!option.label) {
                                return null;
                            }
                            return (
                                option?.id && (
                                    <option
                                        value={`${option.slug}`}
                                        key={`${keyValue}-${option.slug}`}
                                    >
                                        {option.label}
                                    </option>
                                )
                            );
                        }
                    )}
                </select>
            </Text>
        </div>
    );
};

FilterBodySelect.propTypes = {
    keyValue: PropTypes.string,
    label: PropTypes.string,
    labelPrefix: PropTypes.string,
    filters: PropTypes.object,
    filtered: PropTypes.object,
    value: PropTypes.string,
    className: PropTypes.string,
};

export default FilterBodySelect;
