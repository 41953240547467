import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    iconFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockTwoColumnImage from './BlockTwoColumnImage';

const query = /* GraphQL */ `
    ${ctaFragment}
    ${linkFragment}
    ${assetFragment}
    ${iconFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockTwoColumnImage(preview: $preview, id: $id) {
            __typename
            anchorId
            eyebrow
            headline
            richHeadline {
                json
            }
            bodyCopy
            richText {
                json
            }
            theme
            leftImage
            image {
                ...Asset
            }
            cta {
                ... on Cta {
                    ...CTA
                }
            }
            ctasCollection {
                items {
                    ... on Cta {
                        ...CTA
                    }
                }
            }
            icon {
                ... on Icon {
                    ...Icon
                }
            }
            flushTop
            flushBottom
            backgroundColor
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockTwoColumnImage },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockTwoColumnImage;
}

const BlockTwoColumnImageServer = async ({ id }) => {
    const block = await fetchBlock(id);
    return <BlockTwoColumnImage {...block} ctas={block.ctasCollection.items} />;
};

export default BlockTwoColumnImageServer;
