'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Divot from 'components/globals/Divot';

import ButtonCTA from 'components/ui/ButtonCTA';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import Text from 'components/ui/Text';
import TextEyebrow from 'components/ui/TextEyebrow';

import styles from '../BlockCallout.module.scss';

const themeSettingsMap = {
    'Blue Background': {
        background: 'blue',
        headline: 'cream',
        eyebrow: 'cream',
        body: 'cream',
        cta: 'outlinedCream',
    },
    'Cream Background': {
        background: 'cream',
        headline: 'blue',
        eyebrow: 'blue',
        body: 'blue',
        cta: 'outlinedCream',
    },
    default: {
        background: 'blue',
        headline: 'cream',
        eyebrow: 'cream',
        body: 'cream',
        cta: 'outlinedCream',
    },
    'makers-reserve': {
        background: 'cream',
        headline: 'makersReserveBlack',
        eyebrow: 'makersReserveGoldAlt',
        body: 'makersReserveBlack',
        cta: 'outlinedCream',
    },
};

const Banner = ({
    eyebrow,
    richHeadline,
    copy,
    colorTheme: colorThemeOriginal = 'Blue Background',
    className,
    buttonComponentSlot,
    cta,
    ctaSecondary,
    ctaOnly,
    globalTheme,
    firstBlock,
    hasNotch,
    roundedCorners,
}) => {
    roundedCorners ??= true;

    const colorTheme =
        globalTheme === 'makers-reserve' ||
        globalTheme === 'makers-reserve-sharpness'
            ? 'makers-reserve'
            : colorThemeOriginal;

    return (
        <article
            className={cx(
                styles.calloutWrapperFlex,
                styles.calloutWrapper,
                styles[globalTheme],
                className,
                styles.banner,
                {
                    [styles.hasNotch]: hasNotch,
                    [styles.withCopy]: copy,
                    [styles.roundedCorners]: roundedCorners,
                }
            )}
        >
            <div className={styles.calloutWrapper}>
                {hasNotch && (
                    <Divot
                        theme={themeSettingsMap[colorTheme]?.background}
                        className={styles.divot}
                    />
                )}
                <div
                    className={styles.callout}
                    style={{
                        '--eyebrow': `var(--colors-${themeSettingsMap[colorTheme]?.eyebrow})`,
                        '--headline': `var(--colors-${themeSettingsMap[colorTheme]?.headline})`,
                        '--body': `var(--colors-${themeSettingsMap[colorTheme]?.body})`,
                        backgroundColor: `var(--colors-${themeSettingsMap[colorTheme]?.background})`,
                    }}
                >
                    <div className={styles.inner}>
                        {richHeadline && !ctaOnly && (
                            <div className={styles.section}>
                                <TextEyebrow
                                    className={styles.eyebrow}
                                    eyebrow={eyebrow ? eyebrow : ''}
                                    richHeadline={richHeadline}
                                    theme={
                                        globalTheme === 'makers-reserve' ||
                                        globalTheme ===
                                            'makers-reserve-sharpness'
                                            ? 'hero'
                                            : 'normal'
                                    }
                                    disableAnimation={firstBlock}
                                    firstBlock={firstBlock}
                                    cta={
                                        cta && !buttonComponentSlot
                                            ? {
                                                  ...cta,
                                                  theme: themeSettingsMap[
                                                      colorTheme
                                                  ]?.cta,
                                              }
                                            : null
                                    }
                                    ctaButton={ctaSecondary}
                                />
                                {buttonComponentSlot && (
                                    <div className={styles.buttonComponentSlot}>
                                        {buttonComponentSlot}
                                    </div>
                                )}
                            </div>
                        )}
                        {copy && !ctaOnly && (
                            <div
                                className={styles.section}
                                style={{
                                    color: `var(--body)`,
                                }}
                            >
                                <Text.Theme
                                    config={{
                                        'makers-reserve': {
                                            baseTheme: 'bodySmall',
                                            themes: {
                                                large: 'bodyMedium',
                                                xLarge: 'bodyLarge',
                                            },
                                        },
                                        default: {
                                            baseTheme: 'bodySmall',
                                            themes: { large: 'bodyMedium' },
                                        },
                                    }}
                                    className={styles.copy}
                                >
                                    {copy}
                                </Text.Theme>
                            </div>
                        )}
                        <Stagger>
                            {ctaOnly &&
                                (cta?.link || cta?.assetDownloadLink) &&
                                !ctaSecondary && (
                                    <StaggerChild order={0}>
                                        <ButtonCTA
                                            className={styles.cta}
                                            {...cta}
                                        />
                                    </StaggerChild>
                                )}
                            {ctaOnly &&
                                (ctaSecondary?.link ||
                                    ctaSecondary?.assetDownloadLink) && (
                                    <StaggerChild order={0}>
                                        <ButtonCTA
                                            className={styles.cta}
                                            {...ctaSecondary}
                                        />
                                    </StaggerChild>
                                )}
                        </Stagger>
                    </div>
                </div>
            </div>
        </article>
    );
};

Banner.propTypes = {
    backgroundColor: PropTypes.string,
    buttonComponentSlot: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.string,
    colorTheme: PropTypes.string,
    copy: PropTypes.string,
    cta: PropTypes.object,
    ctaSecondary: PropTypes.object,
    ctaOnly: PropTypes.bool,
    eyebrow: PropTypes.string,
    firstBlock: PropTypes.bool,
    globalTheme: PropTypes.string,
    hasNotch: PropTypes.bool,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    imageLarge: PropTypes.shape({
        file: PropTypes.shape({
            details: PropTypes.shape({
                image: PropTypes.shape({
                    width: PropTypes.number,
                    height: PropTypes.number,
                }),
            }),
            url: PropTypes.string,
        }),
    }),
    richHeadline: PropTypes.object,
    roundedCorners: PropTypes.bool,
    theme: PropTypes.string,
};

export default Banner;
