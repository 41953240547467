import React from 'react';

import cx from 'clsx';
import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import RichText, { BLOCKS } from 'components/ui/RichText';
import Text from 'components/ui/Text';

import { getColor } from 'utils';

import styles from './InlineIntro.module.scss';

const InlineIntro = ({
    eyebrow,
    headline,
    copy,
    cta,
    textColor,
    isPlaceholder,
}) => {
    const richTextOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text
                        as="p"
                        baseTheme="bodyMedium"
                        themes={{ xxLarge: 'bodyLarge' }}
                        className={styles.copy}
                    >
                        {children}
                    </Text>
                );
            },
        },
    };

    return (
        <span
            className={cx(styles.root, {
                [styles.isPlaceholder]: isPlaceholder,
            })}
            style={{ '--text-color': `${getColor(textColor)}` }}
            aria-hidden={isPlaceholder}
        >
            {eyebrow && (
                <Text
                    as="p"
                    className={styles.eyebrow}
                    baseTheme="labelSmall"
                    themes={{
                        medium: 'labelLarge',
                    }}
                >
                    {eyebrow}
                </Text>
            )}
            {headline && (
                <Text
                    as="h2"
                    className={styles.headline}
                    baseTheme="displayXSmall"
                    themes={{
                        medium: 'displaySmall',
                        xLarge: 'displayMedium',
                        xxLarge: 'displayLarge',
                    }}
                >
                    {headline}
                </Text>
            )}
            {copy && <RichText richText={copy} overrides={richTextOverrides} />}
            {cta?.text && cta?.link && (
                <ButtonCTA
                    className={styles.cta}
                    style="filled-cream-ocean"
                    text={cta.text}
                    {...cta}
                />
            )}
        </span>
    );
};

InlineIntro.propTypes = {
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    copy: PropTypes.object,
    cta: PropTypes.object,
    textColor: PropTypes.string,
    isPlaceholder: PropTypes.bool,
};

export default InlineIntro;
