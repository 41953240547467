import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import styles from './RichHeadline.module.scss';

const convertLineBreaks = (str = '') =>
    str.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]);

const RichHeadline = ({ richText = {} }) => {
    const themeMap = {
        paragraph: {
            className: 'paragraph',
            tag: 'h3',
            baseTheme: 'headingMedium',
            themes: {
                large: 'headingLarge',
            },
        },
        'heading-3': {
            className: 'heading3',
            tag: 'h3',
            baseTheme: 'displayXSmall',
            themes: {
                large: 'displayMedium',
            },
        },
    };

    return richText.json.content.map((item, i) => {
        if (item.content[0].value) {
            // Allow line breaks for typography control while retaining semantics
            const content = convertLineBreaks(item.content[0].value);

            return (
                <Text
                    key={i}
                    as={themeMap[item.nodeType].tag}
                    className={cx(
                        styles.root,
                        styles[themeMap[item.nodeType].className]
                    )}
                    baseTheme={themeMap[item.nodeType].baseTheme}
                    themes={{
                        medium: themeMap[item.nodeType]?.themes?.medium,
                        large: themeMap[item.nodeType]?.themes?.large,
                    }}
                >
                    {content}
                </Text>
            );
        }
        // Remove tags if empty, i.e <p></p> <h3></h3>
        return null;
    });
};

RichHeadline.propTypes = {
    richText: PropTypes.object,
};

export default RichHeadline;
