import React from 'react';

import cx from 'clsx';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import AnimatedIcon from 'components/ui/AnimatedIcon';
import ButtonCircle from 'components/ui/ButtonCircle';
import EntryLink from 'components/ui/Link/EntryLink';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';

import styles from './CardAnimatedIcon.module.scss';

const copyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => (
            <Text
                className={styles.copyText}
                baseTheme="bodyMedium"
                themes={{ xLarge: 'bodyLarge' }}
            >
                {children}
            </Text>
        ),
    },
};

const CardAnimatedIcon = ({ headline, copy, icon, link }) => {
    if (!link) {
        console.warn('CardAnimatedIcon: Missing link prop');
        return null;
    }

    return (
        <EntryLink entry={link} className={styles.root}>
            <article className={styles.inner}>
                <div className={cx(styles.icon, styles[icon])}>
                    <AnimatedIcon icon={icon} />
                </div>
                {headline && (
                    <Text
                        className={styles.headline}
                        baseTheme="displayXSmall"
                        themes={{ large: 'displaySmall' }}
                    >
                        {headline}
                    </Text>
                )}
                {copy && (
                    <div className={styles.copy}>
                        <RichText richText={copy} overrides={copyOverrides} />
                    </div>
                )}
                <ButtonCircle theme="small-fill-blue" iconType="chevronRight" />
            </article>
        </EntryLink>
    );
};

CardAnimatedIcon.propTypes = {
    headline: PropTypes.string,
    copy: PropTypes.object,
    icon: PropTypes.string,
    link: PropTypes.object.isRequired,
};

export default CardAnimatedIcon;
