import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';

import styles from './BlockTimeline.module.scss';

const TimelineItem = ({
    headline,
    copy,
    year,
    image,
    imageLarge,
    className,
}) => {
    return (
        <article className={cx(styles.timelineItem, className)}>
            <div className={styles.column}>
                {image?.url && imageLarge?.url && (
                    <Img
                        className={styles.image}
                        src={image.url}
                        fallbackImageWidth={500}
                        alt={image.description || ''}
                        customSources={[
                            {
                                breakpoint: 768,
                                src: imageLarge.url,
                                imageWidth: imageLarge.width,
                            },
                            {
                                src: image.url,
                                imageWidth: image.width,
                            },
                        ]}
                    />
                )}
                {image?.url && !imageLarge && (
                    <Img
                        className={styles.image}
                        src={image.url}
                        fallbackImageWidth={500}
                        alt={image.description || ''}
                        customSources={[
                            {
                                src: image.url,
                                imageWidth: image.width,
                            },
                        ]}
                    />
                )}
            </div>
            <div className={styles.column}>
                <div className={styles.text}>
                    <Text
                        as="h3"
                        baseTheme="headingSmall"
                        themes={{ large: 'headingMedium' }}
                        className={styles.headline}
                    >
                        {headline}
                        <div className={styles.year}>
                            <Text
                                as="h5"
                                baseTheme="labelLarge"
                                className={styles.yearInner}
                            >
                                {year}
                            </Text>
                        </div>
                    </Text>
                    <div className={styles.copy}>
                        <RichText richText={copy} />
                    </div>
                </div>
            </div>
        </article>
    );
};

TimelineItem.propTypes = {
    headline: PropTypes.string,
    copy: PropTypes.object,
    year: PropTypes.string,
    image: PropTypes.shape({
        description: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
    imageLarge: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
    className: PropTypes.string,
};

export default TimelineItem;
