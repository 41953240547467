import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import { getDayNums, getMonthNums, getYears } from 'utils';

import styles from './Fields.module.scss';

const DEFAULT = {
    month: 'MM',
    day: 'DD',
    year: 'YYYY',
};

const months = getMonthNums();
const days = getDayNums();
const years = getYears();

const FieldDate = ({ field, required, errors, register, watch }) => {
    const month = `${field}_month`;
    const day = `${field}_day`;
    const year = `${field}_year`;

    const selectValues = watch([month, day, year]);

    return (
        <>
            <Text baseTheme="headingXSmall" className={styles.field}>
                <select
                    {...register(month, {
                        required: required,
                        validate: required
                            ? value => value !== DEFAULT.month
                            : null,
                    })}
                    id={month}
                    className={cx(styles.select, {
                        [styles.hasError]: errors?.[month],
                        [styles.isDefault]: selectValues[0] === DEFAULT.month,
                    })}
                    defaultValue={DEFAULT.month}
                    title="Select the month"
                    aria-label="Select the month"
                >
                    <option value={DEFAULT.month} disabled hidden>
                        MM
                    </option>
                    {months?.map((m, i) => (
                        <option key={i} value={m}>
                            {m}
                        </option>
                    ))}
                </select>
                <select
                    {...register(day, {
                        required: required,
                        validate: required
                            ? value => value !== DEFAULT.day
                            : null,
                    })}
                    id={day}
                    defaultValue={DEFAULT.day}
                    className={cx(styles.select, {
                        [styles.hasError]: errors?.[day],
                        [styles.isDefault]: selectValues[1] === DEFAULT.day,
                    })}
                >
                    <option value={DEFAULT.day} disabled hidden>
                        DD
                    </option>
                    {days?.map((d, i) => (
                        <option key={i} value={d}>
                            {d}
                        </option>
                    ))}
                </select>
                <select
                    {...register(year, {
                        required: required,
                        validate: required
                            ? value => value !== DEFAULT.year
                            : null,
                    })}
                    id={year}
                    defaultValue={DEFAULT.year}
                    className={cx(styles.select, {
                        [styles.hasError]: errors?.[year],
                        [styles.isDefault]: selectValues[2] === DEFAULT.year,
                    })}
                >
                    <option value={DEFAULT.year} disabled hidden>
                        YYYY
                    </option>
                    {years?.map((y, i) => (
                        <option key={i} value={y}>
                            {y}
                        </option>
                    ))}
                </select>
            </Text>
            <div className={styles.error}>
                {(errors?.[month] || errors?.[day] || errors?.[year]) && (
                    <Text baseTheme="bodyXSmall">
                        {errors?.[month]?.type === 'required'
                            ? 'This field is required'
                            : 'Please enter a valid date'}
                    </Text>
                )}
            </div>
        </>
    );
};

FieldDate.propTypes = {
    errors: PropTypes.any,
    field: PropTypes.any,
    placeholder: PropTypes.array,
    register: PropTypes.func,
    required: PropTypes.any,
    watch: PropTypes.func,
};

export default FieldDate;
