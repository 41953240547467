import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Img from 'components/ui/Img';
import RichText, { BLOCKS } from 'components/ui/RichText';
import Text from 'components/ui/Text';
import TextEyebrow from 'components/ui/TextEyebrow';

import { useTheme } from 'hooks/useTheme';

import styles from './CoverSlide.module.scss';

const richTextCopy = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    baseTheme="bodyXSmall"
                    themes={{
                        large: 'bodySmall',
                    }}
                    className={styles.copy}
                    fixWidows
                >
                    {children}
                </Text>
            );
        },
    },
};

const CoverSlide = ({
    eyebrow,
    headline,
    copy,
    portraitImage,
    landscapeImage,
    onClickStart,
    textColor,
}) => {
    eyebrow ??= 'pairings';

    const [globalTheme] = useTheme();

    const eyebrowColorMap = {
        default: {
            eyebrow: textColor,
            headline: textColor,
        },
        'makers-reserve': {
            eyebrow: 'makersReserveGoldAlt',
            headline: 'makersReserveOffBlack',
        },
    };

    return (
        <div
            style={{ '--textColor': `var(--colors-${textColor})` }}
            className={cx(styles.root, styles[`theme--${globalTheme}`])}
        >
            {landscapeImage?.url && portraitImage?.url && (
                <div className={styles.image}>
                    <Img
                        src={portraitImage.url}
                        fallbackImageWidth={768}
                        alt={portraitImage.description || ''}
                        customSources={[
                            {
                                breakpoint: 1920,
                                src: landscapeImage.url,
                                imageWidth: parseInt(1920 * 1.5),
                            },
                            {
                                breakpoint: 1440,
                                src: landscapeImage.url,
                                imageWidth: parseInt(1440 * 1.5),
                            },
                            {
                                breakpoint: 1024,
                                src: landscapeImage.url,
                                imageWidth: parseInt(1024 * 1.5, 10),
                            },
                            {
                                breakpoint: 768,
                                src: portraitImage.url,
                                imageWidth: parseInt(768 * 1.5),
                            },
                            {
                                src: portraitImage.url,
                                imageWidth: parseInt(767 * 1.5),
                            },
                        ]}
                    />
                </div>
            )}
            <div className={styles.inner}>
                <TextEyebrow
                    eyebrow={eyebrow}
                    headline={headline}
                    theme={globalTheme === 'default' ? 'ultrabold' : 'normal'}
                    eyebrowColor={eyebrowColorMap[globalTheme]?.eyebrow}
                    headlineColor={eyebrowColorMap[globalTheme]?.headline}
                />
                <RichText richText={copy} overrides={richTextCopy} />
                <ButtonCTA
                    className={styles.cta}
                    text="See Pairings"
                    style={
                        globalTheme === 'makers-reserve'
                            ? 'makersFilled-gold-cream'
                            : 'filled-cream-white'
                    }
                    onClick={onClickStart}
                />
            </div>
        </div>
    );
};

CoverSlide.propTypes = {
    eyebrow: PropTypes.string,
    headline: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
    copy: PropTypes.object.isRequired,
    portraitImage: PropTypes.object.isRequired,
    landscapeImage: PropTypes.object.isRequired,
    onClickStart: PropTypes.func.isRequired,
    textColor: PropTypes.string.isRequired,
};

export default CoverSlide;
