import React from 'react';

import { graphRequest } from 'services/contentful';

import { assetFragment } from 'lib/graphql/fragments';

import BlockTwoColumnRichText from './BlockTwoColumnRichText';

const query = /* GraphQL */ `
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockTwoColumnRichText(preview: $preview, id: $id) {
            __typename
            anchorId
            richText {
                json
            }
            image {
                ...Asset
            }
            imageLarge {
                ...Asset
            }
            imageOnLeft
            flushTop
            bgTheme
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockTwoColumnRichText },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockTwoColumnRichText;
}

const BlockTwoColumnRichTextServer = async ({ id }) => {
    const block = await fetchBlock(id);
    return <BlockTwoColumnRichText {...block} />;
};

export default BlockTwoColumnRichTextServer;
