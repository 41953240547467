'use client';

import React, { useContext } from 'react';

import cx from 'clsx';

import carouselContext from 'components/ui/CarouselProvider/CarouselContext';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import Text from 'components/ui/Text';

import styles from './OverflowCarouselText.module.scss';

const OverflowCarouselText = () => {
    const context = useContext(carouselContext);

    return context.slides?.map((slide, i) => {
        if (!slide.headline) return null;
        return (
            <Stagger
                key={i}
                className={cx(styles.root, {
                    [styles.isActive]: context.activeIndex === i,
                    [styles.isPrev]: context.prevIndex === i,
                })}
            >
                <div className={styles.textLockup}>
                    {slide.eyebrow && (
                        <StaggerChild
                            order={0}
                            className={styles.eyebrowWrapper}
                        >
                            <Text
                                className={styles.eyebrow}
                                baseTheme={'labelSmall'}
                                themes={{
                                    large: 'labelLarge',
                                }}
                                as="p"
                            >
                                {slide.eyebrow}
                            </Text>
                        </StaggerChild>
                    )}
                    <StaggerChild order={1} className={styles.headline}>
                        <div className={styles.headlineInner}>
                            {slide.headline?.json?.content.map((line, i) => {
                                if (line.content[0].value === '') {
                                    return null;
                                }
                                return (
                                    <Text
                                        key={i}
                                        className={styles.headlineLine}
                                        as="h2"
                                        baseTheme="displaySmall"
                                        themes={{
                                            large: 'displayMedium',
                                            xLarge: 'displayLarge',
                                            xxLarge: 'displayXLarge',
                                        }}
                                    >
                                        {line.content[0].value}
                                    </Text>
                                );
                            })}
                        </div>
                    </StaggerChild>
                </div>
            </Stagger>
        );
    });
};

export default OverflowCarouselText;
