import React from 'react';

import { graphRequest } from 'services/contentful';

import { assetFragment } from 'lib/graphql/fragments';

import BlockAudio from './BlockAudio';

const query = /* GraphQL */ `
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockAudio(preview: $preview, id: $id) {
            audioSourcesCollection(limit: 5) {
                items {
                    ...Asset
                }
            }
            anchorId
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockAudio },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockAudio;
}

const BlockAudioServer = async ({ id }) => {
    const block = await fetchBlock(id);

    return <BlockAudio sources={block.audioSourcesCollection?.items} />;
};

export default BlockAudioServer;
