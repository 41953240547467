import React from 'react';

import { graphRequest } from 'services/contentful';

import { fragment as cardTestimonialFragment } from 'components/cards/CardTestimonial/fragment';

import { assetFragment } from 'lib/graphql/fragments';

import BlockTestimonial from './BlockTestimonial';

const query = /* GraphQL */ `
    ${assetFragment}
    ${cardTestimonialFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockTestimonial(preview: $preview, id: $id) {
            __typename
            anchorId
            testimonial {
                ...CardTestimonialFragment
            }
            backgroundColor
            theme
            flushTop
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockTestimonial },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockTestimonial;
}

const BlockTestimonialServer = async ({ id }) => {
    const block = await fetchBlock(id);
    return <BlockTestimonial {...block} />;
};

export default BlockTestimonialServer;
