export const fragment = /* GraphQL */ `
    fragment VideoPlayerYTFragment on VideoPlayerYoutube {
        headline
        richTextHeadline {
            json
        }
        richTextHeadlineSize
        richTextHeadlineIcon
        eyebrow
        youtubeVideoId
        image {
            ...Asset
        }
        imageLarge {
            ...Asset
        }
        videoTextureMp4 {
            ...Asset
        }
        videoTextureWebm {
            ...Asset
        }
        videoTexturePoster {
            ...Asset
        }
    }
`;
