'use client';

import React, { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import RichText from 'components/ui/RichText';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import styles from './AccordionItem.module.scss';

const richTextOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    baseTheme="bodySmall"
                    className={styles.accordionItemParagraph}
                >
                    {children}
                </Text>
            );
        },
        [BLOCKS.HEADING_3]: (node, children) => {
            return (
                <Text
                    as="h3"
                    baseTheme="headingLarge"
                    className={styles.accordionItemHeading}
                >
                    {children}
                </Text>
            );
        },
    },
};

const AccordionItem = ({ headline, bodyCopy }) => {
    const [expanded, setExpanded] = useState(false);
    const [height, setHeight] = useState(0);
    const heightRef = useRef(null);
    const { width } = useWindowSize();

    const getHeight = () => {
        setHeight(heightRef.current.offsetHeight);
    };

    const handleClick = () => {
        getHeight();
        setExpanded(!expanded);
    };

    useEffect(() => {
        getHeight();
    }, [width]);

    return (
        <section className={styles.accordionItem}>
            <div className={styles.headlineBlock} onClick={handleClick}>
                <div className={styles.innerBlock}>
                    {headline && (
                        <Text
                            baseTheme="labelLarge"
                            className={styles.headline}
                        >
                            {headline}
                        </Text>
                    )}

                    <div className={styles.iconWrapper}>
                        <SvgIcon
                            type="circleArrow"
                            className={cx(styles.icon, {
                                [styles.iconFlipped]: expanded,
                            })}
                        />
                    </div>
                </div>
            </div>
            <div
                className={styles.copyBlock}
                style={{
                    height: expanded ? height : 0,
                }}
            >
                <div ref={heightRef} className={styles.copyInnerHeight}>
                    {bodyCopy && (
                        <div className={styles.copyInner}>
                            <RichText
                                overrides={richTextOverrides}
                                richText={bodyCopy}
                            />
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

AccordionItem.propTypes = {
    headline: PropTypes.string,
    bodyCopy: PropTypes.object,
};

export default AccordionItem;
