import React, { useContext, useEffect, useRef } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import useWait from 'hooks/useWait';
import { scrollToOffset } from 'utils';

import ContactContext from './Context';
import EndState from './EndState';
import { Detail, Grid } from './FormComponents';
import { animationStates } from './constants';

import styles from './BlockContactForm.module.scss';

const { GRID_ENTER, END, DETAIL_ENTER } = animationStates;

const ContactForm = ({ socialLinks, lastBlock }) => {
    const rootRef = useRef(null);

    const wait = useWait();

    const { animationState, timingBase } = useContext(ContactContext);

    useEffect(() => {
        if (
            animationState === DETAIL_ENTER ||
            animationState === GRID_ENTER ||
            animationState === END
        ) {
            wait(10).then(() => {
                scrollToOffset(rootRef?.current, -100, 'smooth');
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animationState]);

    return (
        <div
            ref={rootRef}
            style={{ '--timing': `${timingBase}ms` }}
            className={cx(styles.root, {
                [styles.lastBlock]: lastBlock,
            })}
        >
            <Grid />
            <Detail />
            {animationState === animationStates.END && (
                <EndState socialLinks={socialLinks} />
            )}
        </div>
    );
};

ContactForm.propTypes = {
    productCategories: PropTypes.array,
    socialLinks: PropTypes.array,
    lastBlock: PropTypes.bool,
};

export default ContactForm;
