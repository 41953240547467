export const fragment = /* GraphQL */ `
    fragment CardProductFragment on Product {
        award {
            json
        }
        category {
            slug
            attributes
        }
        textColor
        color
        displayName
        eyebrow
        icon
        slug
        sys {
            id
        }
        sizes3Collection {
            items {
                size
                sku2
            }
        }
        image {
            ...Asset
        }
    }
`;
