import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText';
import Slideshow from 'components/ui/Slideshow';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';
import UltraBoldHeading from 'components/ui/UltraBoldHeading';
import Video from 'components/ui/Video';

import { blockRootProps } from 'utils';

import styles from './BlockHeroSplit.module.scss';

const richTextOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    className={styles.description}
                    as="p"
                    baseTheme="headingXSmall"
                    themes={{ large: 'headingSmall' }}
                >
                    {children}
                </Text>
            );
        },
    },
};

const iconMap = {
    'New (Dark)': 'newPdpRibbonDark',
    'New (Light)': 'newPdpRibbonLight',
};

const BlockHeroSplit = ({
    __typename,
    anchorId,
    influencerImage,
    headline,
    description,
    svgLockup,
    svgLockupSeoText,
    cta,
    customTextHex,
    customHex,
    image,
    imageLarge,
    images,
    imageOnLeft,
    imageVerticalAlignment,
    className,
    icon,
    eyebrow,
    removeMorningStar,
    videoTextureMp4,
    videoTextureWebm,
    videoTexturePoster,
}) => {
    customTextHex ??= '001E60'; //blue
    customHex ??= 'F6F4E0'; //cream
    imageOnLeft ??= true;
    imageVerticalAlignment ??= 'center';

    return (
        <div
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.root, className, {
                [styles.imageOnLeft]: imageOnLeft,
                [styles.hasDescription]: !!description,
                [styles.hasInfluencerImage]: !!influencerImage,
            })}
            style={{
                '--vertical-alignment': imageVerticalAlignment,
            }}
        >
            <div className={styles.inner}>
                <div
                    className={styles.column}
                    style={{
                        backgroundColor: `#${customHex}`,
                        color: `var(--colors-${customTextHex})`,
                    }}
                >
                    <div className={styles.aspect}>
                        <div className={styles.aspectInner}>
                            {influencerImage?.url && (
                                <div className={styles.influencerImage}>
                                    <Img
                                        className={styles.image}
                                        src={influencerImage?.url}
                                        fallbackImageWidth={297 * 2}
                                        alt={influencerImage?.description || ''}
                                        customSources={[
                                            {
                                                src: influencerImage.url,
                                                imageWidth: 297 * 2,
                                            },
                                        ]}
                                        priority
                                    />
                                </div>
                            )}
                            {eyebrow && (
                                <Text
                                    baseTheme="labelSmall"
                                    themes={{ large: 'labelLarge' }}
                                    className={styles.eyebrow}
                                >
                                    {eyebrow}
                                </Text>
                            )}
                            {headline &&
                                typeof headline === 'object' &&
                                !svgLockup && (
                                    <UltraBoldHeading
                                        baseTheme={
                                            influencerImage
                                                ? 'displaySmall'
                                                : 'displayMedium'
                                        }
                                        themes={{
                                            medium: 'displayLarge',
                                            large: influencerImage
                                                ? 'displayLarge'
                                                : 'displayLargeFluid',
                                            xLarge: influencerImage
                                                ? 'displayXLarge'
                                                : 'displayLargeFluid',
                                        }}
                                        icon={
                                            influencerImage ||
                                            eyebrow ||
                                            removeMorningStar
                                                ? null
                                                : 'morningStar'
                                        }
                                        headline={headline}
                                    />
                                )}
                            {svgLockup && (
                                <div
                                    className={styles.svgLockup}
                                    style={{
                                        '--svg-ratio':
                                            svgLockup?.height /
                                            svgLockup?.width,
                                        backgroundImage: `url(${svgLockup?.url})`,
                                    }}
                                >
                                    {svgLockupSeoText && (
                                        <span className="sr-only">
                                            {svgLockupSeoText}
                                        </span>
                                    )}
                                </div>
                            )}
                            {description && (
                                <RichText
                                    overrides={richTextOverrides}
                                    richText={description}
                                />
                            )}
                            {cta && (
                                <div className={styles.ctaWrapper}>
                                    <ButtonCTA {...cta} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.aspect}>
                        {iconMap[icon] && (
                            <div className={styles.iconPlacement}>
                                <div className={styles.icon}>
                                    <SvgIcon type={iconMap[icon]} />
                                </div>
                            </div>
                        )}
                        {image?.url &&
                            imageLarge?.url &&
                            images?.length === 0 &&
                            !videoTextureMp4?.url &&
                            !videoTextureWebm?.url && (
                                <Img
                                    className={styles.image}
                                    src={image?.url}
                                    fallbackImageWidth={297 * 2}
                                    alt={image?.description || ''}
                                    customSources={[
                                        {
                                            breakpoint: 1920,
                                            src: imageLarge?.url,
                                            imageWidth: 1920 * 1.5,
                                        },
                                        {
                                            breakpoint: 1440,
                                            src: imageLarge?.url,
                                            imageWidth: 1440 * 1.5,
                                        },
                                        {
                                            breakpoint: 1024,
                                            src: imageLarge?.url,
                                            imageWidth: 1024 * 1.5,
                                        },
                                        {
                                            breakpoint: 768,
                                            src: imageLarge?.url,
                                            imageWidth: 720 * 2,
                                        },
                                        {
                                            src: image?.url,
                                            imageWidth: 297 * 2,
                                        },
                                    ]}
                                    priority
                                />
                            )}

                        {images?.length > 0 &&
                            !videoTextureMp4?.url &&
                            !videoTextureWebm?.url && (
                                <div className={styles.image}>
                                    <Slideshow images={images} />
                                </div>
                            )}

                        {videoTextureMp4?.url &&
                            videoTextureWebm?.url &&
                            videoTexturePoster?.url && (
                                <Video
                                    className={styles.image}
                                    poster={videoTexturePoster?.url}
                                    sources={[
                                        videoTextureWebm.url,
                                        videoTextureMp4.url,
                                    ]}
                                />
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
};

BlockHeroSplit.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    className: PropTypes.string,
    cta: PropTypes.object,
    customHex: PropTypes.string,
    customTextHex: PropTypes.string,
    description: PropTypes.object,
    eyebrow: PropTypes.string,
    headline: PropTypes.object,
    icon: PropTypes.string,
    image: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
            url: PropTypes.any,
        }),
        height: PropTypes.number,
        url: PropTypes.string,
        width: PropTypes.number,
    }),
    imageLarge: PropTypes.shape({
        height: PropTypes.number,
        url: PropTypes.string,
        width: PropTypes.number,
    }),
    images: PropTypes.array,
    imageOnLeft: PropTypes.bool,
    imageVerticalAlignment: PropTypes.oneOf(['top', 'center', 'bottom']),
    influencerImage: PropTypes.shape({
        description: PropTypes.string,
        url: PropTypes.string,
    }),
    svgLockup: PropTypes.object,
    svgLockupSeoText: PropTypes.string,
    removeMorningStar: PropTypes.bool,
    videoTextureMp4: PropTypes.shape({
        url: PropTypes.string,
    }),
    videoTextureWebm: PropTypes.shape({
        url: PropTypes.string,
    }),
    videoTexturePoster: PropTypes.shape({
        url: PropTypes.string,
    }),
};

export default BlockHeroSplit;
