'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import RichText from 'components/ui/RichText';
import TextEyebrow from 'components/ui/TextEyebrow';

import { blockRootProps } from 'utils';

import styles from './BlockCTAGroup.module.scss';

const BlockCTAGroup = ({
    __typename,
    anchorId,
    headline,
    eyebrow,
    copy,
    ctas,
    className,
}) => {
    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.blockCTAGroup, className)}
        >
            <div className={styles.inner}>
                <div className={styles.eyebrow}>
                    {headline && (
                        <TextEyebrow
                            eyebrow={eyebrow ? eyebrow : ''}
                            richHeadline={headline}
                            theme="display"
                        />
                    )}
                    {copy && (
                        <div className={styles.copy}>
                            <RichText richText={copy} />
                        </div>
                    )}
                    <div className={styles.buttons}>
                        {ctas?.map((cta, i) => (
                            <ButtonCTA key={i} {...cta} />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

BlockCTAGroup.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    className: PropTypes.string,
    copy: PropTypes.object,
    ctas: PropTypes.array,
    eyebrow: PropTypes.string,
    headline: PropTypes.object,
};

export default BlockCTAGroup;
