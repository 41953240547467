import React from 'react';

import PropTypes from 'prop-types';

import BorderDivotSvg1px from './svgs/BorderDivotSvg1px';
import BorderDivotSvg2px from './svgs/BorderDivotSvg2px';

const BorderDivot = ({ borderWidth, ...props }) => {
    return (
        <>
            {borderWidth === 1 && <BorderDivotSvg1px {...props} />}
            {borderWidth === 2 && <BorderDivotSvg2px {...props} />}
        </>
    );
};

BorderDivot.propTypes = {
    borderWidth: PropTypes.oneOf([1, 2]),
};

export default BorderDivot;
