import React, { useContext } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import cx from 'classnames';
import PropTypes from 'prop-types';

import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import ContactContext from '../../Context';

import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = ({ label, className, onClick, isHidden, tabIndex }) => {
    const cc = useContext(ContactContext);
    const { timingBase } = cc;

    return (
        <SwitchTransition>
            <CSSTransition
                key={label || null}
                timeout={timingBase}
                classNames={{
                    enter: styles['root-enter'],
                    enterActive: styles['root-enter-active'],
                    exit: styles['root-exit'],
                    exitActive: styles['root-exit-active'],
                }}
            >
                <button
                    className={cx(styles.root, className, {
                        [styles.isHidden]: isHidden,
                    })}
                    onClick={onClick}
                    tabIndex={tabIndex}
                >
                    <SvgIcon className={styles.icon} type="chevronLeft" />{' '}
                    <Text
                        className={styles.label}
                        baseTheme="bodySmall"
                        themes={{ medium: 'bodyMedium' }}
                    >
                        {label}
                    </Text>
                </button>
            </CSSTransition>
        </SwitchTransition>
    );
};

Breadcrumbs.propTypes = {
    className: PropTypes.string,
    isHidden: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    step: PropTypes.number,
    tabIndex: PropTypes.number,
};

export default Breadcrumbs;
