import React from 'react';

import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import styles from './DatePicker.module.scss';

const TODAY = new Date();
const DEFAULT = 'DEFAULT';
const CURRENT_YEAR = TODAY.getFullYear();
const CURRENT_MONTH = TODAY.getMonth();
const START_YEAR = CURRENT_MONTH > 2 ? CURRENT_YEAR : CURRENT_YEAR - 1;
// ^ Only show the last yeat if it's not past April

export const DAYS = [...Array(31).keys()].map(i =>
    i < 9 ? `0${i + 1}` : (i + 1).toString()
);
export const MONTHS = [...Array(12).keys()].map(i =>
    i < 9 ? `0${i + 1}` : (i + 1).toString()
);

export const YEARS = Array.from(
    { length: CURRENT_YEAR - START_YEAR + 3 },
    (v, k) => k + START_YEAR
).reverse();

const DatePicker = ({
    register,
    watch,
    fieldNameMonth,
    fieldNameDay,
    fieldNameYear,
}) => {
    watch([fieldNameMonth, fieldNameDay, fieldNameYear]);

    return (
        <div className={styles.root}>
            <div className={styles.inputWrapper}>
                <div className={styles.bestByFields}>
                    <Text
                        baseTheme="headingXSmall"
                        className={styles.bestByFieldItem}
                    >
                        <select
                            {...register(fieldNameMonth)}
                            className={styles.select}
                            defaultValue={DEFAULT}
                            title="Select the month"
                            aria-label="Select the month"
                        >
                            <option value={DEFAULT} disabled hidden>
                                MM
                            </option>
                            {MONTHS.map((mon, idx) => (
                                <option value={mon} key={`mon${idx}`}>
                                    {mon}
                                </option>
                            ))}
                        </select>
                    </Text>

                    <Text
                        baseTheme="headingXSmall"
                        className={styles.bestByFieldItem}
                    >
                        <select
                            {...register(fieldNameDay)}
                            className={styles.select}
                            defaultValue={DEFAULT}
                            title="Select the day"
                            aria-label="Select the day"
                        >
                            <option value={DEFAULT} disabled hidden>
                                DD
                            </option>
                            {DAYS.map((day, idx) => (
                                <option value={day} key={`day${idx}`}>
                                    {day}
                                </option>
                            ))}
                        </select>
                    </Text>

                    <Text
                        baseTheme="headingXSmall"
                        className={styles.bestByFieldItem}
                    >
                        <select
                            {...register(fieldNameYear)}
                            className={styles.select}
                            defaultValue={DEFAULT}
                            title="Select the year"
                            aria-label="Select the year"
                        >
                            <option value={DEFAULT} disabled hidden>
                                YYYY
                            </option>
                            {YEARS.map((year, idx) => (
                                <option value={year} key={`year${idx}`}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    </Text>
                </div>
            </div>
        </div>
    );
};

DatePicker.propTypes = {
    register: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    fieldNameMonth: PropTypes.string.isRequired,
    fieldNameDay: PropTypes.string.isRequired,
    fieldNameYear: PropTypes.string.isRequired,
};

export default DatePicker;
