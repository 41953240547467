'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import TextEyebrow from 'components/ui/TextEyebrow';

import { blockRootProps } from 'utils';

import TimelineItem from './TimelineItem';

import styles from './BlockTimeline.module.scss';

const BlockTimeline = ({
    __typename,
    anchorId,
    headline,
    eyebrow,
    items,
    flushTop,
    className,
}) => {
    flushTop ??= true;
    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.blockTimeline, className, {
                [styles.flushTop]: flushTop,
            })}
        >
            <div className={styles.eyebrow}>
                {headline && (
                    <TextEyebrow
                        eyebrow={eyebrow ? eyebrow : ''}
                        headline={headline}
                        theme="display"
                    />
                )}
            </div>
            <div className={styles.items}>
                <div className={styles.timelineLine} role="presentation">
                    <div className={styles.timelineLineInner} />
                </div>
                {items?.map((item, i) => {
                    return <TimelineItem key={i} {...item} />;
                })}
            </div>
        </section>
    );
};

BlockTimeline.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    items: PropTypes.array,
    headline: PropTypes.string,
    eyebrow: PropTypes.string,
    flushTop: PropTypes.bool,
    className: PropTypes.string,
};

export default BlockTimeline;
