'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import TextEyebrow from 'components/ui/TextEyebrow';
import VideoPlayerYT from 'components/ui/VideoPlayerYT';

import { blockRootProps } from 'utils';

import StoryList from './StoryList';

import styles from './BlockNews.module.scss';

const BlockNews = ({
    __typename,
    anchorId,
    headline,
    eyebrow,
    video,
    stories,
    flushTop,
    flushBottom,
}) => {
    return (
        <div
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.newsBlock, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <div className={styles.eyebrowBlock}>
                {headline && (
                    <TextEyebrow
                        eyebrow={eyebrow ? eyebrow : ''}
                        headline={headline}
                        theme="display"
                    />
                )}
            </div>
            {video && (
                <div className={styles.videoBlock}>
                    <VideoPlayerYT {...video} />
                </div>
            )}
            {stories?.length && (
                <div className={styles.storyBlock}>
                    <StoryList stories={stories} />
                </div>
            )}
        </div>
    );
};

BlockNews.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    headline: PropTypes.string,
    eyebrow: PropTypes.string,
    video: PropTypes.object,
    stories: PropTypes.array.isRequired,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
};

export default BlockNews;
