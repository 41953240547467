export const fragment = /* GraphQL */ `
    fragment CardSimpleFragment on CardSimple {
        image {
            ...Asset
        }
        eyebrow
        title
        link {
            ...EntryLink
        }
    }
`;
