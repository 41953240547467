import React from 'react';

import styles from './AnimatedIconFarmers.module.scss';

const AnimatedIconFarmers = () => {
    return (
        <div className={styles.root}>
            <svg width="120px" height="120px" viewBox="0 0 120 120">
                <path
                    fill="#FCFAE6"
                    d="M60,0L60,0c33.1,0,60,26.9,60,60l0,0c0,33.1-26.9,60-60,60l0,0C26.9,120,0,93.1,0,60l0,0C0,26.9,26.9,0,60,0z"
                />
                <g>
                    <path
                        fill="#FFCF84"
                        d="M88.9,60.2c0-15.8-12.7-28.7-28.4-28.7S32.1,44.4,32.1,60.2c0,4.9,1.3,9.7,3.6,13.9l-1.4,0.8c2.7,4.9,6.7,8.9,11.6,11.7l2.5-1.9h0V68.3h24.3v16.4h0l2.5,1.9c4.8-2.7,8.7-6.7,11.4-11.4l-1.4-0.8C87.5,70.1,88.9,65.2,88.9,60.2z"
                    />
                    <path
                        fill="#BADAF3"
                        d="M79.3,68.3H41.9l18.7-14.7L79.3,68.3z"
                    />
                    <g>
                        <defs>
                            <rect
                                id="SVGID_1_"
                                x="31.5"
                                y="52"
                                width="58"
                                height="45"
                            />
                        </defs>
                        <clipPath id="SVGID_00000000224099548266848800000016795439382135573909_">
                            <use xlinkHref="#SVGID_1_" overflow="visible" />
                        </clipPath>
                        <g clipPath="url(#SVGID_00000000224099548266848800000016795439382135573909_)">
                            <path
                                fill="#001E60"
                                d="M74.2,69.9h5.1c0.7,0,1.3-0.4,1.5-1.1c0.2-0.7,0-1.4-0.5-1.8L62.1,52.6v-3.1h9.1l-1,2.5l8.1-4.1l-8.1-4.1
				l1,2.5h-9.1v-6.5h-3.2v6.5h-6.5l-1.6-1.6h-8.1l3.2,3.3l-3.2,3.3h8.1l1.6-1.6h6.5v3.4L40.9,67c-0.5,0.4-0.8,1.2-0.5,1.8
				c0.2,0.7,0.8,1.1,1.5,1.1h4.7v14L31.9,94.9l1.9,2.6l5.1-3.8h43l5.1,3.8l1.9-2.6L74.3,83.9L74.2,69.9L74.2,69.9z M60.6,55.6l14,11
				h-28L60.6,55.6z M71,70v11.5l-9.6-7.1c-0.6-0.4-1.4-0.4-1.9,0l-9.6,7.1V70H71z M67.6,83.1H53.3l7.2-5.3L67.6,83.1L67.6,83.1z
				 M43.4,90.4l5.5-4.1h23.2l5.5,4.1H43.4z"
                            />
                        </g>
                    </g>
                    <path
                        fill="#001E60"
                        d="M62.1,49.5v3.1L60.5,54l-1.7-1.1v-3.4v-3.3v-6.5h3.2v6.5V49.5z"
                    />
                    <path
                        fill="#001E60"
                        d="M90.5,60.2c0-1.2-0.1-2.3-0.2-3.5l7.7-1.4l-0.6-3.2l-7.7,1.4c-1.3-5.9-4.3-11.1-8.4-15.1l4.8-5.8l-2.5-2.1
		l-4.8,5.8c-4.7-3.7-10.5-6-16.7-6.3v-7.4h-3.2v7.4c-6.3,0.3-12,2.6-16.7,6.3l-4.8-5.7l-2.5,2.1l4.8,5.8c-4.2,4-7.2,9.2-8.5,15.1
		l-7.6-1.4L23,55.3l7.7,1.4c-0.1,1.1-0.2,2.3-0.2,3.5c0,4.9,1.2,9.5,3.2,13.6l-6.5,3.8l1.6,2.8l6.5-3.8c1,1.6,2.2,3.1,3.5,4.5l2.7-2
		c-4.8-4.9-7.8-11.6-7.8-19c0-14.9,12-27,26.8-27s26.8,12.1,26.8,27c0,7.4-3,14.1-7.8,19l2.7,2c1.3-1.4,2.5-2.9,3.5-4.5l6.5,3.8
		l1.6-2.8l-6.5-3.8C89.3,69.7,90.5,65.1,90.5,60.2L90.5,60.2z"
                    />
                </g>
            </svg>
            <svg
                className="animated-icon-farmers-arrow"
                width="35.6px"
                height="8.4px"
                viewBox="0 0 35.6 8.4"
            >
                <path
                    fill="#001E60"
                    d="M28.5,5.9h-9.1h-3.2H9.7L8.1,7.5H0l3.2-3.3L0,0.9h8.1l1.6,1.6h6.5h3.2h9.1l-1-2.5l8.1,4.1l-8.1,4.3L28.5,5.9
	z"
                />
            </svg>
        </div>
    );
};

export default AnimatedIconFarmers;
