import { useMemo } from 'react';

import { createClient } from 'contentful';
import fetchRetry from 'fetch-retry';
import jsonpack from 'jsonpack';

import resolveResponse from 'utils/contentful-resolve-response';

export const SITE_CONFIG_ID = '55SHZHDxbeA5MFoLx7hw1g';

export const PREVIEW_MODE = /preview/.test(
    process.env.NEXT_PUBLIC_CONTENTFUL_HOST
);

const fetchWithRetry = fetchRetry(fetch);

// Error Codes https://www.contentful.com/developers/docs/references/graphql/#/reference/graphql-errors/graphql-errors-explained
const warnTypes = [
    'UNRESOLVABLE_LINK',
    'UNKNOWN_LOCALE',
    'UNEXPECTED_LINKED_CONTENT_TYPE',
    'UNRESOLVABLE_RESOURCE_LINK',
];

export async function graphRequest({
    cache,
    headers,
    query,
    variables = {},
    next = {},
}) {
    let request;

    if (next.revalidate) {
        console.info(`Manual cache revalidate set: ${next.revalidate}`);
    }

    // Check if it's an id so we can directly set a next cache tag by convention
    if (variables.id && !next.tags && !next.revalidate) {
        next.tags = [variables.id];
    }

    if (!next.revalidate) {
        next.revalidate = PREVIEW_MODE ? 60 : 60 * 10; // Low-revalidate in preview branches
    }

    try {
        request = await fetchWithRetry(
            `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE}/environments/${process.env.NEXT_PUBLIC_CONTENTFUL_ENV}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${process.env.NEXT_PUBLIC_CONTENTFUL_TOKEN}`,
                    ...headers,
                },
                body: JSON.stringify({
                    ...(query && { query }),
                    variables: {
                        ...variables,
                        preview: PREVIEW_MODE,
                    },
                }),
                retryOn: function (attempt, error, response) {
                    if (response?.status === 400) {
                        return false;
                    }

                    if (attempt > 1) {
                        return false;
                    }
                    // retry on any network error, or 4xx or 5xx status codes
                    if (error !== null || response.status >= 400) {
                        if (response?.status === 429) {
                            console.info(`Contentful: Rate limited`);
                        } else {
                            console.info(
                                `Contentful: Fetch failed, status ${response?.status}, ${response?.statusText}`
                            );
                        }
                        return true;
                    }
                },
                retryDelay: function (attempt, error, response) {
                    if (
                        response?.status === 429 &&
                        response.headers.has('x-contentful-ratelimit-reset')
                    ) {
                        const delay =
                            parseInt(
                                response.headers.get(
                                    'x-contentful-ratelimit-reset'
                                ),
                                10
                            ) * 1000;

                        console.info(`Waiting ${delay / 1000}s...`);
                        return delay;
                    }
                    return 1000; // 1000, 2000, 4000
                },
                cache,
                next,
            }
        );

        const body = await request.json();

        body?.errors &&
            body.errors.forEach(error => {
                if (warnTypes.includes(error?.extensions?.contentful?.code)) {
                    console.warn(error.extensions.contentful.message);
                }
            });

        const firstError =
            body?.errors?.find(error => {
                return !warnTypes.includes(error?.extensions?.contentful?.code);
            }) || false;

        if (firstError) {
            throw new Error(firstError.message);
        }

        return {
            status: request.status,
            body,
        };
    } catch (e) {
        if (
            e?.extensions?.contentful?.code === 'RATE_LIMIT_EXCEEDED' &&
            request
        ) {
            console.log('RATE_LIMIT_EXCEEDED', request);
            // TODO handle rate limiting here
            throw e;
        }

        throw e;
    }
}

export const clientNoLinkResolve = createClient({
    space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE,
    accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_TOKEN,
    environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENV,
    host: process.env.NEXT_PUBLIC_CONTENTFUL_HOST,
}).withoutLinkResolution;

export const client = createClient({
    space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE,
    accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_TOKEN,
    environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENV,
    host: process.env.NEXT_PUBLIC_CONTENTFUL_HOST,
});

export async function fetchSiteConfig() {
    const results = await clientNoLinkResolve.getEntries({
        content_type: 'siteConfig',
        include: 4,
    });
    return cleanResponse(results);
}

export async function fetchAllRedirects() {
    const results = await clientNoLinkResolve.getEntries({
        content_type: 'redirect',
        limit: 500,
        include: 1,
    });
    return cleanResponse(results);
}

export async function fetchConsumerFeedbackInformation() {
    const results = await clientNoLinkResolve.getEntries({
        content_type: 'consumerFeedbackInformation',
        include: 1,
    });
    return cleanResponse(results);
}

export async function fetchProductsLinked({
    showDiscontinued = false,
    isContact = false,
}) {
    if (showDiscontinued) {
        const response = await client.getEntries({
            content_type: 'product',
            order: 'fields.order',
            limit: 500,
            'fields.hideOnContact[ne]': isContact,
            include: 5,
        });
        return response;
    } else {
        const response = await client.getEntries({
            content_type: 'product',
            order: 'fields.order',
            'fields.discontinued[ne]': true,
            'fields.hideOnContact[ne]': isContact,
            limit: 500,
            include: 5,
        });
        return response;
    }
}

export async function fetchProductDetailPageBySlug(slug) {
    if (slug) {
        const results = await clientNoLinkResolve.getEntries({
            content_type: 'product',
            'fields.slug': slug,
            include: 5,
        });
        return cleanResponse(results);
    }
}

export async function fetchProductByTillamapsID(id) {
    if (id) {
        const results = await clientNoLinkResolve.getEntries({
            content_type: 'product',
            'fields.tillamapsID': id,
            include: 2,
        });
        return cleanResponse(results);
    }
}

export async function fetchProducts() {
    return clientNoLinkResolve.getEntries({
        content_type: 'product',
        'fields.discontinued[ne]': true,
        limit: 500,
        include: 5,
    });
}

export async function fetchEntryById(id) {
    if (id) {
        const response = await clientNoLinkResolve.getEntries({
            'sys.id': id,
            include: 5,
        });

        return response;
    }

    return null;
}

export function resolveEntryLink(item, response) {
    try {
        if (item?.sys?.type === 'Link') {
            return response.includes[item.sys.linkType].find(entry => {
                return entry.sys.id === item.sys.id;
            });
        }
        return [];
    } catch (e) {
        return false;
    }
}

export function resolveEntryLinks(items, response) {
    if (Array.isArray(items)) {
        return items.map(item => {
            return response.includes[item.sys.linkType].find(entry => {
                return entry.sys.id === item.sys.id;
            });
        });
    }
    return [];
}

export const useNormalizedContentfulResponse = response => {
    return useMemo(() => {
        return resolveResponse(
            typeof response === 'string' ? jsonpack.unpack(response) : response
        );
    }, [response]);
};

/**
 * Cleans the Contentful response item of unuseful data
 * @param {Object}      response A brute Contentful REST API response
 * @param {Object}      opts
 * @returns {Object}    A Contentful REST API response
 *
 */
const cleanResponse = (response, opts = {}) => {
    response.items?.map(item => cleanEntrySys(item, opts));
    response.includes?.Entry?.map(item => cleanEntrySys(item, opts));
    response.includes?.Asset?.map(item => cleanEntrySys(item, opts));
    return response;
};

/**
 * Cleans the Contentful response item of unuseful data
 * @param {Object}      item A Contentful REST API response item
 * @param {Object}      opts
 * @param {bool}        opts.keepTimestamps skips deletion of the TS keys
 * @returns {Object}    A Contentful REST API response item
 *
 */
export const cleanEntrySys = (item, opts = {}) => {
    if (!opts?.keepTimestamps) {
        delete item.sys.createdAt;
        delete item.sys.updatedAt;
    }
    delete item.sys.revision;
    delete item.sys.environment;
    delete item.sys.locale;
    delete item.sys.space;
    return item;
};
