'use client';

import React from 'react';

import PropTypes from 'prop-types';

import Img from 'components/ui/Img';
import UltraBoldHeading from 'components/ui/UltraBoldHeading';

import { blockRootProps } from 'utils';

import styles from './BlockCategoryDetailIntro.module.scss';

const BlockCategoryDetailIntro = ({
    __typename,
    anchorId,
    headline,
    image,
    portraitImage,
}) => {
    if (!headline || !image?.url || !portraitImage?.url) return null;

    return (
        <div
            {...blockRootProps(anchorId, __typename)}
            className={styles.categoryDetailIntro}
        >
            <div className={styles.content}>
                <div className={styles.headlineWrap}>
                    <div className={styles.headline}>
                        <UltraBoldHeading
                            baseTheme={'displayMedium'}
                            themes={{
                                medium: 'displayLarge',
                                large: 'displayXLarge',
                                xxxLarge: 'displayXXLarge',
                            }}
                            icon={'morningStar'}
                            headline={headline}
                        />
                    </div>
                </div>
                <Img
                    key={portraitImage.url}
                    className={styles.image}
                    src={image.url}
                    fallbackImageWidth={768}
                    priority
                    alt={portraitImage.description || ''}
                    width={portraitImage.width}
                    height={portraitImage.height}
                    customSources={[
                        {
                            breakpoint: 768,
                            src: portraitImage.url,
                            imageWidth: 1640,
                        },
                        {
                            src: image.url,
                            imageWidth: 768,
                        },
                    ]}
                />
            </div>
        </div>
    );
};

BlockCategoryDetailIntro.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    headline: PropTypes.object,
    image: PropTypes.shape({
        description: PropTypes.string,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
    }).isRequired,
    portraitImage: PropTypes.shape({
        description: PropTypes.string,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
    }).isRequired,
};

export default BlockCategoryDetailIntro;
