import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';
import TextEyebrow from 'components/ui/TextEyebrow';

import { blockRootProps, getColor } from 'utils';

import styles from './BlockSectionHeading.module.scss';

function createCopyOverrides(isLarge) {
    // isLarge only changes paragraph size
    // heading 2 and 3 are the same between themes
    return {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                if (isLarge) {
                    return (
                        <Text
                            as="p"
                            baseTheme="bodyMedium"
                            themes={{ medium: 'bodyLarge' }}
                            className={styles.paragraph}
                            fixWidows
                        >
                            {children}
                        </Text>
                    );
                }
                return (
                    <Text
                        as="p"
                        baseTheme="bodySmall"
                        themes={{ large: 'bodyMedium' }}
                        className={styles.paragraph}
                        fixWidows
                    >
                        {children}
                    </Text>
                );
            },
            [BLOCKS.HEADING_2]: (node, children) => {
                return (
                    <Text
                        as="p"
                        baseTheme="headingMedium"
                        themes={{
                            medium: 'headingLarge',
                            xLarge: 'headingXLarge',
                        }}
                        className={styles.paragraph}
                        fixWidows
                    >
                        {children}
                    </Text>
                );
            },
            [BLOCKS.HEADING_3]: (node, children) => {
                return (
                    <Text
                        as="p"
                        baseTheme="headingSmall"
                        themes={{
                            medium: 'headingMedium',
                            xLarge: 'headingLarge',
                        }}
                        className={styles.paragraph}
                        fixWidows
                    >
                        {children}
                    </Text>
                );
            },
        },
    };
}

const headingThemeMap = {
    default: 'display',
    displayLarge: 'displayXLarge',
};

const BlockSectionHeading = ({
    __typename,
    anchorId,
    eyebrow,
    richHeadline,
    copy,
    flushTop,
    flushBottom,
    theme,
    textAlign,
    cta,
    backgroundColor,
    firstBlock,
}) => {
    theme ??= 'default';
    textAlign ??= 'center';

    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.root, styles[theme], {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.textAlignLeft]: textAlign === 'left',
                [styles.hasCopy]: copy,
            })}
            style={{
                '--background-color': backgroundColor
                    ? getColor(backgroundColor)
                    : 'transparent',
            }}
        >
            <div className={styles.inner}>
                {(richHeadline || eyebrow) && (
                    <TextEyebrow
                        eyebrow={eyebrow}
                        className={styles.headline}
                        richHeadline={richHeadline}
                        inView={firstBlock}
                        theme={headingThemeMap[theme]}
                        textAlign={textAlign}
                        firstBlock={firstBlock}
                    />
                )}
                {copy && (
                    <div className={styles.copy}>
                        <RichText
                            richText={copy}
                            overrides={createCopyOverrides(
                                theme === 'displayLarge'
                            )}
                        />
                    </div>
                )}
                {cta && (
                    <ButtonCTA
                        className={styles.cta}
                        style="filled-blue-ocean"
                        {...cta}
                        text={cta.text}
                    />
                )}
            </div>
        </section>
    );
};

BlockSectionHeading.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    backgroundColor: PropTypes.string,
    cta: PropTypes.object,
    eyebrow: PropTypes.string,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
    richHeadline: PropTypes.object,
    copy: PropTypes.object,
    theme: PropTypes.oneOf(['default', 'displayLarge']),
    textAlign: PropTypes.oneOf(['center', 'left']),
    firstBlock: PropTypes.bool,
};

export default BlockSectionHeading;
