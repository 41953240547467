'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import Text from 'components/ui/Text';

import { useTheme } from 'hooks/useTheme';
import { blockRootProps } from 'utils';

import styles from './BlockPromotional.module.scss';

const richTextOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    baseTheme="bodyXSmall"
                    themes={{
                        large: 'bodySmall',
                    }}
                    fixWidows
                >
                    {children}
                </Text>
            );
        },
    },
};

const BlockPromotional = ({
    __typename,
    anchorId,
    className,
    copy,
    cta,
    eyebrow,
    headline,
    image,
    imageLarge,
    flushTop,
    flushBottom,
    lastBlock,
}) => {
    const [globalTheme] = useTheme();
    const isMakersReserve = globalTheme === 'makers-reserve';

    const renderImage = () => {
        const imageSource = [
            {
                src: image?.url,
                imageWidth: 500,
            },
        ];

        let sources = [...imageSource];

        if (imageLarge?.url) {
            const imageLargeSources = [
                {
                    breakpoint: 1440,
                    src: imageLarge.url,
                    imageWidth: 1440 * 1.5,
                },
                {
                    breakpoint: 1024,
                    src: imageLarge.url,
                    imageWidth: 1440,
                },
                {
                    breakpoint: 768,
                    src: imageLarge.url,
                    imageWidth: 900,
                },
                {
                    src: imageLarge.url,
                    imageWidth: 1440,
                },
            ];

            sources = [...imageLargeSources, ...imageSource];
        }

        return (
            <div className={styles.imageAspect}>
                {image?.url && (
                    <Img
                        className={styles.image}
                        src={image.url}
                        fallbackImageWidth={732}
                        alt={image.description || ''}
                        customSources={sources}
                    />
                )}
            </div>
        );
    };

    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.root, className, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.lastBlock]: lastBlock && !flushBottom,
                [styles.makersReserve]: isMakersReserve,
            })}
        >
            <div className={styles.inner}>
                {renderImage()}
                <Stagger className={styles.content}>
                    {eyebrow && (
                        <StaggerChild order={0} className={styles.eyebrow}>
                            <Text.Theme
                                className={styles.eyebrowText}
                                config={{
                                    'makers-reserve': {
                                        baseTheme: 'labelSmallAlt',
                                        themes: {
                                            large: 'labelMediumAlt',
                                        },
                                    },
                                    default: {
                                        baseTheme: 'labelSmall',
                                        themes: {
                                            large: 'labelLarge',
                                        },
                                    },
                                }}
                                as="p"
                            >
                                {eyebrow}
                            </Text.Theme>
                        </StaggerChild>
                    )}
                    {headline?.json && (
                        <StaggerChild order={1} className={styles.headline}>
                            <Text.Theme
                                config={{
                                    'makers-reserve': {
                                        baseTheme: 'parkinsonMedium',
                                        themes: {
                                            large: 'parkinsonLarge',
                                        },
                                    },
                                    default: {
                                        baseTheme: 'displaySmall',
                                        themes: {
                                            large: 'displayMedium',
                                            xxLarge: 'displayLarge',
                                        },
                                    },
                                }}
                                as="h2"
                            >
                                {headline?.json.content.map((line, i) => {
                                    if (line.content[0].value === '') {
                                        return null;
                                    }
                                    return (
                                        <span className={styles.line} key={i}>
                                            {line.content[0].value}
                                        </span>
                                    );
                                })}
                            </Text.Theme>
                        </StaggerChild>
                    )}
                    {copy?.json && (
                        <StaggerChild order={2} className={styles.copy}>
                            <RichText
                                richText={copy}
                                overrides={richTextOverrides}
                            />
                        </StaggerChild>
                    )}
                    {cta && (
                        <StaggerChild order={3} className={styles.cta}>
                            <ButtonCTA {...cta} />
                        </StaggerChild>
                    )}
                </Stagger>
            </div>
        </section>
    );
};

BlockPromotional.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    className: PropTypes.string,
    copy: PropTypes.object,
    cta: PropTypes.shape({
        assetDownloadLink: PropTypes.object,
        link: PropTypes.shape({
            contentTypeId: PropTypes.string,
        }),
        text: PropTypes.string,
    }),
    eyebrow: PropTypes.string,
    headline: PropTypes.object,
    image: PropTypes.shape({
        description: PropTypes.string,
        height: PropTypes.number,
        width: PropTypes.number,
        url: PropTypes.string.isRequired,
    }),
    imageLarge: PropTypes.shape({
        url: PropTypes.string.isRequired,
        height: PropTypes.number,
        width: PropTypes.number,
    }),
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    lastBlock: PropTypes.bool,
};

export default BlockPromotional;
