import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockCarouselShowcase from './BlockCarouselShowcase';

const query = /* GraphQL */ `
    ${ctaFragment}
    ${linkFragment}
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockCarouselShowcase(preview: $preview, id: $id) {
            __typename
            anchorId
            title
            timerDuration
            imageOnLeft
            isContinuous
            slidesCollection {
                items {
                    __typename
                    ... on CarouselShowcaseSlide {
                        richHeadline {
                            json
                        }
                        eyebrow
                        image {
                            ...Asset
                        }
                        imageLarge {
                            ...Asset
                        }
                        cta {
                            ... on Cta {
                                ...CTA
                            }
                        }
                        color
                    }
                }
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockCarouselShowcase },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockCarouselShowcase;
}

const BlockCarouselShowcaseServer = async ({ id }) => {
    const block = await fetchBlock(id);
    return (
        <BlockCarouselShowcase
            {...block}
            slides={block.slidesCollection.items}
        />
    );
};

export default BlockCarouselShowcaseServer;
