export const fragment = /* GraphQL */ `
    fragment CardTestimonialFragment on CardTestimonial {
        quoteIconColor
        mainQuote
        mainQuoteRichText {
            json
        }
        secondaryQuote
        secondaryQuoteRichText {
            json
        }
        author
        authorDescription
        authorImage {
            ...Asset
        }
    }
`;
