import React from 'react';

import { graphRequest } from 'services/contentful';

import { Block } from 'app/templates/renderBlocks';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockFullWidthMedia from './BlockFullWidthMedia';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockFullWidthMedia(preview: $preview, id: $id) {
            __typename
            anchorId
            callout {
                sys {
                    id
                }
            }
            eyebrow
            headline
            flushBottom
            flushTop
            richHeadline {
                json
            }
            headlineSize
            headlineColor
            headlineIcon
            cta {
                ... on Cta {
                    ...CTA
                }
            }
            headlinePortraitAlignment
            headlineLandscapeAlignment
            landscapeImage {
                ...Asset
            }
            portraitImage {
                ...Asset
            }
            theme
            videoTextureMp4 {
                ...Asset
            }
            videoTextureWebm {
                ...Asset
            }
            videoPoster {
                ...Asset
            }
            videoTextureMp4Large {
                ...Asset
            }
            videoTextureWebmLarge {
                ...Asset
            }
            videoPosterLarge {
                ...Asset
            }
            svgLockup {
                ...Asset
            }
            svgPortraitAlignment
            svgLandscapeAlignment
            svgLockupSeoText
            imageLargeVerticalAlignment
            description {
                json
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockFullWidthMedia },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockFullWidthMedia;
}

const BlockFullWidthMediaServer = async ({ id, firstBlock }) => {
    const block = await fetchBlock(id);

    return (
        <BlockFullWidthMedia
            {...block}
            callout={
                block.callout && (
                    <Block id={block.callout.sys.id} type="BlockCallout" />
                )
            }
            firstBlock={firstBlock}
        />
    );
};

export default BlockFullWidthMediaServer;
