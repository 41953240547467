import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockHeroSplit from './BlockHeroSplit';

const query = /* GraphQL */ `
    ${ctaFragment}
    ${linkFragment}
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockHeroSplit(preview: $preview, id: $id) {
            __typename
            anchorId
            influencerImage {
                ...Asset
            }
            headline {
                json
            }
            removeMorningStar
            eyebrow
            description {
                json
            }
            svgLockup {
                ...Asset
            }
            svgLockupSeoText
            cta {
                ... on Cta {
                    ...CTA
                }
            }
            customHex
            customTextHex
            image {
                ...Asset
            }
            imageLarge {
                ...Asset
            }
            imageOnLeft
            imagesCollection(limit: 6) {
                items {
                    ...Asset
                }
            }
            imageVerticalAlignment
            icon
            videoTextureMp4 {
                ...Asset
            }
            videoTextureWebm {
                ...Asset
            }
            videoTexturePoster {
                ...Asset
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockHeroSplit },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockHeroSplit;
}

const BlockHeroSplitServer = async ({ id }) => {
    const block = await fetchBlock(id);
    const images = block?.imagesCollection?.items;
    return <BlockHeroSplit {...block} images={images} />;
};

export default BlockHeroSplitServer;
