import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockCardTrioInline from './BlockCardTrioInline';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockCardTrioInline(preview: $preview, id: $id) {
            __typename
            anchorId
            title
            cardsCollection(limit: 10, preview: $preview) {
                items {
                    ... on FoodServiceCategoryCard {
                        productCategoryCard {
                            productBackgroundColor
                            productImage {
                                ...Asset
                            }
                        }
                        cta {
                            ... on Cta {
                                ...CTA
                            }
                        }
                    }
                }
            }
            flushTop
            flushBottom
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockCardTrioInline },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockCardTrioInline;
}

const BlockCardTrioInlineServer = async ({ id }) => {
    const block = await fetchBlock(id);

    return (
        <BlockCardTrioInline {...block} cards={block.cardsCollection.items} />
    );
};

export default BlockCardTrioInlineServer;
