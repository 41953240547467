import React from 'react';

import { graphRequest } from 'services/contentful';

import { assetFragment } from 'lib/graphql/fragments';

import SignUpForm from './SignUpForm';

const query = /* GraphQL */ `
    ${assetFragment}
    fragment Link on Entry {
        __typename
        sys {
            id
        }
        ... on ExternalLink {
            url
            target
        }
        ... on LinkEvent {
            url
        }
        ... on ManualLink {
            as
            href
        }
        ... on News {
            slug
        }
        ... on Page {
            slug
        }
        ... on Faq {
            slug
            category {
                slug
            }
        }
        ... on Product {
            category {
                slug
            }
            slug
        }
        ... on Recipe {
            slug
        }
        ... on SharpnessStory {
            closeLink {
                slug
            }
        }
        ... on XolaReservationOverlay {
            xolaButtonId
            type
        }
    }
    query getForm($id: String!, $preview: Boolean!) {
        signUpForm(preview: $preview, id: $id) {
            __typename
            klaviyoSourceEventName
            fieldsCollection(limit: 20) {
                items {
                    title
                    required
                    type
                    theme
                    placeholder
                }
            }
            consentAgreement {
                json
                links {
                    entries {
                        block {
                            ...Link
                        }
                        inline {
                            ...Link
                        }
                        hyperlink {
                            ...Link
                        }
                    }
                    assets {
                        hyperlink {
                            __typename
                            ...Asset
                        }
                        block {
                            __typename
                            ...Asset
                        }
                    }
                }
            }
            disclaimer {
                json
                links {
                    entries {
                        block {
                            ...Link
                        }
                        inline {
                            ...Link
                        }
                        hyperlink {
                            ...Link
                        }
                    }
                    assets {
                        hyperlink {
                            __typename
                            ...Asset
                        }
                        block {
                            __typename
                            ...Asset
                        }
                    }
                }
            }
            submitButtonText
            newSignUpOnly
            alertSubscriptionExists
            alertSubmitSuccess
        }
    }
`;

async function fetchForm(id) {
    const {
        body: {
            data: { signUpForm },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return signUpForm;
}

const SignUpFormServer = async ({ id, color, submitButtonStyle }) => {
    const form = await fetchForm(id);
    return (
        <SignUpForm
            {...form}
            fields={form.fieldsCollection.items}
            color={color}
            submitButtonStyle={submitButtonStyle}
        />
    );
};

export default SignUpFormServer;
