export const fragment = /* GraphQL */ `
    fragment CardDualAspectNewsFragment on CardDualAspectNews {
        entryTitle
        headline
        copy {
            json
        }
        image {
            ...Asset
        }
        link {
            ...EntryLink
        }
    }
`;
