'use client';

import React, { memo, useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import CardTestimonial from 'components/cards/CardTestimonial';
import ButtonCTA from 'components/ui/ButtonCTA';
import CountUp from 'components/ui/CountUp';
import Icon from 'components/ui/Icon';
import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';

import styles from './GridRow.module.scss';

const headlineRichTextOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    className={styles.headline}
                    as="h2"
                    baseTheme="headingMedium"
                    themes={{ large: 'headingLarge' }}
                >
                    {children}
                </Text>
            );
        },
        [BLOCKS.HEADING_2]: (node, children) => {
            return (
                <Text
                    className={cx(styles.headline, styles.capitalize)}
                    as="h2"
                    baseTheme="displayMedium"
                    themes={{ large: 'displayLarge' }}
                >
                    {children}
                </Text>
            );
        },
    },
};

const bodyRichTextOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    className={styles.paragraph}
                    as="p"
                    baseTheme="bodySmall"
                    themes={{ large: 'bodyMedium' }}
                >
                    {children}
                </Text>
            );
        },
        [BLOCKS.HEADING_3]: (node, children) => {
            return (
                <Text
                    className={styles.richTextH5}
                    as="p"
                    baseTheme="bodyMedium"
                    themes={{ large: 'bodyLarge' }}
                >
                    {children}
                </Text>
            );
        },
        [BLOCKS.HEADING_5]: (node, children) => {
            return (
                <Text
                    className={styles.richTextEyebrow}
                    as="p"
                    baseTheme="labelSmall"
                    themes={{ large: 'labelLarge' }}
                >
                    {children}
                </Text>
            );
        },
        [BLOCKS.HEADING_6]: (node, children) => {
            return (
                <Text
                    className={styles.listHeadline}
                    as="h6"
                    baseTheme="bodyMedium"
                    themes={{ large: 'bodyLarge' }}
                >
                    {children}
                </Text>
            );
        },
        [BLOCKS.LIST_ITEM]: (node, children) => {
            return <li className={styles.listItem}>{children}</li>;
        },
    },
};

const GridRow = ({
    className,
    headline,
    richHeadline,
    number,
    unit,
    bodyCopy,
    richText,
    leftImage,
    icon,
    blueBackground,
    image,
    imageLarge,
    theme,
    cta,
    testimonial,
}) => {
    theme ??= 'Default';

    const ref = useRef(null);
    const [isInView, setIsInView] = useState(false);

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    useEffect(() => {
        setIsInView(!!intersection?.isIntersecting);
    }, [intersection]);

    return (
        <div
            ref={ref}
            className={cx(styles.gridRow, className, {
                [styles.gridRowReversed]: leftImage,
                [styles.themeBlueBackground]: theme === 'Blue Background',
                [styles.themeCreamBackground]: theme === 'Cream Background',
                [styles.themeGridRowBlue]: theme === 'Cream Background',
            })}
        >
            <div className={styles.column}>
                {image?.url && imageLarge?.url && (
                    <Img
                        className={styles.image}
                        src={image.url}
                        alt={image.description || ''}
                        customSources={[
                            {
                                breakpoint: 1440,
                                src: imageLarge.url,
                                imageWidth: 1024,
                            },
                            {
                                breakpoint: 1024,
                                src: imageLarge.url,
                                imageWidth: 768,
                            },
                            {
                                breakpoint: 768,
                                src: imageLarge.url,
                                imageWidth: 580,
                            },
                            {
                                src: image.url,
                                imageWidth: 768,
                            },
                        ]}
                    />
                )}
            </div>
            <div
                className={cx(styles.column, {
                    [styles.blueBackground]: blueBackground,
                })}
            >
                {testimonial ? (
                    <>
                        <CardTestimonial
                            {...testimonial}
                            backgroundColor={
                                blueBackground || theme === 'Blue Background'
                                    ? 'blueSolidIcon'
                                    : 'white'
                            }
                        />
                        {cta && (
                            <div className={styles.testimonialCta}>
                                <ButtonCTA className={styles.cta} {...cta} />
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className={styles.lockup}>
                            <Text
                                className={cx(styles.headline, {
                                    [styles.capitalize]: number,
                                })}
                                baseTheme={
                                    number ? 'displayMedium' : 'headingMedium'
                                }
                                themes={{
                                    large: number
                                        ? 'displayLarge'
                                        : 'headingLarge',
                                }}
                            >
                                {number && (
                                    <div className={styles.countUpHeadline}>
                                        <CountUp
                                            end={number}
                                            duration={isInView ? 4000 : 0}
                                            startOnMount={false}
                                            start={0}
                                        />
                                        <span>{unit}</span>
                                    </div>
                                )}
                                {!richHeadline && headline}
                            </Text>
                            {richHeadline && (
                                <RichText
                                    overrides={headlineRichTextOverrides}
                                    richText={richHeadline}
                                />
                            )}
                            {icon && (
                                <div className={cx(styles.iconWrapper, 'icon')}>
                                    <Icon
                                        className={styles.icon}
                                        src={icon.icon.url}
                                    />
                                </div>
                            )}
                            <section className={styles.copy}>
                                {bodyCopy && !richText && (
                                    <Text
                                        baseTheme={
                                            number ? 'bodyMedium' : 'bodySmall'
                                        }
                                        themes={
                                            number
                                                ? { large: 'bodyLarge' }
                                                : { large: 'bodyMedium' }
                                        }
                                    >
                                        {bodyCopy}
                                    </Text>
                                )}
                                {richText && !bodyCopy && (
                                    <RichText
                                        richText={richText}
                                        overrides={bodyRichTextOverrides}
                                    />
                                )}
                            </section>
                        </div>
                        {cta && (
                            <div className={styles.buttonWrapper}>
                                <ButtonCTA className={styles.cta} {...cta} />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

GridRow.propTypes = {
    blueBackground: PropTypes.bool,
    bodyCopy: PropTypes.string,
    richText: PropTypes.object,
    className: PropTypes.string,
    cta: PropTypes.shape({
        assetDownloadLink: PropTypes.object,
        link: PropTypes.object,
        text: PropTypes.string,
    }),
    headline: PropTypes.string,
    richHeadline: PropTypes.object,
    iconType: PropTypes.string,
    image: PropTypes.shape({
        contentType: PropTypes.string.isRequired,
        description: PropTypes.string,
        fileName: PropTypes.string.isRequired,
        height: PropTypes.number.isRequired,
        size: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
    }),
    imageLarge: PropTypes.shape({
        contentType: PropTypes.string.isRequired,
        description: PropTypes.string,
        fileName: PropTypes.string.isRequired,
        height: PropTypes.number.isRequired,
        size: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
    }),
    icon: PropTypes.shape({
        name: PropTypes.string,
        icon: PropTypes.shape({
            contentType: PropTypes.string.isRequired,
            fileName: PropTypes.string.isRequired,
            height: PropTypes.number.isRequired,
            size: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            width: PropTypes.number.isRequired,
        }),
    }),
    leftImage: PropTypes.bool,
    number: PropTypes.number,
    unit: PropTypes.oneOf(['%', '+']),
    theme: PropTypes.oneOf(['Default', 'Blue Background', 'Cream Background']),
    testimonial: PropTypes.object,
};

export default memo(GridRow);
