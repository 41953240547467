import React from 'react';

import { graphRequest } from 'services/contentful';

import { fragment as cardProductFragment } from 'components/cards/CardProduct/fragment';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockSimpleProductList from './BlockSimpleProductList';

const query = /* GraphQL */ `
    ${ctaFragment}
    ${linkFragment}
    ${assetFragment}
    ${cardProductFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockSimpleProducts(preview: $preview, id: $id) {
            __typename
            anchorId
            eyebrow
            headline
            productsCollection(limit: 30) {
                items {
                    ... on Product {
                        ...CardProductFragment
                    }
                }
            }
            endCard {
                headline
                text {
                    json
                }
                cta {
                    ... on Cta {
                        ...CTA
                    }
                }
                backgroundColor
                textColor
            }
            flushTop
            flushBottom
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockSimpleProducts },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockSimpleProducts;
}

const BlockSimpleProductListServer = async ({ id }) => {
    const block = await fetchBlock(id);
    const products = block.productsCollection.items;

    return <BlockSimpleProductList {...block} products={products} />;
};

export default BlockSimpleProductListServer;
