import React from 'react';

import { graphRequest } from 'services/contentful';

import { assetFragment } from 'lib/graphql/fragments';

import BlockCategoryDetailIntro from './BlockCategoryDetailIntro';

const query = /* GraphQL */ `
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockCategoryDetailIntro(preview: $preview, id: $id) {
            __typename
            anchorId
            headline {
                json
            }
            image {
                ...Asset
            }
            portraitImage {
                ...Asset
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockCategoryDetailIntro },
        },
    } = await graphRequest({
        query,

        variables: { id },
    });

    return blockCategoryDetailIntro;
}

const BlockCategoryDetailIntroServer = async ({ id }) => {
    const block = await fetchBlock(id);

    return <BlockCategoryDetailIntro {...block} />;
};

export default BlockCategoryDetailIntroServer;
