import React from 'react';

import PropTypes from 'prop-types';

import CarouselProvider from 'components/ui/CarouselProvider';

import { blockRootProps } from 'utils';

import CarouselShowcaseWithCard from './CarouselShowcaseWithCard';

const BlockCarouselShowcaseWithCard = ({
    __typename,
    anchorId,
    slides,
    timerDuration,
    isContinuous,
    flushTop,
    flushBottom,
    navTheme,
}) => {
    timerDuration ??= 7;
    navTheme ??= 'blue';

    return (
        <div {...blockRootProps(anchorId, __typename)}>
            <CarouselProvider
                slides={slides}
                timerDuration={timerDuration}
                isContinuous={isContinuous}
            >
                <CarouselShowcaseWithCard
                    flushTop={flushTop}
                    flushBottom={flushBottom}
                    navTheme={navTheme}
                />
            </CarouselProvider>
        </div>
    );
};

BlockCarouselShowcaseWithCard.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
    navTheme: PropTypes.oneOf(['blue', 'white']),
    slides: PropTypes.array,
    timerDuration: PropTypes.number,
    isContinuous: PropTypes.bool,
};

export default BlockCarouselShowcaseWithCard;
