'use client';

import React from 'react';

import PropTypes from 'prop-types';

import Img from 'components/ui/Img';
import EntryLink from 'components/ui/Link/EntryLink';

import styles from './BlockSubNavCardGroupSectionCard.module.scss';

const BlockSubNavCardGroupSectionCard = ({ image, imageLarge, cta }) => {
    const hasLink = cta?.text && cta?.link;

    const renderImage = () => {
        const imageSources = [
            {
                breakpoint: 768,
                src: imageLarge.url,
                imageWidth: 900,
            },
            {
                src: image?.url,
                imageWidth: 768,
            },
        ];

        const imageLargeSources = [
            {
                breakpoint: 768,
                src: imageLarge.url,
                imageWidth: 900,
            },
            {
                src: imageLarge.url,
                imageWidth: 768,
            },
        ];

        // large image is the required asset, small is optional
        return (
            <>
                {!image?.url && imageLarge?.url && (
                    <Img
                        className={styles.image}
                        src={imageLarge.url}
                        fallbackImageWidth={600}
                        alt={imageLarge.description || ''}
                        customSources={imageLargeSources}
                    />
                )}
                {image?.url && imageLarge?.url && (
                    <Img
                        className={styles.image}
                        src={image.url}
                        fallbackImageWidth={600}
                        alt={imageLarge.description || ''}
                        customSources={imageSources}
                    />
                )}
            </>
        );
    };

    if (hasLink) {
        return (
            <EntryLink
                entry={cta.link}
                className={styles.root}
                aria-label={cta.text}
            >
                {renderImage()}
            </EntryLink>
        );
    }

    return <div className={styles.root}>{renderImage()}</div>;
};

BlockSubNavCardGroupSectionCard.propTypes = {
    image: PropTypes.object,
    imageLarge: PropTypes.object,
    cta: PropTypes.object,
};

export default BlockSubNavCardGroupSectionCard;
