import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Img from 'components/ui/Img';

import styles from './Slide.module.scss';

const Slide = ({
    image,
    imageLarge,
    className,
    isActive,
    isPrev,
    timerDuration,
    isSlideShow,
}) => {
    return (
        <article
            className={cx(styles.root, className, {
                [styles.isActive]: isActive,
                [styles.isPrev]: isPrev,
                [styles.isSlideShow]: isSlideShow,
            })}
            style={{
                '--timer-duration': `${timerDuration + 3}s`,
            }}
        >
            {image?.url && imageLarge.url && (
                <Img
                    className={styles.image}
                    style={{
                        transitionDuration: `${timerDuration + 3}s`,
                    }}
                    src={image.url}
                    fallbackImageWidth={297 * 2}
                    alt={image.description || ''}
                    customSources={[
                        {
                            breakpoint: 2560,
                            src: imageLarge.url,
                            imageWidth: 2560 * 1.5,
                        },
                        {
                            breakpoint: 1920,
                            src: imageLarge.url,
                            imageWidth: 1920 * 1.5,
                        },
                        {
                            breakpoint: 1440,
                            src: imageLarge.url,
                            imageWidth: 1440 * 1.5,
                        },
                        {
                            breakpoint: 1024,
                            src: imageLarge.url,
                            imageWidth: 1024 * 1.5,
                        },
                        {
                            breakpoint: 768,
                            src: imageLarge.url,
                            imageWidth: 768 * 1.5,
                        },
                        {
                            src: image.url,
                            imageWidth: 297 * 2,
                        },
                    ]}
                />
            )}
        </article>
    );
};

Slide.propTypes = {
    className: PropTypes.string,
    image: PropTypes.shape({
        description: PropTypes.string,
        height: PropTypes.number,
        url: PropTypes.string,
        width: PropTypes.number,
    }),
    imageLarge: PropTypes.shape({
        height: PropTypes.number,
        url: PropTypes.string,
        width: PropTypes.number,
    }),
    isActive: PropTypes.bool,
    isPrev: PropTypes.bool,
    isSlideShow: PropTypes.any,
    timerDuration: PropTypes.number,
};

export default Slide;
