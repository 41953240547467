'use client';

import React, { useEffect, useRef } from 'react';
import { useIntersection } from 'react-use';

import cx from 'clsx';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import Carousel from 'components/ui/Carousel';
import Icon from 'components/ui/Icon';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';

import Card from './Card';

import styles from './BlockSubNavCardGroupSection.module.scss';

const copyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    baseTheme="bodyMedium"
                    themes={{
                        large: 'bodyLarge',
                    }}
                    className={styles.copy}
                >
                    {children}
                </Text>
            );
        },
    },
};

const BlockSubNavCardGroupSection = ({
    id,
    index,
    title,
    icon,
    copy,
    cards,
    onInView,
}) => {
    const inViewRef = useRef(null);

    // observe only top 108px of the view for active section monitoring
    const [isMounted, setIsMounted] = React.useState(0);
    const [viewHeight, setViewHeight] = React.useState(0);
    const observerMarginTop = 108;
    const observerMarginBottom = isMounted
        ? viewHeight - observerMarginTop - 1
        : 0;

    const intersection = useIntersection(inViewRef, {
        rootMargin: `-${observerMarginTop}px 0px -${observerMarginBottom}px 0px`,
        threshold: 0,
    });

    useEffect(() => {
        // intersection observer needs isMounted for dynamic rootMargin
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (intersection?.isIntersecting) {
            onInView && onInView(index);
        }
    }, [index, intersection, onInView]);

    useEffect(() => {
        const handleResize = () => {
            setViewHeight(window.innerHeight);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const invalidCards = cards && cards.some(card => !card.imageLarge?.url);

    if (cards && cards.length > 0 && invalidCards) {
        console.warn(
            'BlockSubNavCardGroupSection: imageLarge is required for all cards'
        );
    }

    return (
        <section
            ref={inViewRef}
            className={cx(styles.root, {
                [styles.isSingleCard]: cards.length === 1,
            })}
        >
            <div className={styles.inner}>
                <div
                    id={`${id}-${index}`}
                    className={styles.scrollAnchor}
                    aria-hidden
                />
                <div className={styles.textContainer}>
                    <div className={styles.titleContainer}>
                        <Icon className={styles.icon} src={icon?.icon?.url} />
                        <Text
                            className={styles.title}
                            as="p"
                            baseTheme="displaySmall"
                            themes={{ large: 'displayMedium' }}
                        >
                            {title}
                        </Text>
                    </div>
                    {copy?.json && (
                        <div className={styles.copy}>
                            <RichText
                                richText={copy}
                                overrides={copyOverrides}
                            />
                        </div>
                    )}
                </div>
                {!invalidCards && cards.length > 0 && (
                    <div className={styles.cardsContainer}>
                        <Carousel
                            className={styles.carousel}
                            navigationTheme="cream"
                            autoScroll={false}
                            slideClassName={styles.card}
                            animatedInOverride
                        >
                            {cards.map((card, i) => (
                                <Card key={i} {...card} />
                            ))}
                        </Carousel>
                    </div>
                )}
            </div>
        </section>
    );
};

BlockSubNavCardGroupSection.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.object,
    copy: PropTypes.object,
    cards: PropTypes.array,
    index: PropTypes.number,
    onInView: PropTypes.func,
};

export default BlockSubNavCardGroupSection;
