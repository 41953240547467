'use client';

import React from 'react';

import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';

import { blockRootProps } from 'utils';

import Section from './Section';
import SubNav from './SubNav';

import styles from './BlockSubNavCardGroup.module.scss';

const copyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    baseTheme="bodyXSmall"
                    themes={{
                        large: 'bodyMedium',
                    }}
                >
                    {children}
                </Text>
            );
        },
    },
};

const BlockSubNavCardGroup = ({
    __typename,
    sys,
    sections,
    footnote,
    legend,
}) => {
    const [activeSection, setActiveSection] = React.useState(0);
    const handleInView = index => {
        setActiveSection(index);
    };

    return (
        <div {...blockRootProps(null, __typename)} className={styles.root}>
            <div className={styles.inner}>
                <div className={styles.subnav}>
                    <SubNav
                        id={sys.id}
                        sections={sections}
                        activeIndex={activeSection}
                        legend={legend}
                    />
                </div>
                <div className={styles.sections}>
                    {sections.map((section, i) => (
                        <Section
                            key={i}
                            {...section}
                            id={sys.id}
                            index={i}
                            cards={section?.cardsCollection?.items}
                            onInView={handleInView}
                        />
                    ))}
                </div>
            </div>
            {footnote && (
                <div className={styles.footnote}>
                    <RichText richText={footnote} overrides={copyOverrides} />
                </div>
            )}
        </div>
    );
};

BlockSubNavCardGroup.propTypes = {
    __typename: PropTypes.string,
    sections: PropTypes.array,
    sys: PropTypes.object,
    footnote: PropTypes.object,
    legend: PropTypes.object,
};

export default BlockSubNavCardGroup;
