import { useMemo } from 'react';

import { checkColorContrast } from '@tillamook/brand/lib/colors';

export const useTextAutoContrast = (backgroundColor, minimumRatio = 4.5) => {
    const darkContrast = useMemo(
        () => checkColorContrast(backgroundColor, 'blue'),
        [backgroundColor]
    );

    const lightContrast = useMemo(
        () => checkColorContrast(backgroundColor, 'cream'),
        [backgroundColor]
    );

    if (lightContrast < minimumRatio) {
        return 'dark';
    }

    return lightContrast < darkContrast ? 'dark' : 'light';
};
