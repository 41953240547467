import React from 'react';

import styles from './AnimatedIconPackaging.module.scss';

const AnimatedIconPackaging = () => {
    return (
        <div className={styles.root}>
            <svg width="120px" height="120px" viewBox="0 0 120 120">
                <path
                    fill="#FCFAE6"
                    d="M60,0L60,0c33.1,0,60,26.9,60,60l0,0c0,33.1-26.9,60-60,60l0,0C26.9,120,0,93.1,0,60l0,0C0,26.9,26.9,0,60,0
	z"
                />
                <path
                    fill="#001E60"
                    d="M93.9,47.9c-2.6-0.7-5.3,0.6-6.2,3.1l-6.5,16.3c-2.5-1.7-5.9-1.4-8.1,0.8l-9.2,9.2c-1.6,1.6-2.9,3.5-3.9,5.6
	c-0.9-2.1-2.2-3.9-3.9-5.6l-9.2-9.2c-1.2-1.2-2.8-1.9-4.5-1.9c-1.3,0-2.5,0.4-3.6,1.1l-6.5-16.3c-1-2.5-3.7-3.8-6.2-3.1
	c-2.6,0.7-4.2,3.4-3.6,6.1l4.4,20.6c0.7,3.4,2.4,6.6,4.8,9.2l11.3,12c0.3,0.3,0.5,0.7,0.5,1.2v3.1H47v-3.1c0-1.3-0.5-2.6-1.4-3.6
	l-11.3-12c-2-2.1-3.3-4.7-3.9-7.5l-4.4-20.6c-0.2-0.9,0.3-1.8,1.2-2c0.8-0.2,1.7,0.2,2.1,1l7.3,18.2c-0.2,0.6-0.3,1.3-0.3,2
	c0,1.7,0.7,3.3,1.9,4.5l7.8,7.8l2.5-2.5l-7.8-7.8c-0.5-0.5-0.8-1.3-0.8-2s0.3-1.5,0.8-2c1.1-1.1,3-1.1,4.1,0l9.2,9.2
	c3,3,4.6,6.9,4.6,11.1v9.3h3.5v-9.3c0-4.2,1.6-8.1,4.6-11.1l9.2-9.2c1.1-1.1,2.9-1.1,4.1,0c0.5,0.5,0.8,1.3,0.8,2s-0.3,1.5-0.8,2
	l-7.8,7.8l2.5,2.5l7.8-7.8c1.2-1.2,1.9-2.8,1.9-4.5c0-0.7-0.1-1.4-0.3-2l7.3-18.2c0.3-0.8,1.2-1.3,2.1-1c0.9,0.2,1.4,1.1,1.2,2
	l-4.4,20.6c-0.6,2.8-2,5.4-3.9,7.5l-11.3,12c-0.9,1-1.4,2.2-1.4,3.6v3.1h3.5v-3.1c0-0.4,0.2-0.9,0.5-1.2l11.3-12
	c2.4-2.6,4.1-5.7,4.8-9.2L97.5,54C98.1,51.3,96.5,48.6,93.9,47.9L93.9,47.9z"
                />
            </svg>
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                width="48.7px"
                height="48.7px"
                viewBox="0 0 48.7 48.7"
                className="animated-icon-packaging-earth"
            >
                <path
                    fill="#A9D7AE"
                    d="M46.9,33.7l-3.4-4.2c-1.1-1.6-2.8-2.5-4.7-2.5h-10l3.5-9.6H35c1.7,0,3.3-0.6,4.5-1.7l5.4-4.7
		c-4.5-6.6-12-11-20.5-11C17.7,0,11.6,2.7,7.2,7.1l6.1,6.1c1.4,1.4,1.7,3.7,0.6,5.4l-1.3,2.1c-0.7,1.1-0.4,2.5,0.6,3.2
		s2.3,0.6,3.2-0.2l2-2h5.2l0.9,7c0.5,3.5,3.4,6,6.9,6v2.8c0,1,0.4,2,1,2.8l4.5,4.9C41.3,42.6,44.9,38.5,46.9,33.7L46.9,33.7z"
                />
                <path
                    fill="#A9D7AE"
                    d="M13.8,33.5L12.7,31c-0.9-2.1-3-3.5-5.4-3.5H0.2C1.5,37.6,9,45.8,18.7,48.1l0.8-7.6
		C20.2,36.8,17.4,33.5,13.8,33.5L13.8,33.5z"
                />
                <path
                    fill="#001E60"
                    d="M24.4,48.7c13.4,0,24.3-10.9,24.3-24.3S37.8,0,24.4,0S0,10.9,0,24.3S11,48.7,24.4,48.7z M24.4,3.5
		c11.5,0,20.9,9.4,20.9,20.9s-9.4,20.9-20.9,20.9S3.5,35.9,3.5,24.4C3.5,12.8,12.9,3.5,24.4,3.5z"
                />
            </svg>
        </div>
    );
};

export default AnimatedIconPackaging;
