'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Link from 'components/ui/Link';
import RichText from 'components/ui/RichText';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import Text from 'components/ui/Text';

import { getColor } from 'utils';

import styles from './TextEyebrow.module.scss';

const Eyebrow = ({ children, theme = 'normal', firstBlock = false }) => {
    return (
        <Text.Theme
            config={{
                'makers-reserve': {
                    baseTheme: 'labelSmallAlt',
                    themes: { medium: 'labelMediumAlt' },
                },
                default: {
                    baseTheme:
                        theme === 'display'
                            ? 'labelSmall'
                            : theme === 'hero'
                            ? 'labelMedium'
                            : 'labelLarge',
                    themes: { large: 'labelLarge' },
                },
            }}
            className={cx(styles.innerHeadline, {
                [styles.innerHeadlineDisplay]: theme === 'display',
            })}
            as={firstBlock ? 'h1' : 'h3'}
        >
            {children}
        </Text.Theme>
    );
};

Eyebrow.propTypes = {
    theme: PropTypes.oneOf([
        'normal',
        'display',
        'masthead',
        'hero',
        'displaySmall',
        'displaySmallToLarge1',
        'displayLarge',
        'displayXLarge',
        'ultrabold',
        'makers-reserve',
    ]),
    children: PropTypes.any,
    firstBlock: PropTypes.bool,
};

const Headline = ({ children, theme = 'normal', fixWidows = true }) => {
    const textThemeConfig = {
        'makers-reserve': {
            baseTheme: 'parkinsonMedium',
            themes: { medium: 'parkinsonLarge' },
        },
        default: {
            baseTheme:
                {
                    displaySmallToLarge1: 'displaySmall',
                    displayXSmall: 'headingXSmall2',
                    displaySmall: 'displayXSmall',
                    masthead: 'displaySmall',
                    display: 'displayXSmall',
                    displayLarge: 'displayLarge',
                    displayXLarge: 'displaySmall',
                    ultrabold: 'displaySmall',
                    hero: 'headingMedium',
                }[theme] || 'headingMedium',
            themes: {
                displaySmallToLarge1: {
                    large: 'displayLarge',
                },
                displayXSmall: {
                    large: 'displayXSmall',
                },
                displaySmall: {
                    large: 'displaySmall',
                    xLarge: 'displayMedium',
                },
                masthead: { large: 'displayLarge' },
                display: { large: 'displayLarge' },
                displayLarge: {
                    large: 'displayXLarge',
                },
                displayXLarge: {
                    medium: 'displayMedium',
                    large: 'displayXLarge',
                },
                ultrabold: {
                    large: 'displayMedium',
                },
                hero: {
                    large: 'headingXLarge',
                },
            }[theme] || { large: 'headingLarge' },
        },
    };

    const richTextHeadlineOverrides = {
        // This headline should always come in as a paragraph from the CMS.
        // It is not possible to restrict input to H2.
        renderMark: {
            [MARKS.BOLD]: text => <em>{text}</em>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text.Theme
                        config={textThemeConfig}
                        as="span"
                        className={styles.paragraph}
                    >
                        {children}
                    </Text.Theme>
                );
            },
            // Override for rBST link in product category index
            [INLINES.HYPERLINK]: node => {
                return (
                    <Link
                        target="_self"
                        href={node.data.uri}
                        className={styles.richTextLink}
                    >
                        {node.content[0].value}
                    </Link>
                );
            },
        },
    };

    if (typeof children === 'object') {
        return (
            <RichText
                overrides={richTextHeadlineOverrides}
                richText={children}
            />
        );
    }

    return (
        <Text.Theme config={textThemeConfig} as="span" fixWidows={fixWidows}>
            {children}
        </Text.Theme>
    );
};

Headline.propTypes = {
    theme: PropTypes.oneOf([
        'normal',
        'display',
        'masthead',
        'displayXSmall',
        'displaySmall',
        'displaySmallToLarge1',
        'displayLarge',
        'displayXLarge',
        'ultrabold',
        'makers-reserve',
        'hero',
    ]),
    children: PropTypes.any,
    fixWidows: PropTypes.bool,
};

const TextEyebrow = ({
    theme = 'normal',
    textAlign = 'center',
    eyebrow,
    headline,
    richHeadline,
    className,
    globalTheme,
    eyebrowColor,
    headlineColor,
    cta,
    ctaButton,
    fixWidows,
    disableAnimation,
    inView,
    firstBlock,
}) => {
    theme ??= 'normal';
    fixWidows ??= true;
    disableAnimation ??= true;
    inView ??= false;
    firstBlock ??= false;

    return (
        <Stagger
            inView={inView}
            className={cx(
                styles.root,
                className,
                styles[theme],
                styles[`theme--${globalTheme}`],
                {
                    [styles.textAlignLeft]: textAlign === 'left',
                }
            )}
            style={{
                '--eyebrow': eyebrowColor && getColor(eyebrowColor),
                '--headline': headlineColor && getColor(headlineColor),
            }}
        >
            {eyebrow && (
                <StaggerChild
                    order={0}
                    className={styles.innerTextWrapper}
                    style={{
                        color: 'var(--eyebrow)',
                    }}
                    animation={disableAnimation ? 'disabled' : 'fadeUp'}
                >
                    <Eyebrow theme={theme} firstBlock={firstBlock}>
                        {eyebrow}
                    </Eyebrow>
                </StaggerChild>
            )}
            {(headline || richHeadline) && (
                <StaggerChild
                    order={1}
                    as={firstBlock && !eyebrow ? 'h1' : 'h2'}
                    className={cx(styles.innerTextWrapper, styles.headline)}
                    style={{
                        color: 'var(--headline)',
                    }}
                    animation={disableAnimation ? 'disabled' : 'fadeUp'}
                >
                    <Headline theme={theme} fixWidows={fixWidows}>
                        {richHeadline || headline}
                    </Headline>
                </StaggerChild>
            )}

            {(cta?.link || cta?.assetDownloadLink) && !ctaButton && (
                <StaggerChild
                    order={2}
                    className={styles.ctaWrapper}
                    animation={disableAnimation ? 'disabled' : 'fadeUp'}
                >
                    <ButtonCTA className={styles.cta} {...cta} />
                </StaggerChild>
            )}
            {(ctaButton?.link || ctaButton?.assetDownloadLink) && (
                <StaggerChild
                    order={2}
                    className={styles.ctaWrapper}
                    animation={disableAnimation ? 'disabled' : 'fadeUp'}
                >
                    <ButtonCTA className={styles.cta} {...ctaButton} />
                </StaggerChild>
            )}
        </Stagger>
    );
};

TextEyebrow.propTypes = {
    className: PropTypes.string,
    cta: PropTypes.object,
    ctaButton: PropTypes.object,
    disableAnimation: PropTypes.bool,
    eyebrow: PropTypes.string,
    eyebrowColor: PropTypes.string,
    fixWidows: PropTypes.bool,
    globalTheme: PropTypes.string,
    headline: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    headlineColor: PropTypes.string,
    richHeadline: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    theme: PropTypes.oneOf([
        'normal',
        'display',
        'masthead',
        'displayXSmall',
        'displaySmall',
        'displaySmallToLarge1',
        'displayLarge',
        'displayXLarge',
        'ultrabold',
        'hero',
        'makers-reserve',
    ]),
    textAlign: PropTypes.oneOf(['center', 'left']),
    firstBlock: PropTypes.bool,
    inView: PropTypes.bool,
};

export default TextEyebrow;
