import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import styles from './CardTestimonial.module.scss';

const secondaryQuoteOverride = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="h4"
                    baseTheme="bodyMedium"
                    className={styles.secondaryQuote}
                >
                    {children}
                </Text>
            );
        },
    },
};

const mainQuoteOverride = theme => {
    return {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text
                        as="h3"
                        baseTheme={
                            theme === 'smallQuote'
                                ? 'bodyLarge'
                                : 'headingSmall'
                        }
                        themes={
                            theme === 'smallQuote'
                                ? null
                                : {
                                      large: 'headingLarge',
                                  }
                        }
                        className={styles.mainQuote}
                    >
                        {children}
                    </Text>
                );
            },
        },
    };
};

const colorMap = {
    blue: 'blue',
    white: 'white',
    'ocean blue': 'blueOcean',
};

const CardTestimonial = ({
    mainQuoteRichText,
    secondaryQuoteRichText,
    author,
    authorImage,
    authorDescription,
    className,
    backgroundColor,
    quoteIconColor,
    theme,
}) => {
    theme ??= 'smallQuote';
    backgroundColor ??= 'none';
    quoteIconColor ??= 'white';

    const iconWrapperColor =
        backgroundColor === 'white' && quoteIconColor === 'white'
            ? 'cream'
            : colorMap[quoteIconColor];

    return (
        <div
            className={cx(styles.testimonial, className, {
                [styles.largeQuote]: theme === 'largeQuote',
                [styles.blueBlockTestimonial]: backgroundColor === 'blue',
                [styles.whiteBlockTestimonial]: backgroundColor === 'white',
                [styles.blueSolidIconBlockTestimonial]:
                    backgroundColor === 'blueSolidIcon',
            })}
        >
            <div
                style={{
                    '--icon-wrapper-color': `var(--colors-${iconWrapperColor})`,
                }}
                className={styles.textBlock}
            >
                <div className={styles.iconWrapper}>
                    <SvgIcon type="quote" />
                </div>
                <div className={styles.innerBlock}>
                    <div className={styles.textWrapper}>
                        <RichText
                            richText={mainQuoteRichText}
                            overrides={mainQuoteOverride(theme)}
                        />
                        {secondaryQuoteRichText && (
                            <RichText
                                richText={secondaryQuoteRichText}
                                overrides={secondaryQuoteOverride}
                            />
                        )}
                    </div>
                </div>
                <div className={styles.lineBlock}>
                    <span className={styles.line} />
                </div>
                {authorImage?.url && (
                    <div className={styles.imageBlock}>
                        <Img
                            className={styles.img}
                            src={authorImage.url}
                            fallbackImageWidth={240}
                            alt={authorImage.description || ''}
                            customSources={[
                                {
                                    src: authorImage.url,
                                    imageWidth: 240,
                                },
                            ]}
                        />
                    </div>
                )}
                <div className={styles.authorBlock}>
                    <Text
                        className={styles.authorName}
                        baseTheme="headingSmall"
                        as="h4"
                    >
                        {author}
                    </Text>
                    {authorDescription && (
                        <Text
                            className={styles.authorDescription}
                            baseTheme="bodySmall"
                            as="p"
                        >
                            {authorDescription}
                        </Text>
                    )}
                </div>
            </div>
        </div>
    );
};

CardTestimonial.propTypes = {
    mainQuoteRichText: PropTypes.object,
    secondaryQuoteRichText: PropTypes.object,
    author: PropTypes.string.isRequired,
    authorImage: PropTypes.object,
    authorDescription: PropTypes.string,
    className: PropTypes.string,
    backgroundColor: PropTypes.oneOf([
        'none',
        'blue',
        'white',
        'blueSolidIcon',
    ]),
    theme: PropTypes.oneOf(['smallQuote', 'largeQuote']),
    quoteIconColor: PropTypes.oneOf(['blue', 'white', 'ocean blue']),
};

export default CardTestimonial;
