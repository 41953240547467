import React from 'react';

import { graphRequest } from 'services/contentful';

import BlockPaperForm from './BlockPaperForm';

const query = /* GraphQL */ `
    query getBlock($id: String!, $preview: Boolean!) {
        blockPaperform(preview: $preview, id: $id) {
            __typename
            anchorId
            richText {
                json
            }
            formId
            flushTop
            flushBottom
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockPaperform },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockPaperform;
}

const BlockPaperFormServer = async ({ id }) => {
    const block = await fetchBlock(id);
    return <BlockPaperForm {...block} />;
};

export default BlockPaperFormServer;
