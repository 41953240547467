'use client';

import React, { useEffect } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import VideoPlayerYT from 'components/ui/VideoPlayerYT';

import { blockRootProps } from 'utils';

import styles from './BlockHeroVideo.module.scss';

const BlockHeroVideo = ({
    __typename,
    anchorId,
    video,
    callout,
    flushBottom,
}) => {
    useEffect(() => {
        if (!video) {
            dispatchWarning('No video provided. Video required for render.');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatchWarning = warning => {
        if (warning) {
            console.warn(warning);
        }
    };

    return (
        <div {...blockRootProps(anchorId, __typename)} className={styles.root}>
            <div className={styles.inner}>
                <VideoPlayerYT threshold={0} {...video} heroVideo={true} />
            </div>
            {callout && (
                <div
                    className={cx(
                        styles.calloutWrapper,
                        flushBottom && styles.flushBottom
                    )}
                >
                    {callout}
                </div>
            )}
        </div>
    );
};

BlockHeroVideo.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    video: PropTypes.object.isRequired,
    callout: PropTypes.node,
    flushBottom: PropTypes.bool,
};

export default BlockHeroVideo;
