import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Img from 'components/ui/Img';

import styles from './Image.module.scss';

const Image = ({ image, isTop, isMiddle, isBottom, isPrev, isForward }) => {
    return (
        <figure
            className={cx(styles.root, {
                [styles.top]: isTop,
                [styles.middle]: isMiddle,
                [styles.bottom]: isBottom,
                [styles.prev]: isPrev,
                [styles.isForward]: isForward,
            })}
        >
            {image?.url && (
                <Img
                    className={styles.image}
                    src={image.url}
                    fallbackImageWidth={278 * 2}
                    alt={image.description || ''}
                    customSources={[
                        {
                            breakpoint: 1024,
                            src: image.url,
                            imageWidth: 700 * 2,
                        },
                        {
                            breakpoint: 768,
                            src: image.url,
                            imageWidth: 600 * 2,
                        },
                        {
                            src: image.url,
                            imageWidth: 278 * 2,
                        },
                    ]}
                />
            )}
        </figure>
    );
};

Image.propTypes = {
    image: PropTypes.shape({
        description: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
    isTop: PropTypes.bool,
    isMiddle: PropTypes.bool,
    isBottom: PropTypes.bool,
    isPrev: PropTypes.bool,
    isForward: PropTypes.bool,
};

export default Image;
