import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button';
import TextEyebrow from 'components/ui/TextEyebrow';

import { blockRootProps } from 'utils';

import Item from './Item';

import styles from './BlockLinkList.module.scss';

const BlockLinkList = ({
    __typename,
    anchorId,
    className,
    eyebrow,
    richHeadline,
    links,
    flushTop,
    cta,
}) => {
    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.blockLinkList, className, {
                [styles.flushTop]: flushTop,
            })}
        >
            <div className={styles.inner}>
                <div className={styles.column}>
                    {richHeadline && (
                        <TextEyebrow
                            className={styles.eyebrow}
                            eyebrow={eyebrow ? eyebrow : ''}
                            headline={richHeadline}
                            theme="displaySmall"
                            fixWidows={false}
                        />
                    )}
                    {cta && (
                        <Button
                            theme="filledBlueHoverCream-ultrabold"
                            href={cta.href ? cta.href : null}
                            className={styles.cta}
                        >
                            {cta.text}
                        </Button>
                    )}
                </div>
                <div className={styles.column}>
                    {links && (
                        <ul className={styles.links}>
                            {links.map((link, i) => {
                                const ctaItem =
                                    link.__typename === 'Cta' ? link : null;
                                const linkListItem =
                                    link.__typename === 'LinkListItem'
                                        ? link
                                        : null;

                                return (
                                    <Item
                                        key={i}
                                        ctaItem={ctaItem}
                                        linkListItem={linkListItem}
                                    />
                                );
                            })}
                        </ul>
                    )}
                </div>
            </div>
        </section>
    );
};

BlockLinkList.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    className: PropTypes.string,
    cta: PropTypes.shape({
        href: PropTypes.string,
        text: PropTypes.string,
    }),
    eyebrow: PropTypes.string,
    flushTop: PropTypes.bool,
    richHeadline: PropTypes.object,
    links: PropTypes.array,
};

export default BlockLinkList;
