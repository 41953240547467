import React from 'react';

import styles from './AnimatedIconUtensils.module.scss';

const AnimatedIconUtensils = () => {
    return (
        <div className={styles.root}>
            <svg
                className="animated-icon-utensils-fork"
                width="27.7px"
                height="82.3px"
                viewBox="0 0 27.7 82.3"
            >
                <g>
                    <rect fill="none" width="27.7" height="82.3" />
                    <path
                        fill="#001E60"
                        d="M21.8,0.9c-0.7-0.1-1.4,0.6-1.3,1.3l0,17.7c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1l0.1-17.5
		c0-0.8-0.7-1.6-1.6-1.5c-0.8,0-1.6,0.7-1.5,1.6l-0.1,17.5c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1L13,2.4
		c0-0.8-0.7-1.6-1.6-1.5c-0.8,0-1.6,0.7-1.5,1.6L9.8,20c0,0.6-0.5,1.1-1.1,1.1S7.6,20.6,7.6,20l0-17.7C7.7,1.6,7,1,6.4,1
		C5.7,1,5.1,1.5,5,2.1L2,21c-0.4,2.9,0.2,5.9,1.7,8.4c1.6,2.4,4,4.3,6.9,5.1l0,0l0,0l-0.5,19.8L9.4,80.4c0,0.1,0.1,0.3,0.1,0.5
		c0.1,0.2,0.1,0.3,0.2,0.4c0.1,0.2,0.2,0.2,0.4,0.3c0.1,0,0.3,0.1,0.5,0.1l6.4,0c0.2,0,0.4,0,0.6-0.1c0.1,0,0.2-0.1,0.3-0.1
		c0.2-0.1,0.2-0.2,0.3-0.4c0-0.1,0.1-0.3,0.1-0.5L17.7,59l-0.5-24.2l0,0c2.9-0.8,5.3-2.6,6.9-5.1c1.6-2.5,2.2-5.5,1.8-8.4L23,2.3
		C23,1.5,22.5,1,21.8,0.9z"
                    />
                </g>
            </svg>
            <svg
                className="animated-icon-utensils-spoon"
                width="27.7px"
                height="82.3px"
                viewBox="0 0 27.7 82.3"
            >
                <g>
                    <rect fill="none" width="27.7" height="82.3" />
                    <g>
                        <path
                            fill="#BADAF3"
                            d="M17,58.4l-0.1-5.7l0-1.4l0-1.4l-0.3-13.2c2.9-0.5,5.6-2.1,7.5-4.4s3-5.2,3-8.2c0-6.5-5.3-21.8-12.7-21.9
			C7,2.2,1.5,17.5,1.5,24c0,3,1,5.9,3,8.2c1.9,2.3,4.5,3.9,7.5,4.5l-1.4,45.6l7,0L17,58.4z"
                        />
                        <path
                            fill="#001E60"
                            d="M21.5,13.7c1.3,3.9,1.9,7.9,1.6,10.6c-0.3,1.8-1.1,3.7-2.4,5s-3,2.2-4.9,2.5l-0.2-1l-0.2-1
			c0.1,0,0.4,0,0.5-0.1c1.3-0.3,2.5-1,3.4-2c0.9-1,1.4-2.3,1.6-3.6c0.3-2-0.1-5.2-1-8.1c-0.1-0.5-0.3-1.1-0.5-1.6L21.5,13.7z"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            fill="#001E60"
                            d="M17.5,47.4l0.7,33.4c0,0.1-0.1,0.3-0.1,0.5
			c-0.1,0.2-0.2,0.3-0.3,0.4c-0.2,0.2-0.6,0.4-0.9,0.4l-6.4,0c-0.2,0-0.4,0-0.6-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
			c-0.2-0.1-0.2-0.2-0.2-0.4c0-0.1-0.1-0.3-0.1-0.5l1.1-44.8c-3-0.7-5.6-2.5-7.5-5s-2.9-5.4-2.9-8.5C0.1,15.1,6.1-0.1,14,0
			s13.7,15.2,13.7,22.8c-0.1,3.1-1,6.1-3,8.5c-1.9,2.4-4.6,4.1-7.6,4.8L17.5,47.4z M11.8,79.5l3.8,0l-0.5-22.3l0-2.9L15,51.5l0-1.4
			l0-1.4l-0.3-13.5c0-0.4,0.1-0.6,0.3-0.9c0.2-0.2,0.5-0.4,0.7-0.5c2.7-0.4,5.1-1.8,6.7-3.8c1.7-2,2.7-4.6,2.7-7.2
			c0-6.5-5.3-20.3-11.2-20.3C8,2.5,2.6,16.3,2.6,22.8c0,2.6,0.9,5.3,2.7,7.3c1.7,2,4.1,3.4,6.8,3.8c0.1,0.1,0.3,0.1,0.4,0.1
			c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.5,0.2,0.7l-0.5,16.3l0,1.5l-0.1,3.1l0,1.5L11.8,79.5z"
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default AnimatedIconUtensils;
