import React from 'react';

import PropTypes from 'prop-types';

/* Blocks */
import BlockAccordion from 'components/blocks/BlockAccordion';
import BlockAnchorProductList from 'components/blocks/BlockAnchorProductList';
import BlockAudio from 'components/blocks/BlockAudio';
import BlockCtaGroup from 'components/blocks/BlockCTAGroup';
import BlockCallout from 'components/blocks/BlockCallout';
import BlockCardGroup from 'components/blocks/BlockCardGroup';
import BlockCardGroupSimple from 'components/blocks/BlockCardGroupSimple';
import BlockCardSwipe from 'components/blocks/BlockCardSwipe';
import BlockCardTrioInline from 'components/blocks/BlockCardTrioInline';
import BlockCardTrioSmall from 'components/blocks/BlockCardTrioSmall';
import BlockCarouselHero from 'components/blocks/BlockCarouselHero';
import BlockCarouselShowcase from 'components/blocks/BlockCarouselShowcase';
import BlockCarouselShowcaseWithCard from 'components/blocks/BlockCarouselShowcaseWithCard';
import BlockCategoryDetailIntro from 'components/blocks/BlockCategoryDetailIntro';
import BlockCommitmentChart from 'components/blocks/BlockCommitmentChart';
import BlockContactForm2 from 'components/blocks/BlockContactForm2';
import BlockDeckSlider from 'components/blocks/BlockDeckSlider';
import BlockDonationForm from 'components/blocks/BlockDonationForm';
import BlockDownloadList from 'components/blocks/BlockDownloadList';
import BlockDownloadableResources from 'components/blocks/BlockDownloadableResources';
import BlockFeaturedArticle from 'components/blocks/BlockFeaturedArticle';
import BlockFlipDeck from 'components/blocks/BlockFlipDeck';
import BlockFoodServiceContactForm from 'components/blocks/BlockFoodServiceContactForm';
import BlockFoodServiceProductList from 'components/blocks/BlockFoodServiceProductList';
import BlockFullWidthMedia from 'components/blocks/BlockFullWidthMedia';
import BlockGlobal from 'components/blocks/BlockGlobal';
import BlockGrid from 'components/blocks/BlockGrid';
import BlockHeader from 'components/blocks/BlockHeader';
import BlockHero from 'components/blocks/BlockHero';
import BlockHeroSplit from 'components/blocks/BlockHeroSplit';
import BlockHeroTwoColumn from 'components/blocks/BlockHeroTwoColumn';
import BlockHeroVideo from 'components/blocks/BlockHeroVideo';
import BlockHotspot from 'components/blocks/BlockHotspot';
import BlockImage from 'components/blocks/BlockImage';
import BlockInlineList from 'components/blocks/BlockInlineList';
import BlockIssueBriefGoals from 'components/blocks/BlockIssueBriefGoals';
import BlockLinkList from 'components/blocks/BlockLinkList';
import BlockLocation from 'components/blocks/BlockLocation';
import BlockMediaAccordion from 'components/blocks/BlockMediaAccordion';
import BlockNews from 'components/blocks/BlockNews';
import BlockNewsletter from 'components/blocks/BlockNewsletter';
import BlockOverflowCarousel from 'components/blocks/BlockOverflowCarousel';
import BlockPaperForm from 'components/blocks/BlockPaperForm';
import BlockProductHero from 'components/blocks/BlockProductHero';
import BlockProductPairings from 'components/blocks/BlockProductPairings';
import BlockProductPairingsSlides from 'components/blocks/BlockProductPairingsSlides';
import BlockProductSharpnessStory from 'components/blocks/BlockProductSharpnessStory';
import BlockProducts from 'components/blocks/BlockProducts';
import BlockPromotionPod from 'components/blocks/BlockPromotionPod';
import BlockPromotional from 'components/blocks/BlockPromotional';
import BlockRelatedPages from 'components/blocks/BlockRelatedPages';
import BlockRichText from 'components/blocks/BlockRichText';
import BlockSectionHeading from 'components/blocks/BlockSectionHeading';
import BlockSharpnessStory from 'components/blocks/BlockSharpnessStory';
import BlockSharpnessStoryProxy from 'components/blocks/BlockSharpnessStoryProxy';
import BlockSignUpForm from 'components/blocks/BlockSignUpForm';
import BlockSimpleCards from 'components/blocks/BlockSimpleCards';
import BlockSimpleImageParagraph from 'components/blocks/BlockSimpleImageParagraph';
import BlockSimpleProductList from 'components/blocks/BlockSimpleProductList';
import BlockSlideShow from 'components/blocks/BlockSlideShow';
import BlockSmallMediaWithText from 'components/blocks/BlockSmallMediaWithText';
import BlockSubNavCardGroup from 'components/blocks/BlockSubNavCardGroup';
import BlockTestimonial from 'components/blocks/BlockTestimonial';
import BlockTimeline from 'components/blocks/BlockTimeline';
import BlockTwoColumnCarousel from 'components/blocks/BlockTwoColumnCarousel';
import BlockTwoColumnGrid from 'components/blocks/BlockTwoColumnGrid';
import BlockTwoColumnImage from 'components/blocks/BlockTwoColumnImage';
import BlockTwoColumnRichText from 'components/blocks/BlockTwoColumnRichText';
import BlockTwoColumnText from 'components/blocks/BlockTwoColumnText';
import BlockTwoColumnTextAlt from 'components/blocks/BlockTwoColumnTextAlt';
import BlockVideo from 'components/blocks/BlockVideo';
import BlockVideoCarousel from 'components/blocks/BlockVideoCarousel';
import BlockWhereToBuy from 'components/blocks/BlockWhereToBuy';

import styles from './Blocks.module.scss';

const blockMap = {
    BlockAccordion,
    BlockAnchorProductList,
    BlockAudio,
    BlockCallout,
    BlockCardGroup,
    BlockCardGroupSimple,
    BlockCardSwipe,
    BlockCardTrioInline,
    BlockCardTrioSmall,
    BlockCareersGrid: BlockInlineList,
    BlockCarouselHero,
    BlockCarouselShowcase,
    BlockCarouselShowcaseWithCard,
    BlockCategoryDetailIntro,
    BlockCommitmentChart,
    BlockContactForm2,
    BlockCtaGroup,
    BlockDeckSlider,
    BlockDonationForm,
    BlockDownloadList,
    BlockDownloadableResources,
    BlockEyebrow: BlockSectionHeading,
    BlockFeaturedArticle,
    BlockFlipDeck,
    BlockFoodServiceContactForm,
    BlockFoodserviceProducts: BlockFoodServiceProductList,
    BlockFullWidthMedia,
    BlockGlobal,
    BlockGrid,
    BlockHeader,
    BlockHero,
    BlockHeroSplit,
    BlockHeroTwoColumn,
    BlockHeroVideo,
    BlockHotspot,
    BlockImage,
    BlockImageOffsetContent: BlockSlideShow,
    BlockIssueBriefGoals,
    BlockLinkList,
    BlockLocation,
    BlockMediaAccordion,
    BlockNews,
    BlockNewsletter,
    BlockOverflowCarousel,
    BlockPaperform: BlockPaperForm,
    BlockProductHero,
    BlockProductPairings,
    BlockProductPairingsSlides,
    BlockProductSharpnessStory,
    BlockProducts,
    BlockPromotionPod,
    BlockPromotional,
    BlockRelatedPages,
    BlockRichText,
    BlockSectionHeading,
    BlockSharpnessStory,
    BlockSharpnessStoryProxy,
    BlockSignUpForm,
    BlockSimpleCards,
    BlockSimpleImageParagraph,
    BlockSimpleProducts: BlockSimpleProductList,
    BlockSmallMediaWithText,
    BlockSubNavCardGroup,
    BlockTestimonial,
    BlockTimeline,
    BlockTwoColumnCarousel,
    BlockTwoColumnGrid,
    BlockTwoColumnImage,
    BlockTwoColumnRichText,
    BlockTwoColumnText,
    BlockTwoColumnTextAlt,
    BlockVideo,
    BlockVideoCarousel,
    BlockWhereToBuy,
    default: ({ id, type }) => {
        console.warn(`Block type ${type}  not found in block map`);
        return (
            <div className={styles.missing}>
                <div className={styles.inner}>
                    <div>
                        Block type&nbsp;<strong>{type}</strong>&nbsp;not found
                        in block map <br />
                        Entry Id: `{id}`
                    </div>
                </div>
            </div>
        );
    },
};

export const Block = ({ type, id, ...rest }) => {
    const BlockComponent = blockMap[type] || blockMap.default;
    return <BlockComponent {...rest} id={id} type={type} />;
};

Block.propTypes = {
    id: PropTypes.any,
    type: PropTypes.string,
};

export const renderBlocks = (blocksCollection, props) => {
    return blocksCollection.items.map((block, i) => {
        if (!block) {
            return null;
        }

        return (
            <Block
                key={i}
                topLevel={true}
                firstBlock={i === 0}
                lastBlock={i === blocksCollection.items.length - 1}
                id={block.sys.id}
                type={block.__typename}
                {...props}
            />
        );
    });
};
