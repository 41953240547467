import React from 'react';

import { graphRequest } from 'services/contentful';

import { assetFragment } from 'lib/graphql/fragments';
import { mappable } from 'utils';

import BlockContactForm2 from './BlockContactForm2';

const queryBlock = /* GraphQL */ `
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockContactForm2(preview: $preview, id: $id) {
            __typename
            productCategoriesCollection(limit: 50) {
                items {
                    ... on ProductCategory {
                        title
                        slug
                        attributes
                        image {
                            ...Asset
                        }
                        backgroundColor
                        thumbnail {
                            ...Asset
                        }
                        navLabel
                        isNew
                        order
                    }
                }
            }
            socialLinksCollection(limit: 10) {
                items {
                    ... on SocialMediaLink {
                        platform
                        handle
                        url
                    }
                }
            }
            consumerFeedbackInformation {
                ... on ConsumerFeedbackInformation {
                    title
                    retailers
                    plantCodes
                    complaintCategoryRules
                    developmentLastImportUniqueId
                    productionLastImportUniqueId
                }
            }
        }
    }
`;

const queryProducts = /* GraphQL */ `
    ${assetFragment}

    query getProducts($preview: Boolean!, $skip: Int) {
        productCollection(
            limit: 50
            preview: $preview
            skip: $skip
            where: { hideOnContact_not: true, discontinued_not: true }
        ) {
            total
            limit
            __typename
            items {
                __typename
                sys {
                    id
                }
                pageTitle
                displayName
                image {
                    ...Asset
                }
                category {
                    title
                }
                sizes3Collection(limit: 20) {
                    items {
                        size
                        sku2
                        packagingCodeExample {
                            title
                            illustration {
                                ...Asset
                            }
                            codeExamples
                            codeInformation
                        }
                        itemNumber
                        flavor
                    }
                }
                attributes
                allergens
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockContactForm2 },
        },
    } = await graphRequest({
        query: queryBlock,
        variables: { id },
    });
    return blockContactForm2;
}

async function fetchProducts(skip) {
    const {
        body: {
            data: { productCollection },
        },
    } = await graphRequest({
        query: queryProducts,
        variables: { skip },
    });
    return productCollection;
}

const BlockContactForm2Server = async ({ id }) => {
    const block = await fetchBlock(id);

    const { total, limit } = await fetchProducts();

    const iterations = Math.ceil(total / limit);

    const items = mappable(iterations);

    const products = (
        await Promise.all(
            items.map(async item => {
                return await fetchProducts(item * limit);
            })
        )
    )
        .map(({ items }) => items)
        .flat();

    return (
        <BlockContactForm2
            {...block}
            productCategories={block.productCategoriesCollection.items}
            socialLinks={block.socialLinksCollection.items}
            products={products}
        />
    );
};

export default BlockContactForm2Server;
