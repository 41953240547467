import React from 'react';

import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import Icon from 'components/ui/Icon';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';

import styles from './CardIconStat.module.scss';

export const fragment = /* GraphQL */ `
    fragment CardIconStatFragment on CardIconStat {
        stat
        statIcon {
            title
            id
            icon {
                ...Asset
            }
        }
        copy {
            json
        }
    }
`;

const richTextOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    className={styles.paragraph}
                    baseTheme="bodyMedium"
                    themes={{ large: 'bodyLarge' }}
                >
                    {children}
                </Text>
            );
        },
    },
};

const CardIconStat = ({ statIcon, stat, copy }) => {
    return (
        <div className={styles.cardLinkWrap}>
            <article className={styles.cardIconStat}>
                <div className={styles.inner}>
                    {statIcon?.icon?.url && (
                        <div className={styles.icon}>
                            <Icon src={statIcon.icon.url} />
                        </div>
                    )}
                    {stat && (
                        <Text
                            className={styles.stat}
                            baseTheme="displayMedium"
                            themes={{ medium: 'displayLarge' }}
                            as="h3"
                        >
                            {stat}
                        </Text>
                    )}
                    {copy && (
                        <div className={styles.copy}>
                            <RichText
                                richText={copy}
                                overrides={richTextOverrides}
                            />
                        </div>
                    )}
                </div>
            </article>
        </div>
    );
};

CardIconStat.propTypes = {
    statIcon: PropTypes.object,
    stat: PropTypes.string,
    copy: PropTypes.object,
};

export default CardIconStat;
