import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockCarouselHero from './BlockCarouselHero';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockCarouselHero(preview: $preview, id: $id) {
            __typename
            anchorId
            title
            slidesCollection(limit: 10) {
                items {
                    __typename
                    richHeadline {
                        json
                    }
                    eyebrow
                    image {
                        ...Asset
                    }
                    imageLarge {
                        ...Asset
                    }
                    cta {
                        ... on Cta {
                            ...CTA
                        }
                    }
                    buttonTheme
                }
            }
            timerDuration
            isContinuous
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockCarouselHero },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockCarouselHero;
}

const BlockCarouselHeroServer = async ({ id }) => {
    const block = await fetchBlock(id);

    return (
        <BlockCarouselHero {...block} slides={block.slidesCollection.items} />
    );
};

export default BlockCarouselHeroServer;
