'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import RichText from 'components/ui/RichText';
import Stagger, { Child as StaggerChild } from 'components/ui/Stagger';
import Text from 'components/ui/Text';

import { useTheme } from 'hooks/useTheme';
import { blockRootProps } from 'utils';

import styles from './BlockTwoColumnTextAlt.module.scss';

const richTextOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text.Theme
                    as="p"
                    config={{
                        'makers-reserve': {
                            baseTheme: 'bodyXSmall',
                            themes: { large: 'bodySmall' },
                        },
                        default: {
                            baseTheme: 'bodySmall',
                            themes: { large: 'bodyMedium' },
                        },
                    }}
                    fixWidows
                >
                    {children}
                </Text.Theme>
            );
        },
        [BLOCKS.HEADING_3]: (node, children) => {
            return (
                <Text.Theme
                    as="h3"
                    className={styles.heading}
                    config={{
                        'makers-reserve': {
                            baseTheme: 'parkinsonSmall',
                            themes: { large: 'parkinsonMedium' },
                        },
                        default: {
                            baseTheme: 'headingSmall',
                            themes: { large: 'headingMedium' },
                        },
                    }}
                    fixWidows
                >
                    {children}
                </Text.Theme>
            );
        },
        [BLOCKS.HEADING_4]: (node, children) => {
            return (
                <Text.Theme
                    as="h3"
                    config={{
                        default: {
                            baseTheme: 'bodyMedium',
                            themes: { large: 'bodyLarge' },
                        },
                    }}
                >
                    {children}
                </Text.Theme>
            );
        },
    },
};

const BlockTwoColumnTextAlt = ({
    __typename,
    anchorId,
    eyebrow,
    richHeadline,
    bodyCopy,
    cta,
    className,
    theme,
    backgroundColor,
    flushTop,
    flushBottom,
}) => {
    theme ??= 'default';

    const [globalTheme] = useTheme();
    const isMakersReserve = globalTheme === 'makers-reserve';

    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.root, styles[theme], className, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
                [styles.makersReserve]: isMakersReserve,
            })}
            style={{
                '--background-color': backgroundColor
                    ? `var(--colors-${backgroundColor})`
                    : 'transparent',
            }}
        >
            <div className={styles.inner}>
                {richHeadline && (
                    <div className={styles.column}>
                        <Stagger className={styles.richEyebrow}>
                            {eyebrow && (
                                <StaggerChild order={0}>
                                    <Text.Theme
                                        config={{
                                            'makers-reserve': {
                                                baseTheme: 'labelSmallAlt',
                                                themes: {
                                                    large: 'labelMediumAlt',
                                                },
                                            },
                                            default: {
                                                baseTheme:
                                                    {
                                                        ultrabold: 'labelLarge',
                                                    }[theme] || 'labelSmall',
                                                themes: { large: 'labelLarge' },
                                            },
                                        }}
                                        as="h3"
                                    >
                                        {eyebrow}
                                    </Text.Theme>
                                </StaggerChild>
                            )}
                            <StaggerChild order={1} className={styles.headline}>
                                <Text.Theme
                                    as="h2"
                                    config={{
                                        'makers-reserve': {
                                            baseTheme: 'parkinsonLarge',
                                            themes: {
                                                large: 'parkinsonXLarge',
                                            },
                                        },
                                        default: {
                                            baseTheme:
                                                {
                                                    ultrabold: 'displayXSmall',
                                                }[theme] || 'displayXSmall',
                                            themes: {
                                                small: {
                                                    large: 'headingMedium',
                                                },
                                                large: {
                                                    large: 'displayMedium',
                                                    xxLarge: 'displayXLarge',
                                                },
                                                ultrabold: {
                                                    large: 'displayMedium',
                                                },
                                            }[theme] || {
                                                large: 'displayMedium',
                                            },
                                        },
                                    }}
                                >
                                    {richHeadline.json.content.map(
                                        (line, i) => {
                                            if (line.content[0].value === '') {
                                                return null;
                                            }
                                            return (
                                                <span
                                                    className={styles.line}
                                                    key={i}
                                                >
                                                    {line.content[0].value}
                                                </span>
                                            );
                                        }
                                    )}
                                </Text.Theme>
                            </StaggerChild>
                        </Stagger>
                    </div>
                )}
                <div className={styles.column}>
                    <div className={styles.columnInner}>
                        {bodyCopy && (
                            <RichText
                                richText={bodyCopy}
                                overrides={richTextOverrides}
                            />
                        )}
                        {/* Render from component slot */}
                        {React.isValidElement(cta) && (
                            <div className={styles.buttonWrapper}>{cta}</div>
                        )}
                        {/* Render from model */}
                        {!React.isValidElement(cta) &&
                            (cta?.link || cta?.assetDownloadLink) && (
                                <div className={styles.buttonWrapper}>
                                    <ButtonCTA
                                        className={styles.cta}
                                        {...cta}
                                    />
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </section>
    );
};

BlockTwoColumnTextAlt.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    bodyCopy: PropTypes.object,
    className: PropTypes.string,
    cta: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.shape({
            link: PropTypes.object,
            text: PropTypes.string,
            assetDownloadLink: PropTypes.bool,
        }),
    ]),
    eyebrow: PropTypes.string,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
    richHeadline: PropTypes.object.isRequired,
    theme: PropTypes.string,
    backgroundColor: PropTypes.string,
};

export default BlockTwoColumnTextAlt;
