import React from 'react';

import PropTypes from 'prop-types';

import Grid from 'components/ui/Grid';
import Img from 'components/ui/Img';
import Text from 'components/ui/Text';

import { blockRootProps } from 'utils';

import Goal from './Goal';

import styles from './BlockIssueBriefGoals.module.scss';

const BlockIssueBriefGoals = ({
    __typename,
    anchorId,
    headlineImage,
    headline,
    goals,
    fineprint,
}) => {
    return (
        <div
            {...blockRootProps(anchorId, __typename)}
            className={styles.goalsBlock}
        >
            <Grid className={styles.innerWrapper}>
                <div className={styles.headlineBlock}>
                    {headlineImage && (
                        <div className={styles.imgWrapper}>
                            <Img
                                src={headlineImage?.url}
                                className={styles.img}
                                fallbackImageWidth={100}
                                alt={headline}
                            />
                        </div>
                    )}
                    {headline && (
                        <div className={styles.headlineWrapper}>
                            <Text
                                baseTheme="displaySmall"
                                themes={{ large: 'displayLarge' }}
                                className={styles.headline}
                                as="h3"
                            >
                                {headline}
                            </Text>
                        </div>
                    )}
                </div>
                {goals &&
                    goals.map((goal, id) => {
                        return <Goal {...goal} key={id} />;
                    })}
                {fineprint && (
                    <Text
                        baseTheme="bodyXSmall"
                        hemes={{ large: 'bodyLarge' }}
                        className={styles.finePrint}
                        as="h3"
                    >
                        {fineprint}
                    </Text>
                )}
            </Grid>
        </div>
    );
};

BlockIssueBriefGoals.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    headlineImage: PropTypes.object,
    headline: PropTypes.string,
    fineprint: PropTypes.string,
    goals: PropTypes.array,
};

export default BlockIssueBriefGoals;
