import React from 'react';

import { graphRequest } from 'services/contentful';

import { assetFragment } from 'lib/graphql/fragments';

import BlockRichText from './BlockRichText';

const query = /* GraphQL */ `
    ${assetFragment}

    fragment RichTextLink on Entry {
        __typename
        sys {
            id
        }
        ... on ExternalLink {
            url
            target
        }
        ... on LinkEvent {
            url
        }
        ... on ManualLink {
            as
            href
        }
        ... on News {
            slug
        }
        ... on Page {
            slug
        }
    }
    query getBlock($id: String!, $preview: Boolean!) {
        blockRichText(preview: $preview, id: $id) {
            anchorId
            __typename
            flushTop
            flushBottom
            richText {
                json
                links {
                    entries {
                        inline {
                            ...RichTextLink
                        }
                        hyperlink {
                            ...RichTextLink
                        }
                    }
                    assets {
                        hyperlink {
                            ...Asset
                        }
                    }
                }
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockRichText },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockRichText;
}

const BlockRichTextServer = async ({ id }) => {
    const block = await fetchBlock(id);
    return <BlockRichText {...block} />;
};

export default BlockRichTextServer;
