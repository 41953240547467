'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Img from 'components/ui/Img';
import EntryLink from 'components/ui/Link/EntryLink';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import { useTextAutoContrast } from 'hooks/useTextAutoContrast';
import { getColor } from 'utils';

import styles from './CardProductCategory.module.scss';

const CardProductCategory = ({
    image,
    title,
    link,
    backgroundColor,
    theme,
    fullWidth,
}) => {
    theme ??= 'default';

    const ctaBaseTheme = {
        card: 'labelSmall',
        square: 'headingMedium',
        detail: 'headingMedium',
        trio: 'headingMedium',
    }[theme];

    const ctaThemes = {
        card: { large: 'labelLarge' },
        square: { large: 'headingLarge' },
        trio: { large: 'headingLarge' },
        detail: { large: 'headingLarge' },
    }[theme];

    const isDark = useTextAutoContrast(backgroundColor, 1.5) === 'dark';

    return (
        <EntryLink entry={link} className={styles.cardLink}>
            <article
                style={{
                    '--card-background-color': getColor(backgroundColor),
                }}
                className={cx(styles.card, styles[`card--${theme}`] || null, {
                    [styles['card--dark-text']]: isDark,
                    [styles.fullWidth]: fullWidth,
                })}
            >
                <div className={styles.cardInner}>
                    <div className={styles.image}>
                        <Img
                            src={image?.url}
                            fallbackImageWidth={800}
                            alt={image?.description || ''}
                            width={image?.width}
                            height={image?.height}
                            customSources={[
                                {
                                    breakpoint: 1920,
                                    src: image?.url,
                                    imageWidth: 1920 * 0.75,
                                },
                                {
                                    breakpoint: 1440,
                                    src: image?.url,
                                    imageWidth: 1440 * 0.75,
                                },
                                {
                                    breakpoint: 1024,
                                    src: image?.url,
                                    imageWidth: 1024 * 0.75,
                                },
                                {
                                    breakpoint: 768,
                                    src: image?.url,
                                    imageWidth: 768 * 1.5,
                                },
                                {
                                    src: image?.url,
                                    imageWidth: 800,
                                },
                            ]}
                        />
                    </div>
                    <Text
                        baseTheme={ctaBaseTheme}
                        className={styles.cta}
                        themes={ctaThemes}
                    >
                        {title}
                        <SvgIcon type="circleArrow" className={styles.icon} />
                    </Text>
                </div>
            </article>
        </EntryLink>
    );
};

CardProductCategory.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    link: PropTypes.object,
    backgroundColor: PropTypes.string.isRequired,
    theme: PropTypes.oneOf(['detail', 'square', 'default', 'card', 'trio']),
    fullWidth: PropTypes.bool,
};

export default CardProductCategory;
