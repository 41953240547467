import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockSimpleImageParagraph from './BlockSimpleImageParagraph';

const query = /* GraphQL */ `
    ${ctaFragment}
    ${linkFragment}
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockSimpleImageParagraph(preview: $preview, id: $id) {
            __typename
            anchorId
            image {
                ...Asset
            }
            richCopy {
                json
            }
            cta {
                ... on Cta {
                    ...CTA
                }
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockSimpleImageParagraph },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockSimpleImageParagraph;
}

const BlockSimpleImageParagraphServer = async ({ id }) => {
    const block = await fetchBlock(id);
    return <BlockSimpleImageParagraph {...block} />;
};

export default BlockSimpleImageParagraphServer;
