import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    iconFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockFlipDeck from './BlockFlipDeck';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    ${iconFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockFlipDeck(preview: $preview, id: $id) {
            __typename
            anchorId
            eyebrow
            headline {
                json
            }
            bodyCopy {
                json
            }
            cta {
                ...CTA
            }
            svgIcon {
                ... on Icon {
                    ...Icon
                }
            }
            slidesCollection(limit: 10) {
                items {
                    __typename
                    ... on FlipDeckSlide {
                        image {
                            ...Asset
                        }
                        color
                    }
                }
            }
            timerDuration
            isContinuous
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockFlipDeck },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockFlipDeck;
}

const BlockFlipDeckServer = async ({ id }) => {
    const block = await fetchBlock(id);

    return <BlockFlipDeck {...block} slides={block?.slidesCollection.items} />;
};

export default BlockFlipDeckServer;
