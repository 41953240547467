'use client';

import React, { useEffect } from 'react';
import { useMedia } from 'react-use';

import cx from 'clsx';
import PropTypes from 'prop-types';

import useScrollProgress from 'hooks/useScrollProgress';

import styles from './Parallax.module.scss';

const Parallax = ({ children, className, distance = 200, disabled }) => {
    const [isMounted, setIsMounted] = React.useState(false);

    const isMedium = useMedia('(min-width: 768px)', false);

    // half distance for mobile
    const parallaxDistance = isMedium ? distance : distance / 2;

    const { setSection, setNode } = useScrollProgress({
        distance: parallaxDistance,
        windowHeightBehavior: 'subtract',
    });

    useEffect(() => {
        // fade in after distance shifts
        setIsMounted(true);
    }, []);

    if (disabled) {
        return <div className={className}>{children}</div>;
    }

    return (
        <div
            ref={setSection}
            className={cx(styles.root, className, {
                [styles.isMounted]: isMounted,
            })}
            style={{
                '--distance': `${parallaxDistance}px`,
            }}
        >
            <div ref={setNode} className={styles.inner}>
                {children}
            </div>
        </div>
    );
};

Parallax.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    distance: PropTypes.number,
    disabled: PropTypes.bool,
};

export default Parallax;
