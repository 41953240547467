import React from 'react';

import PropTypes from 'prop-types';

import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import RichText from 'components/ui/RichText';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import styles from './UltraBoldHeading.module.scss';

const UltraBoldHeading = ({ headline, icon, as, baseTheme, ...props }) => {
    as ??= 'h1';
    baseTheme ??= 'displayMedium';

    const richTextOverrides = {
        renderMark: {
            [MARKS.BOLD]: text => <em>{text}</em>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text as="span" className={styles.paragraph}>
                        {children}
                    </Text>
                );
            },
        },
    };

    if (!headline) {
        return null;
    }

    return (
        <Text {...props} as={as} baseTheme={baseTheme} className={styles.root}>
            {icon && <SvgIcon className={styles.icon} type={icon} />}
            <RichText richText={headline} overrides={richTextOverrides} />
        </Text>
    );
};

UltraBoldHeading.propTypes = {
    headline: PropTypes.object,
    icon: PropTypes.string,
    as: PropTypes.string,
    baseTheme: PropTypes.string,
    themes: PropTypes.object,
};

export default UltraBoldHeading;
