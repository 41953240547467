'use client';

import React, { useEffect } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import DecorativeBorder from 'components/globals/DecorativeBorder';

import ButtonCTA from 'components/ui/ButtonCTA';
import Img from 'components/ui/Img';
import Parallax from 'components/ui/Parallax';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';
import UltraBoldHeading from 'components/ui/UltraBoldHeading';
import Video from 'components/ui/Video';

import useMediaQuery from 'hooks/useMediaQuery';
import { useTheme } from 'hooks/useTheme';
import { blockRootProps } from 'utils';

import styles from './BlockFullWidthMedia.module.scss';

const headlineSizes = {
    small: {
        baseTheme: 'displaySmall',
        themes: {
            medium: 'displayLarge',
            large: 'displayXLarge',
            xxxLarge: 'displayXXLarge',
        },
    },
    medium: {
        baseTheme: 'displayMedium',
        themes: {
            medium: 'displayLarge',
            large: 'displayXLarge',
            xxxLarge: 'displayXXLarge',
        },
    },
};

const BlockFullWidthMedia = ({
    __typename,
    anchorId,
    callout,
    className,
    flushBottom,
    flushTop,
    headline,
    richHeadline,
    headlineSize,
    headlineColor,
    headlineIcon,
    cta,
    headlinePortraitAlignment,
    headlineLandscapeAlignment,
    landscapeImage,
    loading,
    portraitImage,
    theme,
    videoTextureMp4,
    videoTextureWebm,
    videoPoster,
    videoTextureMp4Large,
    videoTextureWebmLarge,
    videoPosterLarge,
    svgLockup,
    svgPortraitAlignment,
    svgLandscapeAlignment,
    svgLockupSeoText,
    imageLargeVerticalAlignment,
    description,
    firstBlock,
    eyebrow,
}) => {
    headlineSize ??= 'medium';
    headlineColor ??= 'cream';
    headlineIcon ??= 'morningStar';
    loading ??= 'lazy';
    theme ??= 'Default';

    const isMedium = useMediaQuery('(min-width: 768px)');
    const [globalTheme] = useTheme();

    const isMakersReserve = globalTheme === 'makers-reserve';
    const isSharpnessStory = theme === 'Sharpness Story';

    useEffect(() => {
        if (
            !portraitImage &&
            !landscapeImage &&
            !videoTextureMp4 &&
            !videoTextureWebm
        ) {
            dispatchWarning(
                'No background media provided. Image or video texture required for render.'
            );
        }

        if (
            (portraitImage && !landscapeImage) ||
            (landscapeImage && !portraitImage)
        ) {
            dispatchWarning(
                'Both portrait image and landscape image required for render.'
            );
        }

        if (
            (videoTextureMp4 && !videoTextureWebm) ||
            (videoTextureWebm && !videoTextureMp4)
        ) {
            dispatchWarning(
                'Both mp4 and webm video textures required for render.'
            );
        }
    }, [portraitImage, landscapeImage, videoTextureMp4, videoTextureWebm]);

    const dispatchWarning = warning => {
        if (warning) {
            console.warn(warning);
        }
    };

    const descriptionOverrides = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text.Theme
                        config={{
                            default: {
                                baseTheme: 'headingXSmall',
                                themes: {
                                    medium: 'headingSmall',
                                },
                            },
                        }}
                    >
                        {children}
                    </Text.Theme>
                );
            },
        },
    };

    const richTextOverrides = {
        // This headline will always come in as a paragraph from the CMS.
        // It is not possible to restrict input to H2.

        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <Text.Theme
                        config={{
                            'makers-reserve': {
                                baseTheme: 'parkinsonXLarge',
                                themes: {
                                    large: 'parkinsonXXLarge',
                                },
                            },
                            default: {
                                baseTheme: 'displayMedium',
                                themes: {
                                    large: 'displayXXLarge',
                                },
                            },
                        }}
                        as="h2"
                    >
                        {children}
                    </Text.Theme>
                );
            },
        },
    };

    return (
        <>
            <div
                {...blockRootProps(anchorId, __typename)}
                className={cx(
                    styles.root,
                    styles[`theme--${globalTheme}`],
                    styles[
                        `headlinePortraitAlignment-${headlinePortraitAlignment}`
                    ],
                    styles[
                        `headlineLandscapeAlignment-${headlineLandscapeAlignment}`
                    ],
                    styles[`svgPortraitAlignment-${svgPortraitAlignment}`],
                    styles[`svgLandscapeAlignment-${svgLandscapeAlignment}`],
                    styles[
                        `imageLargeVerticalAlignment-${imageLargeVerticalAlignment}`
                    ],
                    className,
                    {
                        [styles[`theme--midpage`]]: theme === 'Midpage',
                        [styles[`theme--fullHeight`]]: theme === 'Full Height',
                        [styles[`theme--sharpnessStory`]]: isSharpnessStory,
                        [styles[`theme--headlineAlignLeft`]]:
                            theme === 'Headline Align Left',
                    }
                )}
                style={{ color: `var(--colors-${headlineColor})` }}
            >
                {(headline || richHeadline) &&
                    !svgLockup &&
                    globalTheme !== 'makers-reserve' && (
                        <div className={styles.textContainer}>
                            {eyebrow && (
                                <Text
                                    baseTheme="labelSmall"
                                    themes={{ large: 'labelLarge' }}
                                    className={styles.eyebrow}
                                >
                                    {eyebrow}
                                </Text>
                            )}
                            <UltraBoldHeading
                                className={styles.headline}
                                baseTheme={
                                    headlineSizes[headlineSize]?.baseTheme
                                }
                                themes={{
                                    medium: headlineSizes[headlineSize]?.themes
                                        .medium,
                                    large: headlineSizes[headlineSize]?.themes
                                        .large,
                                    xxxLarge:
                                        headlineSizes[headlineSize]?.themes
                                            .xxxLarge,
                                }}
                                icon={
                                    headlineIcon === 'none'
                                        ? null
                                        : headlineIcon
                                }
                                headline={richHeadline}
                            />
                            {description && (
                                <div className={styles.description}>
                                    <RichText
                                        richText={description}
                                        overrides={descriptionOverrides}
                                    />
                                </div>
                            )}
                            {(cta?.link || cta?.assetDownloadLink) && (
                                <div className={styles.buttonWrapper}>
                                    <ButtonCTA {...cta} />
                                </div>
                            )}
                        </div>
                    )}
                {svgLockup?.url && (
                    <div
                        className={styles.svgLockup}
                        style={{
                            '--svg-ratio': svgLockup?.height / svgLockup?.width,
                        }}
                    >
                        <Img src={svgLockup.url} alt="" />
                        {firstBlock && svgLockupSeoText && (
                            <h1 className="sr-only">{svgLockupSeoText}</h1>
                        )}
                        {!firstBlock && svgLockupSeoText && (
                            <h2 className="sr-only">{svgLockupSeoText}</h2>
                        )}
                        {description && (
                            <div className={styles.description}>
                                <RichText
                                    richText={description}
                                    overrides={descriptionOverrides}
                                />
                            </div>
                        )}
                        {(cta?.link || cta?.assetDownloadLink) && (
                            <div className={styles.buttonWrapper}>
                                <ButtonCTA {...cta} />
                            </div>
                        )}
                    </div>
                )}
                {isMakersReserve && (
                    <div className={cx(styles.headline)}>
                        {richHeadline && (
                            <RichText
                                richText={richHeadline}
                                overrides={richTextOverrides}
                            />
                        )}
                    </div>
                )}
                <Parallax className={styles.aspect} disabled={firstBlock}>
                    {portraitImage?.url &&
                        landscapeImage?.url &&
                        !videoTextureMp4 &&
                        !videoTextureWebm && (
                            <Img
                                className={styles.image}
                                src={portraitImage.url}
                                fallbackImageWidth={768}
                                loading={loading}
                                alt={portraitImage.description || ''}
                                priority={firstBlock}
                                customSources={[
                                    {
                                        breakpoint: 1920,
                                        src: landscapeImage.url,
                                        imageWidth: 1920 * 1.5,
                                    },
                                    {
                                        breakpoint: 1440,
                                        src: landscapeImage.url,
                                        imageWidth: 1440 * 1.5,
                                    },
                                    {
                                        breakpoint: 1024,
                                        src: landscapeImage.url,
                                        imageWidth: 1024 * 1.5,
                                    },
                                    {
                                        breakpoint: 768,
                                        src: landscapeImage.url,
                                        imageWidth: 768 * 1.5,
                                    },
                                    {
                                        src: portraitImage.url,
                                        imageWidth: 768,
                                    },
                                ]}
                            />
                        )}
                    {videoTextureMp4?.url && videoTextureWebm?.url && (
                        <Video
                            className={cx(styles.videoSmall, {
                                [styles['large-hide']]:
                                    videoTextureMp4Large?.url,
                            })}
                            poster={videoPoster?.url}
                            sources={[
                                videoTextureWebm.url,
                                videoTextureMp4.url,
                            ]}
                        />
                    )}
                    {videoTextureMp4Large?.url &&
                        videoTextureWebmLarge?.url && (
                            <Video
                                className={cx(styles.videoLarge, {
                                    [styles['small-hide']]:
                                        videoTextureMp4?.url,
                                })}
                                poster={videoPosterLarge?.url}
                                sources={[
                                    videoTextureWebmLarge.url,
                                    videoTextureMp4Large.url,
                                ]}
                            />
                        )}
                    {isMakersReserve && !isSharpnessStory && (
                        <div
                            className={styles.makersReserveBorder}
                            role="presentation"
                        >
                            <div className={styles.makersReserveBorderInner}>
                                <DecorativeBorder
                                    borderWidth={isMedium ? 2 : 1}
                                />
                            </div>
                        </div>
                    )}
                </Parallax>
            </div>
            {callout && (
                <div
                    className={cx(styles.calloutWrapper, {
                        [styles.flushBottom]: flushBottom,
                        [styles.flushTop]: flushTop,
                    })}
                >
                    {callout}
                </div>
            )}
        </>
    );
};

BlockFullWidthMedia.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    callout: PropTypes.object,
    className: PropTypes.string,
    cta: PropTypes.object,
    description: PropTypes.object,
    eyebrow: PropTypes.any,
    firstBlock: PropTypes.bool,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
    headline: PropTypes.string,
    headlineColor: PropTypes.string,
    headlineIcon: PropTypes.string,
    headlineLandscapeAlignment: PropTypes.string,
    headlinePortraitAlignment: PropTypes.string,
    headlineSize: PropTypes.string,
    imageLargeVerticalAlignment: PropTypes.string,
    landscapeImage: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }),
    loading: PropTypes.string,
    portraitImage: PropTypes.shape({
        description: PropTypes.string,
        url: PropTypes.string.isRequired,
    }),
    richHeadline: PropTypes.object,
    svgLandscapeAlignment: PropTypes.string,
    svgLockup: PropTypes.shape({
        url: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
    }),
    svgPortraitAlignment: PropTypes.string,
    svgLockupSeoText: PropTypes.string,
    theme: PropTypes.oneOf([
        'Default',
        'Midpage',
        'Full Height',
        'Headline Align Left',
        "Maker's Reserve",
        "Maker's Reserve w/ Border",
    ]),
    videoPoster: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }),
    videoPosterLarge: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }),
    videoTextureMp4: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }),
    videoTextureMp4Large: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }),
    videoTextureWebm: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }),
    videoTextureWebmLarge: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }),
};

export default BlockFullWidthMedia;
