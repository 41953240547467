import React from 'react';

import PropTypes from 'prop-types';

import CarouselProvider from 'components/ui/CarouselProvider';

import { blockRootProps } from 'utils';

import CarouselShowcase from './CarouselShowcase';

const BlockCarouselShowcase = ({
    __typename,
    anchorId,
    slides,
    timerDuration,
    isContinuous,
}) => {
    timerDuration ??= 7;

    return (
        <div {...blockRootProps(anchorId, __typename)}>
            <CarouselProvider
                slides={slides}
                timerDuration={timerDuration}
                isContinuous={isContinuous}
            >
                <CarouselShowcase />
            </CarouselProvider>
        </div>
    );
};

BlockCarouselShowcase.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    className: PropTypes.string,
    slides: PropTypes.array,
    timerDuration: PropTypes.number,
    isContinuous: PropTypes.bool,
};

export default BlockCarouselShowcase;
