import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import SocialLink from 'components/globals/Footer/SocialLink';

import Button from 'components/ui/Button';
import Text from 'components/ui/Text';

import styles from './CTA.module.scss';

const CTA = ({ headline, theme, socialLinks }) => {
    const renderContent = () => {
        return theme === 'compliment' ? (
            <div className={styles.links}>
                <Text
                    className={styles.label}
                    baseTheme="labelSmall"
                    themes={{ medium: 'labelMedium' }}
                >
                    Share the love on social
                </Text>
                <div className={styles.inner}>
                    {socialLinks &&
                        socialLinks.length &&
                        socialLinks.map((data, i) => {
                            return (
                                <SocialLink
                                    key={i}
                                    href={data.url}
                                    platform={data.platform}
                                />
                            );
                        })}
                </div>
            </div>
        ) : theme === 'visitUs' ? (
            <Button
                theme="filledBlueHoverWhite-ultrabold"
                className={styles.button}
                href="/visit-us"
            >
                Visit Us
            </Button>
        ) : null;
    };

    return (
        <div className={cx(styles.root, styles[theme])}>
            <Text
                className={styles.heading}
                baseTheme="headingSmall"
                themes={{ medium: 'headingLarge' }}
            >
                {headline}
            </Text>
            {renderContent()}
        </div>
    );
};

CTA.propTypes = {
    headline: PropTypes.string,
    socialLinks: PropTypes.array,
    theme: PropTypes.string,
};

export default CTA;
