import React from 'react';

import { graphRequest } from 'services/contentful';

import {
    assetFragment,
    ctaFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockDownloadList from './BlockDownloadList';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockDownloadList(preview: $preview, id: $id) {
            __typename
            anchorId
            headline
            ctAsCollection {
                items {
                    ... on Cta {
                        ...CTA
                    }
                }
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockDownloadList },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockDownloadList;
}

const BlockDownloadListServer = async ({ id, firstBlock }) => {
    const block = await fetchBlock(id);

    return (
        <BlockDownloadList
            {...block}
            downloads={block.ctAsCollection?.items}
            firstBlock={firstBlock}
        />
    );
};

export default BlockDownloadListServer;
