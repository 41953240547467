'use client';

import React from 'react';

import cx from 'clsx';
import PropTypes from 'prop-types';

import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import Legend from './Legend';

import styles from './BlockSubNavCardGroupSubNav.module.scss';

const BlockSubNavCardGroupSubNav = ({ id, sections, activeIndex, legend }) => {
    const handleClick = index => {
        document
            .getElementById(`${id}-${index}`)
            .scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <ul className={styles.root}>
            {sections.map((section, i) => (
                <li key={i}>
                    <button
                        className={cx(styles.item, {
                            [styles.isActive]: i === activeIndex,
                        })}
                        onClick={() => {
                            handleClick(i);
                        }}
                    >
                        <Text
                            baseTheme="labelMedium"
                            themes={{
                                large: 'labelLarge',
                            }}
                        >
                            {section.title}
                        </Text>
                        <SvgIcon
                            className={styles.itemIcon}
                            type="chevronRight"
                        />
                    </button>
                </li>
            ))}

            {legend && (
                <Legend
                    items={legend.legendItemsCollection.items}
                    {...legend}
                />
            )}
        </ul>
    );
};

BlockSubNavCardGroupSubNav.propTypes = {
    id: PropTypes.string,
    sections: PropTypes.array,
    activeIndex: PropTypes.number,
    legend: PropTypes.object,
};

export default BlockSubNavCardGroupSubNav;
